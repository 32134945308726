import { useEffect, useState } from 'react';
import services from '../../../services';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../../utils/seo.json';
import { appealProductsCodes, endDateOfAppealCampaign, startDateOfAppealCampaign } from '../../MATWAppeal/details';
import limbsImg from '../../../images/landings/palestine/big-Limbs_of_Hope_Campaign_Website_Image.jpg';
import refugeeImg from '../../../images/others/help-rebuild-lives.png';
import aboutMatwImg from '../../../images/others/about-matw.png';
import Appeals from './appeals';
import AppealImpact from '../../MATWAppeal/components/appealImpact';
import { CustomButton } from '../../../components/general';
import { useRef } from 'react';
import NewHelmetLayout from '../../../components/general/newHelmetLayout';

const AllAppeals = () => {
  const [products, setProducts] = useState([0, 0, 0]);
  const selectedCurrency = useSelector(selectSelectedCurrency);
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const appealsRef = useRef(null);
  /* ------------------------------ Start methods ----------------------------- */

  const getProducts = async _currency => {
    let req = {
      products: appealProductsCodes,
      currency: _currency || 'AUD',
      start_date: startDateOfAppealCampaign,
      end_date: endDateOfAppealCampaign,
    };
    let tempAppealProducts = [];
    let tempPalestineTotalRaised = 0;
    let tempPalestineTotalSupporters = 0;
    let tempEgyptianTotalRaised = 0;
    let tempEgyptianTotalSupporters = 0;
    let tempLimbsTotalRaised = 0;
    let tempLimbsTotalSupporters = 0;
    let tempPalestineEmergency = {
      product_id: 592,
      product_category_id: 65,
      product_name: 'Gaza Can’t Wait!',
      product_creator: 'MA-PAL-GL-1-001',
      product_description:
        'For almost a year, too many people have suffered for far too long due to the devastation the conflict in Palestine has left in its wake. The lives of children and their families continue to be uprooted, leaving many orphaned with no one to protect them.',
      product_image_link: 'https://cdn.matwproject.org/images/products/large-palestine+emergency.jpg',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: _currency,
      goal: 500000,
      campaign: 'palestine',
      link: 'palestine-emergency',
    };
    let tempPalestineRefuggeSettlement = {
      product_id: 601,
      product_category_id: 66,
      product_name: 'Help Rebuild Lives for Palestinians escaping conflict',
      product_creator: 'MA-PAL-GL-3-001',
      product_description:
        'Imagine losing your belongings, your home, loved ones - everything you’ve ever known. That is the reality for more than 100,000 Palestinians who have fled to neighbouring countries.',
      product_image_link: refugeeImg,
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: _currency,
      goal: 500000,
      campaign: 'egyptian',
      link: 'palestine-refugee-resettlement',
    };
    let tempLimbsOfHope = {
      product_id: 638,
      product_category_id: 73,
      product_name: 'GAZA: Limbs of Hope',
      product_creator: 'EMR-PAL-MASU-003',
      product_description:
        'Your generosity can transform a life. By donating to our Prosthetic Limb Fundraise, you’re helping to provide a prosthetic limb to someone in need.',
      product_image_link: limbsImg,
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: _currency,
      goal: 1000000,
      campaign: 'limbs-of-hope',
      link: 'limbs-of-hope',
    };
    try {
      const response = await services.getLatestOrdersV2(req);
      // setIsLoadedLatestOrders(true)
      let allOrders = response.data;
      allOrders.map(item => {
        if (item.product_category_id === 65) {
          tempPalestineTotalRaised += item.total_sale;
          tempPalestineTotalSupporters += item.total_supporters;
        }
        if (item.product_category_id === 66) {
          tempEgyptianTotalRaised += item.total_sale;
          tempEgyptianTotalSupporters += item.total_supporters;
        }
        if (item.product_category_id === 73) {
          tempLimbsTotalRaised += item.total_sale;
          tempLimbsTotalSupporters += item.total_supporters;
        }
        return item;
      });
      tempPalestineEmergency.total_sale = tempPalestineTotalRaised;
      tempPalestineEmergency.total_supporters = tempPalestineTotalSupporters;
      tempPalestineRefuggeSettlement.total_sale = tempEgyptianTotalRaised;
      tempPalestineRefuggeSettlement.total_supporters = tempEgyptianTotalSupporters;
      tempLimbsOfHope.total_sale = tempLimbsTotalRaised;
      tempLimbsOfHope.total_supporters = tempLimbsTotalSupporters;
      tempAppealProducts[0] = tempPalestineEmergency;
      tempAppealProducts[1] = tempLimbsOfHope;
      tempAppealProducts[2] = tempPalestineRefuggeSettlement;
      setProducts(tempAppealProducts);
    } catch (error) {
      console.error('error========', error);
    }
  };

  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  // useEffect(() => {
  //   window.scroll(0, 0);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    getProducts(selectedCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrency]);
  /* -------------------------------------------------------------------------- */

  return (
    <NewHelmetLayout className="!bg-white">
      {seo && (
        <Helmet>
          <title>{`${seo['appeals']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
          <meta name="description" content={`${seo['appeals']['description']}`} />
        
          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
      )}
      <Appeals products={products} appealsRef={appealsRef} />
      <div className="py-[30px] lg:py-[50px]">
        <AppealImpact usedOnTab buttonClassname="bg-[rgb(0,163,218)]" />
      </div>
      <section className="max-w-[1280px] w-full mx-auto px-5 lg:px-6">
        <h2 className="text-center text-[30px] lg:text-[40px] leading-[32px] lg:leading-[42px] font-bold text-DarkerBlue font-brandingBold mb-[30px] lg:mb-[50px]">
          About MATW Appeals
        </h2>
        <div className="grid lg:grid-cols-2 gap-y-8  gap-x-8 items-center">
          <img src={aboutMatwImg} alt="About Matw" className="w-full" />
          <div className="">
            <p className="text-[24px]   leading-normal font-brandingMedium text-[#78716C]">
              MATW Appeals is your gateway to making a lasting difference in the lives of those most in need. Each appeal represents a direct
              opportunity to provide essential relief such as food, water, shelter, and medical care to families facing unimaginable hardship. Whether
              it’s helping orphans, building water wells, or supporting emergency relief efforts, your contribution through MATW Appeals goes directly
              towards rebuilding lives and restoring dignity. <br /> Join us in delivering hope, rebuilding communities, and offering a future to
              those who need it most. Together, we can create meaningful change.
            </p>
            <CustomButton
              onClicked={() => {
                appealsRef.current.scrollIntoView({ behavior: 'smooth' });
              }}
              title="Donate Now"
              className="!w-fit !mt-5 lg:!mt-[30px]"
            />
          </div>
        </div>
      </section>
    </NewHelmetLayout>
  );
};

export default AllAppeals;
