// // utils/ramadanUtils.js
// import HijriDate from 'hijri-date/lib/safe';
// import { DateTime } from 'luxon';

// /**
//  * Calculates the Gregorian date for 1st Ramadan based on the selected time zone.
//  * @param {number} gregorianYear - The Gregorian year.
//  * @param {string} timeZone - The user's selected time zone.
//  * @returns {string} ISO date string representing the start of Ramadan.
//  */
// export const calculateRamadanStartDate = (gregorianYear, timeZone) => {
//   // Ramadan is the 9th month in the Hijri calendar
//   const ramadanHijriDate = new HijriDate(gregorianYear, 9, 1);

//   // Convert Hijri date to Gregorian date using toGregorian()
//   const gregorianDate = ramadanHijriDate.toGregorian(); // Correct method

//   // Adjust for user's time zone using Luxon
//   const ramadanStart = DateTime.fromJSDate(gregorianDate, { zone: timeZone }).startOf('day');

//   return ramadanStart.toISODate(); // Returns ISO date string
// };


// utils/ramadanUtils.js
import { toGregorian } from 'hijri-converter';
import { DateTime } from 'luxon';

/**
 * Pads single-digit numbers with a leading zero.
 * @param {number} number - The number to pad.
 * @returns {string} - The padded number as a string.
 */
const padZero = (number) => {
  return number < 10 ? `0${number}` : number.toString();
};

/**
 * Calculates the Gregorian date for 1st Ramadan based on the selected time zone.
 * @param {number} gregorianYear - The Gregorian year.
 * @param {string} timeZone - The user's selected time zone.
 * @returns {string|null} - ISO date string representing the start of Ramadan or null if not found.
 */
export const calculateRamadanStartDate = (gregorianYear, timeZone) => {
   // Approximate Hijri year
   const hijriYearApprox = gregorianYear - 579; // Approximation: 1 AH ≈ 622 CE
  //  console.log('Hijri Year Approximation:', hijriYearApprox);

   // Ramadan is the 9th month in the Hijri calendar
   const ramadanMonth = 9;
   const ramadanDay = 1;

   // Convert Hijri date to Gregorian date
   const gregorianDateObj = toGregorian(hijriYearApprox, ramadanMonth, ramadanDay);
   const gregDateStr = `${gregorianDateObj.gy}-${padZero(gregorianDateObj.gm)}-${padZero(gregorianDateObj.gd)}`;
  //  console.log('Converted Gregorian Date:', gregDateStr);

   // Adjust for user's time zone using Luxon and subtract one day
   let ramadanStartDateTime = DateTime.fromISO(gregDateStr, { zone: timeZone }).startOf('day').minus({ days: 1 });
   let ramadanStart = ramadanStartDateTime.toISODate();
  //  console.log('Ramadan Start Date (Adjusted):', ramadanStart);

   // Verify that the Gregorian year matches
   const ramadanStartYear = ramadanStartDateTime.year;
  //  console.log('Ramadan Start Year:', ramadanStartYear);

   if (ramadanStartYear === gregorianYear) {
     return ramadanStart;
   } else {
    // Ramadan 1 might fall in the next Hijri year
    const nextHijriYear = hijriYearApprox + 1;
    // console.log('Checking next Hijri Year:', nextHijriYear);
    const gregorianDateNext = toGregorian(nextHijriYear, ramadanMonth, ramadanDay);
    const gregDateNextStr = `${gregorianDateNext.gy}-${padZero(gregorianDateNext.gm)}-${padZero(gregorianDateNext.gd)}`;
    // console.log('Converted Gregorian Date for Next Hijri Year:', gregDateNextStr);

    // Adjust for user's time zone using Luxon and subtract one day
    ramadanStartDateTime = DateTime.fromISO(gregDateNextStr, { zone: timeZone }).startOf('day').minus({ days: 1 });
    ramadanStart = ramadanStartDateTime.toISODate();
    // console.log('Ramadan Start Date Next Hijri Year (Adjusted):', ramadanStart);

    const ramadanStartNextYear = ramadanStartDateTime.year;
    // console.log('Ramadan Start Year Next Hijri Year:', ramadanStartNextYear);

    if (ramadanStartNextYear === gregorianYear) {
      return ramadanStart;
    }
  }

  console.log('Unable to determine Ramadan Start Date.');
  return null;
};



// utils/ramadanUtils.js

/**
 * Returns the fixed Gregorian date for the start of Ramadan.
 * @returns {string} - ISO date string representing the start of Ramadan.
 */
export const getFixedRamadanStartDate = () => {
    return '2025-02-27'; // February 27, 2025
  };

