import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-new";
import {
  selectSelectedCountryCode,
  selectSelectedCurrency,
} from "../../store/user";
import services from "../../services";
import ProductBox from "../../components/general/productBox";
import WidgetHome from "../../components/widgets/widgetHomeOrdered";
import Skeleton from "react-loading-skeleton";
import SeoData from "../../utils/seo.json";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { generateLinkWithLang } from "../../utils/linkGenerator";
import { useTranslation } from "react-i18next";
import NewHelmetLayout from "../../components/general/newHelmetLayout";
import ProductsTabVertical from "../../components/general/productsTabVertical";
import { ProductBoxCart } from "../home/components/productBoxCart";
import useCart from "../../hooks/cart/useCart";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import CustomButton from "../../components/general/button";
import sortProductsByCurrency from "../../utils/productSortHandler";

const HealthLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  const [products, setProducts] = useState({
    name: "",
    products: [0, 0, 0, 0],
  });
  const [isLoading, setIsLoading] = useState(true);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [meta, setMeta] = useState({
    parent: "",
    child: "",
  });
  const projectNoorCategor = {
    image_link:
      "https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-health-eye-restoration-for-1-person.jpg",
    name: "Project Noor",
    page_title: "Project Noor",
    description:
      "Project Noor aims to improve the quality of life for visually impaired individuals.",
    id: 1,
  };
  const { i18n } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */
  const [showMore, setShowMore] = useState(false);
  const { handleCheckProduct, handleAddToGiving, handleRemoveProduct } =
    useCart();

  /* ------------------------------ Start methods ----------------------------- */
  const handleHeight = () => {
    setShowMore(!showMore);
  };

  const toProperCase = (text) => {
    return text
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getProductsByName = async () => {
    setIsLoading(true);
    try {
      const response = await services.categoryProductsByName("health");
      if (Object.keys(response.data).length === 0) {
        navigate(generateLinkWithLang("/", lang));
      } else {
        response.data.tile_image =
          "/images/landings/sadaqah-jariyah/health-banner.webp";
        let temp = response.data;
        temp.products.map((item) => {
          if (item.name === "Treatment For CP Child") {
            // item.description = 'Donate to help provide prosthetics to disabled Bangladeshis.'
          }
          if (item.name === "Disability Support") {
            // item.description = 'Empower lives: Donate for prosthetics and wheelchairs.'
          }
          if (item.name === "Emergency Medical Aid") {
            // item.description = 'Save lives in emergencies: Your donation supports vital medical care.'
          }
          return item;
        });
        let tempProductFirstTwo = temp.products
          .filter(
            (item, index) =>
              index > 2 &&
              Number(item.status) === 1 &&
              (item.id === 394 || item.id === 395),
          )
          .map((item) => item);

        let tempProdsRest = temp.products
          .filter(
            (item, index) =>
              index > 2 &&
              Number(item.status) === 1 &&
              item.id !== 394 &&
              item.id !== 395,
          )
          .map((item) => item);

        let tempProducts = [...tempProductFirstTwo, ...tempProdsRest];
        temp.products = tempProducts;
        setProducts(temp);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("error========", error);
      setIsLoading(false);
    }
  };
  const handleParagraph = (text) => {
    let tempText = [];
    if (text) {
      tempText = text.split(".");
      return tempText.map((txt, index) => {
        let temp =
          txt === "" ? (
            ""
          ) : (
            <p key={`p${index}`}>
              {txt}.<br />
            </p>
          );
        return temp;
      });
    }
  };
  const camelCaseformat = (val) => {
    let array = val.split("");
    for (let index = 0; index < array.length; index++) {
      if (array[index] === "-") {
        array[index + 1] = array[index + 1].toUpperCase();
      }
    }
    return array.join("").replaceAll("-", "");
  };
  const RawHTML = ({ children, className = "" }) => (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, "") }}
    />
  );
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore
      ? setSelectedCurrency(selectedCurrencyStore)
      : setSelectedCurrency("AUD");
  }, [selectedCurrencyStore]);
  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    let pathNames = window.location.pathname.split("/");
    if (pathNames.length > 2) {
      let temp = pathNames[2].split("-");
      temp = temp.join(" ");
      getProductsByName(temp);
      let p = camelCaseformat(pathNames[1]);
      let c = camelCaseformat(pathNames[2]);
      setMeta({
        ...meta,
        parent: p,
        child: c,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);
  /* -------------------------------------------------------------------------- */

  return (
    <NewHelmetLayout>
      {seo &&
        seo.hasOwnProperty(meta?.parent) &&
        seo[meta?.parent].hasOwnProperty(meta?.child) && (
          <Helmet>
            <title>{`${seo[meta.parent][meta.child]["title"]}`}</title>
            <link
              rel="canonical"
              href={`${window.location.protocol + "//matwproject.org"}${window.location.pathname}`}
            />
            <meta
              name="description"
              content={`${seo[meta.parent][meta.child]["description"]}`}
            />
          
          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
        )}
      {/* <div className="flex md:w-full md:h-[120px] lg:w-full lg:h-[160px] xl:w-full xl:h-[200px] 2xl:w-full 2xl:h-[250px]"> */}
      {!isLoading && (
        <img
          src={products.tile_image}
          alt={products.name}
          className="w-full"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "/images/general/logo-replace.png";
            currentTarget.className = "max-h-[150px] mx-auto";
          }}
        />
      )}
      {/* </div> */}
      <WidgetHome selectedCurrency={(e) => setSelectedCurrency(e.value)} />
      {/* <ProductsTabVertical
        sections={[
              {
                products: products.products,
                title: 'Health',
              }
            ]
          }
      /> */}
      <div className="md:container w-full mx-auto px-4 md:px-0 mt-5">
        <div className="grid grid-cols-12 px-3- md:px-4-">
          <div className="col-span-12 text-center text-6xl text-[#253b7e] flex justify-center items-center py-2- md:py-8- font-['Gotcha'] h-[125px]">
            {!isLoading ? products.name : <Skeleton height={80} />}
          </div>
          <div className="col-start-1 col-span-12 rounded-md">
            <div className="flex flex-wrap gap-3 md:gap-4 justify-center items-start w-full">
              {sortProductsByCurrency(products.products, selectedCurrencyStore.toLowerCase()).map((item, index) => {
                return (
                  <div
                    key={`products.products${index}`}
                    className="w-full sm:max-w-[300px] sm:basis-[calc(50%-8px)]"
                  >
                    <div className="hidden sm:block">
                      <ProductBox product={item} currency={selectedCurrency} />
                    </div>
                    <div className="sm:hidden">
                      <ProductBoxCart
                        key={index}
                        product={item}
                        onCheck={handleCheckProduct}
                        onAdd={handleAddToGiving}
                        onRemove={handleRemoveProduct}
                        currency={selectedCurrency}
                      />
                    </div>
                  </div>
                );
              })}

              <div className="w-full sm:basis-1/2 xl:basis-1/4 sm:max-w-[300px]">
                <div className="sm:hidden">
                  <div className="w-full gap-2 md:gap-4 grid grid-cols-[auto,1fr] shadow-[0_7px_29px_0px_rgba(100,100,111,0.2)] bg-white">
                    <div className="overflow-hidden tooltip img-hover-zoom w-[84px] md:w-[108px] aspect-square">
                      {projectNoorCategor ? (
                        projectNoorCategor.image_link ? (
                          <LazyLoadImage
                            alt={projectNoorCategor.name}
                            effect="blur"
                            width={`100%`}
                            delayTime={500}
                            className="cursor-pointer w-full h-full"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src =
                                "/images/general/logo-replace.png";
                            }}
                            src={projectNoorCategor.image_link} // use normal <img> attributes as props
                          />
                        ) : (
                          <img
                            className="w-full h-full object-cover"
                            src="/images/general/logo-replace.png"
                            alt=""
                          />
                        )
                      ) : (
                        <Skeleton height={344} />
                      )}
                    </div>
                    <div
                      className={`flex flex-col justify-between md:items-baseline gap-1 sm:gap-3 p-2 md:p-3 pl-0`}
                    >
                      <div
                        className={`flex gap-1 flex-col items-center overflow-hidden transition-all duration-300 ${showMore ? "h-auto" : "max-h-[24px]"}`}
                      >
                        <div className="w-full flex gap-2 items-center">
                          <p
                            className={`text-[#00a3da] cursor-pointer md:text-lg font-semibold md:font-bold ${!showMore ? "line-clamp-1" : ""}`}
                          >
                            <span className="sm:hidden">
                              {projectNoorCategor ? (
                                toProperCase(projectNoorCategor.name)
                              ) : (
                                <Skeleton width={70} height={12} count={1} />
                              )}
                            </span>
                            <span className="hidden sm:block">
                              {projectNoorCategor ? (
                                projectNoorCategor.name
                              ) : (
                                <Skeleton width={200} height={12} count={1} />
                              )}
                            </span>
                          </p>
                          <span className="block basis-[18px]">
                            <ChevronDownIcon
                              onClick={handleHeight}
                              className={`md:block w-[18px] h-[18px] text-[#00a3da] transition-all duration-300 cursor-pointer ${
                                showMore ? "transform rotate-180" : ""
                              }`}
                            />
                          </span>
                        </div>
                        <p
                          className={`text-stone-500 text-sm overflow-hidden transition-all duration-200 ${showMore ? "h-auto mb-2" : "max-h-0 mb-0"}`}
                        >
                          {projectNoorCategor && projectNoorCategor.description}
                        </p>
                      </div>

                      <div className="flex justify-end">
                        {projectNoorCategor ? (
                          <CustomButton
                            className="!rounded-none !text-xs !h-[32px] !px-3 mt-2"
                            onClick={() => {
                              navigate("/sadaqah-jariyah/project-noor");
                            }}
                            title="View Appeals"
                          />
                        ) : (
                          <Skeleton height={43} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden sm:block w-full shadow-lg bg-white">
                  <div className="overflow-hidden img-hover-zoom w-full sm:min-h-[169px] md:min-h-[210px] lg:min-h-[301px] xl:min-h-[280px] 2xl:min-h-[344px] tooltip [&>span]:!block">
                    {projectNoorCategor ? (
                      projectNoorCategor.image_link ? (
                        <LazyLoadImage
                          alt={projectNoorCategor.name}
                          effect="blur"
                          width={`100%`}
                          delayTime={500}
                          className="cursor-pointer block"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "/images/general/logo-replace.png";
                          }}
                          src={projectNoorCategor.image_link} // use normal <img> attributes as props
                        />
                      ) : (
                        <img
                          className="w-full"
                          src="/images/general/logo-replace.png"
                          alt=""
                        />
                      )
                    ) : (
                      <Skeleton height={344} />
                    )}
                  </div>

                  <h3 className="text-[#00a3da] cursor-pointer flex justify-center items-center text-sm sm:text-md md:text-md lg:text-lg xl:text-xl text-center px-1 mt-3 min-h-[56px] uppercase font-bold">
                    {projectNoorCategor ? (
                      projectNoorCategor.name
                    ) : (
                      <Skeleton height={56} />
                    )}
                  </h3>
                  <div className="relative text-center">
                    {projectNoorCategor ? (
                      <>
                        <div
                          className={`transition-all duration-300 text-center my-2 relative overflow-hidden ${
                            showMore
                              ? "h-auto mb-4"
                              : "max-h-[65px] md:max-h-[78px]"
                          }`}
                        >
                          <p
                            className={`text-stone-500 text-sm sm:text-sm md:text-base lg:text-base overflow-hidden transition-all duration-200 min-h-[65px] md:min-h-[79px] px-2 md:px-3`}
                          >
                            {projectNoorCategor.description}
                          </p>
                        </div>
                        {
                          // Chevron icon
                          <ChevronDownIcon
                            onClick={handleHeight}
                            className={` md:block w-6 h-6 text-[#00a3da] transition-all duration-300 cursor-pointer mx-auto ${
                              showMore ? "transform rotate-180" : ""
                            }`}
                          />
                        }
                      </>
                    ) : (
                      <Skeleton count={4} />
                    )}
                  </div>
                  <div className="flex justify-center pb-3 md:pb-5 mt-[21px]">
                    {projectNoorCategor ? (
                      <CustomButton
                        className="!rounded-none"
                        onClick={() => {
                          navigate("/sadaqah-jariyah/project-noor");
                        }}
                        title="View Appeals"
                      />
                    ) : (
                      <Skeleton height={43} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-span-12 rounded-xl mt-6 lg:min-h-[500px]- bg-white p-6 lg:p-12 text-center shadow-lg ${
                products &&
                (products.name === "Food Packs") |
                  (products.name === "Cow Share")
                  ? "hidden"
                  : ""
              }`}
            >
              <h1 className="text-[#00a3da] text-4xl mb-4">
                {products ? products.page_title : <Skeleton />}
              </h1>
              <p className="text-[#777] text-xl mb-4">
                {products ? products.page_subtitle : <Skeleton />}
              </p>
              <div className="text-[#999] text-md">
                {products ? (
                  products.page_description &&
                  products.page_description.includes("<") ? (
                    <RawHTML>{products.page_description}</RawHTML>
                  ) : (
                    handleParagraph(products.page_description)
                  )
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="col-span-12 rounded-xl mt-6 bg-white p-2 lg:p-12 text-center shadow-lg">
              <div className="col-span-12 h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                <iframe
                  className="video"
                  width={"100%"}
                  height={"100%"}
                  title={"youtube"}
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={`https://youtube.com/embed/Z3DtUoz0scc?autoplay=0`}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NewHelmetLayout>
  );
};

export default HealthLandingPage;
