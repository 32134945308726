import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import { useSelector } from 'react-redux';
import ToDoBox from './components/toDoBox';
import OtherBox from './components/otherBox';
import { AttendIcon, ShareIcon } from '../../components/general/customIcons';
import FundraisingForm from './components/fundraising-form';
import banner from '../../images/landings/fundraising/fundraising-banner.jpg';
import bannerMobile from '../../images/landings/fundraising/fundraising-banner-mobile.jpg';
import SocialBox from './components/socialBox';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const FundraisingLandingPageUAT = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout>
      {seo && (
        <Helmet>
          <title>{`${seo['aboutUs']['fundraising']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
          <meta name="description" content={`${seo['aboutUs']['fundraising']['description']}`} />
        
          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
      )}
      {/* /* --------------------------------- Banner ---------------------------------  */}
      <section className="flex justify-center">
        <img src={banner} alt="banner" className="max-[500px]:hidden w-full" />
        <img src={bannerMobile} alt="banner" className="min-[501px]:hidden w-full" />
      </section>
      <div className="lg:container mx-auto px-6 lg:px-0 pt-6 relative">
        <div className="w-full -mt-24 bg-white rounded-xl shadow-md p-4 lg:p-12">
          <h1 className="w-full text-center text-4xl sm:text-5xl md:text-6xl text-[#253b7e] py-2 md:pt-8 font-['Gotcha'] mt-6">Fundraise for MATW</h1>
          <div className="flex justify-center text-[#14A2DC] mt-4">
            <span className="text-2xl sm:text-6xl">“</span>
            <div className="text-center italic">
              <h2 className="text-xl sm:text-4xl font-medium-">
                Abu Mas'ud reported: The Prophet, peace and blessings be upon him, said, "Whoever guides someone to goodness will have a reward like
                the one who did it.
              </h2>
              <h4 className="text-xl sm:text-2xl font-light mt-2">Source: Sahih Muslim 1893</h4>
            </div>
            <span className="text-2xl sm:text-6xl rotate-180">“</span>
          </div>
          <p className="text-[#78716C] mt-4 md:mt-8">
            When Ali Banat founded MATW in 2016, his vision was to create a legacy that belonged to every Muslim in the world. Sadaqah Jariyah doesn’t
            always mean the giving of monetary funds. There are so many ways you can contribute, with so many tools that can help build your akhirah,
            and end the cycle of poverty. The most powerful tool however, is the one you have right now in your hand.
            <br />
            <br />
            That’s right, YOU, our incredible MATW family, can make a difference using your phone, your tablet and your network with just a few simple
            clicks, in a few short minutes.
            <br />
            <br />
            From an online fundraiser, to a family gathering or a bake sale at work or school - there are so many amazing things you can do to raise
            funds to help support those in need!
          </p>
          {/* /* -------------------------- To do box? ------------------------------------ */}
          <div className="flex flex-wrap py-2 md:pt-8 mt-6 md:mt-12">
            <h1 className="w-full text-center text-4xl sm:text-5xl md:text-6xl text-[#253b7e] font-medium">All You’ve Got To Do Is…</h1>
            <div className="w-full grid grid-cols-12 gap-2 md:gap-4 mt-12 md:mt-24">
              <ToDoBox
                number="1"
                title="Pick a platform and set up a campaign!"
                description="Whether you’ve decided to participate in (or have created your own) activity, pick your platform of choice, upload a photo, tell your friends and family what’s inspired you and get going!"
              />
              <ToDoBox
                number="2"
                title="Spread the word!"
                description="Again, the most powerful tool is where? That’s right, you’re holding it! Send a text, make some calls, write some emails and post to socials - tell as many people as you can about your commitment to making a difference!"
              />
              <ToDoBox
                number="3"
                isLast
                title="See YOUR impact."
                description="Once your campaign is over, we’ll make sure that the funds go to where it’s needed and we will update you on the progress of the project so that you can show your friends and family the difference you’ve collectively made!"
              />
            </div>
          </div>
          {/* /* -------------------------- What are you waiting? ------------------------- */}
          <div className="flex flex-wrap py-2 md:pt-8 mt-6 md:mt-12">
            <h1 className="w-full text-center text-3xl sm:text-4xl md:text-5xl text-[#253b7e] font-medium">
              So What Are You Waiting For?
              <br />
              Ready To Change The World With Us?
            </h1>
            <div className={`shadow-3xl w-full rounded-md shadow-cyan-500/50 transition-all duration-200 ease-in-out h-auto py-4 mb-6 `}>
              <h1 className="w-full mt-[50px] text-center text-4xl sm:text-5xl md:text-6xl text-[#F60362] font-medium font-['Gotcha']">
              Register to Fundraise below
              </h1>
              <FundraisingForm />
            </div>
          </div>
          {/* /* -------------------------- Other Ways To help ------------------------- */}
          <div className="flex flex-wrap py-2 md:pt-8 mt-6 md:mt-12">
            <h1 className="w-full text-center text-4xl sm:text-5xl md:text-6xl text-[#253b7e] font-medium font-['Gotcha']">Other Ways to Help</h1>
            <div className="w-full grid grid-cols-12 gap-4 md:gap-6 mt-6 md:mt-12">
              <SocialBox svg={<ShareIcon />} link="#" title="Share on socials" description="Spread your impact by spreading the word!" />
              <OtherBox
                svg={<AttendIcon />}
                link="https://events.matwcheckout.org/"
                title="Attend our events"
                description="Connect with likeminded muslims and be inspired"
              />
            </div>
          </div>
        </div>
      </div>
    </NewHelmetLayout>
  );
};

export default FundraisingLandingPageUAT;