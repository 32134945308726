import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Loader, OurPromiseSection } from '../../components/general';
import { buildWaterWellFaqs } from './faqs';
import services from '../../services';
import Accordion from '../../components/general/accordion';
import SadaqahWidget from './sadaqahWidget';
import ProductBox from '../../components/general/productBox';
import FundraiserProductBox from '../../components/general/fundraiserProductBox';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectProducts } from '../../store/products';
import ZakatWidget from '../zakat/zakatWidget';
import { useKeenSlider } from 'keen-slider/react';
// Slider Images
import Process from '../../images/sadaqah/water-process.jpg';
import WaterSlider1 from '../../images/sadaqah/water-slider-1.jpg';
import WaterSlider2 from '../../images/sadaqah/water-slider-2.jpg';
import WaterSlider3 from '../../images/sadaqah/water-slider-3.jpg';
import WaterSlider4 from '../../images/sadaqah/water-slider-4.jpg';
import banner from '../../images/sadaqah/Build-Well-Generic-Hero-Banner-Desktop.jpg';
import bannerMobile from '../../images/sadaqah/Build-Well-Generic-Hero-Banner-Mobile.jpg';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import WidgetMain from '../../components/widgets/widgetMain';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
import useElementRemoval from '../../hooks/ui/useElementRemoval';
import scrollToTop from '../../hooks/ui/useScrollToTop';
import { ProductBoxCart } from '../home/components/productBoxCart';
import useCart from '../../hooks/cart/useCart';
import { CartSheet } from '../home/components/cartSheet';
import sortProductsByCurrency from '../../utils/productSortHandler';
import { ButtonDonateRounded } from '../home/components/buttonDonateRounded';
import staticImages from '../../constants/staticImages';
const images = [WaterSlider1, WaterSlider2, WaterSlider3, WaterSlider4];

const suggestedPrices = [50, 150, 250, 500, 1000, 2000];

// const waterWellBuildProducts = [
//   "MKD-WEL-SDQ-2023-01",
//   "MKD-SDQ-OAW-2023-02-119",
//   "MD-AA-2024-01",
//   "MD-AA-2024-02",
//   "MD-AA-2024-03",
//   "MD-AA-2024-04",
//   "MD-AA-2024-05",
//   "MD-AA-2024-06",
//   "MD-AA-2024-07",
//   "MD-AA-2024-08",
//   "MD-AA-2024-09",
//   "MD-AA-2024-10"
// ]

const defaultListProduct = {
  name:'Water Well General Fund',
  creator:'MD-AA-2024-08'
}

const BuildWaterWellUat = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [waterProducts, setWaterProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [loaded, setLoaded] = useState(false);
  const [futureProducts, setFutureProducts] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const products = useSelector(state => selectProducts(state));
  const [isLoaded, setIsLoaded] = useState(false);

  useElementRemoval("#zsiq_float");
  const waterWellDefault = {
    creator: 'MD-AA-2024-08',
    label: 'Water Well General Fund',
    value: 662
  };

  const {
      handleAddToGiving,
      handleRemoveProduct,
      handleCheckProduct,
    } = useCart()

  /* ----------------------------- Slider variables ---------------------------- */
  const [currentSlideImages, setCurrentSlideImages] = useState(0);
  const altTexts = [
    "Build a water well in Africa with MATW Project",
    "Build a water well in Pakistan",
    "Build a water well in Yemen with solar power",
    "Donate a water well with MATW Project Water Well Charity",
  ];
  const [sliderRefImages, instanceRefImages] = useKeenSlider(
    {
      mode: 'free-snap',
      loop: true,
      slideChanged(slider) {
        setCurrentSlideImages(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
      slides: {
        origin: 'auto',
        perView: 1,
        spacing: 8,
      },
    },
    [
      slider => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ],
  );
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempWaterProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
            //Water Well
            item = Object.assign({}, item, {
              alt: 'Build a well',
            });
            tempWaterProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'WWC-CON-ABW-23-01-028') {
            //Africa Basic Water Well
            item = Object.assign({}, item, {
              alt: 'Build a well in Africa',
            });
            tempWaterProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') {
            //Bangladesh Basic Water Well
            item = Object.assign({}, item, {
              alt: 'Build a water well in Bangladesh',
            });
            tempWaterProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'WWC-CON-IBW-2023-01-031') {
            //India Basic Water Well
            item = Object.assign({}, item, {
              alt: 'Build a water well in India',
            });
            tempWaterProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'WWC-CON-ABW-2023-02-028') tempWaterProducts[4] = inner_item_product; //HD Africa Basic Water Well
          if (inner_item_product.creator === 'WWC-CON-H2W-2023-01-025') tempWaterProducts[5] = inner_item_product; //H2 Hand Pump Water Well
          if (inner_item_product.creator === 'WWC-CON-L1W-2023-01-026') tempWaterProducts[6] = inner_item_product; //L1 Africa Water Well
          if (inner_item_product.creator === 'WWC-CON-L2W-2023-01-027') tempWaterProducts[7] = inner_item_product; //L2 Africa Water Well
          if (inner_item_product.creator === 'WWC-CON-PSPW-2023-01-027') tempWaterProducts[8] = inner_item_product; //Pakistan Solar Powered Well
          if (inner_item_product.creator === 'WWC-CON-PBW-2023-01-030') tempWaterProducts[9] = inner_item_product; //Pakistan Solar Powered Well

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });

    tempWaterProducts = tempWaterProducts.filter(n => n);
    const sortedProducts = sortProductsByCurrency(tempWaterProducts, selectedCurrencyStore.toLowerCase());

    setWaterProducts(sortedProducts);
  };
  const getFundraiserProducts = async () => {
    let tempFutureProducts = [];
    try {
      const response = await services.fundraiserProducts();
      response.data.map(item => {
        item.raised = 0;
        if (item.name === 'Yemen Solar Powered Well') {
          item.goal = 40000;
          tempFutureProducts.push(item);
        }
        return item;
      });

      getProductsReports(tempFutureProducts);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const getProductsReports = async tempFutureProducts => {
    try {
      const response = await services.orphanProductTransactions();
      response.data.length &&
        response.data.map((item, i) => {
          tempFutureProducts.map(future_item => {
            if (future_item.name === item.product_name) {
              future_item.raised = item.total_sales;
            }
            return future_item;
          });
          return null;
        });
      // Object.keys(response.data.data).length && Object.keys(response.data.data).map((keyName, i) => {
      //     tempFutureProducts.map(future_item => {
      //         if (future_item.name === keyName) {
      //             future_item.raised = response.data.data[keyName]
      //         }
      //         return future_item
      //     })
      //     return null;
      // })
      setFutureProducts(tempFutureProducts);
    } catch (error) {
      console.error('error========', error);
    }
  };

  const handleProductsUI = () => (
    <div className={`md:container w-full mx-auto md:my-10 lg:my-16 px-4 md:px-0 mb-6`}>
      <div className="flex flex-wrap gap-3 md:gap-4 justify-center w-full">
        {waterProducts &&
          waterProducts.map((item, index) => {
            return (
              <div key={`makedonation${index}`} className="w-full md:max-w-[300px] md:basis-[calc(50%-8px)]">
                {item.video_src ? (
                  <div
                    key={index}
                    className={`basis-full border p-4 bg-white border-[#dddddd] shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] h-full`}
                  >
                    <iframe
                      className="video"
                      width={'100%'}
                      height={'100%'}
                      title={'youtube'}
                      sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                      src={item.video_src}
                    />
                  </div>
                ) : (
                  <>
                  <div className="w-full hidden md:block">
                      <ProductBox
                        product={item}
                        alt={item.alt}
                        isStaticProduct={item.isStaticProduct || false}
                        currency={selectedCurrency ? selectedCurrency : 'AUD'}
                        titleClasses={`px-1 !text-lg !h-auto !mt-3`}
                        childClasses="!rounded-none !p-0 !pb-3 md:!pb-5 md:!p-0"
                        imageContainerClass="!rounded-none"
                        imageClass="!rounded-none"
                        descriptionStyle="px-2 md:px-3"
                        skeletonStyle="px-2"
                        squareButton
                      />
                    </div>
                    <div className="w-full md:hidden">
                      <ProductBoxCart
                        key={index}
                        product={item}
                        onCheck={handleCheckProduct}
                        onAdd={handleAddToGiving}
                        onRemove={handleRemoveProduct}
                        currency={selectedCurrency ? selectedCurrency : 'AUD'}
                      />
                    </div>
                  </>
                )}
              </div>
            );
          })}
      </div>
    </div>
  )

  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  useEffect(() => {
    if (products && Object.keys(products).length && !isLoaded) {
      getAllProducts();
      getFundraiserProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  /* -------------------------------------------------------------------------- */
  //init


  return (
    <NewHelmetLayout
      className="!bg-white"
      hideDonateNowButton={true}
      highlights={false}
      isRamadanPage
      isRelative={true}
    >
      {seo && (
        <Helmet>
          <title>{`${seo['sadaqahJariyah']['buildAWaterWell']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
          <meta name="description" content={`${seo['sadaqahJariyah']['buildAWaterWell']['description']}`} />
        
          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
      )}
      {/* /* --------------------------------- Banner ---------------------------------  */}
      <section className="flex justify-center">
        <img src={bannerMobile} alt="Build a water well" className="w-full lg:hidden" />
      </section>

      <div className="lg:hidden">
          <WidgetMain 
            selectedCurrency={e => setSelectedCurrency(e.value)} 
            className="!w-full !p-3 md:!p-6" 
            suggestedPrices={suggestedPrices} 
            // additionalProducts={waterWellBuildProducts}
            isTimer={false}
            defaultProduct={waterWellDefault}
            isWaterWell={true}
            defaultListProduct={defaultListProduct}
            />
      </div>
      <MaxWidthContainer className="mt-6 !max-w-[1520px]">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className={`hidden lg:block flex-1 lg:flex-[0_0_450px] bg-[#eaf7ff]`}>
            <WidgetMain 
              selectedCurrency={e => setSelectedCurrency(e.value)} 
              className="!w-full !p-3 md:!p-6" 
              suggestedPrices={suggestedPrices} 
              // additionalProducts={waterWellBuildProducts}
              isTimer={false}
              defaultProduct={waterWellDefault}
              isWaterWell={true}
              defaultListProduct={defaultListProduct}
            />
          </div>
          <div className="flex-1 bg-[#eaf7ff] hidden md:block">
            <img src={banner} alt="Build a well, Sadaqah Jariyah" className="w-full hidden lg:block" />
            <div className="flex flex-col gap-2 p-4">
              <p className="text-3xl home-main-title  font-inter font-extrabold">
                Transform Lives and Build Your Akhirah –
                <span className="text-[#F60362]"> One Water Well at a Time</span>
              </p>
              <p className="sm:hidden text-2xl font-inter font-extrabold">
                Transform Lives and Build Your Akhirah –
                <span className="text-[#F60362]"> One Water Well at a Time</span>
              </p>
              <p className="lg:text-lg font-inter text-[#333333] leading-[26px] hidden md:block">
                When you build a water well with MATW, you’re giving families access to safe, clean water. Your donation doesn’t just quench thirst—it enables cooking, bathing, cleaning, and watering crops. It transforms lives, empowering communities to flourish while leaving an everlasting legacy of hope.
              </p>
            </div>
          </div>
        </div>
      </MaxWidthContainer>

      
      {/* Products */}
      {handleProductsUI()}
      <OurPromiseSection alt={"Build a Water Well with MATW Project, 100% Donation Policy"} />
      <main>
        <section>
          <MaxWidthContainer className="mt-8 md:mt-12 lg:mt-16">
                <div className="relative overflow-hidden flex flex-wrap">
                  <div
                    className="order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[170px]
                                md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                                right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  "
                  >
                    <div className="leading-[50px] w-full text-lg px-8 text-white text-center font-brandingMedium">The Prophet PBUH said:</div>
                    <p className="text-2xl font-montserratBold leading-8 px-10 text-white text-center">“The best charity is giving water to drink.” </p>
                  </div>
                  <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
                  <div className=" basis-full max-[380px]:min-h-[209px] min-[381px]:min-h-[215px] min-[410px]:min-h-[230px] sm:h-[300px] md:h-[600px] lg:h-[1020px]">
                    <iframe
                      className="video pointer-events-none"
                      width={'100%'}
                      height={'100%'}
                      title={'youtube'}
                      frameBorder={0}
                      sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                      src={`https://www.youtube.com/embed/zm1NI7MeRNY?controls=0&autoplay=1&loop=1&mute=1&playlist=zm1NI7MeRNY`}
                    ></iframe>
                  </div>
                </div>
          </MaxWidthContainer>
        </section>

        <section>
          <MaxWidthContainer className="mt-8 md:mt-12 lg:mt-16">
              <div className="flex justify-center">
                <h1 className="md:text-center font-montserratBold text-[#093686] leading-[30px] sm:leading-[60px] text-2xl md:text-[48px] w-full sm:w-10/12">
                  Build a well and transform lives – <span className="text-[#F60362] mr-2">One Water Well at a Time</span>
                </h1>
              </div>
              <div className="space-y-5 md:space-y-10 mt-8 md:mt-12 lg:mt-16">
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C]">
                  Imagine a life without water—no clean drinking water, no way to cook, bathe, or grow food. For too many communities, this is their daily reality. 
                  But you can change that.
                </p>
                <div className="flex flex-col gap-2">
                  <span className='text-xl text-[#253B7E] font-montserratBold'>
                    Be the reason an entire village thrives.
                  </span>
                  <p className="text-base md:text-xl font-montserratMedium text-[#78716C]">
                    When you build a water well with MATW, you’re giving families access to safe, clean water. Your donation doesn’t just quench thirst—it enables cooking, 
                    bathing, cleaning, and watering crops. It transforms lives, empowering communities to flourish while leaving an everlasting legacy of hope.
                  </p>
                </div>
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C]">
                  Last year alone, our water well program touched 459,350 lives. Together, we can reach even more.
                </p>
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C]">
                  This is more than a donation—it’s an ultimate Sadaqah Jariyah, a continuous charity that brings blessings long after you’re gone. Whether you dedicate it in your 
                  name or for a loved one, every drop of water is a reward waiting for you in the Akhirah.
                </p>
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C]">
                  Ali Banat’s first act of charity was a water well in Togo, a legacy of compassion that continues to earn him rewards to this day. Now it’s your turn to carry the torch.
                </p>
                <h2 className='text-xl text-[#253B7E] font-montserratBold'>
                  Build a water well. Transform Lives. Build your Akhirah with Sadaqah Jariyah.
                </h2>
                <p className='text-xl text-[#253B7E] font-montserratBold'>
                  Act now. Donate today.
                </p>
              </div>
          </MaxWidthContainer>
        </section>
         
         {/* What Will */}
         <section className="mt-8 md:mt-12 lg:mt-16">
            <div className="px-4 flex flex-col gap-5 justify-center items-center bg-[url('../src/images/sadaqah/water-well-sub-hero.jpg')] bg-cover h-[260px] aspect-auto">
              <div className="text-white font-montserratBold text-[32px] md:text-[60px]">What will yours be?</div>
              <button
                onClick={scrollToTop}
                className="text-[#F60362] rounded-xl bg-white w-[190px] py-3 text-[18px] mt-3 font-bold"
              >
                Build a well today.
              </button>
            </div>
         </section>

        <section>
          <MaxWidthContainer className="mt-8 md:mt-12 lg:mt-16">
              <div className="flex justify-center">
                <h2 className="md:text-center font-montserratBold text-[#093686] leading-[30px] sm:leading-[60px] text-2xl md:text-[48px] w-full sm:w-10/12 mb-8 md:mb-12 lg:mb-16">
                  Build a Well:
                  <span className="text-[#F60362]"> Give the Gift of Life. Create Eternal Blessings</span>
                </h2>
              </div>
              <div className="space-y-5 md:space-y-10">
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C]">
                  Transform lives and build your legacy with MATW by constructing a water well in your name or the name of a loved one. 
                  This is not just a well; it is a lifeline, a continuous stream of Sadaqah Jariyah—ongoing charity—that will provide clean 
                  water and hope to countless individuals for generations.
                </p>
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C]">
                  At MATW, we are deeply honored to uphold your Amanah, ensuring that every pound, penny, euro, or dollar you entrust to us is 
                  spent with utmost care and dedication to your investment in the Akhirah.
                </p>
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C]">
                  When you commit to building a water well, you’re doing more than donating—you’re saving lives. Our dedicated team conducts 
                  comprehensive needs assessments to identify the areas where clean water is most desperately needed. From remote villages to 
                  conflict-stricken communities, your well becomes a beacon of hope, delivering life where it’s needed most.
                </p>
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C]">
                  We offer tailored solutions to maximize the impact of your donation, choosing the best well type and location to ensure lasting benefits. 
                  All it takes is your pure intention and a simple donation—our experienced team will take care of the rest.
                </p>
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C]">
                  This is your chance to provide more than just water. You’re giving children the chance to go to school instead of walking miles for water. 
                  You’re giving families health, dignity, and hope. You’re building a better future while securing everlasting rewards in the Hereafter.
                </p>
                <p className="text-base md:text-xl font-montserratBold text-[#253B7E]">
                  Start your Sadaqah Jariyah journey today. Build a well. Save lives. Leave a legacy.
                </p>
                <p className="text-base md:text-xl font-montserratBold text-[#253B7E]">
                  Give the gift of water. Give the gift of life.
                </p>
              </div>
          </MaxWidthContainer>
        </section>

        {/* Fund Raise Section */}
        <section>
          <MaxWidthContainer className="mt-8 md:mt-12 lg:mt-16">
            <div className="flex justify-center">
              <div className="md:text-center font-montserratBold text-[#093686] leading-[30px] sm:leading-[60px] text-2xl md:text-[48px] w-full sm:w-10/12 mb-8 md:mb-12 lg:mb-16">
                Give the <span className="text-[#F60362]">gift of water</span>, to <br /> save lives.
              </div>
            </div>
            <div className="flex flex-row justify-center flex-wrap col-span-12">
              {futureProducts ? (
                <div className="flex flex-wrap justify-center">
                  {futureProducts.map((item, index) => {
                    return (
                      <div key={`fundrising${index}`} className="basis-full min-[400px]:basis-1/2 md:basis-1/3 xl:basis-1/4 p-2 overflow-hidden">
                        <FundraiserProductBox
                          goal={item.goal}
                          subTitle="Orphan Education"
                          product={item}
                          currency={selectedCurrency ? selectedCurrency : 'AUD'}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </MaxWidthContainer>
        </section>

        <section>
          <MaxWidthContainer className="mt-8 md:mt-12 lg:mt-16">
            <div className="flex flex-col md:flex-row gap-x-10 flex-wrap sm:flex-nowrap">
              <div className="basis-full overflow-hidden sm:basis-1/2 rounded-lg">
                <img src={Process} alt="See cost of building a water well" className="h-full object-cover object-center" />
              </div>
              <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
                <h1 className="text-[#253B7E] text-[24px] sm:text-[45px] leading-[55px] font-montserratBold">“What if I can't?”</h1>
                <div className="space-y-6 mt-5 mb-5 md:mb-7">
                  <p className="text-[#78716C] font-montserratMedium text-base lg:text-xl">
                    We don’t just offer full project solutions for our Water Wells. We allow for contributions to our general Water Aid program which
                    contribute to provide safe and clean drinking water in emergencies, conflict and natural disaster zones. . Any amount that you
                    contribute to our Water Aid program will also go towards building bigger water infrastructures such as rainwater harvesting
                    projects.
                  </p>
                  <p className="text-[#78716C] font-montserratMedium text-base lg:text-xl">
                    Every amount, no matter how big or small goes a long way - especially in the eyes of Allah.
                  </p>
                </div>
                <button
                  onClick={scrollToTop}
                  className="bg-[#F60362] rounded-xl text-white w-[190px] py-3 text-[18px]  font-bold"
                >
                  Donate Now
                </button>
              </div>
            </div>
          </MaxWidthContainer>
        </section>

        {/* /* --------------------------------- Images --------------------------------- */}
        <section className="bg-[#F8F8F8] py-7 md:py-10 mt-8 md:mt-12 lg:mt-16">
          <MaxWidthContainer>
              <div className="md:container md:mx-auto flex justify-center flex-wrap mt-12">
                <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
                  <div ref={sliderRefImages} className="keen-slider">
                    {images.map((item, index) => (
                        <div
                            key={`carousel_images${index}`}
                            className="keen-slider__slide basis-full flex justify-center rounded-xl sm:rounded-none"
                        >
                          {/* If you prefer using background images, uncomment and adjust the div below */}
                          {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}

                          <img
                              src={item}
                              alt={altTexts[index] || `carousel_image_${index + 1}`} // Fallback alt text
                              className="bg-top object-cover w-full object-top"
                          />
                        </div>
                    ))}
                  </div>
                </div>
                <div className="w-full  flex justify-center">
                  {loaded && instanceRefImages.current && (
                    <div className="dots flex pt-3 pb-6">
                      {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                        return (
                          <button
                            key={idx}
                            onClick={() => {
                              instanceRefImages.current?.moveToIdx(idx);
                            }}
                            className={
                              'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                              (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                            }
                          ></button>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
          </MaxWidthContainer>
        </section>

        {/* -------------------------------------FAQS--------------------------------- */}
        <section>
          <MaxWidthContainer className="mt-8 md:mt-12 lg:mt-16">
            <h1 className="md:text-center font-montserratBold text-[#093686] leading-[30px] sm:leading-[60px] text-2xl md:text-[48px] w-full">
              Build a well donations <span className="text-[#F60362]"> FAQ’s</span>
            </h1>
            <div className="grid grid-cols-3 gap-x-16 gap-y-8 mt-8 lg:mt-10">
              <div className="col-span-3 sm:col-span-1">
                <h1 className="text-[#253B7E] font-montserratBold text-lg md:text-2xl leading-7 mb-4">{buildWaterWellFaqs[0].question}</h1>
                <p className="text-base text-[#78716C] font-brandingMedium mt-3  ">{buildWaterWellFaqs[0].answer}</p>
              </div>
              <div className="col-span-3 sm:col-span-1">
                <h1 className="text-[#253B7E] font-montserratBold text-lg md:text-2xl leading-7 mb-4">{buildWaterWellFaqs[1].question}</h1>
                <p className="text-base text-[#78716C] font-brandingMedium mt-3  ">{buildWaterWellFaqs[1].answer}</p>
              </div>
              <div className="col-span-3 sm:col-span-1">
                <h1 className="text-[#253B7E] font-montserratBold text-lg md:text-2xl leading-7 mb-4">{buildWaterWellFaqs[2].question}</h1>
                <p className="text-base text-[#78716C] font-brandingMedium mt-3 ">{buildWaterWellFaqs[2].answer}</p>
              </div>
            </div>
            <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
              {buildWaterWellFaqs.map((item, index) => {
                return (
                  index > 2 && (
                    <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                      <Accordion
                        key={`keyallfaq${index}`}
                        shadow
                        title={item.question}
                        index={index}
                        level={1}
                        noBorder={true}
                        className="!text-[#253B7E] !text-lg !font-montserratBold"
                      >
                        {item.answer}
                      </Accordion>
                    </div>
                  )
                );
              })}
            </div>
          </MaxWidthContainer>
        </section>
      </main>
    </NewHelmetLayout>
  );
};

export default BuildWaterWellUat;
