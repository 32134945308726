import React from 'react';
import { createPortal } from 'react-dom';

const AdyenModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return createPortal(
    <div 
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999]"
      onClick={onClose}
    >
      <div className="max-h-[80vh] overflow-y-auto w-full max-w-md mx-4">
        <div
          id="dropin-container"
          className="bg-white rounded-lg shadow-lg p-6 relative flex flex-col"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Loading Spinner */}
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
            <div className="w-12 h-12 border-4 border-[#0abf53] border-t-transparent rounded-full animate-spin"></div>
          </div>

          {/* Trust Badges Section */}
          <div className="mt-auto">
            {/* Border Line */}
            <div className="border-t border-gray-200 pt-4">
              {/* Security Labels */}
              <div className="grid grid-cols-2 gap-4 mb-2">
                <div className="text-center">
                  <span className="text-gray-700 font-medium">Secured by Adyen</span>
                </div>
                <div className="text-center">
                  <span className="text-gray-700 font-medium">PCI DSS Compliant</span>
                </div>
              </div>

              {/* Security Badges */}
              <div className="grid grid-cols-2 items-center gap-4 mt-2">
                {/* Adyen Logo */}
                <div className="flex justify-center">
                  <svg
                    fill="#0abf53"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 90 30"
                    aria-label="Adyen logo"
                    role="img"
                    className="h-6 w-auto"
                  >
                    <path d="M27.7851 18.5234H26.0557C25.555 18.5234 25.1454 18.1138 25.1454 17.6131V6.622H21.7093C19.957 6.622 18.5234 8.05563 18.5234 9.80784V19.3198C18.5234 21.0721 19.957 22.5057 21.7093 22.5057H34.4071V0H27.7851V18.5234ZM12.6978 6.6221H0.22756V10.5816H8.35145C8.85209 10.5816 9.26169 10.9913 9.26169 11.4919V18.5235H7.53224C7.0316 18.5235 6.622 18.1139 6.622 17.6132V12.5614H3.18584C1.43363 12.5614 0 13.995 0 15.7473V19.2972C0 21.0494 1.43363 22.483 3.18584 22.483H15.8837V9.78518C15.8837 8.05573 14.4501 6.6221 12.6978 6.6221ZM44.6018 18.5235H46.3313V6.6221H52.9533V25.9419C52.9533 27.6942 51.5196 29.1278 49.7674 29.1278H37.2971V24.4856H46.3313V22.5058H40.2554C38.5032 22.5058 37.0696 21.0722 37.0696 19.3199V6.6221H43.6916V17.6132C43.6916 18.1139 44.1012 18.5235 44.6018 18.5235ZM68.2908 6.6221H55.593V19.32C55.593 21.0722 57.0266 22.5058 58.7788 22.5058H71.2491V18.5462H63.1252C62.6246 18.5462 62.215 18.1366 62.215 17.636V10.5816H63.9444C64.4451 10.5816 64.8547 10.9913 64.8547 11.4919V16.5437H68.2908C70.043 16.5437 71.4767 15.1101 71.4767 13.3579V9.80794C71.4767 8.05573 70.043 6.6221 68.2908 6.6221ZM74.1163 6.6221H86.8141C88.5891 6.6221 90 8.05573 90 9.78518V22.483H83.378V11.4919C83.378 10.9913 82.9684 10.5816 82.4677 10.5816H80.7383V22.5058H74.1163V6.6221Z"></path>
                  </svg>
                </div>
                
                {/* PCI Logo */}
                <div className="flex justify-center">
                  <img
                    src="/images/payment/pci.png"
                    alt="PCI DSS Logo"
                    className="h-6 w-auto"
                  />
                </div>
              </div>

              {/* Security Message */}
              <div className="mt-4 text-center text-sm text-gray-600">
                Donations are processed securely through Adyen, a PCI DSS certified payment gateway.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default AdyenModal;