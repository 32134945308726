export function getUTMData() {
    return {
      utm_source: sessionStorage.getItem("utm_source"),
      utm_medium: sessionStorage.getItem("utm_medium"),
      utm_campaign: sessionStorage.getItem("utm_campaign"),
      utm_content: sessionStorage.getItem("utm_content"),
      utm_term: sessionStorage.getItem("utm_term"),
    };
  }
  
  export function parseUTMParams() {
    const urlParams = new URLSearchParams(window.location.search);

    const utmParams = { 
      utm_source: urlParams.get("utm_source"),
      utm_medium: urlParams.get("utm_medium"),
      utm_campaign: urlParams.get("utm_campaign"),
      utm_content: urlParams.get("utm_content"),
      utm_term: urlParams.get("utm_term"),
    };
  
    // Save to session storage
    Object.keys(utmParams).forEach((key) => {
      if (utmParams[key]) {
        sessionStorage.setItem(key, utmParams[key]);
      }
    });
  }

  //utm params,
  //for only facebook in app browser
  