import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import ShowCurrencies from '../../utils/showCurrencies';
import Skeleton from 'react-loading-skeleton';
import useHandleAddProduct from '../../utils/handleAddProduct';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import CustomButton from './button';
import IsGTMScriptLoaded from '../../utils/isGTMScriptLoaded';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';
import ShowCurrencies from '../../utils/showCurrencies';
import { useDispatch } from 'react-redux';
import useCart from '../../hooks/cart/useCart';
import { openSheet } from '../../store/sheetToggler';
import {checkDomain} from "../../utils/checkDomain";
import useIsExactDomain from '../../hooks/cart/useIsExactDomain';

const ProductBox = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const ref = useRef(null);
  const navigate = useNavigate();
  const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  // const [addProduct] = useHandleAddProduct();
  const isUsa = useIsExactDomain('us.matwproject.org');

  const {
    product,
    currency,
    isStaticProduct,
    isPalestineRefugee = false,
    arabic = false,
    staticLink,
    staticLinkText,
    buttonLable,
    stateVariableObj,
    scrollTop,
    alt,
    linkBtnClasses,
    titleClasses,
    descriptionStyle,
    _lang = 'en',
    removeDataLayer,
    isVisibleDescription = true,
    childClasses,
    defaultPrice = 200,
    roundedButton = false,
    btnContainerClass,
    imageClass,
    imageContainerClass,
    skeletonStyle,
    addToCart = true,

  } = props;
  const [showMore, setShowMore] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const [isAnyAmount, setIsAnyAmount] = useState(false);
  const [selectedSuggestedPrice, setSelectedSuggestedPrice] = useState(null);
  const pixelID = localStorage.getItem('TikTokID');
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const price = product?.suggestedPrices?.length ? selectedSuggestedPrice ?? product.suggestedPrices[0] : defaultPrice;
  /* -------------------------------------------------------------------------- */
  const dispatch = useDispatch();
  const {
    handleAddToGiving: handleAddToGivingCart,
  } = useCart();
  /* ------------------------------ Start methods ----------------------------- */
  // const handleCurrency = (product, currency) => {
  //   console.log("on handle currency=",product.quantity,product,currency)
  //   return product[currency.toLowerCase()]
  //   // if (product.quantity) {
  //   //   return ShowCurrencies(currency, product[currency.toLowerCase()] * product.quantity, false);
  //   // } else {
  //   //   return ShowCurrencies(currency, product[currency.toLowerCase()], false);
  //   // }
  // };
  const handleCustomProductAmount = product => {
    // console.log("product=",product)
    // if (Number(process.env.REACT_APP_MAKE_DONATION_CATEGORY_ID) === Number(product.category_id) || product.id === 52 || product.id === 69) {
    //   setIsAnyAmount(true);
    // } else {
    //   // if (Number(product[currency.toLowerCase()]) === 1) {
    //   let hasQuantity = product.quantity ? (product.quantity > 1 ? true : false) : false;
    //   // if (Number(product[currency.toLowerCase()]) < 6 && !hasQuantity) {
    //   if (Number(product.aud) <= 2 && !hasQuantity) {
    //     setIsAnyAmount(true);
    //   } else {
    //     setIsAnyAmount(false);
    //   }
    // }
    let hasQuantity = product.quantity ? (product.quantity > 1 ? true : false) : false;
    if (Number(product.aud) <= 2 && !hasQuantity) {
      setIsAnyAmount(true);
    } else {
      setIsAnyAmount(false);
    }
  };
  const handleHeight = () => {
    setShowMore(!showMore);
  };

  const handleViewItemDataLayer = (tempProduct, currency) => {
    // console.log("datalayer view item", tempProduct)
    let dataLayerTemp = {
      event: 'view_item',
      ecommerce: {
        currency: currency.toUpperCase(),
        items: [
          {
            item_id: tempProduct.creator,
            item_name: tempProduct.name,
            item_brand: tempProduct.category || '',
            item_category: tempProduct.category || '',
            item_description: tempProduct.description || '',
            price: tempProduct[currency.toLowerCase()],
          },
        ],
      },
    };
    window.dataLayer = window.dataLayer || [];
    // console.log('datalayer view item=', IsGTMScriptLoaded(),tempProduct, dataLayerTemp, window.dataLayer);
    IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);

    let obj = {
      content_type: 'product',
      content_id: tempProduct.creator,
      currency: currency,
      value: tempProduct[currency.toLowerCase()],
      price: tempProduct[currency.toLowerCase()],
      quantity: 1,
      content_category: tempProduct.category ? tempProduct.category : 'donation',
      content_name: tempProduct.name,
      description: tempProduct.description,
    };
    if (!(checkDomain('matwprojectme.org') || checkDomain('matwproject.org'))) {
      window.ttq &&
      window.ttq.instance(pixelID).track('ViewContent', {
        ...obj,
      });
    }
  };
  const ShowCurrencies2 = (currency, _price) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = '';
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'EUR':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        _final = `${price}`;
        break;
      case 'IDR':
        _final = `Rp${price}`;
        break;
      case 'AED':
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    }
    return _final;
  };
  /* -------------------------------------------------------------------------- */
  const handleAddProduct = (addToCart) => {
    if (addToCart) {
      if (product.suggestedPrices?.length) {
        let tempProduct = product;
        tempProduct = Object.assign({}, tempProduct, {
          quantity: price
        });
        handleAddToGivingCart(tempProduct,'custom');
        dispatch(openSheet());
      } else {
        handleAddToGivingCart(product);
        dispatch(openSheet());
      }
    } else {
      // addProduct({ product: tempProduct, currency: currentCurrency });
      // console.log("before adding=", product, price)
      addProduct({ product: product, currency: currency, defaultPrice: price });
    }
  }

  const HandleIsPalestineRefugee = () => {
      if(isUsa) {
        return;
      }
      else{
        window.scrollTo({ top: 0, behavior: 'auto' });
        isPalestineRefugee
          ? navigate(generateLinkWithLang(`/appeals/palestine/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang))
          : isStaticProduct
          ? navigate(generateLinkWithLang(`${staticLink ? staticLink : '/zakat-calculator'}`, lang))
          : navigate(generateLinkWithLang(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang));
      }
    }

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    if (product) {
      handleCustomProductAmount(product);
      if (ref.current && ref.current.clientHeight > 60) {
        setIsOverflow(true);
      }
    }
    // Include dependencies that affect the rendering of the ref'd component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, ref.current]);
  /* -------------------------------------------------------------------------- */
  return (
    <div className={`w-full shadow-lg bg-white pb-3 md:pb-5 ${childClasses ? childClasses : ''}`} style={{ height: '100%' }}>
      <div
        // className="overflow-hidden img-hover-zoom rounded-md max-h-[336px]- w-full sm:w-[197px]- sm:h-[169px] md:w-[210px] md:h-[210px] lg:w-[301px] lg:h-[301px] xl:w-[280px] xl:h-[280px] 2xl:w-[344px] 2xl:h-[344px] tooltip [&>span]:!block"
        className={`overflow-hidden img-hover-zoom w-full sm:min-h-[169px] md:min-h-[210px] lg:min-h-[301px] xl:min-h-[280px] 2xl:min-h-[344px] tooltip [&>span]:!block ${imageContainerClass ? imageContainerClass : ''
          }`}
        // className="overflow-hidden img-hover-zoom rounded-md w-full tooltip [&>span]:!block"
        onClick={HandleIsPalestineRefugee}
      >
        {product ? (
          product.image_link ? (
            <LazyLoadImage
              alt={alt ? alt : product.alt ? product.alt : product.name}
              effect="blur"
              width={`100%`}
              delayTime={500}
              afterLoad={() => {
                !removeDataLayer && handleViewItemDataLayer(product, currency);
              }}
              className={`${!isUsa ? 'cursor-pointer' : ''} block ${imageClass ? imageClass : ''}`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/images/general/logo-replace.png';
              }}
              src={product.image_link} // use normal <img> attributes as props
            />
          ) : (
            <img className={`w-full ${imageClass ? imageClass : ''}`} src="/images/general/logo-replace.png" alt="" />
          )
        ) : (
          <Skeleton height={344} />
        )}
        {/* <span className="tooltiptext bg-[#00a3da] rounded-lg cursor-pointer mx-auto left-0 right-0 z-[1] absolute py-1 text-center text-white opacity-0 transition-all duration-300 w-32 top-[calc(50%_-_17px)]">Details</span> */}
      </div>
      <p
        className={`text-[#00a3da] ${!isUsa ? 'cursor-pointer' : ''} flex justify-center items-center text-sm sm:text-md md:text-md lg:text-lg xl:text-xl text-center px-1 mt-3 min-h-[56px] ${isVisibleDescription ? '' : ''
          }  ${isVisibleDescription ? 'md:mb-2 xs:mb-2' : 'xs:mb-2 md:mb-0'} mt-2 leading-4 md:leading-none uppercase ${arabic || _lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-medium'
          } ${titleClasses ? titleClasses : ''}`}
        onClick={HandleIsPalestineRefugee}
      >
        {/* {product ? product.name : <Skeleton height={56} />} */}
        {product ? product.alternativeName || product.name : <Skeleton height={56} />}
      </p>
      <div className={`relative text-center ${descriptionStyle ? descriptionStyle : ''}`}>
        {product ? (
          <>
            {
              // Description block
              (isVisibleDescription || showMore) && (
                <div
                  className={`transition-all duration-300 text-center my-2 relative overflow-hidden ${showMore ? 'h-auto mb-4' : 'max-h-[65px] md:max-h-[78px]'
                    } ${(product.short_description || product.description).split(' ').length > 28 ? 'mb-0' : 'mb-0'}`}
                >
                  <p
                    dangerouslySetInnerHTML={{ __html: product.short_description || product.description }}
                    ref={ref}
                    className={`text-stone-500 text-sm sm:text-sm md:text-base lg:text-base overflow-hidden transition-all duration-200 min-h-[65px] md:min-h-[79px] px-2 md:px-3 ${descriptionStyle ? descriptionStyle : ''
                      } ${arabic || _lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}
                  ></p>
                </div>
              )
            }
            {
              // Chevron icon
              (isOverflow || !isVisibleDescription) && (
                <ChevronDownIcon
                  onClick={handleHeight}
                  className={` md:block w-6 h-6 text-[#00a3da] transition-all duration-300 cursor-pointer mx-auto ${showMore ? 'transform rotate-180' : ''
                    }`}
                />
              )
            }
          </>
        ) : (
          <Skeleton count={4} />
        )}
      </div>
      <div className={`grid grid-cols-6 gap-1 2xl:gap-2 ${btnContainerClass ? btnContainerClass : ''}`}>
        {product ? (
          !!product.suggestedPrices?.length ? (
            <div className="flex justify-around w-full col-span-6 my-3">
              {product.suggestedPrices.map((price, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedSuggestedPrice(price)}
                  className={`min-w-[55px] lg:min-w-[75px] px-2 lg:px-4 py-1.5 lg:py-3 font-bold  lg:text-lg rounded-xl border-pink flex border-2 flex-col items-center justify-center ${(selectedSuggestedPrice ?? product.suggestedPrices[0]) === price ? 'bg-white text-pink ' : 'bg-pink text-white'
                    }`}
                >
                  {ShowCurrencies(currency, product[currency.toLowerCase()] * price, false)}
                  {<small className={`uppercase leading-3`}>{currency}</small>}
                </button>
              ))}
            </div>
          ) : (
            <div
              className={`text-[#f60362] col-span-6 text-md md:text-xl text-center font-bold ${arabic || _lang === 'ar' ? 'font-[AlmariMedium]' : ''
                }`}
            >
              {isStaticProduct ? (
                ' '
              ) : isAnyAmount ? (
                arabic || _lang === 'ar' ? (
                  'أي مبلغ'
                ) : _lang === 'fr' ? (
                  `N'importe quel montant`
                ) : (
                  'Any Amount'
                )
              ) : (
                <div className="flex items-center justify-center">
                  <div>{ShowCurrencies2(currency, product[currency.toLowerCase()] * (product.quantity || 1), true)}</div>
                  <span className="text-xs ml-0.5">{currency}</span>
                </div>
              )}
            </div>
          )
        ) : (
          <div className={`col-span-6 px-2 ${skeletonStyle ? skeletonStyle : ''}`}>
            <Skeleton />
          </div>
        )}
        <div className="flex justify-center col-span-6">
          {product ? (
            isStaticProduct ? (
              scrollTop && scrollTop >= 0 ? (
                <CustomButton
                  onClick={() => {
                    window.scroll({ top: scrollTop, left: 0, behavior: 'smooth' });
                  }}
                  title={staticLinkText ? staticLinkText : 'Zakat Calculator'}
                  className={`${linkBtnClasses ? linkBtnClasses : '!rounded-none'} `}
                />
              ) : (
                <CustomButton
                  state={stateVariableObj}
                  link={`${staticLink ? staticLink : '/zakat-calculator'}`}
                  title={staticLinkText ? staticLinkText : 'Zakat Calculator'}
                  className={`${linkBtnClasses ? linkBtnClasses : '!rounded-none'}`}
                />
              )
            ) : (
              <CustomButton
                onClick={() => handleAddProduct(addToCart)}
                title={
                  buttonLable
                    ? buttonLable
                    : arabic || _lang === 'ar'
                      ? 'تبرع الان'
                      : _lang === 'fr'
                        ? 'Faites un don maintenant'
                        : _lang === 'es'
                          ? 'Dona ahora'
                          : 'Donate Now'
                }
                className={`${linkBtnClasses ? linkBtnClasses : ''} ${arabic || _lang === 'ar' ? 'font-[AlmariMedium]' : ''} ${roundedButton ? '!rounded-[50px]' : '!rounded-none'
                  }`}
              />
            )
          ) : (
            <Skeleton height={43} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductBox;
