// components/MultiplierSelector.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { FiAlertCircle } from 'react-icons/fi';

const MultiplierSelector = ({
  donationOption,
  setDonationOption,
  selectedConditions,
  setSelectedConditions,
  multipliers,
  setMultipliers,
  numberOfNights,
  isLastTenNightsGiving,
}) => {
  /**
   * Handles the change when a radio button is selected.
   * @param {string} option - The donation option selected ('equal' or 'custom').
   */
  const handleOptionChange = (option) => {
    setDonationOption(option);
    // Reset selected conditions and multipliers if switching to 'equal' option
    if (option === 'equal') {
      setSelectedConditions([]);
      // Optionally reset multipliers to default values
      setMultipliers({
        friday: 1,
        lastTenNights: 1,
        oddNights: 1,
        laylatulQadr: 1,
      });
    }
  };

  /**
   * Handles the change in checkbox selection for special conditions.
   * @param {string} condition - The donation condition.
   */
  const handleConditionChange = (condition) => {
    donationOption !== 'custom' && handleOptionChange('custom')
    if (selectedConditions.includes(condition)) {
      setSelectedConditions(selectedConditions.filter((c) => c !== condition));
      // Optionally reset the multiplier for the deselected condition
      setMultipliers((prev) => ({
        ...prev,
        [condition]: 1, // Default multiplier
      }));
    } else {
      setSelectedConditions([...selectedConditions, condition]);
      // Initialize the multiplier for the newly selected condition
      setMultipliers((prev) => ({
        ...prev,
        [condition]: 2, // Default selected multiplier when condition is selected
      }));
    }
  };

  /**
   * Handles the change in multiplier value for a specific condition.
   * @param {string} condition - The donation condition.
   * @param {number} value - The new multiplier value.
   */
  const handleMultiplierChange = (condition, value) => {
    setMultipliers((prev) => ({
      ...prev,
      [condition]: value,
    }));
  };

  // Define the special conditions
  const specialConditions = [
    { label: 'Give more on Jummah (Friday)', value: 'friday' },
    ...(!isLastTenNightsGiving ? [{ label: 'Give more in the last 10 nights', value: 'lastTenNights' }] : []),
    { label: 'Give more on odd nights', value: 'oddNights' },
    { label: 'Give more on the 27th night - Laylat-ul-Qadr', value: 'laylatulQadr' },
];


  // Define share multipliers for each condition
  // const conditionMultipliers = {
  //   friday: 3,
  //   lastTenNights: 2,
  //   oddNights: 4,
  //   laylatulQadr: 10,
  // };

  // Define dropdown options
  const multiplierOptions = [2, 3, 4, 5];

  return (
    <div className="pl-4 md:pl-0 md:pr-4">
      {/* <h3 className="text-stone-600 text-lg md:text-xl mb-2">Select your giving pattern:</h3> */}
      <p className="text-[#78716C] text-sm md:text-base mb-4">
        {isLastTenNightsGiving ? 'Below you’ll find options to customise your giving during the last 10 days. For example, you can choose to give three times more on Jummah or double your donation on the 27th night' : 'Below you’ll find options that allow you to further customise your giving during the holy month. For example, you can select to give more by three times on Jummah or more by two times during the last ten nights.'}
      </p>
      {/* Equal Division Option */}
      <div className="flex items-center mb-4">
        <label className="flex items-center mr-4 cursor-pointer">
          <input
            type="radio"
            name="donationOption"
            value="equal"
            checked={donationOption === 'equal'}
            onChange={() => handleOptionChange('equal')}
            className="mr-2"
          />
          Give the same amount on every night
        </label>
      </div>

      {/* Custom Conditions Option */}
      <div className="flex items-center mb-4">
        <label className="flex items-center mr-4 cursor-pointer">
          <input
            type="radio"
            name="donationOption"
            value="custom"
            checked={donationOption === 'custom'}
            onChange={() => handleOptionChange('custom')}
            className="mr-2"
          />
          Special Requests
        </label>
      </div>

      {/* Display Checkboxes and Dropdowns if Custom Conditions is Selected */}
      <div className="pl-4">
        <h3 className="mb-2 text-md">Select special requests (at least one):</h3>
        {specialConditions.map((condition) => (
          <div key={condition.value} className="flex items-center mb-4">
            <label className="flex items-center text-sm md:text-base mr-2 cursor-pointer">
              <input
                type="checkbox"
                name="specialConditions"
                value={condition.value}
                checked={selectedConditions.includes(condition.value)}
                onChange={() => handleConditionChange(condition.value)}
                className="mr-2"
              />
              {condition.label}
            </label>

            {/* Dropdown for Multiplier Selection */}
            {selectedConditions.includes(condition.value) && (
              <select
                className="w-[60px] p-1 rounded border border-[#ccc] cursor-pointer"
                value={multipliers[condition.value]}
                onChange={(e) => handleMultiplierChange(condition.value, Number(e.target.value))}
              >
                {multiplierOptions.map((option) => (
                  <option key={option} value={option}>
                    x{option}
                  </option>
                ))}
              </select>
            )}
          </div>
        ))}
        {/* Show a message if no condition is selected */}
        {donationOption === 'custom' && selectedConditions.length === 0 && (
          <div className="flex items-center text-red-500 text-sm p-4 rounded-md border border-red-600 bg-red-50"><FiAlertCircle className="mr-2" />Please select at least one request.</div>
        )}
      </div>
      {/* {donationOption === 'custom' && (
      )} */}
    </div>
  );
};

// Define PropTypes for type checking
MultiplierSelector.propTypes = {
  donationOption: PropTypes.string.isRequired, // 'equal' or 'custom'
  setDonationOption: PropTypes.func.isRequired,
  selectedConditions: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedConditions: PropTypes.func.isRequired,
  multipliers: PropTypes.shape({
    friday: PropTypes.number,
    lastTenNights: PropTypes.number,
    oddNights: PropTypes.number,
    laylatulQadr: PropTypes.number,
  }).isRequired,
  setMultipliers: PropTypes.func.isRequired,
  numberOfNights: PropTypes.number.isRequired,
};

export default MultiplierSelector;
