import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import Skeleton from 'react-loading-skeleton';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
// import useHandleAddProduct from '../../../utils/handleAddProduct';
// import YoutubeVideoHero from '../../../components/general/youtubeVideoHero';
import CustomButton from '../../../components/general/button';
// import ShowCurrencies from '../../../utils/showCurrencies';
// import ConvertAUD from '../../../utils/convertAUD';
import ConvertUSD from '../../../utils/convertUSD';
import Sticky from 'react-sticky-el';
import SocialBox from '../../fundraising/components/socialBox';
import moment from 'moment';
import { endDateOfAppealCampaign, endDateOfGazaCampCampaign, endDateOfLimbsAppealCampaign } from '../details';
import getSymbolFromCurrency from 'currency-symbol-map';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import handleDaysLeft from '../handleDaysLeft';
// import { useLocation } from 'react-router-dom';

const AppealCurrentProduct = ({ lang = 'en', currentProduct, goal, campaign, selectedGivingProducts }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  // const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [addProduct] = useHandleAddProduct();
  // const location = useLocation();
  // const [isRefugeePage, setIsRefugeePage] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [isTabsFixed, setIsTabsFixed] = useState(false);
  const moreInfo = [
    {
      title:
        'More than 39,000 Palestinians have lost their lives including nearly 15,000 children and more than 90,000 have been wounded since October 7, 2023.',
      description: (
        <div className="text-[16px] md:text-[18px]">
          Overwhelmingly, they are broken families with family members left behind or deceased. They have survived the unimaginable - conflict,
          bombing, displacement and hunger, having seen people they know or family members dying, only to escape to another country to receive no
          support. We cannot let that happen. MATW is providing support to refugee families who have fled in the form of housing, rental support,
          furnishing homes, food support, expense support and so much more. Help us support them NOW. Click to know more.
          <br />
          More than 1.9 million people are internally displaced having left everything they have ever known behind to flee south, where conditions are
          barely liveable. As famine and hunger spreads, so will waterborne diseases due to lack of access to safe and clean drinking water.
        </div>
      ),
      box_title: (
        <div className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl ">Time is running out for our brothers and sisters in Palestine.</div>
      ),
      box_description: (
        <div className="text-[16px] md:text-[18px]">
          Having delivered more than 100 trucks carrying essential relief items to Palestine, MATW has one of the largest relief operations on the
          ground. Our teams are working round the clock to reach as many people as possible but we can’t do it alone.
          <br />
          <p className="font-black text-[20px] mt-1"> Help us help them. Donate Now.</p>
        </div>
      ),
    },
    {
      title: 'Your support is needed now more than ever.',
      description: `You can start by using your Sadaqah for Palestine, which will help us provide urgent medical support, food, clean water, shelter, and family relocation assistance today. You can also donate your Zakat to Palestine, as our Palestine emergency response program is 100% Zakat-applicable.`,
      box_title: '',
      box_description: ``,
    },
    {
      title: 'Empower Lives with Prosthetic Support',
      description: (
        <p>
          Your generosity can transform a life. By donating to our Prosthetic Limb Fundraise, you’re helping to provide a prosthetic limb to someone
          in need. Every contribution, big or small, goes toward covering the costs of acquiring, customizing, and fitting a prosthetic limb.
          Together, we can offer the gift of mobility, independence, and a brighter future for individuals who deserve a chance to live fully again.
          Join us in making a profound impact today!
          <br />
          <br />
          Among them, thousands have become amputees - losing an arm, a leg or in some cases all their limbs. <i>Imagine</i> not being able to stand
          in Salat, or wrap your arms around your loved ones!
          <br />
          <br />
          The people of Palestine have already lost so much. They’ve been forced from their homes, lost their livelihoods, their belongings and their
          loved ones - don’t let them lose their dignity or hope.
          <br />
          <br />
          <strong>
            As the official named partner of the Jordan Hashemite Charity Organisation, Muslims Around The World Project (MATW Project), will be
            providing prosthetic limbs in Gaza to amputees through a new ground-breaking technology that can fit a limb within one hour!
          </strong>
          <br />
          <br />
          <strong>
            A Sadaqah Jariyah like this not only has the power to save lives but will also reap immense rewards for your Akhirah Insha’Allah!
          </strong>
          <br />
          <br />
          <strong>Help us provide Limbs of Hope for Gazans in need and restore dignity and hope. Donate Now.</strong>
        </p>
      ),
      box_title: '',
      box_description: ``,
    },
    {
      title: 'The Humanitarian Crisis',
      description: (
        <div>
          <p>
            More than 46,000 Palestinians have lost their lives, including over 16,000 children. Nearly 100,000 people have been wounded, with an
            estimated 10,000 buried under rubble. Despite these devastating challenges, MATW remains committed to providing critical relief:
          </p>
          <ul className="pl-4 lg:pl-8 mt-2">
            <li className="list-disc pl-2">Delivered over 330 trucks of essential relief items</li>
            <li className="list-disc pl-2">Providing daily hot meals</li>
            <li className="list-disc pl-2">Offering consistent food packages</li>
            <li className="list-disc pl-2">Delivering vital medical support</li>
          </ul>
          <h6 className="font-bold text-DarkerBlue mt-2">The camp isn’t home, Gaza is. In order to stay they need urgent shelter.</h6>
          <p>Our MATW team has established a large-scale relief camp at Yarmouk Stadium in Gaza City. </p>
          <br />
          <p>
            Equipped with a water well providing clean water and sanitation facilities to help prevent the spread of diseases, this camp aims to
            provide critical assistance to families displaced by recent violence and turmoil.
          </p>
          <h6 className="font-bold text-DarkerBlue mt-2">MATW Camp Infrastructure and Services:</h6>
          <p>
            Whilst this is only a temporary haven, our MATW team has meticulously designed the camp to ensure it delivers vital support to as many of
            our brothers and sisters as possible.
            <br />
            <br />
            The camp includes:
          </p>
          <ul className="flex flex-col gap-y-1">
            <li className="flex gap-1">
              <p className="text-DarkerBlue font-bold max-w-fit">Shelter -</p>
              <p>500 tents protecting families from harsh weather</p>
            </li>
            <li className="flex gap-1">
              <p className="text-DarkerBlue font-bold max-w-fit">Water -</p>
              <p>A central well providing clean, safe drinking water</p>
            </li>
            <li className="flex gap-1">
              <p className="text-DarkerBlue font-bold max-w-fit">Sanitation -</p>
              <p>Dedicated facilities to maintain hygiene and prevent disease</p>
            </li>
            <li className="flex gap-1">
              <p className="text-DarkerBlue font-bold max-w-fit">Energy -</p>
              <p>Solar-powered system with mobile charging stations</p>
            </li>
          </ul>
          <p className="text-DarkerBlue font-bold max-w-fit mt-4">
            Our brothers and sisters in Palestine are hurting and when one part of our Ummah hurts, we all feel the pain. Now is the time to stand
            together to help our brothers and sisters in Palestine.
          </p>
        </div>
      ),
      box_title: '',
      box_description: ``,
    },
    {
      title:
        'The journey of the Lebanon Orphan Centre began on June 23, 2023, and it is much more than just a building—it will be a home for orphans. The orphanage and medical center are being built in Btouratige, North Lebanon. The facility will be a five-story building, with 360 square meters on each floor. It will provide ample space to accommodate and care for 132 children and will also feature a separate medical centre that will serve not only the orphans but also the surrounding community.',
      description: (
        <p>
          Join us in building a future of hope for Lebanon's orphans. Your support will create a safe home for these children and a medical centre for those in need. Donate today and be part of their brighter tomorrow.
          <br />
          <br />
          <strong>Project Facts</strong>
          <br />
          <ul className="list-disc pl-6">
            <li>Where: The orphanage and medical center is being built in Btouratige, North Lebanon.</li>
            <li>The Building: The orphanage will be constructed over five-stories, with 360 square meters on each floor.</li>
            <li>Capacity: The building will be home for more than 130 children. It will also feature a separate medical centre that will serve the medical needs of the orphaned children as well as local community members.</li>
          </ul>
          <br />
          <br />
          <strong className='text-[20px]'>The Impact Of Your Giving</strong>
          <br />
          <br />
          <strong>Empowering Lives Through Your Sadaqah Jariyah.</strong>
          <br />
          One of Ali Banat’s very first projects was the care and sponsorship of an orphan.
          <br />
          Orphan projects are ingrained in DNA of MATW.
          <br />
          Caring for an orphan is also among the most beloved acts that can be performed by any Muslim.
          When you give to help an orphan, it not only has the power to change their life, this Sadaqah
          Jariyah has the power to elevate your Akhirah - the ultimate legacy Insha’Allah.
          <br />
          <br />
          <strong>Be the Change They Deserve</strong>
          <br />
          Every donation brings us one step closer to completing this home. By giving, you’re not just
          providing orphans like Sana with a place to live—you’re also ensuring they have access to a
          medical center that can help an entire community! With your support, they will finally have
          the chance to draw, cook, read, and dream.
          <br />
          Will you help children like Sana—and so many others—find a place to call home?
          <br />
          <br />
          <strong>Donate Now and Make a Difference</strong>
          <br />
          Together, we can create a future where orphans not only have a roof over their heads but also the hope and dignity they deserve.
        </p>
      ),
      box_title: '',
      box_description: ``,
    },
    {
      title: 'Project Breaking Ground needs $500,000 to provide clean water to 150,000 people.',
      description: (
        <p>
          <strong>The ultimate Sadaqah Jariyah</strong> - $1,000 delivers clean water for 300 people through the construction of the pipelines.
          <br />
          <br />
            <strong>Your ticket to Jannah</strong> - $2,000 delivers clean water for 600 people through the construction of the pipelines.
          <br />
          <br />
            <strong>Immeasurable rewards</strong> - $5,000 delivers clean water for 1,500 people through the construction of the pipelines.
          <br />
          <br />
          <strong>A beautiful Akhirah</strong> - $10,000 delivers clean water for 3,000 people through the construction of the pipelines.
        </p>
      ),
      box_title: '',
      box_description: ``,
    },
  ];
  const [selectedInfo, setSelectedInfo] = useState(moreInfo[0]);
  const [link, setLink] = useState('palestine-emergency');
  /* ------------------------------ Start methods ----------------------------- */
  const handlePercentage = (raised, goal) => {
    return `${((Number(raised) / Number(goal)) * 100).toFixed(2)}%`;
  };
  // const handleDaysLeft = () => {
  //   var now = moment(new Date());
  //   // var before = moment(new Date(startDateOfAppealCampaign), 'DD-MM-YYYY HH:mm:ss');
  //   // var timeDifference = now.diff(before, 'days');
  //   // var after = moment(new Date(endDateOfAppealCampaign), 'DD-MM-YYYY HH:mm:ss');
  //   var after = moment(
  //     new Date(
  //       campaign === 'limbs-of-hope' ? endDateOfLimbsAppealCampaign : campaign === 'gaza-camp' ? endDateOfGazaCampCampaign : endDateOfAppealCampaign,
  //     ),
  //     'DD-MM-YYYY HH:mm:ss',
  //   );
  //   var timeDifference = after.diff(now, 'days');
  //   return timeDifference;
  // };
  const ShowCurrencies2 = (currency, _price, showAbbr = true) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = '';
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'EUR':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        _final = `${price}`;
        break;
      case 'IDR':
        _final = `Rp${price}`;
        break;
      case 'AED':
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    }
    return _final;
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    switch (campaign) {
      case 'palestine':
        setSelectedInfo(moreInfo[0]);
        setLink('palestine-emergency');
        break;
      case 'egyptian':
        setSelectedInfo(moreInfo[1]);
        setLink('palestine-refugee-resettlement');
        break;
      case 'limbs-of-hope':
        setSelectedInfo(moreInfo[2]);
        setLink('limbs-of-hope');
        break;
      case 'palestine-water-pipeline':
        setSelectedInfo(moreInfo[5]);
        setLink('palestine-water-pipeline');
        break;
      case 'gaza-camp':
        setSelectedInfo(moreInfo[3]);
        setLink('gaza-camp');
        break;
      case 'north-lebanon-orphan':
        setSelectedInfo(moreInfo[4]);
        setLink('north-lebanon-orphan');
        break;

      default:
        setSelectedInfo(moreInfo[0]);
        setLink('palestine-emergency');
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */

  return (
    <section className="w-full md:container md:mx-auto grid grid-cols-2 gap-2 md:gap-8 pb-8 md:py-8">
      {campaign !== 'palestine-water-pipeline' && <div className="col-span-2 md:col-span-1">
        <div
          className={`basis-full xs:aspect-w-16  xs:aspect-h-9 sm:h-[300px] md:h-[600px] lg:h-[890px] !max-h-[300px] min-h-fit md:!max-h-[450px]   md:!rounded-lg overflow-hidden`}
        >
          <iframe
            className="video"
            width={'100%'}
            height={'100%'}
            title={'youtube'}
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src={
              campaign === 'palestine'
                ? 'https://youtube.com/embed/8fCYKifjeg4?autoplay=0&feature=oembed'
                : campaign === 'egyptian'
                  ? 'https://youtube.com/embed/_KD4SL8KDk0?autoplay=0&feature=oembed'
                  : campaign === 'limbs-of-hope'
                    ? 'https://youtube.com/embed/ScnETpaXUSY?autoplay=0&feature=oembed'
                    : campaign === 'palestine-water-pipeline'
                    ? 'https://youtube.com/embed/ScnETpaXUSY?autoplay=0&feature=oembed'
                    : campaign === 'gaza-camp'
                      ? 'https://youtube.com/embed/s1NIFIYhC-I?autoplay=0&feature=oembed'
                      : campaign === 'north-lebanon-orphan'
                        ? 'https://youtube.com/embed/Kk8ZVagiT-U?autoplay=0&feature=oembed'
                        : 'https://youtube.com/embed/pyV4cpcqvFE?autoplay=0&feature=oembed'
            }
          />
        </div>
      </div>}
      <div className={`col-span-2 ${campaign === 'palestine-water-pipeline' ? '' : 'md:col-span-1'} px-4 py-3 lg:pl-2 lg:pr-0 md:pt-0 md:pb-0`}>
        <h3 className="w-full text-DarkerBlue text-2xl lg:text-3xl xl:text-4xl font-medium my-3 md:my-2 md:leading-none">
          {campaign === 'palestine'
            ? 'Gaza Can’t Wait!'
            : campaign === 'egyptian'
              ? 'Help Rebuild Lives for Palestinians escaping conflict'
              : campaign === 'limbs-of-hope'
                ? 'GAZA: Limbs of Hope'
              : campaign === 'palestine-water-pipeline'
                ? 'BREAK GROUND WITH US IN GAZA!'
                : campaign === 'gaza-camp'
                  ? 'Here To Stay: Build a camp in Gaza!'
                  : 'Help Us Build a New Beginning: Orphanage and Medical Centre in Northern Lebanon'}
        </h3>
        <div className="w-full relative">
          <div className={`my-2 relative overflow-hidden h-auto mb-4`}>
            <p className={`text-[#78716C] text-xs- sm:text-sm overflow-hidden transition-all duration-200 min-h-[79px]- font-inter`}>
              {campaign === 'palestine' ? (
                'For almost a year, too many people have suffered for far too long due to the devastation the conflict in Palestine has left in its wake. The lives of children and their families continue to be uprooted, leaving many orphaned with no one to protect them.'
              ) : campaign === 'egyptian' ? (
                'Imagine losing your belongings, your home, loved ones - everything you’ve ever known. That is the reality for more than 100,000 Palestinians who have fled to neighbouring countries.'
              ) : campaign === 'limbs-of-hope' ? (
                `Deep inside the ruins of Gaza lies a problem not known to many. More than 94,000
                      Palestinians have been severely injured since the beginning of the most recent conflict on
                      October 7, 2023.`
              ) : campaign === 'palestine-water-pipeline' ? (
                `This Ramadan, invest in the ultimate Sadaqah Jariyah project in Gaza - clean water! MATW is breaking ground in three neighbourhoods in Gaza by restoring water infrastructure and building new pipelines. The project will help provide clean water to 150,000 people - imagine the reward Insha’Allah! Your donation right now will not only help provide life’s most basic necessity but also reap immense rewards in the blessed month of Ramadan. Don’t wait. Give like it’s your last Ramadan.`
              )
              : campaign === 'gaza-camp' ? (
                <span>
                  After more than a year of unimaginable suffering, Palestinian families now face the brutal threat of winter without shelter. Their
                  homes destroyed, these resilient communities remain determined to survive and stay. Beneath the rubble lies Gaza - a home that beats
                  with an unbreakable spirit.
                  <br />
                  <br />
                  MATW is building a camp to shelter 400 families - 2,500 people - and we urgently need your support to provide tents, supplies, and
                  hope in their darkest hour. The people of Gaza have chosen life, and together, we can ensure they stay. Use your Zakat and Sadaqah
                  to save lives today.
                </span>
              ) : (
                <span>
                  Amidst the chaos of crippling economic crisis and mounting unrest, the most vulnerable - orphaned children - suffer in silence.
                  With families torn apart by poverty and instability, countless children are left without the care and protection they so desperately need.
                  Already marked by loss and uncertainty, are now further endangered in a system stretched beyond capacity.
                  <br />
                  <br />
                  MATW’s Orphanage Project in Lebanon is not just a shelter, it is a lifeline, offering these children a chance to heal, to grow and to be
                  nurtured in a world that has often overlooked them. Providing stability, education, love and medical care through its in-house medical centre,
                  we can offer a future where these children no longer bear the weight of their circumstances.
                  <br />
                  <br />
                  Help build the MATW Orphanage & Medical Centre today.
                </span>
              )}
            </p>
          </div>
        </div>
        <CustomButton
          onClick={() => setShowInfo(!showInfo)}
          title={'Click to know more'}
          icon={<ChevronDownIcon className={`w-5 h-5 transition-all duration-300 cursor-pointer ml-1 ${showInfo ? ' transform rotate-180' : ''}`} />}
          type="button"
          className={`!bg-LightBlue !text-[10px] !h-8`}
        />
        <div
          className={`flex flex-col overflow-hidden w-full bg-stone-100- rounded-md ${showInfo ? 'min-h-[600px] py-4 md:py-8' : 'h-0 py-0'
            } transition-all duration-200`}
        >
          <div className={`text-2xl md:text-4xl mb-4 ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-DarkerBlue`}>
            {selectedInfo.title}
          </div>
          <div className={`text-[#78716C] md:text-lg ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}>
            {selectedInfo.description}
            {/* {<RawHTML>{selectedInfo.description}</RawHTML>} */}
          </div>
          <div className="bg-white rounded-md shadow-md mt-2 grid grid-cols-2 gap-2 md:p-4">
            <div className="col-span-2">
              <div className={`text-DarkerBlue text-4xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-gotcha- font-brandingBold'}`}>
                {selectedInfo.box_title}
              </div>
            </div>
            <div className="col-span-2 mb-4">
              <div className={`text-[#78716C] md:text-lg ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}>
                {selectedInfo.box_description}
              </div>
            </div>
          </div>
          <CustomButton
            onClick={() => setShowInfo(!showInfo)}
            title={'Close'}
            icon={<ChevronDownIcon className={`w-5 h-5 transition-all duration-300 cursor-pointer ml-1 transform rotate-180`} />}
            type="button"
            className={`!bg-white !text-LightBlue !text-[10px] !h-8 shadow-md`}
          />
        </div>
        <div className="w-full flex-col flex justify-center pt-2 md:pt-4">
          <div className="flex items-center justify-between mb-2">
            <div className="flex flex-col w-full">
              <div className="text-lg md:text-2xl text-DarkerBlue basis-full font-bold">
                {currentProduct && currentProduct.totalRaised
                  ? ShowCurrencies2(selectedCurrency, Math.round(currentProduct.totalRaised), true)
                  : ShowCurrencies2(selectedCurrency, 0, true)}
                <span className="text-sm ml-0.5">{selectedCurrency}</span>
              </div>
              <div className="flex items-center gap-1">
                <span className="text-sm inline-block lg:w-auto">Raised of </span>
                <div className="text-DarkerBlue font-bold">
                  {ShowCurrencies2(selectedCurrency, Math.round(ConvertUSD(selectedCurrency, goal || 500000, false), false))}
                </div>
                <span className="text-sm inline-block lg:w-auto">goal</span>
              </div>
            </div>
          </div>
          <div className="flex w-full h-2 bg-[#E3E3E3] bg-opacity-10- mb-2">
            {currentProduct && (
              <div
                className={`bg-[#F60362]`}
                style={{
                  width: `${handlePercentage(currentProduct ? currentProduct.totalRaised : 0, ConvertUSD(selectedCurrency, goal, false))}`,
                }}
              />
            )}
          </div>
          <div className="flex w-full text-xs">
            <div className="text-DarkerBlue font-bold mr-0.5">{currentProduct ? currentProduct.totalSupporters : 0}</div>
            <span className="inline-block lg:w-auto mr-1">Supporters,</span>
            <div className="text-DarkerBlue font-bold mr-0.5">{handleDaysLeft(campaign)}</div>
            <span className="inline-block lg:w-auto">days left</span>
          </div>
        </div>
        <div className="w-full hidden sm:flex items-center mt-4 md:mt-8">
          {currentProduct ? (
            <div className="w-full hidden sm:flex items-center">
              {campaign === 'limbs-of-hope' || campaign === 'palestine-water-pipeline' || campaign === 'palestine' || campaign === 'north-lebanon-orphan' || campaign === 'gaza-camp' ? (
                selectedGivingProducts.length ? (
                  <CustomButton
                    link={`/checkout`}
                    title={'Donate Now'}
                    type="button"
                    className={`!rounded-full w-full sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                  />
                ) : (
                  <CustomButton
                    title={'Donate Now'}
                    type="button"
                    className={`!rounded-full w-full sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                    onClick={() => addProduct({ product: currentProduct, currency: selectedCurrency })}
                  />
                )
              ) : (
                <CustomButton
                  link={`/appeals/${link}`}
                  title={'Donate Now'}
                  type="button"
                  className={`!rounded-full w-full sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                />
              )}
              <div className="order-1 md:order-2">
                <SocialBox>
                  <div
                    className={`!rounded-full !bg-DarkerBlue flex items-center justify-center text-center font-['Montserrat'] font-bold px-4 text-[12px] md:text-[14px] h-[42px] md:h-[44px] transition duration-150 ease-in-out uppercase`}
                  >
                    Share
                  </div>
                </SocialBox>
              </div>
            </div>
          ) : (
            <Skeleton height={43} />
          )}
        </div>
        <div className="flex sm:hidden [&>div]:w-full z-10">
          <Sticky
            stickyStyle={{ bottom: '0px', top: 'unset' }}
            stickyClassName="w-full flex justify-end items-center shadow-[rgba(189,189,189,0.5)_0px_-15px_25px_-1px] z-10"
            mode="top"
            onFixedToggle={() => {
              setIsTabsFixed(!isTabsFixed);
            }}
          >
            <div className="w-full flex items-center bg-white rounded-t-md p-2">
              {campaign === 'limbs-of-hope' || campaign === 'palestine-water-pipeline' || campaign === 'palestine' || campaign === 'north-lebanon-orphan' || campaign === 'gaza-camp' ? (
                selectedGivingProducts.length ? (
                  <CustomButton
                    link={`/checkout`}
                    title={'Donate Now'}
                    type="button"
                    className={`!rounded-full w-full- w-[calc(100%_-_140px)] sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                  />
                ) : (
                  <CustomButton
                    title={'Donate Now'}
                    type="button"
                    className={`!rounded-full w-full- w-[calc(100%_-_140px)] sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                    onClick={() => addProduct({ product: currentProduct, currency: selectedCurrency })}
                  />
                )
              ) : (
                <CustomButton
                  link={`/appeals/${link}`}
                  title={'Donate Now'}
                  type="button"
                  className={`!rounded-full w-full- w-[calc(100%_-_140px)] sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                />
              )}
              <SocialBox top={isTabsFixed}>
                <div
                  className={`!rounded-full !bg-DarkerBlue flex items-center justify-center text-center font-['Montserrat'] font-bold px-4 text-[12px] md:text-[14px] h-[42px] md:h-[44px] transition duration-150 ease-in-out uppercase`}
                >
                  Share
                </div>
              </SocialBox>
            </div>
          </Sticky>
        </div>
      </div>
    </section>
  );
};

export default AppealCurrentProduct;
