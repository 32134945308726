import { Builder } from '@builder.io/react';
import React, { useEffect, useRef, useState } from 'react';
import RamadanTabContent from '../../../../../general/ramadanTabs2/tab-content2';
import { selectProducts } from '../../../../../../store/products';
import { useSelector } from 'react-redux';
import WidgetZakatVertical from '../../../components/general/WidgetZakatVertical';

const RamadanTabsWidget = props => {
  const { tabs, showZakatCalculator } = props;
  const contentRef = useRef(null);
  const visibleTabs = tabs.filter(tab => tab.isTabsVisible !== false);

  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const [tabTopProducts, setTabTopProducts] = useState([]);
  const [tabBottomeProducts, setTabBottomeProducts] = useState([]);
  const [topCardProduct, setTopCardProduct] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const _allProducts = useSelector(state => selectProducts(state));

  const getAllProducts = _allProducts => {
    setIsLoaded(true);
    let tempSelectedTopProducts = [];
    let tempSelectedBottomProducts = [];
    let tempSelectedTopCardProduct = [];

    tabs.forEach(() => {
      tempSelectedTopCardProduct.push({ products: [] });
      tempSelectedTopProducts.push({ products: [] });
      tempSelectedBottomProducts.push({ products: [] });
    });

    const normalizeArray = value => {
      if (Array.isArray(value)) return value;
      if (typeof value === "string") return value.split(",").map(id => id.trim());
      return [];
    };
    
    _allProducts.forEach(item => {
      item.sub_categories.forEach(innerItem => {
        innerItem.products.forEach(innerProduct => {
          tabs.forEach((tabItem, tabIndex) => {
            const topProductsArray = normalizeArray(tabItem.topProducts);
            const bottomProductsArray = normalizeArray(tabItem.bottomProducts);
    
            if (topProductsArray.includes(innerProduct.creator)) {
              tempSelectedTopProducts[tabIndex].products.push(innerProduct);
            }
            if (bottomProductsArray.includes(innerProduct.creator)) {
              tempSelectedBottomProducts[tabIndex].products.push(innerProduct);
            }
            if (tabItem.topCardProduct && tabItem.topCardProduct.trim() === innerProduct.creator.trim()) {
              tempSelectedTopCardProduct[tabIndex].products.push(innerProduct);
            }
          });
        });
      });
    });
    

    tabs.forEach((tabItem, tabIndex) => {
      if (tabItem.topProducts) {
        tempSelectedTopProducts[tabIndex].products = Array.from(
          new Set(tempSelectedTopProducts[tabIndex].products.map(item => item.creator))
        )
          .map(creator => {
            const foundProduct = tempSelectedTopProducts[tabIndex].products.find(product => product.creator === creator);
            return foundProduct;
          })
          .sort((a, b) => {
            return tabItem.topProducts.indexOf(a.creator) - tabItem.topProducts.indexOf(b.creator);
          });
      }
      if (tabItem.bottomProducts) {
        tempSelectedBottomProducts[tabIndex].products = Array.from(
          new Set(tempSelectedBottomProducts[tabIndex].products.map(item => item.creator))
        )
          .map(creator => {
            const foundProduct = tempSelectedBottomProducts[tabIndex].products.find(product => product.creator === creator);
            return foundProduct;
          })
          .sort((a, b) => {
            return tabItem.bottomProducts.indexOf(a.creator) - tabItem.bottomProducts.indexOf(b.creator);
          });
      }
    });

    setTabTopProducts(tempSelectedTopProducts);
    setTabBottomeProducts(tempSelectedBottomProducts);
    setTopCardProduct(tempSelectedTopCardProduct);
  };

  useEffect(() => {
    if (tabs.length > 0) {
      _allProducts && Object.keys(_allProducts).length && getAllProducts(_allProducts);
    }
  }, [_allProducts, tabs]);

  // Insert Zakat tab at the second position if showZakatCalculator is true
  const tabsWithZakat = showZakatCalculator ? [tabs[0], { title: 'ZAKAT', isZakat: true }, ...tabs.slice(1)] : tabs;

  // Function to get the original tab index based on the activeTabProducts state
  const getOriginalTabIndex = index => {
    if (!showZakatCalculator) return index;
    if (index === 1) return -1; // Zakat tab has no corresponding original tab
    return index < 1 ? index : index - 1;
  };

  return (
    <section className="bg-transparent">
      <style>
        {`
          @keyframes blink {
            0% { background-color: white; color: rgb(30,60,131); }
            50% { background-color: rgb(30,60,131); color: white; }
            100% { background-color: white; color: rgb(30,60,131); }
          }
          .blinking-tab {
            animation: blink 1s infinite;
          }
        `}
      </style>
      <div className="md:container w-full mx-auto px-0 py-4 ">
        <div className={`flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center ${visibleTabs.length > 0 ? '' : 'hidden'}`} >
          {tabsWithZakat.map(({ title, isZakat }, index) => (
            <button
              key={`btn_${index}`}
              onClick={() => setActiveTabProducts(index)}
              className={`${
                index === activeTabProducts
                  ? 'bg-[rgb(30,60,131)] text-white'
                  : isZakat && index !== activeTabProducts
                  ? 'blinking-tab border-2 border-[rgb(30,60,131)]'
                  : 'bg-white text-[rgb(30,60,131)] border-2 border-[rgb(30,60,131)]'
              } text-[14px] md:text-base rounded-full max-[500px]:col-span-2 p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase font-['Montserrat'] font-bold`}
            >
              {title}
            </button>
          ))}
        </div>

        <div className={`flex flex-wrap items-center justify-center ${visibleTabs.length > 0 ? 'gap-6 md:gap-12 my-5 md:my-10' : ''}`}>
          <div className="flex flex-col w-full justify-center">
            {tabsWithZakat.map((item, index) => {
              const originalTabIndex = getOriginalTabIndex(index);
              return (
                index === activeTabProducts && (
                  <React.Fragment key={`description_${index}`}>
                    {item.isZakat ? (
                      <div
                        ref={contentRef}
                        className="w-full justify-center items-center md:container md:mx-auto bg-[#DFEEF2]- bg-transparent rounded-md p-2 md:p-4"
                      >
                        <h2 className="text-3xl max-w-[738px] justify-center place-self-center font-bold text-primaryDark mb-4 text-center">Transform entire communities, lifting people out of poverty. </h2>
                        <WidgetZakatVertical
                          className={'bg-[#DFEEF2]- bg-transparent  h-auto py-6'}
                          zakatCalculator={true}
                          CustomProductsList={['DD-ZK-PAL-25-01','DD-ZK-PAL-25-00','DD-ZK-PAL-25-02','DD-ZK-PAL-25-03']}
                          defaultCreator={'DD-ZK-PAL-25-01'}
                          defaultName={'Give Your Zakat to Gaza'}
                          defaultProduct={{
                            creator: 'DD-ZK-PAL-25-01',
                            label: 'Give Your Zakat to Gaza',
                            value: 884,
                          }}
                        />
                      </div>
                    ) : (
                      <div ref={contentRef}>
                        {/* Map the original tab data correctly */}
                        <RamadanTabContent
                          topProducts={tabTopProducts[originalTabIndex] ? tabTopProducts[originalTabIndex].products : []}
                          bottomProducts={tabBottomeProducts[originalTabIndex] ? tabBottomeProducts[originalTabIndex].products : []}
                          topBanner={tabs[originalTabIndex] ? tabs[originalTabIndex].topBanner : { image: '', alt: '', link: '' }}
                          cardTitle={tabs[originalTabIndex] ? tabs[originalTabIndex].cardTitle : ''}
                          cardSubTitle={
                            tabs[originalTabIndex]
                              ? tabs[originalTabIndex].cardSubTitle
                              : 'Each moment that Allah SWT gives us is a blessing - an opportunity. What will you do with yours? Why wait for the month of Ramadan to come when tomorrow isn’t promised? Build your Akhirah and save lives now.'
                          }
                          topCardTitle={tabs[originalTabIndex] ? tabs[originalTabIndex].topCardTitle : ''}
                          topCardProduct={topCardProduct[originalTabIndex] ? topCardProduct[originalTabIndex].products : []}
                          topCardImageBlue={tabs[originalTabIndex] ? tabs[originalTabIndex].topCardImageBlue : ''}
                          isLoaded={isLoaded}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

Builder.registerComponent(RamadanTabsWidget, {
  name: 'RamadanTabsWidget2',
  inputs: [
    {
      name: 'tabs',
      type: 'list',
      defaultValue: [],
      minRows: 0,
      maxRows: 6,
      defaultItem: {
        topBanner: '',
        title: '',
        description: '',
        cardTitle: '',
        cardSubTitle: '',
        topCardTitle: '',
        topCardProduct: '',
        topProducts: [],
        bottomProducts: [],
        isTabsVisible: true,
      },
      subFields: [
        {
          name: 'topBanner',
          type: 'object',
          required: true,
          subFields: [
            {
              name: 'image',
              type: 'file',
              required: true,
              allowedFileTypes: ['png', 'jpg', 'jpeg'],
              description: 'Image for banner',
            },
            {
              name: 'alt',
              type: 'string',
              required: true,
              description: 'Alt text for the banner image',
            },
            {
              name: 'link',
              type: 'url',
              required: false,
              description: 'Link for the banner',
            },
          ],
        },
        
        {
          name: 'title',
          type: 'string',
          required: true,
          helpText: 'Enter the Tab Title.',
        },
        {
          name: 'description',
          type: 'string',
          required: true,
          helpText: 'Enter the Description.',
        },
        {
          name: 'cardTitle',
          type: 'string',
          required: false,
          helpText: 'Enter the Card Title.',
        },
        {
          name: 'cardSubTitle',
          type: 'html',
          required: false,
          helpText: 'Enter the Card Sub Title.',
        },
        {
          name: 'topCardTitle',
          type: 'string',
          required: false,
          helpText: 'Enter the top Card Title.',
        },
        {
          name: 'topCardImageBlue',
          type: 'file',
          required: true,
          allowedFileTypes: ['png', 'jpg', 'jpeg'],
          description: 'Image for the Food for Orphan main',
        },
        {
          name: 'topCardProduct',
          type: 'string',
          required: false,
          helpText: 'Enter the top Card Product.',
        },
        {
          name: 'topProducts',
          type: 'string',
          required: true,
          helpText: 'Enter the Top Products.',
        },
        {
          name: 'bottomProducts',
          type: 'string',
          required: true,
          helpText: 'Enter the Bottom Products.',
        },
        {
          name: 'isTabsVisible',
          type: 'boolean',
          defaultValue: true,
          helpText: 'Toggle to show/hide this tab.',
        },
      ],
    },
    {
      name: 'showZakatCalculator',
      type: 'boolean',
      defaultValue: false,
      helpText: 'Toggle to display the Zakat Calculator.',
    },
  ],
});

export default RamadanTabsWidget;
