import { Link, useLocation } from "react-router-dom";
import notificationIcon from '../../../images/general/notification-bar-icon.png';
import notificationDownBtn from '../../../images/general/notification-bar-down-btn.png';

const NotificationBar = ({
  title = "Send your Zakat to Gaza",
  pageLink = "/zakat",
  classNames = "",
  excludedRoutes = ["/checkout","/zakat"]
}) => {
    const location = useLocation();

  if (excludedRoutes.includes(location.pathname)) {
    return null; // Hide NotificationBar for these routes
  }

  return (
    <Link
      to={pageLink}
      className={`block w-full mx-auto text-white bg-[#FF005D] font-signika text-[16px] leading-[28px] py-1 px-4 uppercase relative border-b-2 border-[#FFFFFF7D] ${classNames}`}
    >
      <div className="w-full flex items-center justify-center gap-1 sm:gap-3">
        <img src={notificationIcon} alt="bell icon" className="flex-shrink-0 w-[20px] sm:w-[24px]" />

        {/* Desktop - static title */}
        <span className="hidden sm:block text-center">{title}</span>

        {/* Mobile - scrolling marquee title only */}
        <div className="sm:hidden w-full overflow-hidden relative flex-1">
          <div className="notification-marquee-track flex">
            <span className="pr-4 whitespace-nowrap">{title}</span>
            <span className="pr-4 whitespace-nowrap">{title}</span>
          </div>
        </div>
        <img src={notificationDownBtn} alt="arrow icon" className="rotate-[90deg] flex-shrink-0 w-[20px] sm:w-[24px]" />
      </div>
    </Link>
  );
};

export default NotificationBar;
