import { useEffect, useState, useCallback, useRef } from 'react';
import WidgetHome from '../../components/widgets/widgetHomeOrdered';
import { selectSelectedCurrency } from '../../store/user';
import { useSelector } from 'react-redux';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import { Helmet } from 'react-helmet-new';
import IftarDonationWidget from '../../components/general/ramadanTabs2/IftarDonationWidget2';
import services from '../../services';
import ProductsTabVertical from '../../components/general/productsTabVertical';
import AppealWidgetNewWithCarousel from '../MATWAppeal/components/appealWidgetNewWithCarousel';
import { appealProductsCodes, endDateOfAppealCampaign, startDateOfAppealCampaign } from '../MATWAppeal/details';
import refugeeImg from '../../images/landings/palestine-refugee/Help-Rebuild-Lives-for-Palestinians-escaping-conflict.jpg';
import limbsImg from '../../images/landings/palestine/big-Limbs_of_Hope_Campaign_Website_Image.jpg';
import ramadanAppaelImg from '../../images/ramadan/Ramadan Appeal.jpg';
import bringRamadanImg from '../../images/ramadan/Bring Ramadan to Gaza Appeal.jpg';
import { checkDomain } from '../../utils/checkDomain';
import tiktokBanner from '../../images/banners/tiktok_banner.jpg';
import snapchatBanner from '../../images/banners/snapchat_banner.jpg';

function TikTokLandingPage({ lang = 'en', isTikotok=false }) {
  console.log(isTikotok,'checking');
  const iftarDonationWidgetRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [selectedCurrency, setSelectedCurrency] = useState(selectedCurrencyStore || 'AUD');
  const [tabTopProducts, setTabTopProducts] = useState([]);
  const [tabBottomProducts, setTabBottomProducts] = useState([]);
  const [topCardProduct, setTopCardProduct] = useState([]);
  const [waterAidProductsAll, setWaterAidProductsAll] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [ramadanProducts, setRamadanProducts] = useState([0, 0, 0, 0, 0, 0]);

  const [urgentAppealProducts2, setUrgentAppealProducts2] = useState([0, 0, 0, 0, 0, 0]);
  const [urgentAppealProducts, setUrgentAppealProducts] = useState([0, 0, 0, 0, 0]);

  const [isLoaded, setIsLoaded] = useState(false);
  const topProducts = ['RM25-00-002', 'RM25-00-003', 'RM25-00-004', 'RM25-00-001'];
  const bottomProducts = ['TXT-RM25-00-01', 'TXT-RM25-00-02', 'RM25-00-011', 'TXT-RM25-00-03', 'TXT-RM25-00-04'];
  const cardTitle = '';
  const cardSubTitle = '';
  const topCardTitle = '';
  const topCardImageBlue = '';
  const WaterAidProductsAllIds = [
    'LP-PAL-OR-1-003',
    'LP-PAL-OR-1-004',
    'FST-DEL-F25-23-01-008',
    'FWD-DIS-BMW-2023-01-048',
    'FWD-DIS-BMM-2023-01-049',
    'EMR-DIS-AA-2024-404',
    'AO07-FWD-DIS-FAO-2023-01-062',
  ];

  const getLatestOrders = async _currency => {
    let req = {
      products: appealProductsCodes,
      currency: _currency || 'AUD',
      start_date: startDateOfAppealCampaign,
      end_date: endDateOfAppealCampaign,
    };
    let tempPalestineTotalRaised = 0;
    let tempPalestineTotalSupporters = 0;
    let tempEgyptianTotalRaised = 0;
    let tempEgyptianTotalSupporters = 0;
    let tempLimbsTotalRaised = 0;
    let tempLimbsTotalSupporters = 0;
    let tempGazaCampTotalRaised = 0;
    let tempGazaCampTotalSupporters = 0;
    let tempPalestineWaterTotalRaised = 0;
    let tempPalestineWaterTotalSupporters = 0;
    let tempPalestineEmergency = {
      product_id: 592,
      product_category_id: 65,
      product_name: 'Gaza Can’t Wait!',
      product_creator: 'MA-PAL-GL-1-001',
      product_description:
        'For almost a year, too many people have suffered for far too long due to the devastation the conflict in Palestine has left in its wake. The lives of children and their families continue to be uprooted, leaving many orphaned with no one to protect them.',
      product_image_link: 'https://cdn.matwproject.org/images/products/large-palestine+emergency.jpg',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: _currency,
      goal: 1000000,
      campaign: 'palestine',
      link: 'palestine-emergency',
    };
    let tempPalestineRefuggeSettlement = {
      product_id: 601,
      product_category_id: 66,
      product_name: 'Help Rebuild Lives for Palestinians escaping conflict',
      product_creator: 'MA-PAL-GL-3-001',
      product_description:
        'Imagine losing your belongings, your home, loved ones - everything you’ve ever known. That is the reality for more than 100,000 Palestinians who have fled to neighbouring countries.',
      product_image_link: refugeeImg,
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: _currency,
      goal: 500000,
      campaign: 'egyptian',
      link: 'palestine-refugee-resettlement',
    };
    let tempLimbsOfHope = {
      product_id: 638,
      product_category_id: 73,
      product_name: 'GAZA: Limbs of Hope',
      product_creator: 'EMR-PAL-MASU-003',
      product_description:
        'Your generosity can transform a life. By donating to our Prosthetic Limb Fundraise, you’re helping to provide a prosthetic limb to someone in need.',
      product_image_link: limbsImg,
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: _currency,
      goal: 1000000,
      campaign: 'limbs-of-hope',
      link: 'limbs-of-hope',
    };
    let tempPalestineWaterPipeline = {
      product_id: 864,
      product_category_id: 27,
      product_name: 'GAZA: Palestine Water Pipeline',
      product_creator: 'EMR-PAL-PW-2025-001',
      product_description: 'Water for Gaza, Palestine. Donate to our appeal to restore water for palestine. Gaza strip water supply reconstruction.',
      product_image_link: 'https://cdn.matwproject.org/images/products/1741261324_matw-product-image.jpg',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: selectedCurrencyStore,
      goal: 500000,
      campaign: 'palestine-water-pipeline',
      link: 'palestine-water-pipeline',
    };
    let tempGazaCamp = {
      product_id: 747,
      product_category_id: 27,
      product_name: 'Here To Stay: Build a camp in Gaza!',
      product_creator: 'EMR-PAL-GEN-24-11-1',
      product_description:
        'After more than a year of unimaginable suffering, Palestinian families now face the brutal threat of winter without shelter. Their homes destroyed, these resilient communities remain determined to survive and stay. Beneath the rubble lies Gaza - a home that beats with an unbreakable spirit.',
      product_image_link: 'https://cdn.matwproject.org/images/products/1731507690_gaza-shelter-kit.jpg',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: _currency,
      goal: 2000000,
      campaign: 'gaza-camp',
      link: 'gaza-camp',
    };
    try {
      const response = await services.getLatestOrdersV2(req);
      // setIsLoadedLatestOrders(true)
      let allOrders = response.data;
      allOrders.map(item => {
        if (item.product_category_id === 65) {
          tempPalestineTotalRaised += item.total_sale;
          tempPalestineTotalSupporters += item.total_supporters;
        }
        if (item.product_category_id === 66) {
          tempEgyptianTotalRaised += item.total_sale;
          tempEgyptianTotalSupporters += item.total_supporters;
        }
        if (item.product_category_id === 73) {
          tempLimbsTotalRaised += item.total_sale;
          tempLimbsTotalSupporters += item.total_supporters;
        }
        if (item.product_category_id === 27) {
          tempGazaCampTotalRaised += item.total_sale;
          tempGazaCampTotalSupporters += item.total_supporters;
        }
        if (item.product_creator === 'EMR-PAL-PW-2025-001') {
          tempPalestineWaterTotalRaised += item.total_sale;
          tempPalestineWaterTotalSupporters += item.total_supporters;
        }
        return item;
      });
      tempPalestineEmergency.total_sale = tempPalestineTotalRaised;
      tempPalestineEmergency.total_supporters = tempPalestineTotalSupporters;
      tempPalestineRefuggeSettlement.total_sale = tempEgyptianTotalRaised;
      tempPalestineRefuggeSettlement.total_supporters = tempEgyptianTotalSupporters;
      tempLimbsOfHope.total_sale = tempLimbsTotalRaised;
      tempLimbsOfHope.total_supporters = tempLimbsTotalSupporters;
      tempGazaCamp.total_sale = tempGazaCampTotalRaised;
      tempGazaCamp.total_supporters = tempGazaCampTotalSupporters;
      tempPalestineWaterPipeline.total_sale = tempPalestineWaterTotalRaised;
      tempPalestineWaterPipeline.total_supporters = tempPalestineWaterTotalSupporters;

      setUrgentAppealProducts(prev => {
        if (checkDomain('matwprojectusa.org', 'matwproject.org.uk', 'matwproject.org')) {
          prev[1] = tempGazaCamp;
          prev[7] = tempPalestineEmergency;
          prev[8] = tempLimbsOfHope;
        } else {
          prev[1] = tempGazaCamp;
          prev[2] = tempPalestineEmergency;
          prev[5] = tempLimbsOfHope;
        }

        return [...prev];
      });
      let ramadanAppealPrd = {
        name: 'Ramadan Appeal',
        description:
          'This Ramadan, light up the lives of those in need. Your generous donations will provide iftars for families, nourishing food packs for the needy, comforting hot meals for orphans, and heartfelt Zakat for the most vulnerable. Together, we can spread the true essence of compassion',
        status: 1,
        isStaticProduct: true,
        staticLink: '/ramadan',
        image_link: ramadanAppaelImg,
        staticLinkText: 'Donate Now',
      };
      let bringRamadanPrd = {
        name: 'Save Ramadan in Gaza',
        description: 'Ensure that families in Gaza have all they need to survive and commemorate the month of Ramadan with dignity.',
        status: 1,
        isStaticProduct: true,
        staticLink: '/crisis-and-emergencies/palestine',
        image_link: bringRamadanImg,
        staticLinkText: 'Donate Now',
      };
      setUrgentAppealProducts2(prev => {
        const updated = [...prev]; // Create a new array copy
        updated[0] = bringRamadanPrd;
        updated[1] = ramadanAppealPrd;
        updated[3] = tempGazaCamp;
        updated[4] = tempPalestineEmergency;
        updated[5] = tempLimbsOfHope;
        // updated[5] = tempPalestineWaterPipeline;
        return updated;
      });
    } catch (error) {
      console.error('error========', error);
    }
  };
  const getLatestOrdersForPalestineWaterPiplien = async _currency => {
    let req = {
      products: appealProductsCodes,
      currency: _currency || 'AUD',
      start_date: startDateOfAppealCampaign,
      end_date: '2025-03-30',
    };
    let tempPalestineWaterTotalRaised = 0;
    let tempPalestineWaterTotalSupporters = 0;
    let tempPalestineWaterPipeline = {
      product_id: 864,
      product_category_id: 27,
      product_name: 'GAZA: Palestine Water Pipeline',
      product_creator: 'EMR-PAL-PW-2025-001',
      product_description: 'Water for Gaza, Palestine. Donate to our appeal to restore water for palestine. Gaza strip water supply reconstruction.',
      product_image_link: 'https://cdn.matwproject.org/images/products/1741261324_matw-product-image.jpg',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: selectedCurrencyStore,
      goal: 500000,
      campaign: 'palestine-water-pipeline',
      link: 'palestine-water-pipeline',
    };
    try {
      const response = await services.getLatestOrdersV2(req);
      // setIsLoadedLatestOrders(true)
      let allOrders = response.data;
      allOrders.map(item => {
        if (item.product_creator === 'EMR-PAL-PW-2025-001') {
          console.log('found==', item);
          tempPalestineWaterTotalRaised += item.total_sale;
          tempPalestineWaterTotalSupporters += item.total_supporters;
        }
        return item;
      });

      tempPalestineWaterPipeline.total_sale = tempPalestineWaterTotalRaised;
      tempPalestineWaterPipeline.total_supporters = tempPalestineWaterTotalSupporters;
      setUrgentAppealProducts2(prev => {
        const updated = [...prev]; // Create a new array copy
        updated[2] = tempPalestineWaterPipeline;
        return updated;
      });
    } catch (error) {
      console.error('error========', error);
    }
  };
  const allCarousalProductsTab = [
    {
      title: 'urgent appeals',
      products: (
        <AppealWidgetNewWithCarousel className="bg-transparent !py-0" allProducts={urgentAppealProducts2.filter(i => !!i || i === 0)} isHomePage />
      ),
      description: '',
      isCarousel: true,
    },
    {
      title: lang === 'fr' ? 'AIDE ALIMENTAIRE' : lang === 'ar' ? 'مساعدات غذائية' : 'Food and Water Aid',
      description: '',
      products: (
        <AppealWidgetNewWithCarousel className="bg-transparent !py-0" allProducts={waterAidProductsAll.filter(i => !!i || i === 0)} isHomePage />
      ),

      sortOrder: 'asc',
      isCarousel: true,
    },
  ];

  const getSelectedProducts = useCallback(async () => {
    setIsLoaded(true);
    try {
      const response = await services.allProducts();
      let tempSelectedTopProducts = [];
      let tempSelectedBottomProducts = [];
      let tempSelectedTopCardProduct = [];
      let tempWaterAidProducts = [];
      let tempRamadanProducts = [];
  
      response.data.forEach(item => {
        item.sub_categories.forEach(innerItem => {
          innerItem.products.forEach(innerProduct => {
            if (topProducts.includes(innerProduct.creator)) {
              tempSelectedTopProducts.push(innerProduct);
            }
            if (bottomProducts.includes(innerProduct.creator)) {
              tempSelectedBottomProducts.push(innerProduct);
            }
            if (topCardProduct === innerProduct.creator) {
              tempSelectedTopCardProduct.push(innerProduct);
            }
            if (WaterAidProductsAllIds.includes(innerProduct.creator)) {
              tempWaterAidProducts.push(innerProduct);
            }
          });
        });
      });
  
      // Sort selected products based on the predefined order
      tempSelectedTopProducts.sort((a, b) => topProducts.indexOf(a.creator) - topProducts.indexOf(b.creator));
      tempSelectedBottomProducts.sort((a, b) => bottomProducts.indexOf(a.creator) - bottomProducts.indexOf(b.creator));
  
      setTabTopProducts(tempSelectedTopProducts);
      setTabBottomProducts(tempSelectedBottomProducts);
      setTopCardProduct(tempSelectedTopCardProduct);
      setWaterAidProductsAll(tempWaterAidProducts.filter(n => n));
      setRamadanProducts(tempRamadanProducts.filter(n => n));
  
    } catch (error) {
      console.error('Error fetching selected products:', error);
    }
  }, [topProducts, bottomProducts, topCardProduct]);
  

  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    if (selectedCurrencyStore) {
      getLatestOrders(selectedCurrencyStore);
      getLatestOrdersForPalestineWaterPiplien(selectedCurrencyStore);
    } else {
      // getLatestOrders('AUD');
    }
    // getOrders(selectedCurrencyStore)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);

  useEffect(() => {
    getSelectedProducts();
  }, [getSelectedProducts]);

  useEffect(() => {
    if (selectedCurrencyStore) {
      setSelectedCurrency(selectedCurrencyStore);
    }
  }, [selectedCurrencyStore]);

  return (
    <NewHelmetLayout hideFooter className="bg-white " childClasses={'pb-0'}>
      {seo && (
        <Helmet>
          <title>{isTikotok ? `${seo['tiktok']['title']}` : `${seo['snapchat']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={isTikotok ? `${seo['tiktok']['description']}` : `${seo['snapchat']['description']}`} />
        </Helmet>
      )}

      <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />

      <div className="bg-[#EAF7FF]">
        <div className="flex w-full h-full md:w-[70%] md:h-[70%] md:py-8 place-self-center">
          {
            <img
              src={isTikotok ? tiktokBanner : snapchatBanner}
              alt={'yasminmogahedindonesia'}
              className="w-full"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/images/general/logo-replace.png';
                currentTarget.className = 'max-h-[150px] mx-auto';
              }}
            />
          }
        </div>
      </div>

      <div className="flex w-full flex-wrap justify-center items-start gap-2 md:gap-4 md:h-auto bg-[#EAF7FF]" ref={iftarDonationWidgetRef}>
        <IftarDonationWidget
          topList={tabTopProducts}
          bottomList={tabBottomProducts}
          cardTitle={cardTitle}
          cardSubTitle={cardSubTitle}
          topCardTitle={topCardTitle}
          topCardProduct={topCardProduct}
          topCardImageBlue={topCardImageBlue}
        />
      </div>

      <ProductsTabVertical sections={allCarousalProductsTab} selectedCurrency={selectedCurrency} />

      {isTikotok ? (
        <section class="mb-0 md:mb-8 px-5 md:px-[40px] py-5 md:py-[40px] bg-[url('../src/images/ali-banat/feel-pattern.png')] bg-[#14a3dc2c]">
          <div class="mx-auto px-4 sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1200px] 2xl:max-w-[1400px] bg-white p-5 md:p-[40px] md-p-[40px] rounded-[10px]">
            <p class="mx-auto mb-5 md:w-10/12 text-[25px] leading-7 md:leading-[50px] md:text-[40px] text-center text-[#093686] font-brandingBold">
              TikTok Fam: This Ramadan, Let’s Make a REAL Difference!
            </p>
            <div class="space-y-3 md:space-y-5 text-[#78716C] font-BrandingMediumItalic md:text-lg md:text-center md:w-10/12 md:mx-auto mt-4 undefined">
              <p>
                <b>
                  <span style={{ color: '#f60362' }}>Ramadan is here—a month of blessings, giving, and rewards.</span>
                </b>
                <br />
                Every day, we break our fast with food on the table. But in Palestine, families are breaking their fast with nothing. No water. No warm
                meal. Just hunger and struggle.
                <br />
                <br />
                Imagine fasting all day… and having no iftar.
                <br />
                <br />
                This Ramadan, YOU can change that. With just a small donation, you can feed a fasting person, provide iftar, and pay your Zakat to those
                who need it most.
                <br />
                <h2 class="text-2xl md:text-4xl font-bold my-4">Why Give Now? </h2>
                ✅ Feeding a fasting person = the reward of their fast <br />
                ✅ Zakat cleanses your wealth and multiplies your blessings <br />
                ✅ Ramadan donations = multiplied rewards <br />
                <br />
                Gen Z, you are the generation of impact. You support causes, you stand for justice, and you show up when it matters. <br />
                <br />
                This is your chance. Your Zakat, your iftar donation, your sadaqah—it all makes a difference. Even $5 can feed a fasting person.
                <br />
                <br /> 👉 Swipe up and give now. Let’s make this Ramadan truly about sharing, caring, and changing lives
              </p>
            </div>
            <div class="mx-auto mt-6 w-full flex flex-col md:flex-row justify-center items-center">
              <a
                class="flex items-center justify-center text-center font-['Montserrat'] font-bold rounded-md px-4 text-[12px] md:text-[14px] h-[42px] md:h-[44px] transition duration-150 ease-in-out uppercase min-w-[200px] bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]"
                href="#"
                onClick={e => {
                  e.preventDefault();
                  iftarDonationWidgetRef.current.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Donate Now
              </a>
            </div>
          </div>
        </section>
      ) : (
        <section class="mb-0 md:mb-8 px-5 md:px-[40px] py-5 md:py-[40px] bg-[url('../src/images/ali-banat/feel-pattern.png')] bg-[#14a3dc2c]">
          <div class="mx-auto px-4 sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1200px] 2xl:max-w-[1400px] bg-white p-5 md:p-[40px] md-p-[40px] rounded-[10px]">
            <p class="mx-auto mb-5 md:w-10/12 text-[25px] leading-7 md:leading-[50px] md:text-[40px] text-center text-[#093686] font-brandingBold">
              URGENT: Your Zakat Can Save Lives in Palestine This Ramadan
            </p>
            <div class="space-y-3 md:space-y-5 text-[#78716C] font-BrandingMediumItalic md:text-lg md:text-center md:w-10/12 md:mx-auto mt-4 undefined">
              <p>
                <b>
                  <span style={{ color: '#f60362' }}>Gen Z, This is YOUR Moment to Make a Difference!</span>
                </b>
                <br />
                Right now, families in Palestine are struggling. Bombs have shattered their homes. Parents are desperate to feed their children. The cries of orphans echo through the rubble. And while the world debates, YOU have the power to ACT.
                <br />
                <br />
                Ramadan is the month of giving. And Zakat isn’t just charity—it’s a DUTY.
                <br />
                <br />
                Gen Z, you are the generation of ACTION. You don’t just talk. You change the game. You rally, you protest, you raise awareness, and you make waves. But awareness without action is meaningless.
                <br />
                <br />
                Today, your Zakat can put food on a mother’s table. It can clothe an orphan. It can provide urgent medical aid to the wounded.
                <br />
                <h2 class="text-2xl md:text-4xl font-bold my-4">Why Zakat for Palestine? </h2>
                ✅ 100% Donation Policy – Every penny you give goes directly to those who need it most. <br />
                ✅ Emergency Aid – Food, water, shelter, and medical care for families who’ve lost everything. <br />
                ✅ Immediate Impact – Your donation could be in the hands of a struggling family within hours. <br />
                <br />
                What if your Zakat is the meal that stops a child from starving? What if your donation saves a life today?
                <br />
                <br />
                Don’t Wait – Give Your Zakat NOW.
                <br />
                <br />
                Snapchat brought you here for a reason. Scrolling won’t save lives—but YOU can.
                <br />
                <br />
                Click below and be the change. Give your Zakat today.
              </p>
            </div>
            <div class="mx-auto mt-6 w-full flex flex-col md:flex-row justify-center items-center">
              <a
                class="flex items-center justify-center text-center font-['Montserrat'] font-bold rounded-md px-4 text-[12px] md:text-[14px] h-[42px] md:h-[44px] transition duration-150 ease-in-out uppercase min-w-[200px] bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]"
                href="#"
                onClick={e => {
                  e.preventDefault();
                  iftarDonationWidgetRef.current.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Donate Now
              </a>
            </div>
          </div>
        </section>
      )}
    </NewHelmetLayout>
  );
}

export default TikTokLandingPage;