import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import useOutsideClick from '../../hooks/ui/useOutsideClick';
import { closeSheet, selectSheetVisibility } from '../../store/sheetToggler';

export const Sheet = ({ children, refTo }) => {
  const ref = useRef(null);
  const isVisible = useSelector(selectSheetVisibility);
  const dispatch = useDispatch();

  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showSheet, setShowSheet] = useState(false);

  const handleClick = () => {
    dispatch(closeSheet());
  };
  useOutsideClick(ref, handleClick);

  useEffect(() => {
    if (isVisible) {
      setShowBackdrop(true);
      setShowSheet(true);
    } else {
      setShowSheet(false);
      setTimeout(() => setShowBackdrop(false), 300);
    }
  }, [isVisible]);
  

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      requestAnimationFrame(() => {
        document.body.style.overflow = 'auto';
      });
    }
  
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);
  
  

  return (
    <div
      className={`fixed top-0 z-[200] min-h-[100svh] backdrop-blur-lg h-full backdrop-brightness-50 overflow-y-auto overflow-x-hidden w-full flex justify-end ${
        showBackdrop ? 'right-0' : 'right-[110%]'
      }`}
      style={{
        WebkitOverflowScrolling: 'touch',
        willChange: 'transform',
      }}
    >
      <div
        ref={ref}
        className={`w-full md:max-w-[500px] h-full min-h-max transition-all duration-300 ease-in-out bg-[#0286c3] sheet-bg-image`}
        style={{
          transform: showSheet ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)',
        }}
      >
        <div ref={refTo} className="h-full">
          {children}
        </div>
      </div>
    </div>
  );
};
