import { Builder } from '@builder.io/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCurrency } from "../../store/user";
import ReactSelect from 'react-select';
import creditImg from "../../images/payment/credit-cards.png";
import SelectCurrency from "../general/selectCurrency";
import useHandleAddProduct from "../../utils/handleAddProduct";
import { selectProducts } from "../../store/products";
import CustomButton from "../general/button";
import ShowCurrencies from "../../utils/showCurrencies";
import useCart from '../../hooks/cart/useCart';
import { openSheet } from '../../store/sheetToggler';

const styles = {
    menuList: (base) => ({
        ...base,
        '::-webkit-scrollbar': {
            width: '6px',
            height: '0px',
        },
        '::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '::-webkit-scrollbar-thumb': {
            background: '#888',
        },
        '::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    }),
};

const DonorMakeDonation = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const {
        defaultPrice,
        defaultName = 'Lebanon Emergency Appeal',
        defaultCreator = 'EMR-LEB-24-000',
        addToCart = true,
        // Legacy string prop:
        productCreators,
        // New array prop:
        productList = [],
    } = props;

    const dispatch = useDispatch();
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector(selectProducts);
    const [addProduct] = useHandleAddProduct();
    const { handleAddToGiving: handleAddToGivingCart } = useCart();

    // We build a map of creator -> {prices, quantities}
    const [productMap, setProductMap] = useState({});

    // Merge old `productCreators` with new `productList` creators
    const [finalProductCreators, setFinalProductCreators] = useState(productCreators || "");

    const [productsList, setProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const [currentCurrency, setCurrentCurrency] = useState();
    const [selectedProduct, setSelectedProduct] = useState(null);

    // `selectedPrice` tracks the currently active suggested button
    const [selectedPrice, setSelectedPrice] = useState(null);
    // `productAmount` is what's in the input box
    const [productAmount, setProductAmount] = useState(null);

    const [currentProduct, setCurrentProduct] = useState(null);

    // We'll dynamically track the suggested "price or quantity" options.
    const [suggestedPrices, setSuggestedPrices] = useState([100, 150, 200, 500]); // fallback

    // Determine which type the product is using
    const usesPrices = !!selectedProduct?.prices;
    const usesQuantities = !!selectedProduct?.quantities;

    /* ------------------------------ Start methods ----------------------------- */

    const handleCurrency = (currency) => {
        setCurrentCurrency(currency.value);
    };

    const handleProduct = (value) => {
        // Match the selected item from our allProducts array
        const found = allProducts.find((item) => item.id === value.value);
        if (found) {
            setSelectedProduct(found);
        }
        setCurrentProduct(value);
    };

    const handleAddNewProduct = () => {
        if (!selectedProduct) return;

        let tempProduct = selectedProduct;
        // If user typed something, use that; else fallback to selectedPrice
        const customAmount = Number(productAmount) || Number(selectedPrice) || 100;

        tempProduct = {
            ...tempProduct,
            quantity: customAmount,
        };

        if (addToCart) {
            handleAddToGivingCart(tempProduct, 'custom');
            dispatch(openSheet());
        } else {
            addProduct({ product: tempProduct, currency: currentCurrency });
        }
    };

    // Convert productList into a comma-separated string and build a map
    useEffect(() => {
        if (productList?.length) {
            const mappedCreators = productList.map((p) => p.creator).join(',');
            setFinalProductCreators((oldString) => {
                if (!oldString) return mappedCreators;
                return oldString + "," + mappedCreators;
            });

            const tempMap = {};
            productList.forEach((prod) => {
                tempMap[prod.creator] = prod;
            });
            setProductMap(tempMap);
        }
    }, [productList]);

    /* --------------------------------------------------------------------------
     *  Use existing logic to filter from store, but attach .prices/.quantities
     *  from productMap using a cloned object.
     * ------------------------------------------------------------------------ */
    const getAllProducts = (productsData) => {
        setIsLoaded(true);

        const creatorsArray = finalProductCreators
            ? finalProductCreators.split(',').map((c) => c.trim())
            : [];

        let tempMakeDonationProducts = [];
        let tempProductsList = [];

        // Loop over all products + subcategories
        productsData.forEach((item) => {
            item.sub_categories?.forEach((subCat) => {
                subCat.products?.forEach((p) => {
                    if (Number(p.status) === 1 && creatorsArray.includes(p.creator)) {
                        // Clone p instead of mutating
                        const newP = { ...p };

                        // Attach prices/quantities from productMap if available
                        if (productMap[newP.creator]) {
                            newP.prices = productMap[newP.creator].prices;
                            newP.quantities = productMap[newP.creator].quantities;
                        }

                        tempMakeDonationProducts.push(newP);
                        tempProductsList.push({
                            value: newP.id,
                            label: newP.name,
                            creator: newP.creator,
                        });
                    }
                });
            });
        });

        setProductsList(tempProductsList);
        setAllProducts(tempMakeDonationProducts);

        // (1) Automatically pick the first product if available
        if (tempMakeDonationProducts.length > 0) {
            const firstP = tempMakeDonationProducts[0];
            setSelectedProduct(firstP);
            setCurrentProduct({
                value: firstP.id,
                label: firstP.name,
                creator: firstP.creator,
            });
        }
    };

    useEffect(() => {
        if (products && Object.keys(products).length && finalProductCreators) {
            getAllProducts(products);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, finalProductCreators]);

    useEffect(() => {
        if (selectedCurrencyStore) {
            setCurrentCurrency(selectedCurrencyStore);
        } else {
            setCurrentCurrency('AUD');
        }
    }, [selectedCurrencyStore]);

    // Whenever the selected product changes, recompute suggested prices
    // (2) We also set the second suggested button active by default
    // (3) And fill `productAmount` accordingly
    useEffect(() => {
        if (!selectedProduct) return;

        if (usesPrices) {
            const prices = selectedProduct.prices || [100, 150, 200, 500];
            setSuggestedPrices(prices);
            // Second item if available, else fallback to first
            const defaultVal = prices[1] ?? prices[0];
            setSelectedPrice(defaultVal);
            setProductAmount(defaultVal);
        } else if (usesQuantities) {
            const quantities = selectedProduct.quantities || [1, 2, 5, 10];
            setSuggestedPrices(quantities);
            const defaultVal = quantities[1] ?? quantities[0];
            setSelectedPrice(defaultVal);
            setProductAmount(defaultVal);
        } else {
            // Fallback
            const fallbackArr = [100, 150, 200, 500];
            setSuggestedPrices(fallbackArr);
            const defaultVal = fallbackArr[1]; // which is 150
            setSelectedPrice(defaultVal);
            setProductAmount(defaultVal);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProduct]);

    /* -------------------------------------------------------------------------- */
    return (
        <div className="w-full bg-[#00a3da] px-3 py-3 flex items-center rounded-b-lg z-30">
            <div className="w-full">
                <div className="grid grid-cols-12 gap-2 mx-auto">
                    {/* Products Select */}
                    <div className="col-span-12 order-1">
                        <ReactSelect
                            options={productsList}
                            className="max-h-[48px] [&>div]:!rounded-md"
                            classNamePrefix="home-select"
                            value={currentProduct}
                            isSearchable={false}
                            styles={styles}
                            onChange={(val) => handleProduct(val)}
                        />
                    </div>

                    {/* Suggested Prices/Quantities */}
                    <div className="col-span-12 order-2 grid grid-cols-12 gap-2">
                        {suggestedPrices.map((priceOrQty, index) => (
                            <div key={index} className="col-span-3">
                                <div
                                    className={`h-[38px] text-sm transition-all duration-200 cursor-pointer flex justify-center items-center px-2 py-2 min-w-[60px] rounded-md ${
                                        selectedPrice === priceOrQty
                                            ? 'bg-[#F60362] text-white'
                                            : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'
                                    }`}
                                    onClick={() => {
                                        setSelectedPrice(priceOrQty);
                                        setProductAmount(priceOrQty); // (3) Keep input in sync
                                    }}
                                >
                                    {usesPrices ? (
                                        <>
                                            {ShowCurrencies(currentCurrency, priceOrQty, false)}
                                            <span className="uppercase ml-1 text-xs">
                                                {currentCurrency}
                                            </span>
                                        </>
                                    ) : (
                                        // If "quantities", show e.g. "x1", "x2", ...
                                        <span>{`x${priceOrQty}`}</span>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Currency + Amount (with extra display for quantity scenario) */}
                    <div className="col-span-12 order-3 flex items-center bg-white rounded-md h-[38px]">
                        <div className="min-w-max">
                            <SelectCurrency
                                onChangeCurrency={(e) => handleCurrency(e)}
                                classNamePrefix="home_select"
                            />
                        </div>

                        {/* This is the original numeric input, hidden if using quantities */}
                        <input
                            value={productAmount || ''}
                            name="amount"
                            className="h-[38px] text-[#777] w-full p-2 rounded-lg text-sm pl-1 focus:outline-none"
                            type="number"
                            min="0"
                            style={{ display: usesQuantities ? 'none' : 'block' }}
                            disabled={usesQuantities} // also disable, just in case
                            onChange={(e) => {
                                const regex = /^(?:[1-9]\d*)?$/;
                                if (regex.test(e.target.value)) {
                                    setProductAmount(e.target.value);
                                    setSelectedPrice(null); // Clears active button if user types a custom amount
                                }
                            }}
                            placeholder="Amount"
                        />

                        {/* New text field (same design) shown ONLY for 'quantities' scenario */}
                        {usesQuantities && (
                            <div className="h-[38px] text-[#777] w-full p-2 rounded-lg text-sm pl-1 flex items-center">
                                {/* Example: 200$ x3=600$ Total */}
                                <>
                                    {`${selectedProduct[currentCurrency.toString().toLowerCase()] || selectedProduct.usd} x${productAmount}`}
                                </>
                            </div>
                        )}
                    </div>

                    {/* Credit Card Image */}
                    <div className="col-span-6 order-4 rounded-md px-3 h-[38px] bg-[#80ceff] flex items-center justify-center">
                        <img
                            src={creditImg}
                            alt="MATW"
                            className="w-[125px] h-auto"
                        />
                    </div>

                    {/* Quick Donate Button */}
                    <div className="order-5 col-span-6">
                        <CustomButton
                            onClick={handleAddNewProduct}
                            title="Quick Donate"
                            disabled={!isLoaded}
                            className={`w-full hover:!bg-[#F60362] !h-[38px] !px-2 ${
                                isLoaded ? 'blob red' : ''
                            }`}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

/**
 * If using Builder, keep the same registration. We’ve simply updated logic
 * so that the first product is defaulted, and the second suggested button
 * and corresponding Amount input are selected/fill by default.
 */
Builder.registerComponent(DonorMakeDonation, {
    name: 'DonorMakeDonationV2',
    inputs: [
        { name: 'defaultPrice', type: 'string' },
        { name: 'defaultName', type: 'string' },
        { name: 'defaultCreator', type: 'string' },
        {
            name: 'productCreators',
            type: 'string',
            helperText: 'Comma-separated list of product creators to show (legacy)',
        },
        {
            name: 'productList',
            type: 'json',
            helperText:
                'Pass an array of products, each with .creator and .prices or .quantities',
        },
    ],
});

export default DonorMakeDonation;
