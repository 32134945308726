import React, { useEffect } from 'react';
import { GlobalDebug } from './utils/removeConsole';
import AllRoutes from './routes';
import GoogleTagManager from './utils/googleTagManager';
import { selectSelectedCurrency, setCountry, setSelectedCurrencyInStore } from './store/user';
import { useDispatch, useSelector } from 'react-redux';
import { checkDomain } from './utils/checkDomain';
import { BASKET_CACHE_KEY } from './utils/handleLocalStorage';
import { builder } from '@builder.io/react'; // Import builder
import { loadDynamicComponents } from './components/dynamicComponentLoader';
import { useLocation } from 'react-router-dom';

builder.init('32b8c354d4bc455dbf10fed3923dfae1');

const App = () => {
  const dispatch = useDispatch();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const routesLocation = useLocation();
  const from = routesLocation.state?.from;

  useEffect(() => {
    if (routesLocation.pathname.includes("/30-steps-to-jannah")) {
      localStorage.setItem("IS_DAILY_DONATION", JSON.stringify(true));
    } else if (from !== "30-steps-to-jannah") {
      localStorage.setItem("IS_DAILY_DONATION", JSON.stringify(false));
    }
  }, [routesLocation.pathname, from]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === BASKET_CACHE_KEY) {
        const newCurrency = JSON.parse(event.newValue)?.currency;
        if (newCurrency) dispatch(setSelectedCurrencyInStore(newCurrency));
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [dispatch]);

  useEffect(() => {
    // Load and register dynamic components
    loadDynamicComponents();
  }, []);

  useEffect(() => {
    (process.env.APP_ENV === 'production' || process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'STAGING') && GlobalDebug(false);
    GoogleTagManager(window.location.href);
    // if (!window.location.href.includes('checkout')) {
    // }
    fetch('https://matwproject.org/ip-api')
      .then(res => res.json())
      .then(response => {
        if (!selectedCurrencyStore) {
          if (response.timezone.includes('Europe')) {
            if (response.countryCode === 'GB') {
              dispatch(setSelectedCurrencyInStore('GBP'));
            } else {
              dispatch(setSelectedCurrencyInStore('EUR'));
            }
          } else {
            switch (response.countryCode) {
              case 'AU':
                dispatch(setSelectedCurrencyInStore('AUD'));
                break;
              case 'US':
                dispatch(setSelectedCurrencyInStore('USD'));
                break;
              case 'MY':
                dispatch(setSelectedCurrencyInStore('MYR'));
                break;
              case 'SG':
                dispatch(setSelectedCurrencyInStore('SGD'));
                break;
              case 'CA':
                dispatch(setSelectedCurrencyInStore('CAD'));
                break;
              // case 'AE':
              //   dispatch(setSelectedCurrencyInStore('AED'));
              //   break;
              default:
                dispatch(setSelectedCurrencyInStore('USD'));
            }
          }
        }
        dispatch(setCountry(response.country === 'United Kingdom' ? 'uk' : ''));
      })
      .catch((data, status) => {
        dispatch(setSelectedCurrencyInStore('USD'));
        console.log('Request failed:', data);
      });
    if (checkDomain('matwproject.org')) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        var u = "aHR0cHM6Ly9nZW8tdHJhY2tlci5zbWFkZXguY29tL2h5cGVyYWQvcGl4ZWwtdHJhY2tpbmc/b3JkZXI9MTE0NzM1JmFjdGlvbj1sYW5kaW5n";
        u = atob(u);
        var t = new Date().getTime();
        var ut = u + "&rand=" + t;
        var it = document.createElement("img");
        it.src = ut;
        it.border = 0;
      `;
      document.body.appendChild(script);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* -------------------------------------------------------------------------- */

  return <AllRoutes />;
};

export default App;
