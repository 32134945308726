import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import TabContent from '../../home/components/tab-content';
import { useRef } from 'react';

const RamadanPageTabs = (props) => {
  /* ----------------------------- Start variables ---------------------------- */
  const {riceBagProduct, foodAidProducts, zakatProducts, zakatAlFitrProducts, orphansProducts, palestineEmergencyProducts } = props;
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [activeTabProducts, setActiveTabProducts] = useState(0);

  const palestineProd = palestineEmergencyProducts ? {
    title: 'palestine emergency',
    description: 'More than 828 million people sleep hungry.  You can help change that. Last Ramadan, you helped deliver an impact of 22.9 Million. Help us reach more now.',
    products: palestineEmergencyProducts
  } : null;

  const tabButtonsProducts = [
    ...(palestineProd ? [palestineProd] : []),
    {
      title: 'food aid',
      description: 'More than 828 million people sleep hungry.  You can help change that. Last Ramadan, you helped deliver an impact of 22.9 Million. Help us reach more now.',
      products: foodAidProducts
    },
    {
      title: 'zakat',
      description: 'Your Zakat changes lives every day and transcends in Ramadan.',
      products: zakatProducts
    },
    {
      title: 'zakat al-fitr',
      description: 'Zakat Al-Fitr (also known as Fitrana or Fitrah) is a charitable donation of the cost of one meal to be given before Eid prayer, before the end of the month of Ramadan.',
      products: zakatAlFitrProducts
    },
    {
      title: 'orphans',
      description: 'MATW has reached and cared for more than 50,000 orphans worldwide. Help us reach more.',
      products: orphansProducts
    },
    {
      title: 'rice bag',
      description: 'Your support can make a lasting impact, ensuring that families have access to a vital source of food and nutrition.',
      products: riceBagProduct
    },
  ];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]">
      <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
        <div className=" flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center">
          {tabButtonsProducts.map(({ title }, index) => (
            <button
              key={`btn_${index}`}
              onClick={() => {
                window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 })
                setActiveTabProducts(index);
              }}
              className={`${index === activeTabProducts ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'} text-[14px] md:text-base- rounded-full max-[500px]:col-span-2- p-2 min-[501px]:min-w-[160px] uppercase font-['Montserrat'] font-bold ${index === 0 && activeTabProducts === 0 && palestineEmergencyProducts ? 'circle_animation-zakat' : ''}`}>
              {title}
            </button>
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
          <div className="flex flex-col w-full justify-center">
            {tabButtonsProducts.map((item, index) =>
              index === activeTabProducts && <React.Fragment key={`description_${index}`}>
                <div className="flex w-full justify-center mb-6">
                  <p className='text-center text-white text-[18px] md:text-3xl md:w-2/3'>
                    {item.description}
                  </p>
                </div>
                <div ref={contentRef}>
                  <TabContent
                    products={item.products}
                    showArrows={false}
                    currency={selectedCurrency} />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </section>

  );
};
export default RamadanPageTabs;