import { Builder } from '@builder.io/react';
import React, { useEffect, useState } from 'react';
const YoutubeBox = props => {
  const {
    video,
    title = '',
    description = '',
    overLayClasses = '',
    autoPlay = true
  } = props;
  /* ----------------------------- Start variables ---------------------------- */
  const [embedURL, setEmbedURL] = useState(null);
  const [error, setError] = useState('');
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getYouTubeID = url => {
    const regex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };
  const createEmbedURL = videoID => {
    if (!videoID) return null;
    const baseURL = `https://www.youtube.com/embed/${videoID}`;
    const params = new URLSearchParams({
      controls: autoPlay ? 0 : 1,
      autoplay: autoPlay ? 1 : 0,
      loop: autoPlay ? 1 : 0,
      mute: autoPlay ? 1 : 0,
      playlist: videoID // Required for looping
    });
    return `${baseURL}?${params.toString()}`;
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    if (video) {
      const videoID = getYouTubeID(video.trim());
      if (videoID) {
        const url = createEmbedURL(videoID);
        setEmbedURL(url);
        setError('');
      } else {
        setEmbedURL(null);
        setError('Invalid YouTube URL. Please enter a valid link.');
      }
    }
  }, [video]);
  /* -------------------------------------------------------------------------- */

  return <div className='relative overflow-hidden flex flex-wrap'>
    <div className={`order-2 z-10 sm:order-2 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full min-h-[170px] py-3 bg-no-repeat bg-cover bg-center ${autoPlay ? 'md:max-w-[365px] md:absolute md:rounded-2xl md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2 right-16' : ''}`}>
      <div className='w-full text-[24px] sm:text-[50px] md:text-[30px] lg:text-[28px] px-8 text-white text-center font-montserratBold'>{title}</div>
      <p className='text-lg font-montserratMedium sm:leading-8 px-10 text-white text-center mt-2 md:mt-0'>
        {description}
      </p>
    </div>
    {autoPlay && <div className={`hidden sm:block absolute w-full h-full bg-[#00000085] ${overLayClasses}`} />}
    <div className="order-1 sm:order-1 basis-full max-[380px]:min-h-[209px] min-[381px]:min-h-[215px] min-[410px]:min-h-[230px] sm:h-[300px] md:h-[600px] lg:h-[720px]">
      {error && <p >{error}</p>}
      <iframe className={`video ${autoPlay ? 'pointer-events-none' : ''}`} width={'100%'} height={'100%'} title={'youtube'} frameBorder={0} sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation" src={embedURL}></iframe>
    </div>

  </div>;
};
Builder.registerComponent(YoutubeBox, {
  name: 'YoutubeBox',
  inputs: [{
    "name": "video",
    "type": "string"
  }, {
    "name": "title",
    "type": "string"
  }, {
    "name": "description",
    "type": "string"
  }, {
    "name": "overLayClasses",
    "type": "string"
  },
  {
    "name": "autoPlay",
    "type": "boolean"
  }
  ]
});
export default YoutubeBox;