import { Builder } from '@builder.io/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../../../store/user';
import ReactSelect from 'react-select';
import SelectCurrency from '../../../../general/selectCurrency';
import useHandleAddProduct from '../../../../../utils/handleAddProduct';
import { selectProducts } from '../../../../../store/products';
import CustomButton from '../../../../general/button';
import ShowCurrencies from '../../../../../utils/showCurrencies';
import staticImages from '../../../../../constants/staticImages';
import useCart from '../../../../../hooks/cart/useCart';
import { openSheet } from '../../../../../store/sheetToggler';
import PersistentTimerMain from '../../../../general/persistTimerMain';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { InfoIcon } from '../../../../general/customIcons';

const WidgetRamadanOne = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { defaultPrice, sections, addToCart = true } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector(state => selectProducts(state));
  const [productsList, setProductsList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [addProduct] = useHandleAddProduct();
  const [currentCurrency, setCurrentCurrency] = useState();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedPrice, setSelectedPrice] = useState(defaultPrice || 200);
  const [productAmount, setProductAmount] = useState(selectedPrice || 200);
  const [activeButton, setActiveButton] = useState(null);

  /* -------------------------------------------------------------------------- */
  const dispatch = useDispatch();
  const { handleAddToGiving: handleAddToGivingCart } = useCart();
  const [isExpanded, setIsExpanded] = useState(false);

  /* ------------------------------ Start methods ----------------------------- */
  const handleCurrency = currency => {
    setCurrentCurrency(currency.value);
    // selectedCurrency(currency.value);
  };

  const handleAddNewProduct = () => {
    let tempProduct = selectedProduct;
    let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice) ? Number(selectedPrice) : 200;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
      // recurring: selectedRecurring.value,
    });

    if (addToCart) {
      handleAddToGivingCart(tempProduct, 'custom');
      dispatch(openSheet());
    } else {
      addProduct({ product: tempProduct, currency: currentCurrency });
    }
  };
  const handleIncrease = () => {
    setProductAmount(prev => prev + 1);
  };

  const handleDecrease = () => {
    setProductAmount(prev => (prev > 0 ? prev - 1 : 0));
  };

  /* ------------------------------- Start hooks ------------------------------ */
  const getAllProducts = (products, sections) => {
    setIsLoaded(true);
    let tempSelectedProducts = [];

    const productIDs = sections?.flatMap(section => section?.Products.split(',').map(id => id.trim()));

    products?.forEach(item => {
      item.sub_categories.forEach(innerItem => {
        innerItem.products.forEach(innerProduct => {
          if (productIDs?.includes(innerProduct.creator)) {
            tempSelectedProducts.push(innerProduct);
          }
        });
      });
    });

    setProductsList(tempSelectedProducts);
  };

  useEffect(() => {
    if (products && Object.keys(products).length && sections) {
      getAllProducts(products, sections);
    }
  }, [products, sections]);
  
  useEffect(() => {
    if (productsList.length > 0) {
      setSelectedProduct(productsList.find(prod => prod.creator === sections[0].Products.split(',')[0].trim()));
      
      setActiveButton(`0-0`);
    }
  }, [productsList]);

  const dynamicSections = sections.map((section, sectionIndex) => ({
    title: section.title,
    buttons: section.Products.split(',').map(id => {
      const product = productsList.find(prod => prod.creator === id.trim());
      return product ? product.name : 'Unknown';
    }),
  }));

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  /* -------------------------------------------------------------------------- */

  return (
    <div className={`w-full bg-[#eaf7ff] px-3 p-6 md:p-6 sm:px-6 flex flex-col gap-[18px] items-center`}>
      {/*Top Header */}
      <div className="flex bg-[#F60362] w-full p-2 justify-center">
        <h2 className="text-white text-[14px] font-Signika font-weight-400">Please don’t leave without donating, even if it’s just $1</h2>
      </div>

      {/* 2nd Header */}
      <div className="w-full px-2 py-2 border border-[#00A3DA] bg-white flex flex-col cursor-pointer relative">
        <div className="flex flex-row justify-between items-center">
          <div>
            <div className="flex items-center" onClick={toggleExpand}>
              <h2 className={`text-[#00A3DA] font-bold text-[23px] ${isExpanded ? 'pt-1' : ''}`}>Who is MATW Project?</h2>
              {isExpanded ? <ChevronUpIcon className="w-6 h-6 ml-2 text-[#00A3DA]" /> : <ChevronDownIcon className="w-6 h-6 ml-2 text-[#00A3DA]" />}
            </div>
            <p className={`text-[#F60462] text-[12px] ${isExpanded ? ' pt-0.5 ' : ''}`}>
              {isExpanded
                ? `Ramadan is the best time to give. Your rewards multiply. Your impact grows. 
              And nowhere is your generosity needed more than in Gaza. Families have lost everything. 
              Homes destroyed. Parents struggling to feed their children. Basic necessities are out of reach. 
              But you can change that. The MATW Project ensures your donation goes straight to those who need it most. 
              Hot iftar meals. Fresh water. Shelter for families with nowhere to go. Medical help for the injured. 
              This is not just charity. This is a lifeline. And the best part? Every penny you give counts in this life and the next. 
              You already know the rewards of giving in Ramadan. Now take action. Stand with the people of Gaza. Give today.`
                : 'Here’s why you should always give through MATW...'}
            </p>
          </div>
          <div>
            {!isExpanded && (
              <div className="absolute -top-4 right-0">
                <a href="https://youtu.be/dzL6BLPAFBo" target="_blank" rel="noopener noreferrer">
                  <img
                    className="h-[73px]"
                    src="https://cdn.builder.io/api/v1/image/assets%2F32b8c354d4bc455dbf10fed3923dfae1%2F76ae428c67fa4cf59896e0ac41f8c985"
                    alt="Ramadan_2025"
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* 3rd Header */}
      <div className="w-full  flex flex-row justify-between items-center">
        <div>
          <h2 className="text-[#00A3DA] text-Signika font-bold text-[34.13px]">Ramadan 2025</h2>
          <div className="flex items-center">
            <div className="relative group flex cursor-pointer" style={{ alignSelf: 'flex-start' }}>
              <InfoIcon className="w-5 h-5 mr-1 text-[#F60462]" />
              <div
                className={`z-20 group-hover:flex hidden w-64 absolute transition duration-150 ease-in-out 
      top-full left-0 mt-2 shadow-lg bg-[#F60462] text-[#F60462] p-4 rounded`}
              >
                <svg
                  className="absolute -top-[9px] left-4"
                  width="9px"
                  height="16px"
                  viewBox="0 0 9 16"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="currentColor">
                    <g id="Tooltips-" transform="translate(-874.000000, -1029.000000)" fill="currentColor">
                      <g id="Group-3-Copy-16" transform="translate(850.000000, 975.000000)">
                        <g id="Group-2" transform="translate(24.000000, 0.000000)">
                          <polygon
                            id="Triangle"
                            transform="translate(4.500000, 62.000000) rotate(90.000000) translate(-4.500000, -62.000000) "
                            fill="currentColor"
                            points="4.5 57.5 12.5 66.5 -3.5 66.5"
                          ></polygon>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <span className="text-white text-xs md:text-[15px] leading-5 text-left">
                  Ramadan is a month of mercy and generosity, where giving Zakat and Sadaqah purifies our wealth and brings us closer to Allah. As we
                  fast, we feel a fraction of what the less fortunate endure daily. Every act of charity eases their burdens and multiplies our
                  rewards. May our giving be sincere, our hearts open, and may Allah accept and bless our efforts in this sacred month.
                </span>
              </div>
            </div>

            <p className="text-[#F60462] text-Signika text-[12px]">Make 2025 your most generous Ramadan!</p>
          </div>
        </div>
        <div className="">
          <img className="flex w-[80px] " {...staticImages.policyDonationLogo} alt="Ramadan_2025" />
        </div>
      </div>

      {/* 4th Header img */}
      <div className="w-full flex ">
        <img src="https://cdn.builder.io/api/v1/image/assets%2F32b8c354d4bc455dbf10fed3923dfae1%2F4d953a97c8d3450497bb0f93b07cc628" />
      </div>

      {/* 5th Products Section */}
      <div className="w-full">
        {dynamicSections.map((section, sectionIndex) => (
          <div key={sectionIndex}>
            <div className={`w-full flex bg-[#00A3DA] justify-center py-1 ${sectionIndex === 0 ? '' : 'mt-4'}`}>
              <h2 className="text-white text-[15px] font-Signika">{section.title}</h2>
            </div>

            <div className="w-full flex gap-3 mt-3 justify-center">
              {section.buttons.map((buttonName, btnIndex) => (
                <button
                  key={btnIndex}
                  onClick={() => {
                    setActiveButton(`${sectionIndex}-${btnIndex}`);
                    setSelectedProduct(productsList.find(prod => prod.name === buttonName));
                  }}
                  className={`flex-1 text-center p-1 font-signika text-[16px] text-[#b2a79d] 
                  lg:hover:bg-[#F60362] lg:hover:text-white border border-[#cccac5] 
                  ${activeButton === `${sectionIndex}-${btnIndex}` ? 'bg-[#F60362] text-white font-bold' : 'bg-white text-[#F60362] font-normal'}`}
                >
                  {buttonName}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Currency Select and Amount Input */}
      <div className="w-full gap-3 items-center rounded-none !h-[60px] flex">
        <div className="flex-[0_0_calc(33%-10px)] !h-[60px] z-[31]">
          <SelectCurrency
            onChangeCurrency={e => handleCurrency(e)}
            classNamePrefix="currency-select-new currency-select-new--main"
            className="[&>div]:!rounded-none !max-h-[60px]"
          />
        </div>
        <div className="flex items-center border border-[#cccac6] !rounded-none w-full">
          {/* Input Field */}
          <input
            value={productAmount === 0 ? '' : productAmount}
            name="amount"
            className="!h-[60px] text-[#b1a79d] w-full truncate p-2 text-xl focus:outline-none !font-signika input_widget"
            type="number"
            min="0"
            onChange={e => {
              const regex = /^[0-9]*$/;
              if (regex.test(e.target.value)) {
                setProductAmount(Number(e.target.value));
              }
            }}
            placeholder="Other Amount"
          />

          {/* Increment / Decrement Buttons */}
          <div className="flex flex-col">
            <div className="p-0.5">
              <button className="bg-[#00A3DA] px-3 text-white hover:bg-[#F60362]" onClick={handleIncrease}>
                ^
              </button>
            </div>
            <div className="p-0.5">
              <button className="bg-[#00A3DA] px-3 rotate-180 text-white hover:bg-[#F60362]" onClick={handleDecrease}>
                ^
              </button>
            </div>
          </div>
        </div>
        <div className="w-full">
          <CustomButton
            onClick={() => {
              handleAddNewProduct();
            }}
            icon={<img {...staticImages.donateNowIcon} />}
            title={'Add to Donation'}
            disabled={!isLoaded || !selectedProduct?.name || productAmount <= 0}
            className={`w-full hover:!bg-[#F60362] !h-[60px] !rounded-none !text-[20px] !capitalize !font-signika ${
              isLoaded && selectedProduct?.name && productAmount > 0 ? 'blob red' : ''
            }`}
          />
        </div>
      </div>

      {/* Payment Methods Image */}
      <div className="w-full mt-1 bg-[#80CEFF] !h-[60px] flex justify-center items-center">
        <img {...staticImages.supportingPaymentBig} />
      </div>
    </div>
  );
};
Builder.registerComponent(WidgetRamadanOne, {
  name: 'WidgetRamadanOne',
  inputs: [
    {
      name: 'selectedCurrency',
      type: 'string',
    },
    {
      name: 'defaultPrice',
      type: 'number',
    },
    {
      name: 'sections',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'string',
          required: true,
          helpText: 'Enter the Title.',
        },
        {
          name: 'Products',
          type: 'string',
          required: false,
          helpText: 'Enter the Products.',
        },
      ],
    },
  ],
});

export default WidgetRamadanOne;
