import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showDonationPopup: false, // Show donation popup
  showEmailPopup: false,     // Show email subscription popup
  popupClosed: false,        // Track if any popup is closed
};

const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    showDonationPopup: (state) => {
      state.showDonationPopup = true;
      state.showEmailPopup = false; // Close email popup if donation popup is shown
    },
    showEmailPopup: (state) => {
      state.showEmailPopup = true;
      state.showDonationPopup = false; // Close donation popup if email popup is shown
    },
    closePopup: (state) => {
      state.showDonationPopup = false;
      state.showEmailPopup = false;
      state.popupClosed = true; // Mark popups as closed
    },
  },
});

export const { showDonationPopup, showEmailPopup, closePopup } = popupSlice.actions;
export default popupSlice.reducer;
