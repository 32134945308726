import { Builder } from '@builder.io/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCurrency } from "../../../../../store/user";
import ReactSelect from 'react-select';
import SelectCurrency from "../../../../general/selectCurrency";
import useHandleAddProduct from "../../../../../utils/handleAddProduct";
import { selectProducts } from "../../../../../store/products";
import CustomButton from "../../../../general/button";
import ShowCurrencies from "../../../../../utils/showCurrencies";
import staticImages from "../../../../../constants/staticImages";
import PersistentTimer from "../../../../general/persistentTimer";
import useCart from '../../../../../hooks/cart/useCart';
import { openSheet } from '../../../../../store/sheetToggler';


const styles = {
    menuList: base => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '6px',
        height: '0px'
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888'
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    })
  };


const OrphansWidget = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const {
      defaultPrice,
      defaultProduct,
      dropDownProducts,
      importantDonation,
      mode = 0, // 0 for 'Single', 1 for 'Monthly' if needed
      addToCart = true,
      showTimer,
      title,
    } = props;
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => selectProducts(state));
    const [productsList, setProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [addProduct] = useHandleAddProduct();
    const [currentCurrency, setCurrentCurrency] = useState();
    const [selectedProduct, setSelectedProduct] = useState({
      creator: defaultProduct.creator,
      label: defaultProduct.label,
      value: defaultProduct.value,
    });
    const [selectedPrice, setSelectedPrice] = useState(defaultPrice || 250);
    const [productAmount, setProductAmount] = useState(selectedPrice || 250);
    const [currentProduct, setCurrentProduct] = useState({
      creator: defaultProduct.creator,
      label: defaultProduct.label,
      value: defaultProduct.value,
    });
  
    const importantDonationTemp = importantDonation || 'Make A Donation';
  
    // Added for recurring modes
    const suggestedRecurringModes = [
      {
        value: "one-off",
        label: "Single",
      },
      {
        value: "month",
        label: "Monthly",
      },
    ];
    const [selectedRecurring, setSelectedRecurring] = useState(
      mode ? suggestedRecurringModes[mode] : suggestedRecurringModes[0],
    );
    const suggestedPrices = [70, 160, 320];
    /* -------------------------------------------------------------------------- */
    const dispatch = useDispatch();
    const { handleAddToGiving: handleAddToGivingCart } = useCart();
    /* ------------------------------ Start methods ----------------------------- */
    const handleCurrency = (currency) => {
      setCurrentCurrency(currency.value);
      // selectedCurrency(currency.value);
    };
    const handleProduct = (value) => {
      allProducts.map(
        (item) => item.id === value.value && setSelectedProduct(item),
      );
      setCurrentProduct(value);
    };
    const handleAddNewProduct = () => {
      let tempProduct = selectedProduct;
      let customAmount = Number(productAmount)
        ? Number(productAmount)
        : Number(selectedPrice)
          ? Number(selectedPrice)
          : 250;
      tempProduct = Object.assign({}, tempProduct, {
        quantity: customAmount,
        recurring: selectedRecurring.value,
      });
  
      if (addToCart) {
        handleAddToGivingCart(tempProduct, "custom");
        dispatch(openSheet());
      } else {
        addProduct({ product: tempProduct, currency: currentCurrency });
      }
    };
  
    /* ------------------------------- Start hooks ------------------------------ */
    const getAllProducts = (products) => {
      setIsLoaded(true);
      let tempMakeDonationProducts = [];
      let tempProductsList = [];
      let tempImportantProducts = [];
      products.forEach((item) => {
        item.sub_categories.map((inner_item) => {
          inner_item.products.map((inner_item_product) => {
            if (Number(inner_item_product.status) === 1) {
              if (inner_item_product.creator === defaultProduct.creator) {
                console.log('first',inner_item_product)
                setSelectedProduct(inner_item_product);
              }
              if (dropDownProducts.split(/\s*,\s*/).includes(inner_item_product.creator)) {
                console.log('dropDownProducts.includes(inner_item_product.creator)');
                console.log('dropDownProducts', dropDownProducts);
                console.log(inner_item_product.creator);
                tempMakeDonationProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: inner_item_product.name,
                  creator: inner_item_product.creator,
                });
              }
            }
            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });
      
      tempImportantProducts = tempImportantProducts.concat(tempProductsList);
      // Ensure no empty values
      tempImportantProducts = tempImportantProducts.filter((n) => n);
  
      // Sort so dropDownProducts come first
      tempImportantProducts.sort((a, b) => {
        const aIsDropDown = dropDownProducts.includes(a.creator);
        const bIsDropDown = dropDownProducts.includes(b.creator);
        
        if (aIsDropDown && !bIsDropDown) return -1;
        if (!aIsDropDown && bIsDropDown) return 1;
        return 0;
      });
  
      // Check if default product exists in the list
      const hasDefaultProduct = tempImportantProducts.some(product => product.creator === defaultProduct.creator);
  
      if (!hasDefaultProduct) {
        tempImportantProducts.unshift({
          value: defaultProduct.value,
          label: defaultProduct.label,
          creator: defaultProduct.creator,
        });
      }
  
  
      const defaultProductIndex = tempImportantProducts.findIndex(product => product.creator === defaultProduct.creator);
  
      if (defaultProductIndex !== -1) {
        const [defaultProductCreator] = tempImportantProducts.splice(defaultProductIndex, 1); 
        tempImportantProducts.splice(0, 0, defaultProductCreator); 
      }
  
      setProductsList(tempImportantProducts);
      setAllProducts(tempMakeDonationProducts);
      try {
      } catch (error) {
        console.error("Error fetching selected products:", error);
      }
    };
    useEffect(() => {
      products && Object.keys(products).length && getAllProducts(products);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);
    useEffect(() => {
      selectedCurrencyStore
        ? setCurrentCurrency(selectedCurrencyStore)
        : setCurrentCurrency("AUD");
    }, [selectedCurrencyStore]);
  

  return (
    <section className="sticky top-16 lg:top-24 z-30">
      <div className="max-w-[1160px] mx-auto">
        <div
          className={`w-full rounded-lg py-2 md:py-4 flex flex-wrap items-center`}
        >
          <div className="w-full grid md:grid-cols-2 xl:grid-flow-col auto-cols-max gap-2 ">
            <div className="flex gap-2 notranslate">
              <div className="min-w-[100px]" dir='ltr'>
                <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" hideFlag />
              </div>
              <ReactSelect
                options={productsList}
                className="max-h-[48px] w-full sm:min-w-[260px]"
                value={currentProduct}
                defaultValue={defaultProduct}
                isSearchable={false}
                styles={styles}
                placeholder="Select Product"
                onChange={e => handleProduct(e)}
                classNamePrefix="orphan_select" 
              />
            </div>

            <div className="flex gap-2 notranslate">
              <ReactSelect
                options={suggestedRecurringModes}
                className="[&>*]:!cursor-pointer w-full"
                value={selectedRecurring}
                isSearchable={false}
                styles={styles}
                classNamePrefix="orphan_select"
                onChange={e => {
                  setSelectedRecurring(e);
                }}
              />
              {suggestedPrices.map((price, index) => {
                return (
                  <div key={`prices${index}`} className="col-span-12 sm:col-span-4 xl:col-span-4 mb-1 md:mb-0">
                    <div
                      className={`h-12 transition-all duration-200 cursor-pointer flex justify-center items-center min-w-[64px] rounded-md py-2 md:py-4 px-1 md:px-2 md:text-lg lg:hover:bg-[#F60362] lg:hover:text-white border border-[#cccac5] ${
                selectedPrice == price ? 'bg-[#F60362] !text-white' : 'bg-white text-[#F60362]'
              }`}
                      onClick={() => {
                        setSelectedPrice(price);
                        setProductAmount(price);
                      }}
                    >
                      {ShowCurrencies(currentCurrency, price, false)}
                    </div>
                  </div>
                );
              })}
            </div>
            <input
              value={productAmount}
              className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent min-w-[156px]"
              type="number"
              placeholder="Other Amount"
              onChange={e => {
                setProductAmount(e.target.value);
                setSelectedPrice(null);
              }}
            />
            <button
              disabled={!selectedProduct.id}
              className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
                }`}
              onClick={handleAddNewProduct}
            >
              Quick Donate
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

Builder.registerComponent(OrphansWidget, {
  name: 'OrphansWidget',
  inputs: [
    {
      name: "selectedCurrency",
      type: "string",
    },
    {
      name: "className",
      type: "string",
    },
    {
      name: "defaultPrice",
      type: "number",
    },
    {
      name: "defaultProduct",
      type: "object",
      defaultValue: {
        creator: "DD-EMR-PAL-24-11-01",
        label: "Save Lives in Gaza - Where Needed",
        value: 751,
      },
      subFields: [
        {
          name: 'creator',
          type: 'string',
          helperText: 'Enter creator ID',
          required: true,
          hideFromUI: false
        },
        {
          name: 'label',
          type: 'string',
          helperText: 'Enter label',
          required: true,
          hideFromUI: false
        },
        {
          name: 'value',
          type: 'number',
          helperText: 'Enter value',
          required: true,
          hideFromUI: false
        },
      ]
    },
    
    {
      name: 'dropDownProducts',
      type: 'string', 
      required: true,
    },
    {
      name: "mode",
      type: "string",

    },
  ],
});

export default OrphansWidget