import { useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
// import ShowCurrencies from '../../../utils/showCurrencies';
// import useHandleAddProduct from '../../../utils/handleAddProduct';
import "react-lazy-load-image-component/src/effects/blur.css";
// import ConvertAUD from '../../../utils/convertAUD';
import ConvertUSD from "../../../utils/convertUSD";
import CustomButton from "../../../components/general/button";
import { useNavigate } from "react-router-dom";
// import moment from "moment";
// import {
//   endDateOfAppealCampaign,
//   endDateOfGazaCampCampaign,
//   endDateOfLimbsAppealCampaign,
// } from "../details";
// import IsGTMScriptLoaded from '../../../utils/isGTMScriptLoaded';
// import limbsImg from '../../../images/landings/palestine/big-Limbs_of_Hope_Campaign_Website_Image.jpg';
import { generateLinkWithLang } from "../../../utils/linkGenerator";
import getSymbolFromCurrency from "currency-symbol-map";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import handleDaysLeft from "../handleDaysLeft";

const AppealFundraiserProductBoxCartV3 = (props) => {
  /* ----------------------------- Start variables ---------------------------- */
  const ref = useRef(null);
  const navigate = useNavigate();
  const refImg = useRef(null);
  const refLazyImg = useRef(null);
  const { product, currency, goal, campaign } = props;
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [showMore, setShowMore] = useState(false);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleHeight = () => {
    setShowMore(!showMore);
  };

  const handlePercentage = (total_sale, goal) => {
    return `${((Number(total_sale) / Number(goal)) * 100).toFixed(2)}%`;
  };
  // const handleDaysLeft = () => {
  //   var now = moment(new Date());
  //   var after = moment(
  //     new Date(
  //       campaign === "limbs-of-hope"
  //         ? endDateOfLimbsAppealCampaign
  //         : campaign === "gaza-camp"
  //           ? endDateOfGazaCampCampaign
  //           : endDateOfAppealCampaign,
  //     ),
  //     "DD-MM-YYYY HH:mm:ss",
  //   );
  //   var timeDifference = after.diff(now, "days");
  //   return timeDifference;
  // };
  const ShowCurrencies = (currency, _price, showAbbr = true) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString("en", options);
    }
    let _final = "";
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case "GBP":
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case "EUR":
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case "MYR":
        _final = `${price}`;
        break;
      case "IDR":
        _final = `Rp${price}`;
        break;
      case "AED":
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    }
    return _final;
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-full shadow-[0_7px_29px_0px_rgba(100,100,111,0.2)] bg-white">
      {console.log("productttttt=====",product)}
      <div className="w-full gap-2 sm:gap-4 grid grid-cols-[auto,1fr] ">
        <div
          ref={refImg}
          className={`overflow-hidden tooltip img-hover-zoom w-[72px] sm:w-[108px] aspect-square`}
          onClick={() =>
            navigate(generateLinkWithLang(`/appeals/${product.link}`, lang))
          }
        >
          {product ? (
            (product.product_image_link || product.image_link) ? (
              <LazyLoadImage
                ref={refLazyImg}
                alt={product.product_name}
                effect="blur"
                width={`100%`}
                delayTime={500}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/images/general/logo-replace.png";
                }}
                className={`cursor-pointer block`}
                src={product.product_image_link || product.image_link}
              />
            ) : (
              <img
                className={`w-full`}
                src="/images/general/logo-replace.png"
                alt=""
              />
            )
          ) : (
            <Skeleton height={344} />
          )}
        </div>
        <div className="flex flex-col justify-between sm:items-baseline gap-1 sm:gap-3 p-2 sm:p-3 pl-0">
          <div
            className={`flex gap-1 flex-col items-center overflow-hidden transition-all duration-300 ${showMore ? 'h-auto' : 'max-h-[24px]'}`}
          >
              <div className="w-full flex gap-2 items-center">
                <p
                  onClick={() =>
                    navigate(
                      generateLinkWithLang(`/appeals/${product?.link}`, lang),
                    )
                  }
                  className={`text-[#00a3da] cursor-pointer md:text-lg font-semibold md:font-bold ${!showMore ? 'line-clamp-1' : ''}`}
                >
                  {product ? (
                    product.product_name
                  ) : (
                    <Skeleton height={56} width={70} count={1} />
                  )}
                </p>
                <span className="block basis-[18px]">
                    <ChevronDownIcon
                        onClick={handleHeight}
                        className={`sm:block w-[18px] h-[18px] text-[#00a3da] transition-all duration-300 cursor-pointer ${
                        showMore ? 'transform rotate-180' : ''
                        }`}
                    />
                </span>
              </div>
              <p
                className={`text-stone-500 text-sm overflow-hidden transition-all duration-200 ${showMore ? 'h-auto mb-2' : 'max-h-0 mb-0'}`}
              >
                {product.product_description}
              </p>
          </div>
          <div className="">
            <div className="flex items-center justify-between mb-2">
              <div className="flex w-full text-sm">
                <strong className="text-[#00a3da] font-bold mr-0.5">
                  {product.total_supporters}
                </strong>
                <span className="inline-block lg:w-auto mr-1">Supporters,</span>
                <strong className="text-[#00a3da] font-bold mr-0.5">
                  {handleDaysLeft(campaign)}
                </strong>
                <span className="inline-block lg:w-auto">days left</span>
              </div>
            </div>
            <div className="flex w-full h-2 bg-[#E3E3E3] bg-opacity-10- mb-2">
              <div
                className={`bg-[#F60362]`}
                style={{
                  width: `${handlePercentage(product.total_sale, ConvertUSD(currency, goal, false))}`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-2 md:p-3 md:pb-5">
        <div className="basis-full flex justify-between items-center mt-2">
          <div className="flex flex-col w-full">
            <div className="text-lg md:text-2xl text-[#00a3da] basis-full font-bold flex items-center">
              {product.total_sale &&
                ShowCurrencies(currency, Math.round(product.total_sale), false)}
              <span className="text-xs ml-0.5">{currency}</span>
            </div>
            <div className="flex flex-wrap w-full text-xs items-center">
              <span className="text-[#78716C] text-xs">Raised of</span>
              <div className="text-[#00a3da] font-bold mx-1 text-sm">
                {ShowCurrencies(
                  currency,
                  Math.round(ConvertUSD(currency, goal, false)),
                )}
              </div>
              Goal
            </div>
          </div>
          {product ? (
            <CustomButton
              onClick={() =>
                navigate(generateLinkWithLang(`/appeals/${product.link}`, lang))
              }
              type="button"
              title={"Donate"}
              className={`!rounded-none`}
            />
          ) : (
            <Skeleton height={43} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppealFundraiserProductBoxCartV3;
