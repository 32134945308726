import { Builder } from '@builder.io/react';

const HeroVideo = ({ src, title }) => {
  return (
    <section className="flex justify-center">
      <div
        className="basis-full xs:aspect-w-16 xs:aspect-h-9 sm:h-[300px] md:h-[600px] lg:h-[890px] !max-h-[300px] min-h-fit md:!max-h-[450px] overflow-hidden"
      >
        <iframe
          className="video"
          width="100%"
          height="100%"
          title={title || 'YouTube Video'}
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={src || 'https://youtube.com/embed/kcM2kxxjHCU?autoplay=0&feature=oembed'}
        />
      </div>
    </section>
  );
};

// Registering the component with Builder.io
Builder.registerComponent(HeroVideo, {
  name: 'HeroVideo',
  inputs: [
    {
      name: 'src',
      type: 'string',
      friendlyName: 'Video URL',
      helperText: 'Enter the YouTube embed URL',
      defaultValue: 'https://youtube.com/embed/kcM2kxxjHCU?autoplay=0&feature=oembed',
    },
    {
      name: 'title',
      type: 'string',
      friendlyName: 'Video Title',
      helperText: 'Enter the title of the video',
      defaultValue: 'YouTube Video',
    },
  ],
});

export default HeroVideo;
