import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import CustomButton from '../../../components/general/button';
import { getCampaign } from '../dates';

const AppealDetailsTabsUAT = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    givingProducts,
    lang = 'en',
    handleImpactSelected,
    handleDonorsSelected,
    impactReached,
    donorsReached,
    levelGivingReached,
    campaign
  } = props;
  const contentRef = useRef(null);

  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const tabButtonsProducts = [
    {
      tabTitle: lang === 'fr' ? 'Urgence Palestinienne' : lang === 'ar' ? 'فلسطين الطوارئ' : 'Level of Giving',
    },
    {
      tabTitle: lang === 'fr' ? 'sauver les orphelins' : lang === 'ar' ? 'إنقاذ الأيتام' : 'Donors',
    },
  ];

  getCampaign(campaign).showImpactSection && tabButtonsProducts.push({ tabTitle: lang === 'fr' ? 'Installation des réfugiés palestiniens' : lang === 'ar' ? 'مستوطنة اللاجئين الفلسطينيين' : 'Impact' })
  /* -------------------------------------------------------------------------- */

  const handleTabChange = index => {
    switch (index) {
      case 0:
        window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 });
        setActiveTabProducts(index);
        break;
      case 1:
        handleDonorsSelected();
        break;
      case 2:
        handleImpactSelected();
        break;
      default:
        window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 });
        setActiveTabProducts(index);
    }
    setActiveTabProducts(index);
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    impactReached && setActiveTabProducts(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impactReached]);
  useEffect(() => {
    donorsReached && setActiveTabProducts(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donorsReached]);
  useEffect(() => {
    levelGivingReached && setActiveTabProducts(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelGivingReached]);
  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]-" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className={`${activeTabProducts === 1 || activeTabProducts === 3 ? '' : 'md:container'} w-full mx-auto px-4 md:px-0 py-4 md:py-12`}>
        <div className="hidden sm:flex items-center w-full overflow-x-auto">
          <div className="flex gap-1 min-[768px]:gap-2 items-center md:justify-center w-full min-w-[500px] max-w-[600px] mx-auto">
            {/* {console.log("camp==", campaign, getCampaign(campaign).showImpactSection)} */}
            {tabButtonsProducts.map(({ tabTitle }, index) => (
              // (tabTitle === 'impact' && !getCampaign(campaign).showImpactSection)
              < CustomButton
                key={`btn_${index}`}
                title={tabTitle}
                onClick={handleTabChange.bind(this, index)}
                className={`${index === activeTabProducts ? '!bg-DarkerBlue text-white' : '!bg-[#F8F8F8] !border !border-DarkerBlue !text-DarkerBlue'
                  } min-w-fit !rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase mr-1 md:mr-0 ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-["Montserrat"] font-bold'
                  } text-[14px]`}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 mt-5 md:mt-10">
          <div className="flex flex-col w-full justify-center" ref={contentRef}>
            {activeTabProducts === 0 && <div>{givingProducts}</div>}
          </div>
        </div>
      </div>
    </section>
  );
};
export default AppealDetailsTabsUAT;
