// dates.js

import dayjs from 'dayjs'; // Ensure Day.js is installed

const STORAGE_KEY = 'ALL_CAMPAIGNS_INFO3';

// Initialize campaigns from localStorage or use default
let campaigns = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [
  {
    name: 'palestine',
    startDate: '2025-03-01',
    endDate: '2025-04-30',
    goal: 1000000,
    creatorCodes: ['EMR-PAL-PO-25-00', 'EMR-PAL-PO-25-01', 'EMR-PAL-PO-25-02', 'EMR-PAL-PO-25-03', 'EMR-PAL-PO-25-04', 'EMR-PAL-PO-25-05'],
    youtube: 'https://youtube.com/embed/8fCYKifjeg4?autoplay=0&feature=oembed',
    mainProduct: {
      product_id: 878,
      name: 'Support Orphans in Gaza',
      creator: 'EMR-PAL-PO-25-00',
      product_description:
        'Your support can be the lifeline they desperately need. By providing food and shelter, you can offer them not just survival, but a glimmer of hope for a brighter future. Together, we can make a profound difference in their lives because no child should have to face the world alone',
      product_image_link: 'https://cdn.matwproject.org/images/products/1741613591_gaza-orphan-support.jpg',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: '',
      link: 'palestine-emergency',
      showOnOtherAppeal: true,
    },
    showOtherAppeals: true,
    showImpactSection: true,
    lastExtended: null,
  },
  {
    name: 'egyptian',
    startDate: '2024-09-05',
    endDate: '2025-01-25',
    goal: 500000,
    creatorCodes: ['MA-PAL-GL-3-001', 'MA-PAL-GL-3-002', 'MA-PAL-GL-3-003', 'MA-PAL-GL-3-004', 'MA-PAL-GL-3-005', 'MA-PAL-GL-3-006'],
    youtube: 'https://youtube.com/embed/8fCYKifjeg4?autoplay=0&feature=oembed',
    mainProduct: {
      product_id: 601,
      name: 'Help Rebuild Lives for Palestinians escaping conflict',
      creator: 'MA-PAL-GL-3-001',
      product_description:
        'Imagine losing your belongings, your home, loved ones - everything you’ve ever known. That is the reality for more than 100,000 Palestinians who have fled to neighbouring countries.',
      product_image_link: 'https://cdn.matwproject.org/images/products/1728047980_help-provide-to-orphan-families.jpg',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: '',
      link: 'palestine-refugee-resettlement',
      showOnOtherAppeal: false,
    },
    showOtherAppeals: false,
    showImpactSection: false,
    lastExtended: null,
  },
  {
    name: 'north-lebanon-orphan',
    startDate: '2024-09-05',
    endDate: '2025-01-27',
    goal: 800000,
    creatorCodes: ['ORP-CON-LEB-24-00', 'ORP-CON-LEB-24-01', 'ORP-CON-LEB-24-02', 'ORP-CON-LEB-24-03', 'ORP-CON-LEB-24-04'],
    youtube: 'https://youtube.com/embed/pyV4cpcqvFE?autoplay=0&feature=oembed',
    mainProduct: {
      product_id: 740,
      name: 'North Lebanon Orphan',
      creator: 'ORP-CON-LEB-24-01',
      product_description: 'Your generosity can transform a life. By donating to our Prosthetic Limb Fundraise, you’re helping to provide a prosthetic limb to someone in need.',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: '',
      link: 'north-lebanon-orphan',
      showOnOtherAppeal: false,
    },
    showOtherAppeals: true,
    showImpactSection: false,
    lastExtended: null,
  },
  {
    name: 'gaza-camp',
    startDate: '2024-09-10',
    endDate: '2025-01-05',
    goal: 4000000,
    creatorCodes: [
      'EMR-PAL-GEN-24-11-1',
      'EMR-PAL-GEN-24-11-2',
      'AO-EMR-PAL-GEN-24-11-3',
      'EMR-PAL-GEN-24-11-4',
      'EMR-WNT-GEN-24-06',
      'EMR-WNT-GEN-24-03',
      'EMR-PAL-25-014',
    ],
    youtube: 'https://youtube.com/embed/s1NIFIYhC-I?autoplay=0&feature=oembed',
    mainProduct: {
      product_id: 747,
      name: 'Here To Stay: Build a camp in Gaza!',
      creator: 'EMR-PAL-GEN-24-11-1',
      product_description:
        'After more than a year of unimaginable suffering, Palestinian families now face the brutal threat of winter without shelter. Their homes destroyed, these resilient communities remain determined to survive and stay. Beneath the rubble lies Gaza - a home that beats with an unbreakable spirit.',
      product_image_link: 'https://cdn.matwproject.org/images/products/1731507690_gaza-shelter-kit.jpg',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: '',
      link: 'gaza-camp',
      showOnOtherAppeal: true,
    },
    showOtherAppeals: false,
    showImpactSection: false,
    lastExtended: null,
  },
  {
    name: 'limbs-of-hope',
    startDate: '2024-09-15',
    endDate: '2025-02-15',
    goal: 1000000,
    creatorCodes: ['EMR-PAL-MASU-003', 'EMR-PAL-MASU-005', 'EMR-PAL-MASU-006', 'EMR-PAL-MASU-009', 'EMR-PAL-MASU-201', 'EMR-PAL-MASU-202', 'EMR-PAL-MASU-203',],
    youtube: 'https://youtube.com/embed/DnuL-z8zfSw?autoplay=0&feature=oembed',
    mainProduct: {
      product_id: 638,
      name: 'GAZA: Limbs of Hope',
      creator: 'EMR-PAL-MASU-003',
      product_description:
        'Your generosity can transform a life. By donating to our Prosthetic Limb Fundraise, you’re helping to provide a prosthetic limb to someone in need.',
      product_image_link: 'https://cdn.matwproject.org/images/products/1742387846_limbs-of-hope.jpg',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: '',
      link: 'limbs-of-hope',
      showOnOtherAppeal: true,
    },
    showOtherAppeals: true,
    showImpactSection: true,
    lastExtended: null,
  },
  {
    name: 'palestine-water-pipeline',
    startDate: '2025-02-01',
    endDate: '2025-04-30',
    goal: 1000000,
    creatorCodes: ['EMR-PAL-PW-2025-001', 'EMR-PAL-PW-2025-002', 'EMR-PAL-PW-2025-003', 'EMR-PAL-PW-2025-004', 'EMR-PAL-PW-2025-005'],
    youtube: '',
    mainProduct: {
      product_id: 864,
      name: 'GAZA: Palestine Water Pipeline',
      creator: 'EMR-PAL-PW-2025-001',
      product_description:
        'Water for Gaza, Palestine. Donate to our appeal to restore water for palestine. Gaza strip water supply reconstruction.',
      product_image_link: 'https://cdn.matwproject.org/images/products/1741261324_matw-product-image.jpg',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: '',
      link: 'palestine-water-pipeline',
      showOnOtherAppeal: true,
    },
    showOtherAppeals: true,
    showImpactSection: false,
    lastExtended: null,
  },
  {
    name: 'gaza-bakery',
    startDate: '2025-03-01',
    endDate: '2025-04-30',
    goal: 1000000,
    creatorCodes: ['SDQ-PAL-BK-25-01'],
    youtube: '',
    mainProduct: {
      product_id: 904,
      name: 'The Gaza Bakery Project',
      creator: 'SDQ-PAL-BK-25-01',
      product_description:
        'The Gaza Bakery Project focuses on raising funds to build a bakery, empowering individuals to create sustainable income while providing freshly baked bread to feed the residents of Gaza. This initiative addresses both economic empowerment and food security for the local community.',
      product_image_link: 'https://cdn.matwproject.org/images/products/1742471470_bakery-project.jpg',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: '',
      link: 'gaza-bakery',
      showOnOtherAppeal: true,
    },
    showOtherAppeals: false,
    showImpactSection: true,
    lastExtended: null,
  },
  {
    name: 'gaza-babies',
    startDate: '2025-04-01',
    endDate: '2025-05-30',
    goal: 1000000,
    creatorCodes: ['EMR-PAL-NBB-25-01','EMR-PAL-NBB-25-02','EMR-PAL-NBB-25-03','EMR-PAL-NBB-25-04'],
    youtube: '',
    mainProduct: {
      product_id: 912,
      name: 'Gaza New Born Babies',
      creator: 'EMR-PAL-NBB-25-01',
      product_description:
        'Provide essential supplies to ensure the health and well-being of vulnerable infants and their mothers in Gaza. This life-saving kit includes items like baby milk, diapers, and basic hygiene products, addressing critical needs in a region facing severe humanitarian challenges',
      product_image_link: 'https://cdn.matwproject.org/images/products/1743099685_emr-pal-nbb-25-01.jpg',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: '',
      link: 'gaza-babies',
      showOnOtherAppeal: true,
    },
    showOtherAppeals: false,
    showImpactSection: false,
    lastExtended: null,
  },
  // Add more campaigns as needed
];

// Getter to retrieve campaigns
export const getCampaignDates = () => campaigns;

// Getter to retrieve a specific campaign by name
export const getCampaign = campaignName => {
  return campaigns.find(campaign => campaign.name === campaignName) || null;
};

// Getter to retrieve all creatorCodes concatenated from all campaigns
export const getAllCreatorCodesCampaigns = () => {
  return campaigns.flatMap(campaign => campaign.creatorCodes);
};

// Returns an array of objects: { id, start_date, end_date } for each creatorCode
export const getCreatorCodesWithDates = () => {
  return campaigns.flatMap(campaign =>
    campaign.creatorCodes.map(code => ({
      id: code,
      start_date: campaign.startDate,
      end_date: campaign.endDate,
      campaign: campaign.name
    })),
  );
};

// Setter to update a specific campaign's end date and lastExtended
export const updateCampaignEndDate = (campaignName, newEndDate) => {
  const today = dayjs().format('YYYY-MM-DD');
  campaigns = campaigns.map(campaign => (campaign.name === campaignName ? { ...campaign, endDate: newEndDate, lastExtended: today } : campaign));
  // Persist the updated campaigns to localStorage
  localStorage.setItem(STORAGE_KEY, JSON.stringify(campaigns));
};
