import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-new';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
import bannerMobile from '../../images/landings/muharram/muharram-mobile-banner.jpg';
import MuharramTabs from './components/MuharramTabs';
import SadaqahJariyahTabs from '../home/components/sadaqaJariyahTabs';
import cardTextImg from '../../images/landings/muharram/card-text.jpg';
import { OurPromiseSection } from '../../components/general';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import SeoData from '../../utils/seo.json';

// import services from '../../services';
import { muharramFaqs } from '../sadaqah-jariyah/faqs';
import { useSelector } from 'react-redux';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
// import { selectProducts } from '../../store/products';

const MuharramLandingPage = ({type = 'main'}) => {
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const { allProducts } = useSelector(state => state.products);
  const seo = SeoData[selectedcountryCode];
  const [waterAidProducts, setWaterAidProducts] = useState([]);
  const [foodAidProducts, setFoodAidProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sadaqahProducts, setSadaqahProducts] = useState([0, 0, 0, 0, 0]);
  const [selectedCurrency, setSelectedCurrency] = useState();

  const getAllProducts = async () => {
    try {
      let waterAidProducts = [];
      let foodAidProducts = [];
      let sadaqahProducts = [];
      allProducts.forEach(item => {
        item.sub_categories.forEach(inner_item => {
          inner_item.products.forEach(inner_item_product => {
            if (
              ['MA-PAL-GL-4-003', 'MA-PAL-GL-4-006', 'MA-PAL-GL-4-007', 'MA-PAL-GL-4-008', 'MA-PAL-GL-4-010'].includes(inner_item_product.creator)
            ) {
              waterAidProducts.push(inner_item_product);
            }
            if (['MA-PAL-GL-4-001', 'MA-PAL-GL-4-002', 'MA-PAL-GL-4-004', 'MA-PAL-GL-4-005'].includes(inner_item_product.creator)) {
              foodAidProducts.push(inner_item_product);
            }
            if (['GD-MP015', 'MKD-WEL-SDQ-2023-01', 'MKD-GEN-MOR-2023-01-119', 'MKD-GEN-MPN-2023-01-124'].includes(inner_item_product.creator)) {
              sadaqahProducts.push(inner_item_product);
            }
          });
        });
      });

      setWaterAidProducts(waterAidProducts);
      setFoodAidProducts(foodAidProducts);
      setSadaqahProducts(sadaqahProducts);
    } catch (error) {
      console.error('error========', error);
    }
  };

  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  useEffect(() => {
    const fetchData = async () => {
      await getAllProducts();
    };
    fetchData();
  });

  useEffect(() => {
    if (waterAidProducts.length > 0 && foodAidProducts.length > 0) {
      setIsLoaded(true);
    }
  }, [waterAidProducts, foodAidProducts]);

  return (
    <div>
      <NewHelmetLayout>
        {seo && (
          <Helmet>
            <title>{`${type === 'main' ? seo['muharram']['title'] : seo['muharram']['muharram-2024']['title']}`}</title>
            <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
            <meta name="description" content={`${type === 'main' ? seo['muharram']['description'] : seo['muharram']['muharram-2024']['description']}`} />
          
          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
        )}
        <section className="flex justify-center">
          <img src="https://cdn.matwproject.org/images/banners/muharram-large-banner.965d956e7bb3461ba130.jpg" alt={type === 'main' ? 'Donate to Muharram' : 'muharram 2024'} className="max-[500px]:hidden w-full" />
          <img src={bannerMobile} alt={type === 'main' ? 'Donations to Muharram' : 'islamic new year 2024'} className="min-[501px]:hidden w-full" />
        </section>
        <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
          <div className="max-w-[1160px] px-3 mx-auto">
            <CrisisWidget
              floating
              className={'bg-[#DFEEF2] h-auto pt-10'}
              fixPrice={false}
              defaultProduct={{
                creator: 'MKD-GEN-MWA-2023-01-120',
                label: 'Water Aid',
                value: 104,
              }}
            />
          </div>
        </section>
        <section className="bg-[#FFFFFF] py-6 md:py-8 flex flex-col">
          <MaxWidthContainer className="!max-w-[1440px] !px-4 md:!px-6">
            <div className="text-content md:px-52">
              <h1 className="text-[30px] md:text-[40px] text-[#253b7e] mb-8 leading-8 md:leading-10 md:text-center font-brandingBold">
                Give the best charity and save a life in Gaza during the blessed month of Muharram.
              </h1>
              <p className="text-sm md:text-xl text-[#14A2DC] mb-8 italic text-center font-brandingBold">
                “The Prophet (PBUH) said, “The best month [after Ramadhan] is the month of Allah which you call <br /> Muharram”Al-Nasa'i
              </p>
              <p className="text-[#78716C] mb-5 md:text-center md:leading-5 font-brandingMedium">
                Muharram is one of the four sacred months in Islam. Being the first month in the Islamic calendar year, there’s no better way to
                commemorate the start of the year than to give during what is known as the month of Allah.
              </p>
              <p className="text-[#78716C] mb-5 md:text-center md:leading-5 font-brandingMedium">
                In this month full of blessings we ask that you help us save lives in Gaza. Our MATW team are on the ground right now building water
                wells and providing urgent clean and safe drinking water inside Palestine. To combat the looming famine, MATW is also delivering
                urgent food relief to vulnerable families including orphaned children.
              </p>
              <p className="text-[#253b7e] mb-5 md:text-center md:leading-4 font-brandingBold">
                MATW has sent more than 100 trucks carrying urgent relief items into Palestine. Help us do more. Be a lifesaver in the month of
                Muharram. Donate now.
              </p>
            </div>
          </MaxWidthContainer>
        </section>
        {/* -------- AID WATER&FOOD  START  */}
        <section className="aid-section bg-[#FFFFFF]">
          {isLoaded && (
            <MaxWidthContainer className="!max-w-[1440px]">
              <div className="bg-[#253b7e]">
                <MuharramTabs waterAidProducts={waterAidProducts} foodAidProducts={foodAidProducts} />
              </div>
            </MaxWidthContainer>
          )}
        </section>
        {/* -------- AID WATER&FOOD  END  */}

        {/*--------- OUR PROMISE START --------- */}
        <section className="our-promise bg-[#FFFFFF]">
          {/* /* ---------------------------- Our Promise Section ---------------------------------------------- */}
          <MaxWidthContainer className="!max-w-[1440px] !px-4 md:!px-6">
            <OurPromiseSection alt="Donate to palestine with our 100% Donation policy" />
          </MaxWidthContainer>
          {/* ------------------------------------------------------------- */}
        </section>
        {/*--------- OUR PROMISE END --------- */}

        <section className="card-text bg-[#FFFFFF]">
          <MaxWidthContainer className="!max-w-[1200px] !px-4 md:!px-6">
            <div className="flex flex-wrap pt-8">
              <div className="w-full md:w-1/2 p-3 md:pl-6">
                <img src={cardTextImg} alt="card-text-img" className="max-[100%]" />
              </div>
              <div className="w-full md:w-1/2 p-3 md:pr-16">
                <h2 className="text-[30px] md:text-[40px] text-[#253b7e] mb-8 leading-8 md:leading-10 font-brandingBold">
                  Do more good during the month of Allah. Give clean water and feed the hungry in Gaza.
                </h2>
                <p className="text-[18px] text-[#78716C] mb-5 md:leading-5 font-brandingMedium">
                  MATW has been on the ground delivering urgent relief, caring for orphans and restoring homes in Palestine since before the conflict.
                  Since October 7, 2023, our team has sent more than 100 trucks into Palestine delivering tonnes of relief items. During this blessed
                  month, our good deeds are amplified and our sins are forgiven.{' '}
                </p>
                <p className="text-[18px] text-[#78716C] mb-5 md:leading-5 font-brandingMedium">
                  Muharram is a month full of blessings that is sacred to Allah SWT. During this month we are encouraged to fast, increase our good
                  deeds, and our charity.
                </p>
              </div>
            </div>
          </MaxWidthContainer>
        </section>
        <section>
          <div className="mt-4">
            <SadaqahJariyahTabs sadaqahProducts={sadaqahProducts} currency={selectedCurrency} />
          </div>
        </section>
        <section>
          <div className="md:container md:mx-auto mt-10 sm:mt-20 mb-10">
            <div className="grid grid-cols-12 px-4 md:px-2 lg:px-0">
              <div className="px-4 sm:px-6 md:px-10 lg:px-16  col-span-12   rounded-md bg-white pt-6 sm:py-16">
                <div className="w-full mt-5- col-span-12 ">
                  <div className="col-span-12 h-[300px] md:h-[400px] lg:h-[600px]">
                    <iframe
                      className="video rounded"
                      width={'100%'}
                      height={'100%'}
                      title="Gifted with Cancer - Ali Banat"
                      sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                      src={`https://www.youtube.com/embed/21CM1nzdd7E?si=hHWIfKFboO1r-yKa`}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* -------------------------------------FAQS--------------------------------- */}
        <section className="md:container w-full mx-auto  mt-10">
          <h1 className="text-[#253B7E] text-center mb-8 text-[30px] sm:text-[60px] font-bold">
            Muharram <span className="text-[#F60362]"> FAQ’s</span>
          </h1>
          <div className="grid grid-cols-3 gap-x-16 gap-y-8 px-5">
            <div className="col-span-3 md:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{muharramFaqs[0].question}</h1>
              <p className="text-[#78716C] text-base font-montserratMedium leading-4 mt-3">{muharramFaqs[0].answer}</p>
            </div>
            <div className="col-span-3 md:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{muharramFaqs[1].question}</h1>
              <p className="text-[#78716C] text-base font-montserratMedium leading-4 mt-3">
                {muharramFaqs[1].answer}
                {muharramFaqs[1].part.map(item => (
                  <li className="mt-2">{item}</li>
                ))}
              </p>
            </div>
            <div className="col-span-3 md:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{muharramFaqs[2].question}</h1>
              <p className="text-[#78716C] text-base font-montserratMedium leading-4 mt-3">{muharramFaqs[2].answer}</p>
            </div>
          </div>
        </section>
        {/* /* -------------------------------------------------------------------------- */}
      </NewHelmetLayout>
    </div>
  );
};

export default MuharramLandingPage;
