
export function generateFingerprint() {
    const userAgent = navigator.userAgent;
    const platform = navigator.userAgentData?.platform || "unknown"; // Fallback to "unknown" if userAgentData is unavailable
    const language = navigator.language;
    const screenResolution = `${window.screen.width}x${window.screen.height}`;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    

    // Create a JSON-like object
    const fingerprint = {
        userAgent: userAgent,
        platform: platform,
        language: language,
        screenResolution: screenResolution,
        timezone: timezone,
    };

    return fingerprint; // Return as an object
}
