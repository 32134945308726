// components/RamadanDailyDonationWidget.jsx
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
// import ConvertUSD from "../../../utils/convertUSD";
import { selectProducts } from '../../../store/products';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { calculateDonations } from '../../../utils/calculationUtils';
import { calculateRamadanStartDate } from '../../../utils/ramadanUtils'; // Import the utility function
// import DateSelector from "./dateSelector";
import TimeZoneSelector from './timeZoneSelector';
import ProductSelector from './productSelector';
import MultiplierSelector from './multiplierSelector';
import Summary from './summary';
import DonationCalendar from './donationCalendar';
import { DateTime } from 'luxon';
import RamadanStartDateSelector from './ramadanStartDateSelector';
// import CustomButton from "../../general/button";
import SelectCurrency from '../../general/selectCurrency';
import { BASKET_CACHE_KEY } from '../../../utils/handleLocalStorage';
import useHandleAddProduct from '../../../utils/handleAddProduct';
// import RamadanEndDateSelector from "./ramadanEndtDateSelector";
// import DonationStartDaySelector from "./donationStartDaySelector";

const RamadanDailyDonationWidget = props => {
    /* ----------------------------- Start variables ---------------------------- */
    const { productsList = [], isLastTenNightsGiving = false } = props;
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector(state => selectProducts(state));
    const [selectedProductsList, setSelectedProductsList] = useState([]);

    const [startDate, setStartDate] = useState(null);
    // donationStartDay remains in state but is not used for startDate calculation now.
    const [donationStartDay, setDonationStartDay] = useState(1);
    const [ramadanStartDate, setRamadanStartDate] = useState(null); // New State
    const [ramadanEndDate, setRamadanEndDate] = useState(null); // New State
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [selectedProducts, setSelectedProducts] = useState([]);

    // Updated State to track the donation option and selected conditions
    const [donationOption, setDonationOption] = useState('equal'); // 'equal' or 'custom'
    const [selectedConditions, setSelectedConditions] = useState([]); // Array of condition values
    const [multipliers, setMultipliers] = useState({
        friday: 2,
        lastTenNights: 2,
        oddNights: 2,
        laylatulQadr: 2,
    });

    const [donationSchedule, setDonationSchedule] = useState([]);
    const [summary, setSummary] = useState({
        totalAmount: 0,
        totalShares: 0,
        shareValue: 0,
    });
    // const [donationDifference, setDonationDifference] = useState(0); // To store the rounding difference

    // New state variable to store number of nights to donate
    const [numberOfNights, setNumberOfNights] = useState(10);
    const [numberOfMultiplierNights, setNumberOfMultiplierNights] = useState(0);
    const calendarRef = useRef(null);
    const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start hooks ------------------------------ */
    const getAllProducts = products => {
        let tempSelectedProductsList = [];
        // let tempAlternativeProductsList = [];
        // let tempAlternativeProduct;
        // let tempCurrentProduct;
        // let alternativeProducts = [
        //     'OPR-GEN-SUP-2023-01-01',
        //     'MD-AA-2024-02',
        //     'MKD-MN-001',
        //     'MD-AA-2024-07',
        //     'MKD-GEN-MZK-2023-01-123'
        // ];
        products.forEach(item => {
            item.sub_categories.forEach(inner_item => {
                inner_item.products.forEach(inner_item_product => {
                    if (inner_item_product.creator === 'RDD-2025-01') tempSelectedProductsList[0] = inner_item_product;
                    if (inner_item_product.creator === 'RDD-2025-06') tempSelectedProductsList[1] = inner_item_product;
                    if (inner_item_product.creator === 'RDD-2025-07') tempSelectedProductsList[2] = inner_item_product;
                    if (inner_item_product.creator === 'RDD-2025-08') tempSelectedProductsList[3] = inner_item_product;
                    if (inner_item_product.creator === 'RDD-2025-09') tempSelectedProductsList[4] = inner_item_product;
                    if (inner_item_product.creator === 'RDD-2025-10') tempSelectedProductsList[5] = inner_item_product;
                    if (inner_item_product.creator === 'RDD-2025-11') tempSelectedProductsList[6] = inner_item_product;
                    if (inner_item_product.creator === 'RDD-2025-13') tempSelectedProductsList[7] = inner_item_product;
                    // const matchedProduct = productsList.find(
                    //     // (product) => product.productId === inner_item_product.creator
                    //     (product) => product === inner_item_product.creator
                    // );
                    // if (matchedProduct) {
                    //     tempSelectedProductsList.push(inner_item_product);
                    //     if (matchedProduct.defaultProduct) {
                    //         tempAlternativeProduct = inner_item_product;
                    //     }
                    // }
                    // if (alternativeProducts.includes(inner_item_product.creator)) {
                    //     tempAlternativeProductsList.push(inner_item_product);
                    // }
                });
            });
        });
        // let uniqueItems;
        // if (tempSelectedProductsList.length) {
        //     uniqueItems = tempSelectedProductsList.filter((item, index, self) =>
        //         index === self.findIndex(t => t.creator === item.creator)
        //     );
        // } else {
        //     uniqueItems = tempAlternativeProductsList.filter((item, index, self) =>
        //         index === self.findIndex(t => t.creator === item.creator)
        //     );
        // }
        // if (!tempAlternativeProduct) {
        //     tempAlternativeProduct = uniqueItems[0];
        // }
        tempSelectedProductsList = tempSelectedProductsList.filter(n => n);
        setSelectedProductsList(tempSelectedProductsList);
    };
    useEffect(() => {
        if (products && Object.keys(products).length && productsList.length) {
            getAllProducts(products);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, productsList]);

    useEffect(() => {
        if (timeZone) {
            // Determine the current Gregorian year based on user's time zone
            const currentYear = DateTime.now().setZone(timeZone).year;
            // Calculate Ramadan start date
            const calculatedRamadanStartDate = calculateRamadanStartDate(currentYear, timeZone);

            if (calculatedRamadanStartDate) {
                // console.log("calculatedRamadanStartDate=", calculatedRamadanStartDate);
                setRamadanStartDate(calculatedRamadanStartDate);
            } else {
                setRamadanStartDate(null);
            }
        }
    }, [timeZone]);

    // Automatically compute donationStartDay based on the current day relative to Ramadan start.
    useEffect(() => {
        if (ramadanStartDate && timeZone) {
            const ramadanStartLuxon = DateTime.fromISO(ramadanStartDate, { zone: timeZone });
            const now = DateTime.now().setZone(timeZone).startOf('day');
            let computedDonationStartDay = 1;
            if (now >= ramadanStartLuxon) {
                computedDonationStartDay = Math.floor(now.diff(ramadanStartLuxon, 'days').days) + 1;
                if (computedDonationStartDay > 30) {
                    computedDonationStartDay = 30;
                }
            }
            setDonationStartDay(computedDonationStartDay);
        }
    }, [ramadanStartDate, timeZone]);

    // New logic for calculating startDate:
    // - If today (in selected timeZone) is on or before the ramadanStartDate, use ramadanStartDate.
    // - If today is after ramadanStartDate, use today's date.
    useEffect(() => {
        // console.log("on ramadan start=", ramadanStartDate, timeZone)
        if (ramadanStartDate && timeZone) {
            const ramadanStartLuxon = DateTime.fromISO(ramadanStartDate, { zone: timeZone }).startOf('day');
            const now = DateTime.now().setZone(timeZone).startOf('day');
            if (now <= ramadanStartLuxon) {
                setStartDate(ramadanStartLuxon.toISODate());
                // console.log(`Today is before or equal to Ramadan start. Start Date = ${ramadanStartLuxon.toISODate()}`);
            } else {
                setStartDate(now.toISODate());
                // console.log(`Today is after Ramadan start. Start Date = ${now.toISODate()}`);
            }
        } else {
            setStartDate(null);
        }
    }, [ramadanStartDate, timeZone]);

    // Set Ramadan end date to 30 days after the Ramadan start date.
    useEffect(() => {
        if (ramadanStartDate && timeZone) {
            const ramadanStartLuxon = DateTime.fromISO(ramadanStartDate, { zone: timeZone });
            const computedRamadanEndDate = ramadanStartLuxon.plus({ days: 29 }).toISODate(); // 30 days total
            setRamadanEndDate(computedRamadanEndDate);
        } else {
            setRamadanEndDate(null);
        }
    }, [ramadanStartDate, timeZone]);

    // Calculate donations when necessary inputs change.
    useEffect(() => {
        const ramadanStart = DateTime.fromISO(ramadanStartDate, { zone: timeZone });
        const ramadanEnd = ramadanStart.plus({ days: 29 }); // 30 nights total

        const userStartDate = DateTime.fromISO(startDate, { zone: timeZone }).startOf('day');

        let nightsToDonate = ramadanEnd.diff(userStartDate, 'days').days + 1; // Inclusive of start date
        nightsToDonate = Math.max(1, Math.min(30, Math.floor(nightsToDonate)));
        setNumberOfNights(nightsToDonate);
        if (startDate && selectedProducts.length > 0 && ramadanStartDate) {
            // Ensure ramadanStartDate is available
            try {

                const adjustedRamadanStart = userStartDate;
                const adjustedRamadanEnd = adjustedRamadanStart.plus({ days: nightsToDonate - 1 });

                const {
                    donationSchedule: adjustedDonationSchedule,
                    difference,
                    totalNights,
                } = calculateDonations({
                    startDate: adjustedRamadanStart.toISODate(),
                    timeZone,
                    selectedProducts,
                    donationOption, // Updated parameter
                    selectedConditions, // Updated parameter
                    multipliers,
                    currency: selectedCurrencyStore,
                    numberOfNights: nightsToDonate, // Pass the number of nights
                    isLast10Nights: isLastTenNightsGiving,
                });
                // console.log("donation schedule=",adjustedDonationSchedule, adjustedRamadanStart.toISODate())
                setDonationSchedule(adjustedDonationSchedule);
                setNumberOfNights(totalNights);

                const exactTotalAmount = selectedProducts.reduce((sum, p) => {
                    const price = p[selectedCurrencyStore.toLowerCase()];
                    if (typeof price === 'number') {
                        return sum + price * p.quantity;
                    }
                    return sum;
                }, 0);

                const totalAmountRounded = Math.round(exactTotalAmount);

                const totalShares = adjustedDonationSchedule.reduce((sum, day) => sum + (day.shares || 1), 0);

                const shareValue = totalShares > 0 ? totalAmountRounded / totalShares : 0;

                setSummary({ totalAmount: totalAmountRounded, totalShares, shareValue });

                const multiplierNights = adjustedDonationSchedule.filter(day => day.isMultiplierNight).length;

                setNumberOfMultiplierNights(multiplierNights);
            } catch (error) {
                console.error('Error calculating donations:', error);
            }
        } else {
            setDonationSchedule([]);
            setSummary({ totalAmount: 0, totalShares: 0, shareValue: 0 });
            // setNumberOfNights(10);
            setNumberOfMultiplierNights(0);
        }
    }, [startDate, timeZone, selectedProducts, donationOption, selectedConditions, multipliers, selectedCurrencyStore, ramadanStartDate]);

    // Determine the selected strategies based on donationOption and selectedConditions.
    const determineSelectedStrategies = () => {
        // console.log("selected strategies==",donationOption)
        if (donationOption === 'equal') {
            return [
                {
                    label: 'Equal Division',
                    multiplier: 1,
                    nights: numberOfNights,
                },
            ];
        } else if (donationOption === 'custom') {
            return selectedConditions.map(condition => {
                let label = '';
                let multiplier = multipliers[condition] || 1;
                let applicableNights = 0;

                switch (condition) {
                    case 'friday':
                        label = 'on Jummah (Friday)';
                        applicableNights = donationSchedule.filter(day => {
                            const date = DateTime.fromISO(day.date, { zone: timeZone });
                            return date.weekday === 5;
                        }).length;
                        break;
                    case 'lastTenNights':
                        label = 'in the last 10 nights';
                        applicableNights = donationSchedule.filter((day, index) => index >= Math.max(0, numberOfNights - 10)).length;
                        break;
                    case 'oddNights':
                        label = 'on odd nights (within last 10 nights)';
                        applicableNights = donationSchedule.filter((day, index) => {
                            const nightNumber = index + 1;
                            const isOdd = nightNumber % 2 !== 0;
                            const isInLastTen = index >= Math.max(0, numberOfNights - 10);
                            return isOdd && isInLastTen;
                        }).length;
                        break;
                    case 'laylatulQadr':
                        label = 'on the 27th night - Laylat-ul-Qadr';
                        applicableNights = donationSchedule.some(day => day.date === ramadanStartDate && numberOfNights >= 27) ? 1 : 0;
                        break;
                    default:
                        label = '';
                        applicableNights = 0;
                }

                return {
                    label,
                    multiplier,
                    nights: applicableNights,
                };
                // }).filter(strategy => strategy.nights > 0);
            });
        } else {
            return [];
        }
    };

    const selectedStrategies = determineSelectedStrategies();
    // console.log("selected strategies=",selectedStrategies)
    // ---------- Loading from LocalStorage on Mount ----------
    useEffect(() => {
        const storedData = localStorage.getItem('ramadanWidgetData');
        const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY)) || {};
        // let tempCreators = ['OPR-GEN-SUP-2023-01-01', 'MD-AA-2024-02', 'MKD-MN-001', 'MD-AA-2024-07', 'MKD-GEN-MZK-2023-01-123'];
        let tempCreators = ['RDD-2025-01', 'RDD-2025-06', 'RDD-2025-07', 'RDD-2025-08', 'RDD-2025-09', 'RDD-2025-10', 'RDD-2025-11', 'RDD-2025-13'];
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            if (parsedData.ramadanStartDate) setRamadanStartDate(parsedData.ramadanStartDate);
            if (parsedData.timeZone) setTimeZone(parsedData.timeZone);
            if (parsedData.donationOption) setDonationOption(parsedData.donationOption);
            if (parsedData.selectedConditions) setSelectedConditions(parsedData.selectedConditions);
            if (parsedData.multipliers) setMultipliers(parsedData.multipliers);
            if (parsedData.numberOfNights) setNumberOfNights(parsedData.numberOfNights);
            //   if (parsedData.selectedProducts) setSelectedProducts(parsedData.selectedProducts);
            // let temp = parsedData.selectedProducts || [];
            // temp.push(foodPrd)
            // console.log("food prds=", temp)
            // if (parsedData.selectedProducts) setSelectedProducts(temp);
            if (parsedData.selectedProducts) {
                const filteredProducts = parsedData.selectedProducts.filter(product =>
                    tempCreators.includes(product.creator)
                );
                setSelectedProducts(filteredProducts);
            }
            if (parsedData.numberOfMultiplierNights) setNumberOfMultiplierNights(parsedData.numberOfMultiplierNights);
            if (parsedData.summary) setSummary(parsedData.summary);
            if (parsedData.donationSchedule) setDonationSchedule(parsedData.donationSchedule);
            if (
                basketStatesFromLocalStorage &&
                basketStatesFromLocalStorage.products &&
                basketStatesFromLocalStorage.products.length &&
                !basketStatesFromLocalStorage.products[0]
            ) {
                if (parsedData.selectedProducts) {
                    parsedData.selectedProducts.map(item => addProduct({ product: item, currency: selectedCurrencyStore, justAddToLocalStorage: true }));
                }
            }
            //   console.log("baksetsss=",basketStatesFromLocalStorage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ---------- Saving to LocalStorage when any dependency changes ----------
    useEffect(() => {
        const dataToStore = {
            ramadanStartDate,
            timeZone,
            donationOption,
            selectedConditions,
            multipliers,
            numberOfNights,
            selectedProducts,
            numberOfMultiplierNights,
            summary,
            donationSchedule,
            selectedStrategies,
        };
        localStorage.setItem('ramadanWidgetData', JSON.stringify(dataToStore));
    }, [
        ramadanStartDate,
        timeZone,
        donationOption,
        selectedConditions,
        multipliers,
        numberOfNights,
        selectedProducts,
        numberOfMultiplierNights,
        summary,
        donationSchedule,
        selectedStrategies,
    ]);

    return (
        <div className={`w-full scrollarea min-h-[950px]`}>
            <div className={`w-full py-2 md:py-4 flex flex-wrap items-start`}>
                <div className="basis-full lg:basis-2/3 px-4 md:px-4 flex flex-col gap-4 md:gap-6">
                    <SectionTitle
                        count={1}
                        text={isLastTenNightsGiving ? 'Select your start date for Ramadan' : 'Select the Starting Date of your daily giving'}
                    />
                    <div>
                        <p className="text-[#78716C] text-sm md:text-base">
                            {isLastTenNightsGiving
                                ? 'Enter the date that you started fasting this Ramadan, and we will calculate the start date for your giving on the last ten nights!'
                                : 'This will be the day your daily giving will begin from until the end of Ramadan'}
                        </p>
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-2">
                        <div className="w-full md:w-1/3 flex flex-col md:flex-row gap-2">
                            <RamadanStartDateSelector
                                setRamadanStartDate={setRamadanStartDate}
                                timeZone={timeZone}
                                ramadanStartDate={ramadanStartDate} // Pass ramadanStartDate
                            />
                        </div>
                        <div className="w-full md:w-2/3 flex gap-2">
                            <TimeZoneSelector timeZone={timeZone} setTimeZone={setTimeZone} />
                            <div className="w-1/2 md:w-full">
                                <label className="text-stone-500 flex items-center">Currency</label>
                                <div className="cursor-pointer relative w-full rounded-md h-12 border border-[#282828]" id="ramadan-daily-currency">
                                    <SelectCurrency onChangeCurrency={e => { }} className={`h-full [&>div]:h-full-`} />
                                </div>
                            </div>
                        </div>
                        {/* Removed DonationStartDaySelector */}
                    </div>
                    <SectionTitle count={2} text={isLastTenNightsGiving ? 'Customise Your Giving' : 'Select your giving pattern'} />
                    <MultiplierSelector
                        donationOption={donationOption}
                        setDonationOption={setDonationOption}
                        selectedConditions={selectedConditions}
                        setSelectedConditions={setSelectedConditions}
                        multipliers={multipliers}
                        setMultipliers={setMultipliers}
                        numberOfNights={numberOfNights}
                        isLastTenNightsGiving={isLastTenNightsGiving}
                    />
                    <div className="bg-[#78716C] h-[3px] w-full"></div>
                    <SectionTitle count={3} text={isLastTenNightsGiving ? 'Choose your cause' : 'Breaking it down'} />
                    <div>
                        {/* <h3 className="text-stone-600 text-lg md:text-xl mb-2">
                            Breaking it down
                        </h3> */}
                        <p className="text-[#78716C] text-sm md:text-base">
                            {isLastTenNightsGiving
                                ? 'Below you’ll find a list of projects and causes that you can choose to allocate all or part of your giving to.'
                                : 'Below you’ll find a breakdown of your donations across the month of Ramadan. If you wish to divide your amount by project or cause, you can do this also in the designated project boxes shown below!'}
                        </p>
                    </div>
                    <ProductSelector
                        products={selectedProductsList}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        // setTotalAmount={setTotalAmount} // Pass the setter for total amount
                        currency={selectedCurrencyStore}
                    />
                    {/* <CustomButton
                        title="Zakat Calculator"
                        className={`mt-4 w-full md:w-auto md:max-w-[250px] mb-4 !bg-transparent !text-[#14A2DC] underline`}
                        link={'/zakat-calculator'}
                        target={'_blank'}
                    /> */}
                </div>
                <div className="basis-full lg:basis-1/3 px-2 md:px-4 sticky top-28">
                    <Summary
                        selectedProducts={selectedProducts}
                        numberOfMultiplierNights={numberOfMultiplierNights}
                        numberOfNights={numberOfNights}
                        selectedStrategies={selectedStrategies} // Updated prop
                        totalAmount={summary.totalAmount}
                        currency={selectedCurrencyStore}
                        viewCalendar={() => window.scrollTo({ behavior: 'smooth', top: calendarRef.current.offsetTop - 100 })}
                        selectedConditions={selectedConditions}
                        donationOption={donationOption}
                        isLastTenNightsGiving={isLastTenNightsGiving}
                    />
                </div>
            </div>
            {isLastTenNightsGiving && (
                <div className={`w-full py-2 md:py-4 flex flex-wrap items-start`}>
                    <div className="basis-full lg:basis-2/3 px-4 md:px-4 flex flex-col gap-4 md:gap-6">
                        <p className="text-[#78716C] text-sm md:text-base">
                            Below you’ll find a breakdown of your donations across the last 10 nights of Ramadan. If you wish to divide your amount by project or cause, you
                            can do this also in the designated project boxes shown below!
                        </p>
                    </div>
                </div>
            )}
            <div className="w-full mt-4 py-2 md:py-4 px-0 md:px-4" id="calendar" ref={calendarRef}>
                <DonationCalendar
                    donationSchedule={donationSchedule}
                    currency={selectedCurrencyStore}
                    ramadanStartDate={ramadanStartDate} // Pass Ramadan start date
                    timeZone={timeZone} // Pass user's time zone
                    totalAmount={summary.totalAmount}
                    numberOfNights={numberOfNights}
                    isLastTenNightsGiving={isLastTenNightsGiving}
                // setNumberOfNights={setNumberOfNights}
                />
            </div>
        </div>
    );
};

const SectionTitle = props => {
    const { count = 0, text = '' } = props;
    return (
        <div className="w-full flex items-center border border-[#253B7E] bg-white rounded-full p-2 md:p-4 shadow-md">
            <div className="text-white bg-[#F60362] rounded-full w-10 h-10 md:w-14 md:h-14 min-w-[40px] flex justify-center items-center font-bold text-lg md:text-2xl mr-2 md:mr-4">
                {count}
            </div>
            <p className="text-lg md:text-xl text-[#253B7E] font-bold">{text}</p>
        </div>
    );
};

export default RamadanDailyDonationWidget;
