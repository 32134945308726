// components/TimeZoneSelector.jsx
import React, { useState, useRef, useEffect } from 'react';
import Select, { components } from 'react-select';
import timeZones from '../../../utils/timeZones';
import { FiGlobe } from 'react-icons/fi';

// Custom Control component to include the icon
const ControlWithIcon = ({ children, ...props }) => {
    return (
        <components.Control {...props}>
            {children}
            <FiGlobe size={20} className="mr-2 text-[#1E3271]" />
        </components.Control>
    );
};

// Custom DropdownIndicator component to remove the default arrow
const DropdownIndicator = () => null;

const TimeZoneSelector = ({ timeZone, setTimeZone }) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectorRef = useRef(null);

    // Handle clicks outside the component to close the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                selectorRef.current &&
                !selectorRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='w-1/2 md:w-full'>
            <label className='text-stone-500 flex items-center'><span className='text-red-600 mr-1'>*</span>Time Zone</label>
            <div
                className="cursor-pointer relative w-full rounded-md h-12 border border-[#282828]"
                onClick={() => setIsOpen(!isOpen)}
                ref={selectorRef}
            >
                <Select
                    aria-label="Select time zone"
                    value={timeZones.find((tz) => tz.value === timeZone)}
                    onChange={(selected) => {
                        setTimeZone(selected.value);
                        setIsOpen(false); // Close dropdown after selection
                    }}
                    options={timeZones}
                    placeholder="Select time zone"
                    components={{
                        Control: ControlWithIcon,
                        DropdownIndicator: DropdownIndicator, // Remove the arrow
                        IndicatorSeparator: () => null, // Remove the separator line
                    }}
                    className="hidden md:block w-full h-full rounded-md [&>div]:min-h-full"
                    menuIsOpen={isOpen}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: 'none',
                            cursor: 'pointer', // Change cursor to pointer
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            color: '#6B7280', // Tailwind's gray-500
                        }),
                        menu: (provided) => ({
                            ...provided,
                            zIndex: 9999, // Ensure the menu appears above other elements
                        }),
                    }}
                />
                <Select
                    aria-label="Select time zone"
                    value={timeZones.find((tz) => tz.value === timeZone)}
                    onChange={(selected) => {
                        setTimeZone(selected.value);
                        setIsOpen(false); // Close dropdown after selection
                    }}
                    options={timeZones}
                    placeholder="Select time zone"
                    components={{
                        Control: ControlWithIcon,
                        DropdownIndicator: DropdownIndicator, // Remove the arrow
                        IndicatorSeparator: () => null, // Remove the separator line
                    }}
                    className="md:hidden w-full h-full rounded-md [&>div]:min-h-full"
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: 'none',
                            cursor: 'pointer', // Change cursor to pointer
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            color: '#6B7280', // Tailwind's gray-500
                        }),
                        menu: (provided) => ({
                            ...provided,
                            zIndex: 9999, // Ensure the menu appears above other elements
                        }),
                    }}
                />
            </div>
        </div>
    );
};

export default TimeZoneSelector;

