import { Builder } from '@builder.io/react';
import { Helmet } from 'react-helmet-new';
const MetaTags = ({
  title = '',
  description = ''
}) => {
  /* ----------------------------- Start variables ---------------------------- */
  /* -------------------------------------------------------------------------- */
  return <>
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
          <meta name="description" content={description} />
        
          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
        <div></div>
        </>;
};
Builder.registerComponent(MetaTags, {
  name: 'MetaTags',
  inputs: [{
    "name": "title",
    "type": "string"
  }, {
    "name": "description",
    "type": "string"
  }]
});
export default MetaTags;