import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import ConvertUSD from "../../../../../utils/convertUSD";
import Sticky from "react-sticky-el";
import useHandleAddProduct from "../../../../../utils/handleAddProduct";
import handleDaysLeft from "../../../../../pages/MATWAppeal/handleDaysLeft";
import SocialBox from "../../../../../pages/fundraising/components/socialBox";
import getSymbolFromCurrency from "currency-symbol-map";
import { selectSelectedCurrency } from "../../../../../store/user";
import CustomButton from "../../../../general/button";
import { Builder } from "@builder.io/react";

const AppealCurrentProduct = ({
  lang = "en",
  currentProduct,
  goal,
  campaign,
  selectedGivingProducts,
  iframeVideoLink = "https://youtube.com/embed/ScnETpaXUSY?autoplay=0&feature=oembed",
  campaignTitle,
  campaignDescription,
  selectedInfo,
  areTabsFixed = false,
}) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [addProduct] = useHandleAddProduct();
  const [showInfo, setShowInfo] = useState(false);
  const [isTabsFixed, setIsTabsFixed] = useState(areTabsFixed || false);
  const [currentProductState,setCurrentProductState] = useState()

  const [link, setLink] = useState("palestine-emergency");
  /* ------------------------------ Start methods ----------------------------- */
  const handlePercentage = (raised, goal) => {
    return `${((Number(raised) / Number(goal)) * 100).toFixed(2)}%`;
  };

  const ShowCurrencies2 = (currency, _price, showAbbr = true) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString("en", options);
    }
    let _final = "";
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case "GBP":
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case "EUR":
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case "MYR":
        _final = `${price}`;
        break;
      case "IDR":
        _final = `Rp${price}`;
        break;
      case "AED":
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    }
    return _final;
  };
  /* ------------------------------- Start hooks ------------------------------ */

  useEffect(() => {
    selectedCurrencyStore
      ? setSelectedCurrency(selectedCurrencyStore)
      : setSelectedCurrency("AUD");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */

  return (
    <section className="w-full md:container md:mx-auto grid grid-cols-2 gap-2 md:gap-8 pb-8 md:py-8">
      <div className="col-span-2 md:col-span-1">
        <div
          className={`basis-full xs:aspect-w-16  xs:aspect-h-9 sm:h-[300px] md:h-[600px] lg:h-[890px] !max-h-[300px] min-h-fit md:!max-h-[450px]   md:!rounded-lg overflow-hidden`}
        >
          <iframe
            className="video"
            width={"100%"}
            height={"100%"}
            title={"youtube"}
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src={iframeVideoLink}
          />
        </div>
      </div>
      <div className="col-span-2 md:col-span-1 px-4 py-3 lg:pl-2 lg:pr-0 md:pt-0 md:pb-0">
        <h3 className="w-full text-DarkerBlue text-2xl lg:text-3xl xl:text-4xl font-medium my-3 md:my-2 md:leading-none">
          {campaignTitle}
        </h3>
        <div className="w-full relative">
          <div className={`my-2 relative overflow-hidden h-auto mb-4`}>
            <p
              className={`text-[#78716C] text-xs- sm:text-sm overflow-hidden transition-all duration-200 min-h-[79px]-`}
            >
              {campaignDescription}
            </p>
          </div>
        </div>
        <CustomButton
          onClick={() => setShowInfo(!showInfo)}
          title={"Click to know more"}
          icon={
            <ChevronDownIcon
              className={`w-5 h-5 transition-all duration-300 cursor-pointer ml-1 ${showInfo ? " transform rotate-180" : ""}`}
            />
          }
          type="button"
          className={`!bg-LightBlue !text-[10px] !h-8`}
        />
        <div
          className={`flex flex-col overflow-hidden w-full bg-stone-100- rounded-md ${
            showInfo ? "min-h-[600px] py-4 md:py-8" : "h-0 py-0"
          } transition-all duration-200`}
        >
          {selectedInfo?.map((info, index) => (
            <div key={index} className="mb-6">
              <div
                className={`text-2xl md:text-4xl mb-4 ${lang === "ar" ? "font-[AlmariMedium] font-bold" : "font-brandingBold"} text-DarkerBlue`}
              >
                {info.title}
              </div>
              <div
                className={`text-[#78716C] md:text-lg ${lang === "ar" ? "font-[AlmariMedium]" : "font-brandingMedium"}`}
              >
                {info.description}
              </div>

              {/* Only render the box if it has content */}
              {(info.box_title || info.box_description) && (
                <div className="bg-white rounded-md shadow-md mt-2 grid grid-cols-2 gap-2 md:p-4">
                  <div className="col-span-2">
                    <div
                      className={`text-DarkerBlue text-4xl ${lang === "ar" ? "font-[AlmariMedium] font-bold" : "font-gotcha- font-brandingBold"}`}
                    >
                      {info.box_title}
                    </div>
                  </div>
                  <div className="col-span-2 mb-4">
                    <div
                      className={`text-[#78716C] md:text-lg ${lang === "ar" ? "font-[AlmariMedium]" : "font-brandingMedium"}`}
                    >
                      {info.box_description}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}

          <CustomButton
            onClick={() => setShowInfo(!showInfo)}
            title={"Close"}
            icon={
              <ChevronDownIcon
                className={`w-5 h-5 transition-all duration-300 cursor-pointer ml-1 transform rotate-180`}
              />
            }
            type="button"
            className={`!bg-white !text-LightBlue !text-[10px] !h-8 shadow-md`}
          />
        </div>
        <div className="w-full flex-col flex justify-center pt-2 md:pt-4">
          <div className="flex items-center justify-between mb-2">
            <div className="flex flex-col w-full">
              <div className="text-lg md:text-2xl text-DarkerBlue basis-full font-bold">
                {currentProduct && currentProduct.totalRaised
                  ? ShowCurrencies2(
                      selectedCurrency,
                      Math.round(currentProduct.totalRaised),
                      true,
                    )
                  : ShowCurrencies2(selectedCurrency, 50, true)}
                <span className="text-sm ml-0.5">{selectedCurrency}</span>
              </div>
              <div className="flex items-center gap-1">
                <span className="text-sm inline-block lg:w-auto">
                  Raised of{" "}
                </span>
                <div className="text-DarkerBlue font-bold">
                  {ShowCurrencies2(
                    selectedCurrency,
                    Math.round(
                      ConvertUSD(selectedCurrency, goal || 500000, false),
                      false,
                    ),
                  )}
                </div>
                <span className="text-sm inline-block lg:w-auto">goal</span>
              </div>
            </div>
          </div>
          <div className="flex w-full h-2 bg-[#E3E3E3] bg-opacity-10- mb-2">
            {currentProduct && (
              <div
                className={`bg-[#F60362]`}
                style={{
                  width: `${handlePercentage(currentProduct ? currentProduct.totalRaised : 0, ConvertUSD(selectedCurrency, goal, false))}`,
                }}
              />
            )}
          </div>
          <div className="flex w-full text-xs">
            <div className="text-DarkerBlue font-bold mr-0.5">
              {currentProduct ? currentProduct.totalSupporters : 0}
            </div>
            <span className="inline-block lg:w-auto mr-1">Supporters,</span>
            <div className="text-DarkerBlue font-bold mr-0.5">
              {handleDaysLeft(campaign)}
            </div>
            <span className="inline-block lg:w-auto">days left</span>
          </div>
        </div>
        <div className="w-full hidden sm:flex items-center mt-4 md:mt-8">
          {currentProduct ? (
            <div className="w-full hidden sm:flex items-center">
              {campaign === "limbs-of-hope" ||
              campaign === "palestine" ||
              campaign === "north-lebanon-orphan" ||
              campaign === "gaza-camp" ? (
                selectedGivingProducts.length ? (
                  <CustomButton
                    link={`/checkout`}
                    title={"Donate Now"}
                    type="button"
                    className={`!rounded-full w-full sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                  />
                ) : (
                  <CustomButton
                    title={"Donate Now"}
                    type="button"
                    className={`!rounded-full w-full sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                    onClick={() =>
                      addProduct({
                        product: currentProduct,
                        currency: selectedCurrency,
                      })
                    }
                  />
                )
              ) : (
                <CustomButton
                  link={`/appeals/${link}`}
                  title={"Donate Now"}
                  type="button"
                  className={`!rounded-full w-full sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                />
              )}
              <div className="order-1 md:order-2">
                <SocialBox>
                  <div
                    className={`!rounded-full !bg-DarkerBlue flex items-center justify-center text-center font-['Montserrat'] font-bold px-4 text-[12px] md:text-[14px] h-[42px] md:h-[44px] transition duration-150 ease-in-out uppercase`}
                  >
                    Share
                  </div>
                </SocialBox>
              </div>
            </div>
          ) : (
            <Skeleton height={43} />
          )}
        </div>
        <div className="flex sm:hidden [&>div]:w-full z-10">
          <Sticky
            stickyStyle={{ bottom: "0px", top: "unset" }}
            stickyClassName="w-full flex justify-end items-center shadow-[rgba(189,189,189,0.5)_0px_-15px_25px_-1px] z-10"
            mode="top"
            onFixedToggle={() => {
              setIsTabsFixed(!isTabsFixed);
            }}
          >
            <div className="w-full flex items-center bg-white rounded-t-md p-2">
              {campaign === "limbs-of-hope" ||
              campaign === "palestine" ||
              campaign === "north-lebanon-orphan" ||
              campaign === "gaza-camp" ? (
                selectedGivingProducts.length ? (
                  <CustomButton
                    link={`/checkout`}
                    title={"Donate Now"}
                    type="button"
                    className={`!rounded-full w-full- w-[calc(100%_-_140px)] sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                  />
                ) : (
                  <CustomButton
                    title={"Donate Now"}
                    type="button"
                    className={`!rounded-full w-full- w-[calc(100%_-_140px)] sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                    onClick={() =>
                      addProduct({
                        product: currentProduct,
                        currency: selectedCurrency,
                      })
                    }
                  />
                )
              ) : (
                <CustomButton
                  link={`/appeals/${link}`}
                  title={"Donate Now"}
                  type="button"
                  className={`!rounded-full w-full- w-[calc(100%_-_140px)] sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                />
              )}
              <SocialBox top={isTabsFixed}>
                <div
                  className={`!rounded-full !bg-DarkerBlue flex items-center justify-center text-center font-['Montserrat'] font-bold px-4 text-[12px] md:text-[14px] h-[42px] md:h-[44px] transition duration-150 ease-in-out uppercase`}
                >
                  Share
                </div>
              </SocialBox>
            </div>
          </Sticky>
        </div>
      </div>
    </section>
  );
};

Builder.registerComponent(AppealCurrentProduct, {
  name: "AppealCurrentProduct",
  inputs: [
    {
      name: "lang",
      type: "string",
      defaultValue: "en",
    },
    {
      name: "currentProduct",
      type: "object",
    },
    {
      name: "goal",
      type: "number",
    },
    {
      name: "campaign",
      type: "string",
    },
    {
      name: "selectedGivingProducts",
      type: "object",
    },
    {
      name: "iframeVideoLink",
      type: "string",
      defaultValue:
        "https://youtube.com/embed/ScnETpaXUSY?autoplay=0&feature=oembed",
    },
    {
      name: "campaignTitle",
      type: "string",
      defaultValue: "GAZA: Limbs of Hope",
    },
    {
      name: "campaignDescription",
      type: "string",
      defaultValue: `Deep inside the ruins of Gaza lies a problem not known to many. More than 94,000 Palestinians have been severely injured since the beginning of the most recent conflict on October 7, 2023.`,
    },
    {
      name: "selectedInfo",
      type: "object",
      defaultValue: {
        title: "Empower Lives with Prosthetic Support",
        description: `Your generosity can transform a life. By donating to our Prosthetic Limb Fundraise, you’re helping to provide a prosthetic limb to someone in need. Every contribution, big or small, goes toward covering the costs of acquiring, customizing, and fitting a prosthetic limb. Together, we can offer the gift of mobility, independence, and a brighter future for individuals who deserve a chance to live fully again. Join us in making a profound impact today!`,
        box_title: "",
        box_description: "",
      },
      subFields: [
        {
          name: "title",
          type: "string",
        },
        {
          name: "description",
          type: "string",
        },
        {
          name: "box_title",
          type: "string",
        },
        {
          name: "box_description",
          type: "string",
        },
      ],
    },
    {
      name: "areTabsFixed",
      type: "boolean",
      defaultValue: false,
    },
  ],
});

export default AppealCurrentProduct;
