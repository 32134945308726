import React, { useState, useEffect } from 'react';
import { set, useForm } from 'react-hook-form';
import { generateFingerprint } from '../../../utils/fingerprintUtils';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserIP, selectUserIP } from '../../../store/user';
import { getUTMData } from '../../../utils/utmUtils';
import PhoneInput from 'react-phone-input-2';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import fetchPopupSettings from '../../../utils/fetchPopSetting';
import { showDonationPopup } from '../../../store/popupSlice';

const setCookie = (name, value, days) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${encodeURIComponent(JSON.stringify(value))};expires=${expires.toUTCString()};path=/`;
};

const getCookie = name => {
  const cookies = document.cookie.split('; ');
  const cookie = cookies.find(row => row.startsWith(`${name}=`));
  return cookie ? JSON.parse(decodeURIComponent(cookie.split('=')[1])) : null;
};

const FloatingDonationBox = () => {
  const [fingerprint, setFingerprint] = useState(null);
  const [showWidget, setShowWidget] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [isFacebookBrowser, setIsFacebookBrowser] = useState(false);
  const [phoneCountry, setPhoneCountry] = useState('us');
  const [countryCode, setCountryCode] = useState('1');


  const dispatch = useDispatch();
  const userIP = useSelector(selectUserIP);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      aggree: true,
    },
  });

  const handlePhoneValidation = (value, country, setFieldValue) => {
    let inputValue = value.startsWith('+') ? value : `+${value}`;
    const asYouType = new AsYouType(country.iso2);
    let formattedPhone = asYouType.input(inputValue);

    if (country.dialCode === '44' && formattedPhone.startsWith('+44 0')) {
      formattedPhone = `+44 ${formattedPhone.slice(5)}`;
    }

    const isValid = isValidPhoneNumber(formattedPhone, country.iso2);

    let plainNumber = formattedPhone.replace(/\D/g, '');

    setIsPhoneValid(isValid);
    if (isValid) {
      setFieldValue('phone', plainNumber);
      setCountryCode(country.dialCode);
      clearErrors('phone');
      setPhoneError('');
    } else {
      setFieldValue('phone', plainNumber);
      setError('phone', {
        type: 'manual',
        message: 'Invalid phone number. Please check and try again.',
      });
      setPhoneError('Invalid phone number. Please check and try again.');
    }
  };
  const notify = text =>
    toast.error(text, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isInAppBrowser =
      userAgent.includes('FBAN/') || // Facebook
      userAgent.includes('FBAV/') ||
      userAgent.includes('Instagram') || // Instagram
      userAgent.includes('TikTok'); // TikTok

    setIsFacebookBrowser(isInAppBrowser);
    if (!isInAppBrowser) {
      setIsEnabled(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchPopupSettings();
        const isPopupEnabled = data.some(popup => popup.popup_type === 'utm' && popup.enabled === true);
        // console.log('isPopupEnabled', isPopupEnabled);
        setIsEnabled(isPopupEnabled);
      } catch (error) {
        setIsEnabled(false);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   fetchPopupSettings();
  // }, []);

  useEffect(() => {
    // console.log('isEnabled', isEnabled);
    if (isEnabled) {
      const userData = getCookie('user_data');
      if (!userData) {
        // console.log('setShowWidget', true);
        setShowWidget(true);
        dispatch(showDonationPopup());
      }
    }
  }, [isEnabled]);

  useEffect(() => {
    const storedFingerprint = generateFingerprint();
    setFingerprint(storedFingerprint);
  }, []);


  useEffect(() => {
    if (!userIP) {
      fetch('https://matwproject.org/ip-api')
        .then(res => res.json())
        .then(response => {
          dispatch(setUserIP(response.query)); // Store IP in Redux
          if (response.countryCode) {
            setPhoneCountry(response.countryCode.toLowerCase()); // Convert to lowercase for PhoneInput
          }
        })
        .catch(error => {
          console.error('Request failed:', error);
        });
    }
  }, [userIP]);

  const onSubmit = data => {
    if (!isPhoneValid) {
      notify('Please enter a valid phone number.');
      return;
    }
    const utmData = getUTMData();
    const dataToTrack = {
      ...data,
      fingerprint: {
        ...fingerprint,
        userIP,

      },
      utmData,
    };
    setCookie('user_data', {...dataToTrack, countryCode}, 7);
    setShowWidget(false);

    fetch('https://matw.finbix.io/254fd2a6-5977-40be-8fad-95b5edfa0c98/api/campaign/utm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToTrack),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(responseText => {
        console.log('Tracking success:', responseText);
        reset(); // Reset the form after successful submission
      })
      .catch(error => {
        console.error('Tracking error:', error);
      });
  };

  const handleClose = () => {
    setShowWidget(false);
    setCookie('popupClosed', 'true', 1);
  };

  if (!showWidget) {
    return null;
  }

  return (
    <div
      className={`fixed top-0 left-0 bottom-0 right-0 bg-black/60 z-[210] flex justify-center items-center ${
        showWidget ? 'opacity-100 scale-100' : 'opacity-0 scale-0.9'
      } transition-all duration-500`}
      // onClick={handleClose}
      style={{
        pointerEvents: showWidget ? 'auto' : 'none',
        display: !isFacebookBrowser ? 'none' : '',
      }}
    >
      <div
        onClick={e => e.stopPropagation()}
        className="w-full max-w-xs bg-popUpBackground rounded-lg border-[0.5px] border-pink shadow-[0_0_10px_rgba(246,3,98,0.6)] px-6 py-8"
      >
        <p className="text-center text-white text-lg font-Helvetica mx-auto mb-6">Yes, I want to help Palestine.</p>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-4">
          <input
            type="text"
            name="name"
            autoComplete="name"
            {...register('name', { required: 'Name is required' })}
            placeholder="Your Name"
            className="min-h-[40px] bg-white md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300  py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
          />
          {errors.name && <span className="text-red-500 text-xs">{errors.name.message}</span>}

          <input
            type="email"
            name="email"
            autoComplete="email"
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Invalid email address',
              },
            })}
            placeholder="Your Email"
            className="min-h-[40px] bg-white md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300  py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
          />
          {errors.email && <span className="text-red-500 text-xs">{errors.email.message}</span>}

          <PhoneInput
            name="phone"
            autoComplete="tel"
            country={phoneCountry}
            placeholder="Your Phone"
            inputStyle={{ color: 'black', fontfamily: 'inherit' }}
            buttonStyle={{}}
            inputClass={`!w-full px-4 py-2 outline-none rounded-md bg-popUpEmailBackground placeholder:text-white placeholder:opacity-70 shadow-md text-white ${
              errors.phone ? 'border-red-500' : ''
            }`}
            containerClass="min-h-[40px] w-full"
            onChange={(value, country) => handlePhoneValidation(value, country, setValue)}
          />
          {errors.phone && <small className="text-red-500 text-sm">{errors.phone.message || 'Phone number is invalid'}</small>}

          <div className="max-w-[500px] flex items-center gap-x-2 mt-4 px-1">
            <input
              id="aggree-receive-newsletter"
              className="relative float-left mr-1 h-[1.125rem] w-[1.125rem] rounded-[0.25rem] bg-white hover:cursor-pointer"
              type="checkbox"
              {...register('aggree')}
            />
            <label
              htmlFor="aggree-receive-newsletter"
              className="hover:cursor-pointer text-white w-[calc(100%_-_1.125rem)] text-xs sm:text-sm flex items-start flex-col sm:flex-row font-sans"
            >
              <span>
                I agree to receive emails from MATW Project in line with the{' '}
                <Link to="/gdpr" target="_blank" className="underline">
                  privacy policy
                </Link>
                .
              </span>
            </label>
          </div>
          {errors.aggree && <span className="text-red-500 text-xs">{errors.aggree.message}</span>}

          <button type="submit" className="bg-popUpButtonBackground h-10 rounded-md text-white font-bold">
            Next
          </button>
        </form>
        <p className="text-pink text-sm text-center mt-4">100% Donation Policy</p>
      </div>
    </div>
  );
};

export default FloatingDonationBox;
