import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import ProductBox from '../../components/general/productBox';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectProducts } from '../../store/products';
import banner from '../../images/landings/afghanistan/afghanistan-hero-banner-desktop.jpg';
import bannerMobile from '../../images/landings/afghanistan/afghanistan-hero-banner-mobile.jpg';
import afghanistanFamilyImg from '../../images/landings/afghanistan/afghanistan-family.jpg';
import CrisisWidget from './components/crisisWidget';
import { OurPromiseSection } from '../../components/general';
import useHandleAddProduct from '../../utils/handleAddProduct';
import CustomButton from '../../components/general/button';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const AfghanistanLandingPageUAT = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [afghanistanProduct, setAfghanistanProduct] = useState([0]);
    const [isLoaded, setIsLoaded] = useState(false);
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const products = useSelector(state => selectProducts(state));
    const [addProduct] = useHandleAddProduct();
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        let tempProduct = [];
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === 'EMR-AFG-FND-2024-01') tempProduct.push(inner_item_product); // Afghanistan Flood Appeal
                    return inner_item_product;
                });
                return inner_item;
            });
            return item;
        });
        console.log("produc=", tempProduct)
        setAfghanistanProduct(tempProduct);
        setIsLoaded(true);
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */

    return (
        <NewHelmetLayout className='!bg-white'>
            {seo &&
                <Helmet>
                    <title>{`${seo['crisisAndEmergencies']['afghanistan']['title']}`}</title>
                    <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
                    <meta name="description" content={`${seo['crisisAndEmergencies']['afghanistan']['description']}`} />

          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
            }
            <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
                <CrisisWidget
                    ramadanMobileWidget
                    className={'bg-[#DFEEF2] h-auto'}
                    fixPrice={false}
                    defaultProduct={{
                        creator: 'EMR-AFG-FND-2024-01',
                        label: "Afghanistan Flood Appeal",
                        value: 553
                    }}
                />
            </section>
            <section className="flex justify-center">
                <img src={banner} alt="Afghanistan Floods: Donate to afghanistan" className="max-[500px]:hidden w-full" />
                <img src={bannerMobile} alt="Afghanistan Flooding: Donations to Afghanistan Charity" className="min-[501px]:hidden w-full" />
            </section>
            {/* /* --------------------------------- Widget --------------------------------- */}
            <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
                <div className="max-w-[1160px] px-3 mx-auto">
                    <CrisisWidget
                        floating
                        className={'bg-[#DFEEF2] h-auto pt-10'}
                        fixPrice={false}
                        defaultProduct={{
                            creator: 'EMR-AFG-FND-2024-01',
                            label: "Afghanistan Flood Appeal",
                            value: 553
                        }}
                    />
                </div>
            </section>
            {/* /* -------------------------------------------------------------------------- */}

            <section className="bg-[#253B7E]">
                <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
                    <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
                        <div className="basis-2/3 sm:basis-1/3 md:basis-1/3 xl:basis-2/5 p-2">
                            <ProductBox
                                // stateVariableObj={{ productName: afghanistanProduct[0].name ? afghanistanProduct[0].name : '' }}
                                product={afghanistanProduct[0]}
                                currency={selectedCurrency ? selectedCurrency : 'AUD'}
                                titleClasses={`uppercase`}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <OurPromiseSection />
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* -------------------------------------------------------------------------- */}
            <div className="md:container md:mx-auto px-4 py-8 md:py-12">
                <h1 className="text-2xl md:text-[40px] text-[#253b7e] font-brandingBold mb-5 md:mb-8 md:text-center md:leading-[50px]">
                    Thousands have been displaced and
                    <span className="mx-1 text-[#f60362]">more than 300 lives</span>
                    have been claimed by the flash floods that have swept through the Northern province of Baghlan and Takhar in Afghanistan.
                </h1>
                <div className="text-[#78716C] md:text-[18px] font-BrandingMedium space-y-5">
                    <p>More than 1,000 homes have been destroyed as people begin to search for loved ones amidst the devastation.</p>
                    <p>As Afghanistan grapples with the aftermath of these devastating floods,
                        urgent emergency relief is imperative to help save lives of families including
                        the most vulnerable - the elderly, women and children.</p>
                </div>
            </div>
            {/* /* ------------------- Section Two - Most Needed Products ------------------- */}
            <section className='px-4 xl:px-0 md:container md:mx-auto'>
                <div className='flex gap-x-10 flex-wrap sm:flex-nowrap mt-8'>
                    <div className="basis-full sm:basis-1/2"><img src={afghanistanFamilyImg} alt="afghanistanFamily" /></div>
                    <div className="basis-full sm:basis-1/2 flex justify-between items-start flex-col">
                        <h1 className='text-[#253B7E] text-[25px] sm:text-[30px] sm:leading-[40px] font-brandingBold'>
                            MATW has been working in Afghanistan responding to emergencies and delivering seasonal aid to
                            <span className="mx-1 text-[#f60362]">support vulnerable families</span>
                            living in poverty.
                        </h1>
                        <div className='text-[16px] sm:text-[18px] text-[#78716C] leading[19px] sm:leading-6 font-brandingMedium my-4'>
                            Our ground teams have been quick to respond to recent emergencies, including the flash
                            flooding, with emergency relief items such as urgent food, clean water and more.
                            <br />
                            <br />
                            In the wake of the recent floods, urgent assistance is required to attend to the needs of
                            those affected. Many are without shelter and essential supplies.
                            <br />
                            <br />
                            Help us save lives with your Sadaqah by donating today.
                        </div>
                        <CustomButton
                            onClick={() => { afghanistanProduct[0].id && addProduct({product: afghanistanProduct[0], currency: selectedCurrencyStore}) }}
                            title={'Donate Now'}
                            disabled={!isLoaded}
                            // className={`w-full !h-[38px] !px-2`}
                        />
                    </div>
                </div>
            </section>
            {/* /* -------------------------------------------------------------------------- */}
        </NewHelmetLayout>
    );
}

export default AfghanistanLandingPageUAT;
