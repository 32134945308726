import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useKeenSlider } from 'keen-slider/react';
import AppealFundraiserProductBox from './appealFundraiserProductBox';
import { selectSelectedCurrency } from '../../../store/user';
import ProductBox from '../../../components/general/productBox';
import AppealFundraiserProductBoxV3 from '../cartComponents/appealFundraiserProductBoxV3';
import Arrow from '../../home/components/tab-arrow';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
// import { ProductBoxCart } from '../../home/components/productBoxCart';
// import useCart from '../../../hooks/cart/useCart';
// import AppealFundraiserProductBoxCartV3 from '../cartComponents/appealFundraiserProductBoxCartV3';
// import Arrow from './tab-arrow';

const AppealWidgetNewWithCarousel = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { className, usedOnTab, title = 'MATW Appeals', isHomePage = false, allProducts } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [pause, setPause] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: 'snap',
    loop: true,
    breakpoints: {
      '(min-width: 320px)': {
        slides: { perView: 1, spacing: 10 },
      },
      '(min-width: 768px)': {
        slides: { perView: 2, spacing: 10 },
      },
      '(min-width: 992px)': {
        slides: { perView: 4, spacing: 16 },
      },
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: 'auto',
      // perView: 1,
      // spacing: 8,
    },
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  // Autoplay effect
  useEffect(() => {
    if (!instanceRef.current) return;

    const autoplay = setInterval(() => {
      if (!pause) {
        instanceRef.current.next();
      }
    }, 8000); // Change slide every 6 seconds

    return () => clearInterval(autoplay);
  }, [pause, instanceRef, currentSlide]); // Added currentSlide to reset timer after swipe

  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  /* -------------------------------------------------------------------------- */
  return (
      <section className={`${className ? className : 'bg-LighterBlue'} w-full ${usedOnTab ? 'py-2 md:py-4' : 'py-4 md:py-12'}`}>
        <div className="md:container w-full mx-auto">
          {!isHomePage && (
              <div className="w-full justify-center items-center flex pb-4 md:pb-8">
                <h2 className={`text-DarkerBlue font-brandingBold ${usedOnTab ? 'text-lg md:text-3xl' : 'text-2xl md:text-4xl'}`}>
                  {title}
                </h2>
              </div>
          )}
          <div className="w-full flex-wrap justify-center items-center relative">
            <div className={`${allProducts.length > 3 ? 'flex' : 'flex md:hidden'}`}>
              {instanceRef.current && (
                  <div
                      className={`absolute flex justify-center  items-center translate-y-1/2 w-8 h-8 top-[47%] -left-[0px] md:-left-[16px] xl:-left-[32px] z-10 cursor-pointer text-stone-600 bg-white bg-opacity-70 rounded-full`}
                      onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
                  >
                    <LiaAngleLeftSolid size={25} />
                  </div>
              )}
              {instanceRef.current && (
                  <div
                      className={`absolute flex justify-center items-center translate-y-1/2 w-8 h-8 top-[47%] -right-[0px] md:-right-[16px] xl:-right-[32px] z-10 cursor-pointer text-stone-600 bg-white bg-opacity-70 rounded-full`}
                      onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                  >
                    <LiaAngleRightSolid size={25} />
                  </div>
              )}
            </div>
            {/* {console.log("all products===",allProducts)} */}
            {allProducts.length > 3
                ? <div
                    ref={sliderRef}
                    className="keen-slider"
                    onMouseEnter={() => setPause(true)}
                    onMouseLeave={() => setPause(false)}
                >
                  {allProducts.map((item, index) => {
                    return (
                        <div
                            key={`active_carousel_${index}`}
                            className={`keen-slider__slide basis-full flex justify-center w-full sm:max-w-[300px] sm:basis-[calc(50%-8px)]`}
                        >
                          {isHomePage ? (
                              !item.campaign ? (
                                  <>
                                    <div className={`w-full h-fit`}>
                                      <ProductBox
                                          product={item}
                                          currency={selectedCurrency ? selectedCurrency : 'AUD'}
                                          btnContainerClass={'h-[106px]'}
                                          titleClasses={`uppercase px-1 !text-lg !h-auto !mt-3 min-h-[56px]`}
                                          childClasses="!rounded-none !p-0 !pb-3 md:!pb-5 md:!p-0"
                                          imageContainerClass="!rounded-none"
                                          imageClass="!rounded-none"
                                          descriptionStyle="px-2 md:px-3"
                                          skeletonStyle="px-2"
                                          squareButton
                                          isStaticProduct={item.isStaticProduct ? true : false}
                                          staticLink={item.staticLink}
                                          staticLinkText={item.staticLinkText}
                                          addToCart={true}
                                      />
                                    </div>
                                  </>
                              ) : (
                                  <div className="">
                                    <div className="">
                                      <AppealFundraiserProductBoxV3
                                          campaign={item.campaign || 'palestine'}
                                          goal={item.goal}
                                          totalRaised={item.totalRaised}
                                          subTitle="Orphan Education"
                                          product={item}
                                          isHomePage={isHomePage}
                                          currency={selectedCurrency ? selectedCurrency : 'AUD'}
                                      />
                                    </div>
                                  </div>
                              )
                          ) : (
                              <AppealFundraiserProductBox
                                  campaign={item.campaign || 'palestine'}
                                  goal={item.goal}
                                  totalRaised={item.totalRaised}
                                  subTitle="Orphan Education"
                                  product={item}
                                  isHomePage={isHomePage}
                                  currency={selectedCurrency ? selectedCurrency : 'AUD'}
                              />
                          )}
                        </div>
                    );
                  })}
                </div>
                : <>
                  <div className="hidden md:flex flex-wrap gap-3 sm:gap-4 justify-center items-start w-full">
                    {allProducts.map((item, index) => {
                      return (
                          <div
                              key={`active_carousel_${index}`}
                              className={`w-full sm:max-w-[300px] sm:basis-[calc(50%-8px)]`}
                          >
                            {isHomePage ? (
                                !item.campaign ? (
                                    <>
                                      <div className={`w-full h-fit`}>
                                        <ProductBox
                                            product={item}
                                            currency={selectedCurrency ? selectedCurrency : 'AUD'}
                                            btnContainerClass={'h-[106px]'}
                                            titleClasses={`uppercase px-1 !text-lg !h-auto !mt-3 min-h-[56px]`}
                                            childClasses="!rounded-none !p-0 !pb-3 md:!pb-5 md:!p-0"
                                            imageContainerClass="!rounded-none"
                                            imageClass="!rounded-none"
                                            descriptionStyle="px-2 md:px-3"
                                            skeletonStyle="px-2"
                                            squareButton
                                            isStaticProduct={item.isStaticProduct ? true : false}
                                            staticLink={item.staticLink}
                                            staticLinkText={item.staticLinkText}
                                            addToCart={true}
                                        />
                                      </div>
                                    </>
                                ) : (
                                    <div className="">
                                      <div className="">
                                        <AppealFundraiserProductBoxV3
                                            campaign={item.campaign || 'palestine'}
                                            goal={item.goal}
                                            totalRaised={item.totalRaised}
                                            subTitle="Orphan Education"
                                            product={item}
                                            isHomePage={isHomePage}
                                            currency={selectedCurrency ? selectedCurrency : 'AUD'}
                                        />
                                      </div>
                                    </div>
                                )
                            ) : (
                                <AppealFundraiserProductBox
                                    campaign={item.campaign || 'palestine'}
                                    goal={item.goal}
                                    totalRaised={item.totalRaised}
                                    subTitle="Orphan Education"
                                    product={item}
                                    isHomePage={isHomePage}
                                    currency={selectedCurrency ? selectedCurrency : 'AUD'}
                                />
                            )}
                          </div>
                      );
                    })}
                  </div>
                  <div
                      ref={sliderRef}
                      className="keen-slider md:!hidden"
                      onMouseEnter={() => setPause(true)}
                      onMouseLeave={() => setPause(false)}
                  >
                    {allProducts.map((item, index) => {
                      return (
                          <div
                              key={`active_carousel_${index}`}
                              className={`keen-slider__slide basis-full flex justify-center w-full sm:max-w-[300px] sm:basis-[calc(50%-8px)]`}
                          >
                            {isHomePage ? (
                                !item.campaign ? (
                                    <>
                                      <div className={`w-full h-fit`}>
                                        <ProductBox
                                            product={item}
                                            currency={selectedCurrency ? selectedCurrency : 'AUD'}
                                            btnContainerClass={'h-[106px]'}
                                            titleClasses={`uppercase px-1 !text-lg !h-auto !mt-3 min-h-[56px]`}
                                            childClasses="!rounded-none !p-0 !pb-3 md:!pb-5 md:!p-0"
                                            imageContainerClass="!rounded-none"
                                            imageClass="!rounded-none"
                                            descriptionStyle="px-2 md:px-3"
                                            skeletonStyle="px-2"
                                            squareButton
                                            isStaticProduct={item.isStaticProduct ? true : false}
                                            staticLink={item.staticLink}
                                            staticLinkText={item.staticLinkText}
                                            addToCart={true}
                                        />
                                      </div>
                                    </>
                                ) : (
                                    <div className="">
                                      <div className="">
                                        <AppealFundraiserProductBoxV3
                                            campaign={item.campaign || 'palestine'}
                                            goal={item.goal}
                                            totalRaised={item.totalRaised}
                                            subTitle="Orphan Education"
                                            product={item}
                                            isHomePage={isHomePage}
                                            currency={selectedCurrency ? selectedCurrency : 'AUD'}
                                        />
                                      </div>
                                    </div>
                                )
                            ) : (
                                <AppealFundraiserProductBox
                                    campaign={item.campaign || 'palestine'}
                                    goal={item.goal}
                                    totalRaised={item.totalRaised}
                                    subTitle="Orphan Education"
                                    product={item}
                                    isHomePage={isHomePage}
                                    currency={selectedCurrency ? selectedCurrency : 'AUD'}
                                />
                            )}
                          </div>
                      );
                    })}
                  </div>
                </>
            }
          </div>
        </div>
      </section>
  );
};
export default AppealWidgetNewWithCarousel;
