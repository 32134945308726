import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import banner from '../../images/ramadan/Last-10-Nights-Gaza-Banner.jpg';
import bannerMobile from '../../images/ramadan/Last-10-Nights-Gaza-Banner.jpg';
import RamadanDailyDonationWidget from '../../components/ramadan/ramadanDailyDonation';
import Accordion from '../../components/general/accordion';
import { lastTenNightsDailyGivingFaqs } from './faq';
import last10Img from '../../images/ramadan/last-10-nights-text.jpg';

const LastTenNightsDailyGiving = () => {
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const products = ['OPR-GEN-SUP-2023-01-01', 'MD-AA-2024-02', 'MKD-MN-001', 'MD-AA-2024-07', 'MKD-GEN-MZK-2023-01-123'];
  // useEffect(()=>{
  //     localStorage.setItem('IS_DAILY_DONATION', JSON.stringify(true));
  // },[])
  /* ------------------------------ Start methods ----------------------------- */
  return (
    <NewHelmetLayout hideFooter className="bg-white " childClasses={'pb-0'}>
      {seo && (
        <Helmet>
          <title>{`${seo['ramadanDaily']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={`${seo['ramadanDaily']['description']}`} />
        </Helmet>
      )}
      <section className="flex justify-center">
        <img src={banner} alt="ramadan 2025" className="max-[500px]:hidden w-full" />
        <img src={bannerMobile} alt="ramadan 2025" className="min-[501px]:hidden w-full" />
      </section>
      <section className="w-full md:container md:mx-auto py-4 md:py-8 gap-4">
        <div className="w-full">
          <div className="flex justify-center">
            <div className="font-brandingBold sm:text-center font-bold text-[#093686] text-2xl sm:text-3xl lg:text-5xl px-4 w-full sm:w-10/12">
              10 Nights To Save Gaza
              <br />
              {/* <span className="text-[#F60362] mr-2">Laylatul Qadr again!</span> */}
            </div>
          </div>
          <div className="px-4 md:px-6 mt-2 sm:mt-10 leading-5">
            <div className="text-[#78716C] text-lg text-left font-brandingMedium">
              <p>
                As we enter the final ten nights of Ramadan, we are reminded of the immense power of each moment. Just as our beloved brother, Ali
                Banat, used every final moment as if it were his last to do good for the sake of Allah, we too must recognize the preciousness of
                these nights. Ali’s unwavering dedication to serving others, even in the face of his illness, serves as an inspiration for us all. He
                understood that our time in this world is limited, and every act of charity, no matter how small, carries great reward when done for
                the sake of Allah.
                <br />
                <br />
                Now, as our brothers and sisters in Gaza face indescribable hardship, we must act. Their homes, schools, and mosques have been reduced
                to rubble, and their lives shattered by violence. As the violence escalates, they need us now more than ever. In these last ten nights
                of Ramadan, we have an incredible opportunity to make a difference.
                <br />
                <br />
                Gaza is calling out for help. Over 90% of its homes have been damaged or destroyed. More than 64,000 lives have been lost - more than
                900 people since the ceasefire ended. And yet, their faith remains unshaken, their hearts filled with the remembrance of Allah. Let us
                be the answer to their duas, the vessel of Allah’s Mercy.
                <br />
                <br />
                As Ali Banat once said, “You never know when your time will be up, so make every moment count.” In the final ten nights of Ramadan, 
                let us make every moment count for Gaza.<b>  By automating your charity</b>, you can ensure that your support reaches those in need throughout
                these blessed nights, including the 27th night, which is widely believed to be Laylatul Qadr—a night that carries the reward of a
                lifetime.
                <br />
                <br />
                In these final nights, give for Gaza. Seek Allah’s mercy, seek His blessings, and seek the rewards that come with helping those in
                need. Let us show the people of Gaza that they are not forgotten, that they have not been forsaken, and that their suffering is not
                ignored.
                <br />
                <br />
                These ten nights are a gift. Let us use them to help save lives in Gaza. Give like it’s your last ten nights.
                <br /> <br />
                Donate Now
                <br />
                <br />
                {/* <ul>
                            <li>Select your giving start date.</li>
                            <li>Select your cause</li>
                            <li>Make your donation.</li>
                        </ul> */}
                <img src={last10Img} alt="ramadan 2025" className="w-full max-w-[500px] my-1 md:my-3" />
                <br />
                You can also choose to give more on the 27th night or on Jummah - or both!
                <br />
                <br />
                So don’t wait. If Ali Banat’s story has taught us anything, it’s that the next moment isn’t promised and neither is Ramadan. We’ve
                made it to the most blessed nights of the entire month - let’s make them count! Automate your donations now.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="w-full px-2 md:w-10/12 md:mx-auto max-[380px]:min-h-[209px] min-[381px]:min-h-[300px]- min-[410px]:min-h-[300px] sm:h-[300px] md:h-[600px] lg:h-[1000px]- py-4 md:py-8">
                    <div className="font-brandingBold sm:text-center font-bold text-[#093686] text-xl sm:text-4xl px-4 w-full mb-4">
                        How it works?
                    </div>
                    <iframe
                        className="video pointer-events-none rounded-md"
                        width={'100%'}
                        height={'100%'}
                        title={'youtube'}
                        frameBorder={0}
                        sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                        src={`https://www.youtube.com/embed/ciUzaL90cxA?controls=0&autoplay=1&loop=1&mute=1&playlist=ciUzaL90cxA`}
                    ></iframe>
                </div> */}
        <div className="w-full mt-4 md:mt-8">
          <RamadanDailyDonationWidget productsList={products} isLastTenNightsGiving />
        </div>
        <div className="">
          <h1 className="text-[#253B7E] text-center my-4 text-[30px] sm:text-[60px] font-bold">
            Ramadan
            <span className="text-[#F60362]"> FAQ’s</span>
          </h1>
          <div className="grid grid-cols-3 gap-x-16 gap-y-8 px-6 md:px-0">
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{lastTenNightsDailyGivingFaqs[0].question}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3">{lastTenNightsDailyGivingFaqs[0].answer}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{lastTenNightsDailyGivingFaqs[1].question}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3">{lastTenNightsDailyGivingFaqs[1].answer}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{lastTenNightsDailyGivingFaqs[2].question}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3">{lastTenNightsDailyGivingFaqs[2].answer}</p>
            </div>
          </div>
          <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
            {lastTenNightsDailyGivingFaqs.map((item, index) => {
              return (
                index > 2 && (
                  <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                    <Accordion
                      key={`keyallfaq${index}`}
                      shadow
                      title={item.question}
                      index={index}
                      level={1}
                      noBorder={true}
                      className="!text-[#253B7E] text-lg font-brandingBold"
                    >
                      {item.answer}
                    </Accordion>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </section>
    </NewHelmetLayout>
  );
};

export default LastTenNightsDailyGiving;
