import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectProducts } from '../../store/products';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { useRef } from 'react';
import SelectCurrency from '../../components/general/selectCurrency';
import ShowCurrencies from '../../utils/showCurrencies';
import { CustomButton, OurPromiseSection } from '../../components/general';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
// import banner from '../../images/landings/zakat-calculator/zakat-calculator-hero-banner.jpg';
// import bannerMobile from '../../images/landings/zakat-calculator/zakat-calculator-hero-banner-mobile.jpg';

const ZakatCalculatorUATLandingPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const refAssets = useRef(null);
    const refLiabilities = useRef(null);
    const [assets, setAssets] = useState(0);
    const [liabilities, setLiabilities] = useState(0);
    const [zakat, setZakat] = useState(0);
    const [selectedNisab, setSelectedNisab] = useState('Silver');
    const [goldNisabAmount, setGoldNisabAmount] = useState(0);
    const [silverNisabAmount, setSilverNisabAmount] = useState(0);
    const products = useSelector((state) => selectProducts(state));
    const [addProduct] = useHandleAddProduct();
    const [zakatAlMalProduct, setZakatAlMalProduct] = useState();
    const goldNisabGrams = 87.48;
    const silverNisabGrams = 612.36;
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleSelectNisab = (value) => {
        console.log("radio", value)
        setSelectedNisab(value);
        handleCalculateZakat(assets, liabilities, value)
    }
    const handleCurrency = (currency) => {
        setSelectedCurrency(currency.value);
        // selectedNisab === 'Gold' ? handleGoldNisabAmount(currency.value) : handleSilverNisabAmount(currency.value)
        handleGoldNisabAmount(currency.value);
        handleSilverNisabAmount(currency.value);
    }
    const handleAssets = (value) => {
        refAssets.current.value = '';
        if (parseInt(value) >= 0) {
            setAssets(parseInt(value));
            handleCalculateZakat(parseInt(value), liabilities)
        } else {
            setAssets(0);
            setZakat(0)
        }
    }
    const handleLiabilities = (value) => {
        refLiabilities.current.value = '';
        if (parseInt(value) >= 0) {
            setLiabilities(parseInt(value));
            handleCalculateZakat(assets, parseInt(value))
        } else {
            handleCalculateZakat(assets, 0)
            setLiabilities(0);
        }
    }
    const handleCalculateZakat = (assets, liabilities, nisab = selectedNisab) => {
        let zakatableWealth = (parseInt(assets) - parseInt(liabilities));
        console.log("handle-CalculateZakat", parseInt(assets), parseInt(liabilities), silverNisabAmount,goldNisabAmount)
        if (nisab === 'Gold') {
            // zakatableWealth = (parseInt(assets) - parseInt(liabilities));
            // zakatableWealth > goldNisabAmount ? setZakat(Math.round((zakatableWealth - parseInt(goldNisabAmount)) * 0.025)) : setZakat(0)
            zakatableWealth > goldNisabAmount ? setZakat(Math.round(zakatableWealth * 0.025)) : setZakat(0)
        } else {
            // zakatableWealth > silverNisabAmount ? setZakat(Math.round((zakatableWealth - parseInt(silverNisabAmount)) * 0.025)) : setZakat(0)
            zakatableWealth > silverNisabAmount ? setZakat(Math.round(zakatableWealth * 0.025)) : setZakat(0)
        }
    }
    const handleKeyDown = (value) => {
        let checkIfNum;
        if (value.key !== undefined) {
            checkIfNum = value.key === ".";
        }
        return checkIfNum && value.preventDefault();
    }
    const handleGoldNisabAmount = (currency) => {
        switch (currency) {
            case 'USD':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_USD) * Number(goldNisabGrams))
                break;
            case 'AUD':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_AUD) * Number(goldNisabGrams))
                break;
            case 'CAD':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_CAD) * Number(goldNisabGrams))
                break;
            case 'GBP':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_GBP) * Number(goldNisabGrams))
                break;
            case 'EUR':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_EUR) * Number(goldNisabGrams))
                break;
            case 'SGD':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_SGD) * Number(goldNisabGrams))
                break;
            case 'MYR':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_MYR) * Number(goldNisabGrams))
                break;
            case 'AED':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_AED) * Number(goldNisabGrams))
                break;
            case 'IDR':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_IDR) * Number(goldNisabGrams))
                break;
            default:
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_USD) * Number(goldNisabGrams))
                break;
        }
    }
    const handleSilverNisabAmount = (currency) => {
        console.log("handle-SilverNisabAmount=",currency)
        switch (currency) {
            case 'USD':
                console.log("silver nissab usd=", Number(process.env.REACT_APP_SILVER_NISAB_USD), Number(silverNisabGrams), Number(process.env.REACT_APP_SILVER_NISAB_USD) * Number(silverNisabGrams));
                handleCalculateZakat(assets, liabilities)
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_USD) * Number(silverNisabGrams))
                break;
            case 'AUD':
                console.log("silver nissab aud=", Number(process.env.REACT_APP_SILVER_NISAB_AUD), Number(silverNisabGrams), Number(process.env.REACT_APP_SILVER_NISAB_AUD) * Number(silverNisabGrams))
                handleCalculateZakat(assets, liabilities)
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_AUD) * Number(silverNisabGrams))
                break;
            case 'CAD':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_CAD) * Number(silverNisabGrams))
                break;
            case 'GBP':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_GBP) * Number(silverNisabGrams))
                break;
            case 'EUR':
                console.log("silver nissab eur=", Number(process.env.REACT_APP_SILVER_NISAB_EUR), Number(silverNisabGrams), Number(process.env.REACT_APP_SILVER_NISAB_EUR) * Number(silverNisabGrams))
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_EUR) * Number(silverNisabGrams))
                break;
            case 'SGD':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_SGD) * Number(silverNisabGrams))
                break;
            case 'MYR':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_MYR) * Number(silverNisabGrams))
                break;
            case 'AED':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_AED) * Number(silverNisabGrams))
                break;
            case 'IDR':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_IDR) * Number(silverNisabGrams))
                break;
            default:
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_USD) * Number(silverNisabGrams))
                break;
        }
    }
    const getAllProducts = () => {
        setIsLoaded(true);
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === 'RM24-ZM-013') setZakatAlMalProduct(inner_item_product); // Zakat Al Mal
                    return inner_item_product;
                });
                return inner_item;
            })
            return item;
        });
    };
    const handleAddNewProduct = (creator, currency, zakat) => {
        let tempProduct = zakatAlMalProduct;
        let customAmount = Number(zakat) ? Number(zakat) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
        })
        addProduct({product: tempProduct, currency: currency})
    }
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        // window.scroll({top: 0, left: 0, behavior: 'smooth' });
        // window.scroll(0, 0);
        handleGoldNisabAmount(selectedCurrency)
        handleSilverNisabAmount(selectedCurrency)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        products.length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    useEffect(() => {
        handleCalculateZakat(assets, liabilities)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goldNisabAmount, silverNisabAmount])
    useEffect(() => {
        console.log("=========================temp in zakat calculator", selectedCurrencyStore)
        // selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
        if(selectedCurrencyStore) {
            handleGoldNisabAmount(selectedCurrencyStore);
            handleSilverNisabAmount(selectedCurrencyStore);
            setSelectedCurrency(selectedCurrencyStore);
        } else {
            handleGoldNisabAmount('AUD');
            handleSilverNisabAmount('AUD');
            setSelectedCurrency('AUD')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <NewHelmetLayout className='bg-white' hideFooter>
            {seo &&
                <Helmet>
                    <title>{`${seo['zakat']['zakatCalculator']['title']}`}</title>
                    <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
                    <meta name="description" content={`${seo['zakat']['zakatCalculator']['description']}`} />
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "HowTo",
                            "name": "How to Calculate Zakat",
                            "description": "This guide outlines the steps to calculate Zakat, ensuring you fulfill your Islamic financial obligations.",
                            "step": [
                                {
                                    "@type": "HowToStep",
                                    "name": "Determine Nisab",
                                    "text": "Calculate the current value of Nisab in your local currency to determine if you are eligible to pay Zakat."
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Calculate Assets",
                                    "text": "Total your assets that are subject to Zakat, including cash, gold, silver, and business commodities."
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Deduct Liabilities",
                                    "text": "Subtract any debts or liabilities from your total assets to find your net assets."
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Apply Zakat Rate",
                                    "text": "If your net assets exceed the Nisab, apply the Zakat rate of 2.5% to calculate the amount of Zakat due."
                                }
                            ],
                            "estimatedCost": {
                                "@type": "MonetaryAmount",
                                "value": "0",
                                "description": "Free"
                            },
                            "tool": {
                                "@type": "HowToTool",
                                "name": "Zakat Calculator 2024",
                                "url": "https://matwproject.org/zakat-calculator"
                            }
                        })}
                    </script>

          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
            }

            {/* /* --------------------------------- Calculator ---------------------------------  */}
            <div className="w-full py-6 md:py-12 px-4 md:px-0 bg-[url('../src/images/ali-banat/pattern.png')]">
                <div className="w-full grid grid-cols-6 gap-4 md:gap-8 justify-center items-center md:container md:mx-auto bg-white rounded-md p-4 md:p-8 lg:p-16 xl:p-24">
                    <h1 className="col-span-6 md:col-span-4 md:col-start-2 text-[#253B7E] font-medium text-xl md:text-[25px] text-center mb-4 md:mb-6">
                        Zakat calculator 2024: Ensure your contribution matches the nisab with our zakat al mal calculator.
                    </h1>
                    <div className="col-span-6 flex justify-center mb-4 md:mb-8">
                        <div className="w-full md:w-[300px]">
                            <SelectCurrency onChangeCurrency={(e) => handleCurrency(e)} />
                        </div>
                    </div>
                    <div className="col-span-6 min-[400px]:col-span-3 py-2 rounded-md">
                        <div className={`w-full hover:shadow-[0px_0px_15px_rgb(240,240,240)] border rounded-md p-2 sm:p-4 flex items-end sm:items-start justify-between transition-all duration-200 cursor-pointer ${selectedNisab === 'Silver' ? 'border-[#00a3da] bg-[#00a3da]' : 'border-stone-200 bg-white'}`}
                            onClick={() => handleSelectNisab('Silver')}>
                            <div className="flex flex-col w-full">
                                <div className="flex flex-col items-center justify-center">
                                    <p className={`mb-0 text-lg sm:text-2xl mt-3 transition-all duration-100 ${selectedNisab === 'Silver' ? 'text-white font-medium' : 'text-[#00a3da] font-normal'}`}>Silver NISAB</p>
                                    <p className={`${selectedNisab === 'Silver' ? 'text-white' : 'text-[#78716C]'} font-light transition-all duration-200 text-sm sm:text-base`}>
                                        Silver ({silverNisabGrams} grams) ≅ {ShowCurrencies(selectedCurrency, silverNisabAmount.toFixed(2))}
                                    </p>
                                </div>
                            </div>
                            <div className=''>
                                <svg className={`transition-all duration-200 text-[#00a3da] ${selectedNisab === 'Silver' ? 'scale-75 sm:scale-100' : 'scale-0'}`} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill='#fff' width='24' height='24'>
                                    <circle cx="24" cy="24" r="24" />
                                    <polyline points="13.2 25.02 19.83 31.65 35.75 15.73" strokeWidth='5' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 min-[400px]:col-span-3 py-2 rounded-md">
                        <div className={` w-full hover:shadow-[0px_0px_15px_rgb(240,240,240)] border rounded-md p-2 sm:p-4 flex items-end sm:items-start justify-between transition-all duration-200 cursor-pointer ${selectedNisab === 'Gold' ? 'border-[#00a3da] bg-[#00a3da]' : 'border-stone-200 bg-white'}`}
                            onClick={() => handleSelectNisab('Gold')}>
                            <div className="flex flex-col w-full">
                                <div className="flex flex-col items-center justify-center">
                                    <p className={`mb-0 text-lg sm:text-2xl mt-3 transition-all duration-100 ${selectedNisab === 'Gold' ? 'text-white font-medium' : 'text-[#00a3da] font-normal'}`}>Gold NISAB</p>
                                    <p className={`${selectedNisab === 'Gold' ? 'text-white' : 'text-[#78716C]'} text-center text-sm sm:text-base font-light transition-all duration-200`}>
                                        Gold (87.48 grams) ≅ {ShowCurrencies(selectedCurrency, goldNisabAmount.toFixed(2))}
                                        <br />
                                        <span className="text-sm">(The Nisaab is applicable according to 21 Karat)</span>
                                    </p>
                                </div>
                            </div>
                            <div className=''>
                                <svg className={`transition-all duration-200 text-[#00a3da] ${selectedNisab === 'Gold' ? 'scale-75 sm:scale-100' : 'scale-0'}`} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill='#fff' width='24' height='24'>
                                    <circle cx="24" cy="24" r="24" />
                                    <polyline points="13.2 25.02 19.83 31.65 35.75 15.73" strokeWidth='5' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3 flex flex-wrap mt-4">
                        <div className="basis-full flex flex-col mb-4 md:mb-6">
                            <label className='text-xl md:text-[25px] font-medium text-[#253B7E] w-full'>Assets</label>
                            <small className='w-full text-[#78716C] text-lg md:text-[18px] mb-1'>Include gold, silver, cash, savings, business assets etc.</small>
                            <input
                                ref={refAssets}
                                type="number"
                                value={assets}
                                className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                                onChange={(e) => handleAssets(e.target.value)}
                                onKeyDown={(e) => handleKeyDown(e)}
                            />
                        </div>
                        <div className="basis-full flex flex-col">
                            <label className='text-xl md:text-[25px] font-medium text-[#253B7E] w-full'>Liabilities</label>
                            <small className='w-full text-[#78716C] text-lg md:text-[18px] mb-1'>Include money you owe, other outgoings due.</small>
                            <div className='min-h-[40px] md:min-h-[48px] w-full rounded border border-stone-300 py-[0.32rem] px-3 flex'>
                                <input
                                    ref={refLiabilities}
                                    type="number"
                                    value={liabilities}
                                    className='text-base md:text-md ml-[10px] bg-transparent outline-none transition-all duration-200 ease-linear w-full'
                                    onChange={(e) => handleLiabilities(e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3 flex flex-wrap mt-4 h-full">
                        <div className="basis-full flex flex-col justify-between items-center rounded-md bg-stone-100 p-6">
                            <div className="flex flex-col items-center pt-4">
                                <h2 className="text-[#00a3da] font-medium text-2xl md:text-[30px]">
                                    {ShowCurrencies(selectedCurrency, zakat)}
                                </h2>
                                {/* <small className='text-[#78716C] text-lg md:text-2xl'>{selectedNisab} Nisab</small> */}
                                <small className='text-[#78716C] text-lg md:text-[25px]'>Zakat Owed</small>
                            </div>
                            <CustomButton disabled={!(zakat > 0)} title='Pay Zakat Now' onClicked={() => handleAddNewProduct('RM24-ZM-013', selectedCurrency, zakat)} className='mt-4 md:mt-8 uppercase' />
                        </div>
                    </div>
                </div>
            </div>
            <OurPromiseSection alt="Zakat Calculator 2024: Gold zakat calculator"/>
            <div className="w-full bg-white p-4">
                <div className='w-full md:container md:mx-auto bg-white'>
                    <section className='xl:px-10 mt-8 md:mt-16'>
                        <h2 className="w-full text-center text-[25px] md:text-[40px] text-[#253b7e] font-medium mb-4 md:mb-8">
                            Calculate zakat for <span className="text-[#F60362]">2024</span> with our easy to use zakat calculator
                        </h2>
                        <h2 className=" text-[#14A2DC] text-xl md:text-[25px] text-center mb-3 md:mb-6 font-medium">
                            How to calculate Zakat: Use our Zakat Calculator to see how much you owe this year.
                        </h2>
                        <p className=" text-[#78716C]">
                            To use our Zakat calculator, enter all the assets that have been in your possession over the course of a lunar year into the Zakat
                            calculator. This will then give you the total amount of the Zakat you owe.
                            <br />
                            <br />
                            Breaking your assets down into different categories makes
                            the Zakat calculation process easier. The process presented on the calculator is broken up by Zakatable assets which include gold, silver,
                            cash, savings, business assets etc. and deductible liabilities money you owe, other outgoings due so you can calculate the Zakat you
                            owe easily. The amount of Zakat that appears will be what you need to pay.
                            <br />
                            <br />
                            Zakat, one of the Five Pillars of Islam, is a form of almsgiving treated in Islam as a religious obligation or tax, which, by Quranic
                            ranking, is next after prayer in importance. As a religious obligation, it is deeply embedded in the Islamic faith, and its calculation
                            is an essential practice for Muslims worldwide. With the advent of technology, the process has been simplified through the use of a Zakat
                            Calculator, making it easier for Muslims to fulfill this crucial duty.
                        </p>
                        <h2 className="w-full text-[18px] md:text-[25px] text-[#253b7e] font-medium my-4">
                            Learn how to calculate zakat, we make it simple
                        </h2>
                        <p className=" text-[#78716C]">
                            Calculating Zakat involves several steps and considerations to ensure one fulfills this religious obligation accurately.
                            The process starts by evaluating all assets subject to Zakat, which include cash, gold, silver, business inventory, and other
                            income-generating assets. The Zakat Calculator 2024 simplifies this task by providing a user-friendly interface where individuals
                            can input their asset values, and the calculator will automatically compute the Zakat due.
                            <br />
                            <br />
                            To calculate Zakat, one must first determine the Nisab value, which is the minimum amount of wealth a Muslim must possess before they
                            are liable to pay Zakat. This value is typically based on the current price of gold or silver. Once the Nisab is determined, Muslims
                            who own wealth above this threshold must pay 2.5% of their total qualifying assets as Zakat.
                        </p>
                        <h2 className="w-full text-[18px] md:text-[25px] text-[#253b7e] font-medium my-4">
                            Zakat calculator for gold: Use our gold zakat calculator to see what you owe
                        </h2>
                        <p className=" text-[#78716C]">
                            Gold is a significant asset in Zakat calculation due to its intrinsic value and common possession among Muslims. The Zakat Calculator
                            on Gold simplifies the computation by considering the current gold prices and the weight of the gold owned. Whether you have gold in
                            the form of jewelry, coins, or bars, the Gold Zakat Calculator allows you to input the total weight in grams or tolas and calculates
                            the Zakat due based on the current gold Nisab value.
                        </p>
                        <h2 className="w-full text-[18px] md:text-[25px] text-[#253b7e] font-medium my-4">
                            Zakat calculator for gold: Use our gold zakat calculator to see what you owe
                        </h2>
                        <p className=" text-[#78716C]">
                            Zakat on cash involves assessing all liquid assets, including money in bank accounts, savings, and even cash at hand. The UK Zakat
                            Calculator provides an easy-to-use platform for UK residents, ensuring they can calculate their Zakat on cash accurately,
                            taking into account any region-specific considerations.
                            <br />
                            <br />
                            To calculate Zakat on cash, one must sum up all liquid assets and apply the 2.5% rate. This includes checking and savings account balances,
                            cash at home, and any other liquid assets. It is crucial to ensure that the total amount meets or exceeds the Nisab threshold for Zakat
                            to be obligatory.
                        </p>
                    </section>
                </div>
            </div>


        </NewHelmetLayout>
    )
}

export default ZakatCalculatorUATLandingPage;