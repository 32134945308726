import { Builder } from '@builder.io/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../../../store/user';
import SelectCurrency from '../../../../../components/general/selectCurrency';
import ReactSelect from 'react-select';
import useHandleAddProduct from '../../../../../utils/handleAddProduct';
import { selectProducts } from '../../../../../store/products';
import { useNavigate } from 'react-router-dom';
import { generateLinkWithLang } from '../../../../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';
import { openSheet } from '../../../../../store/sheetToggler';
import useCart from '../../../../../hooks/cart/useCart';

const styles = {
  control: base => ({
    ...base,
    height: '48px',
    border: 'none',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)',
  }),
  menuList: base => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  shadow: {
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)',
  },
};

const ZakatAlFitrWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { defaultName = 'Zakat Al Mal', defaultCreator = 'TXT-RM25-00-04', mode = 'one-off', arabic = false, addToCart } = props;

  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector(selectProducts);
  const dispatch = useDispatch();
  const { handleAddToGiving: handleAddToGivingCart } = useCart();

  const [isLoaded, setIsLoaded] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState();
  const [productsList, setProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({
    creator: defaultCreator,
    label: defaultName,
    value: 255,
  });

  // For the drop-down of persons
  const [selectedPersons, setSelectedPersons] = useState(1);

  const [currentProduct, setCurrentProduct] = useState({
    creator: defaultCreator,
    label: defaultName,
    value: '',
  });

  const [addProduct] = useHandleAddProduct();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const suggestedRecurringModes = [
    {
      value: 'one-off',
      label: arabic ? 'فردي' : 'Single',
    },
    {
      value: 'week',
      label: arabic ? 'أسبوعي' : 'Weekly',
    },
    {
      value: 'month',
      label: arabic ? 'شهري' : 'Monthly',
    },
  ];

  // Function to get the recurring mode based on the mode value
  const getRecurringMode = mode => {
    return suggestedRecurringModes.find(recurringMode => recurringMode.value === mode) || suggestedRecurringModes[0];
  };

  const [selectedRecurring] = useState(getRecurringMode(mode));

  /* -------------------------------------------------------------------------- */
  /* ------------------------------ Start methods ----------------------------- */

  const handleSelectedProduct = product => {
    // Find the matching product object in allProducts
    allProducts.forEach(item => {
      if (item.creator === product.creator) {
        setSelectedProduct(item);
      }
    });
  };

  const handleAddNewProduct = () => {
    // The total cost is (cost per person) * (number of persons)
    const productCost = selectedProduct?.[currentCurrency?.toLowerCase()] || 0;
    const totalAmount = Number(selectedPersons) * productCost;
    let tempProduct = { ...selectedProduct };
    tempProduct = Object.assign({}, tempProduct, {
      quantity: selectedPersons,
      recurring: selectedRecurring.value,
    });

    if (addToCart) {
      handleAddToGivingCart(tempProduct, 'custom');
      dispatch(openSheet());
    } else {
      addProduct({ product: tempProduct, currency: currentCurrency });
    }
  };

  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */

  const getAllProducts = products => {
    setIsLoaded(true);
    let tempProductsList = [];
    let crisisWidgetProducts = [];
    let tempDefaultProduct;
    let tempAlternativeProduct;

    if (arabic) {
      // AR logic
      products.forEach(item => {
        item.sub_categories.map(inner_item => {
          inner_item.products.map(inner_item_product => {
            if (Number(inner_item_product.status) === 1) {
              if (inner_item_product.creator === defaultCreator) {
                tempDefaultProduct = inner_item_product;
              }
              // ... and so on for other creators ...
              // We push them into crisisWidgetProducts / tempProductsList
            }
            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });
      // deduplicate
      tempProductsList = tempProductsList.filter(function ({ creator }) {
        return !this.has(creator) && this.add(creator);
      }, new Set());
    } else {
      // EN logic
      products.forEach(item => {
        item.sub_categories.map(inner_item => {
          inner_item.products.map(inner_item_product => {
            if (Number(inner_item_product.status) === 1) {
              if (inner_item_product.creator === defaultCreator) {
                tempDefaultProduct = inner_item_product;
              }
              // ... similar for other creators ...
            }
            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });
    }

    if (tempDefaultProduct) {
      crisisWidgetProducts.push(tempDefaultProduct);
      setSelectedProduct(tempDefaultProduct);
      setCurrentProduct({
        value: tempDefaultProduct.id,
        label: tempDefaultProduct.name,
        creator: tempDefaultProduct.creator,
      });
    } else if (tempAlternativeProduct) {
      // if we don't have a default but do have an alternative
      setSelectedProduct(tempAlternativeProduct);
      setCurrentProduct({
        value: tempAlternativeProduct.id,
        label: tempAlternativeProduct.name,
        creator: tempAlternativeProduct.creator,
      });
    }

    const uniqueItems = tempProductsList.filter((item, index, self) => index === self.findIndex(t => t.creator === item.creator));
    setProductsList(uniqueItems);
    setAllProducts(crisisWidgetProducts);
  };

  useEffect(() => {
    if (selectedCurrencyStore) {
      setCurrentCurrency(selectedCurrencyStore);
    } else {
      setCurrentCurrency('AUD');
    }
  }, [selectedCurrencyStore]);

  useEffect(() => {
    if (products && Object.keys(products).length) {
      getAllProducts(products);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  /* -------------------------------------------------------------------------- */
  /* ------------------------- Build persons dropdown -------------------------- */

  // Build your list [1..10] with the total for each:
  const productCost = selectedProduct?.[currentCurrency?.toLowerCase()] || 0;
  const personsOptions = Array.from({ length: 10 }, (_, i) => i + 1).map(num => {
    const totalForN = num * productCost;
    const label = arabic
      ? `${num} شخص${num > 1 ? 'اً' : ''} x ${productCost} ${currentCurrency} = ${totalForN} ${currentCurrency}`
      : `${num} Person${num > 1 ? 's' : ''} x ${productCost} ${currentCurrency} = ${totalForN} ${currentCurrency} Total`;
    return { value: num, label };
  });

  const handlePersonsChange = option => {
    setSelectedPersons(option.value);
  };

  /* -------------------------------------------------------------------------- */
  return (
    <div
      dir={arabic ? 'rtl' : 'ltr'}
      className={`${arabic ? 'font-[AlmariMedium]' : ''} w-full rounded-lg bg-slate-200- h-[400px]- py-2 md:py-4 flex flex-wrap items-center`}
    >
      <div className="basis-full grid grid-cols-12 gap-2">
        <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <ReactSelect
            options={productsList}
            className="max-h-[48px] w-full pointer-events-none"
            defaultValue={selectedProduct}
            isSearchable={false}
            styles={styles}
            placeholder="Select Product"
            onChange={e => handleSelectedProduct(e)}
            readOnly
          />
        </div>

        {/* Persons DropDown */}
        <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <ReactSelect
            className={'w-full'}
            options={personsOptions}
            value={personsOptions.find(opt => opt.value === selectedPersons)}
            onChange={handlePersonsChange}
            styles={styles}
          />
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" />
        </div>

        <div className="col-span-12 sm:col-span-6  md:col-span-6 lg:col-span-6 flex justify-center">
          <button
            className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${
              isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
            }`}
            onClick={handleAddNewProduct}
          >
            Quick Donate
          </button>
        </div>
      </div>
    </div>
  );
};

Builder.registerComponent(ZakatAlFitrWidget, {
  name: 'ZakatAlFitrWidgetV2',
  inputs: [
    {
      name: 'defaultName',
      defaultValue: 'Zakat Al Mal',
      friendlyName: 'Default Name',
      type: 'string',
    },
    {
      name: 'addToCart',
      type: 'boolean',
      defaultValue: false,
      friendlyName: 'Add to side cart or navigate to checkout',
    },
    {
      name: 'defaultCreator',
      defaultValue: 'TXT-RM25-00-04',
      friendlyName: 'Default Creator',
      type: 'string',
    },
    {
      name: 'mode',
      type: 'enum', // Use 'enum' to define a dropdown with specific options
      enum: ['one-off', 'week', 'month'], // Define the available options
      defaultValue: 'one-off', // Set a default value if desired
      enumLabels: ['Single', 'Weekly', 'Monthly'],
    },
    {
      name: 'arabic',
      type: 'boolean',
      // defaultValue: false,
      friendlyName: 'Show Arabic Content',
    },
  ],
});

export default ZakatAlFitrWidget;
