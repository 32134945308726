// Add a cache at the module level
let popupSettingsCache = null;
let popupSettingsPromise = null;

const fetchPopupSettings = async () => {
  // If there's a promise in progress, return that
  if (popupSettingsPromise) {
    return popupSettingsPromise;
  }

  // If cached data exists, use that
  if (popupSettingsCache) {
    return Promise.resolve(popupSettingsCache);
  }

  // Otherwise, fetch the data and cache it
  const currentPage = window.location.pathname;
  const apiUrl = `https://matwproject.org/backend/index.php/api/popups?page=${currentPage}`;

  popupSettingsPromise = fetch(apiUrl)
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch popup settings');
      }
      return response.json();
    })
    .then(data => {
      popupSettingsCache = data; // Cache the data
      return data;
    })
    .catch(error => {
      console.error('Error fetching popup settings:', error);
      popupSettingsCache = null; // Reset cache on error
      throw error;
    })
    .finally(() => {
      popupSettingsPromise = null; // Clear the promise after completion
    });

  return popupSettingsPromise;
};

export default fetchPopupSettings;