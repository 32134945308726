import { Builder } from '@builder.io/react';
import React, { useState } from 'react';
import HtmlContent from "../../../../general/htmlContent";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
const AccordionWidget = props => {
  const {
    question,
    level = 1,
    answer
  } = props;
  const [activeIndex, setActiveIndex] = useState(false);
  return <div className='bg-white rounded-md w-full shadow-md p-4 flex flex-col justify-center items-center text-[#78716C]'>
    <div onClick={() => setActiveIndex(!activeIndex)} className={`flex w-full justify-between rounded ${Number(level) === 1 ? 'p-2 mt-2' : 'px-4 py-2'} cursor-pointer`}>
      <div className='flex'>
        <div className={`text-xl font-montserratBold ${Number(level) === 1 ? activeIndex ? 'text-[#00a3da]' : 'text-[#777]' : activeIndex ? 'text-[#00a3da] font-normal' : 'text-[#888] font-normal'}`}>{question}</div>
      </div>
      <div className="flex items-center justify-center">
        <ChevronDownIcon className={`w-4 h-4 text-stone-600 transition-all duration-300 ${activeIndex ? ' transform rotate-180' : ''}`} />
      </div>
    </div>
    <div className={`shadow-3xl w-full rounded-md shadow-cyan-500/50 bg-stone-50 transition-all duration-200 ease-in-out px-3 sm:px-6 font-montserratMedium ${activeIndex ? ' h-auto py-4 mb-6' : 'h-0 overflow-hidden'}`}>
      <HtmlContent htmlCode={answer} />
    </div>
  </div>;
};
Builder.registerComponent(AccordionWidget, {
  name: 'AccordionWidget',
  inputs: [{
    name: "question",
    type: "string",
    friendlyName: 'Question',
    helperText: 'Add Question'
  }, {
    name: "answer",
    type: "string",
    friendlyName: 'Answer',
    helperText: 'Add Answer'
  },
  {
    name: "level",
    type: "string",
    friendlyName: 'Level',
    defaultValue: 1
  },
  ]
});
export default AccordionWidget;