// components/ProductSelector.jsx
import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import PropTypes from 'prop-types';
import CustomButton from '../../general/button';
import useHandleAddProduct from '../../../utils/handleAddProduct';

const ProductSelector = ({ products, selectedProducts, setSelectedProducts, currency }) => {
    // Local state to track input quantities for products not yet selected
    const [inputQuantities, setInputQuantities] = useState({});
    const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
    const isDailyDonation = JSON.parse(localStorage.getItem('IS_DAILY_DONATION'));
    // Handler for toggling product selection
    const handleToggleSelect = (productId, value) => {
        if (selectedProducts.some((p) => p.id === productId)) {
            // Deselect product
            setSelectedProducts(selectedProducts.filter((p) => p.id !== productId));
        } else {
            // Select product with entered quantity or default
            const product = products.find((p) => p.id === productId);
            // const enteredQuantity = inputQuantities[productId];
            const enteredQuantity = value;
            // console.log("changedd===", product, enteredQuantity, selectedProducts, productId)
            if (!enteredQuantity || enteredQuantity <= 0) {
                // Do not add the product if quantity is 0 or not entered
                return;
            }
            const quantity = enteredQuantity;
            addProduct({ product: product, currency: currency, justAddToLocalStorage: true });
            // console.log("changedd=", quantity, product, productId)
            setSelectedProducts([...selectedProducts, { ...product, quantity }]);
            // Clear inputQuantities for this product
            setInputQuantities((prev) => {
                const newInputs = { ...prev };
                delete newInputs[productId];
                return newInputs;
            });
        }
    };

    // Handler for changing donation quantity when product is selected
    const handleQuantityChange = (productId, quantity) => {
        const numQuantity = Number(quantity);
        if (isNaN(numQuantity) || numQuantity <= 0 || quantity === '') {
            // Deselect product
            setSelectedProducts(selectedProducts.filter((p) => p.id !== productId));
        } else {
            const sanitizedQuantity = Math.floor(numQuantity);
            const isSelected = selectedProducts.some((p) => p.id === productId);
            // console.log("change=", sanitizedQuantity, isSelected, productId)
            if (isSelected) {
                setSelectedProducts(
                    selectedProducts.map((p) =>
                        p.id === productId ? { ...p, quantity: sanitizedQuantity } : p
                    )
                );
            } else {
                setInputQuantities(
                    {
                        ...inputQuantities,
                        [productId]: sanitizedQuantity,
                    }
                );
                handleToggleSelect(productId, sanitizedQuantity)
            }
        }
    };

    // Handler for changing donation quantity when product is not selected
    // const handleInputQuantityChange = (productId, quantity) => {
    //     const numQuantity = Number(quantity);
    //     if (isNaN(numQuantity) || numQuantity <= 0 || quantity === '') {
    //         // Remove from inputQuantities if 0 or empty
    //         setInputQuantities((prev) => {
    //             const newInputs = { ...prev };
    //             delete newInputs[productId];
    //             return newInputs;
    //         });
    //     } else {
    //         const sanitizedQuantity = Math.floor(numQuantity);
    //         const isSelected = selectedProducts.some((p) => p.id === productId);
    //         console.log("change=", sanitizedQuantity, isSelected, productId)
    //         setInputQuantities(
    //             {
    //                 ...inputQuantities,
    //                 [productId]: sanitizedQuantity,
    //             }
    //         );
    //         handleToggleSelect(productId)
    //         if (isSelected) {
    //         } else {
    //         }
    //     }
    // };

    // Effect to calculate and set the total amount
    // useEffect(() => {
    //     const total = selectedProducts.reduce((sum, product) => {
    //         const price = product[currency.toLowerCase()] || 0;
    //         return sum + (price * product.quantity);
    //     }, 0);
    //     // setTotalAmount(total);
    //     // }, [selectedProducts, currency, setTotalAmount]);
    // }, [selectedProducts, currency]);

    return (
        <div className="w-full">
            {products.filter(product => Number(product.status) === 1).map((product) => {
                const isSelected = selectedProducts.some((p) => p.id === product.id);
                const selectedProduct = selectedProducts.find((p) => p.id === product.id);
                return (
                    <div key={product.id} className="flex flex-col sm:flex-row w-full py-2">
                        <div className="flex justify-center w-full sm:w-1/4 lg:w-1/5 mb-3 sm:mb-0">
                            {product.image_link ? (
                                <LazyLoadImage
                                    alt={product.name}
                                    effect="blur"
                                    width={`100%`}
                                    delayTime={500}
                                    className={`cursor-pointer block rounded-md`}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = '/images/general/logo-replace.png';
                                    }}
                                    src={product.image_link} // use normal <img> attributes as props
                                />
                            ) : (
                                <img className={`w-full`} src="/images/general/logo-replace.png" alt="" />
                            )}
                            {/* <img className={`w-full max-w-[200px] mx-auto`} src="/images/general/logo-replace.png" alt="" /> */}
                        </div>
                        <div className="flex flex-col justify-center w-full sm:w-3/4 lg:w-4/5 sm:px-4">
                            <h2 className="text-[#253B7E] font-bold text-lg xl:text-xl mb-1">{product.name}</h2>
                            <p dangerouslySetInnerHTML={{ __html: product.short_description || product.description }} className={`text-stone-500 font-normal text-sm md:text-base`}></p>
                            <div className="flex justify-between mt-2 md:mt-4">
                                <input
                                    className='mr-1 md:mr-2 rounded-md border border-[#78716C] px-2 h-12'
                                    type="number"
                                    id={`quantity-${product.id}`}
                                    min="0" // Changed from min="1" to min="0"
                                    step="1"
                                    // value={isSelected ? selectedProduct.quantity : (inputQuantities[product.id] || '')}
                                    value={selectedProduct && selectedProduct.quantity ? selectedProduct.quantity : ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        // !isDailyDonation && localStorage.setItem('IS_DAILY_DONATION', JSON.stringify(true));
                                        localStorage.setItem('IS_DAILY_DONATION', JSON.stringify(true));
                                        handleQuantityChange(product.id, value);
                                        // if (isSelected) {
                                        //     handleQuantityChange(product.id, value);
                                        // } else {
                                        //     handleInputQuantityChange(product.id, value);
                                        // }
                                    }}
                                    placeholder={'Enter Amount'}
                                />
                                {product.name === 'Zakat'
                                    ? <CustomButton
                                        title="Zakat Calculator"
                                        className={`mt-4- w-full md:w-auto md:max-w-[250px] mb-4 !bg-transparent !text-[#14A2DC] underline`}
                                        link={'/zakat-calculator'}
                                        target={'_blank'}
                                    />
                                    : ''
                                }
                                {/* <CustomButton
                                    type="button"
                                    title={isSelected ? 'Added' : 'Add'}
                                    disabled={isSelected && (!inputQuantities[product.id] || inputQuantities[product.id] <= 0)}
                                    className={`${isSelected ? '!bg-[#253B7E]' : ''} w-full sm:w-auto`}
                                    onClick={() => handleToggleSelect(product.id)}
                                /> */}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

// PropTypes for type checking
ProductSelector.propTypes = {
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
    setSelectedProducts: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired, // New prop
    // setTotalAmount: PropTypes.func.isRequired, // New prop
};

export default ProductSelector;
