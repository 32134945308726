import React, { useEffect, useState } from "react";
import useCart from "../../hooks/cart/useCart";
import ProductBox from "./productBox";
import { ProductBoxCart } from "../../pages/home/components/productBoxCart";
import { selectSelectedCurrency } from "../../store/user";
import { useSelector } from "react-redux";
import sortProductsByCurrency from "../../utils/productSortHandler";
import MultiProductsBoxCart from "./multiProductsBoxCart";
import MultiProductsBox from "./multiProductsBox";

const ProductsTabVertical = ({
  sections = [],
  isInWidget = false,
  arabic = false,
  lang = "en",
  bgColor,
}) => {
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState(
    selectedCurrencyStore || "AUD",
  );

  const { handleCheckProduct, handleAddToGiving, handleRemoveProduct } =
    useCart();

  useEffect(() => {
    setSelectedCurrency(selectedCurrencyStore || "AUD");
  }, [selectedCurrencyStore]);


  return (
    <section className={`${bgColor ? bgColor : "bg-[#eaf7ff]"}`}>
      <div
        className={`md:container w-full mx-auto px-4 md:px-0 ${isInWidget && "mb-6"} py-4 md:py-12 space-y-4 md:space-y-7`}
      >
        {sections && sections.map(({ title,alterNatetitle, description, products, shouldRenderProducts = true, isCarousel = false, noOrder=false, sortOrder="asc", isAppealProducts = false }, sectionIndex) => {
          return shouldRenderProducts ? (
            <div key={`section-${sectionIndex}`} className="">
              {/* Section Title */}
              <div className="mb-4 md:mb-7 flex justify-center items-center flex-col gap-4 md:gap-6">
                {title && (
                  <div className="flex justify-center items-center mx-auto">
                    <p className="bg-[#14a2dc] text-[#fff] text-[14px] text-center rounded-none p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase">
                      {title}
                    </p>
                  </div>
                )}
                {alterNatetitle && (
                  <div className="flex justify-center items-center mx-auto">
                    <p className="bg-[#14a2dc] text-[#fff] text-[14px] text-center rounded-none p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase">
                      {alterNatetitle}
                    </p>
                  </div>
                )}
                {description && (
                  <div className="flex w-full justify-center">
                    <p className="text-center text-[#14a2dc] text-[18px] md:text-3xl md:w-2/3">{description}</p>
                  </div>
                )}
              </div>
              {/* Product Cards */}
              {isCarousel || isAppealProducts ? (
                <>{products}</>
              ) : (
                <div className="flex flex-wrap gap-3 md:gap-4 justify-center items-start w-full">
                  {(noOrder ? products : sortProductsByCurrency(products, selectedCurrency.toLowerCase(),sortOrder)).map((item, productIndex) => (
                    <React.Fragment key={`product-${sectionIndex}-${productIndex}`}>
                      <div className="w-full sm:max-w-[300px] sm:basis-[calc(50%-8px)] hidden sm:block">
                        {item.products ? (
                          <MultiProductsBox
                            key={productIndex}
                            multiProducts={item.products}
                            staticLink={'/'}
                            redirectUrl={item.redirectUrl}
                            title={item.title}
                            description={item.description}
                            image={item.img}
                            arabic={arabic}
                            _lang={lang}
                            alt={item?.alt}
                            currency={selectedCurrency}
                          />
                        ) : (
                          <ProductBox
                            key={productIndex}
                            stateVariableObj={{ productName: item.name }}
                            product={item}
                            arabic={arabic}
                            _lang={lang}
                            linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                            isStaticProduct={item.isStaticProduct}
                            staticLink={item.staticLink}
                            staticLinkText={item.staticLinkText}
                            currency={selectedCurrency}
                            titleClasses={`uppercase px-1 !text-lg !h-auto !mt-3 min-h-[56px]`}
                          />
                        )}
                      </div>
                      <div className="w-full sm:hidden">
                        {item.products ? (
                          <MultiProductsBoxCart
                            key={productIndex}
                            product={item}
                            multiProducts={item.products}
                            staticLink={"/"}
                            redirectUrl={item.redirectUrl}
                            title={item.title}
                            description={item.description}
                            image={item.img}
                            arabic={arabic}
                            _lang={lang}
                            alt={item?.alt}
                            currency={selectedCurrency}
                            onCheck={handleCheckProduct}
                            onAdd={handleAddToGiving}
                            onRemove={handleRemoveProduct}
                          />
                        ) : (
                          <ProductBoxCart
                            key={productIndex}
                            product={item}
                            onCheck={handleCheckProduct}
                            onAdd={handleAddToGiving}
                            onRemove={handleRemoveProduct}
                            currency={selectedCurrency}
                            stateVariableObj={{ productName: item.name }}
                            arabic={arabic}
                            _lang={lang}
                            linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                            isStaticProduct={item.isStaticProduct}
                            staticLink={item.staticLink}
                            staticLinkText={item.staticLinkText}
                          />
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          ) : null; // Return `null` when `shouldRenderProducts` is false
        })}

      </div>
    </section>
  );
};

export default ProductsTabVertical;