import { combineReducers } from 'redux';

import user from './user';
import products from './products';
import visibility from './visibility';
import menu from './menu';
import prayer from "./prayer";
import popupSlice from "./popupSlice";

import widgetCart from './widgetCart';
import sheet from './sheetToggler';
const allReducers = combineReducers({
    user,
    products,
    visibility,
    prayer,
    menu,
    widgetCart,
    sheet,
    popupSlice
});
export default allReducers;
// export default allReducers;