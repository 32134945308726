import React from "react";

const WistiaEmbed = () => {
  return (
    <div
      style={{
        position: "relative",
        paddingTop: "56.25%", // Maintain aspect ratio (16:9)
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <iframe
          src="https://fast.wistia.net/embed/iframe/jpw18p392k?web_component=true&seo=true"
          title="MATW Video"
          allow="autoplay; fullscreen"
          allowTransparency={true}
          frameBorder="0"
          scrolling="no"
          style={{ width: "100%", height: "100%" }}
        ></iframe>
      </div>
    </div>
  );
};

export default WistiaEmbed;
