import { Builder } from '@builder.io/react';
import { useEffect, useState, useRef, useCallback } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import CustomButton from "../../../../general/button";
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { generateLinkWithLang } from "../../../../../utils/linkGenerator";
import { useTranslation } from 'react-i18next';
import useHandleAddProduct from "../../../../../utils/handleAddProduct";
import { selectSelectedCurrency } from "../../../../../store/user";
import { useSelector } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import IsGTMScriptLoaded from "../../../../../utils/isGTMScriptLoaded";
import SelectCurrency from "../../../../general/selectCurrency";
import { selectProducts } from '../../../../../store/products';
import { checkDomain } from '../../../../../utils/checkDomain';
const styles = {
  control: base => ({
    ...base,
    height: '48px',
    border: 'none',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)'
  }),
  menuList: base => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "6px",
      height: "0px"
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1"
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555"
    }
  }),
  shadow: {
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
  }
};
const ProductCardAnyAmount = ({
  productId,
  quantities = []
}) => {
  const navigate = useNavigate();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const {
    i18n
  } = useTranslation();
  const lang = i18n.language;
  const [showMore, setShowMore] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const [addProduct] = useHandleAddProduct();
  const [product, setProduct] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState();
  const [productAmount, setProductAmount] = useState('');
  const ref = useRef(null);
  const quantitiesArray = Array.isArray(quantities) ? quantities : [];
  const products = useSelector(state => selectProducts(state));

  const handleHeight = () => {
    setShowMore(!showMore);
  };
  const handleViewItemDataLayer = (tempProduct, currency) => {
    const dataLayerTemp = {
      event: 'view_item',
      ecommerce: {
        currency: currency.toUpperCase(),
        items: [{
          item_id: tempProduct.creator,
          item_name: tempProduct.name,
          item_brand: tempProduct.category || '',
          item_category: tempProduct.category || '',
          item_description: tempProduct.description || '',
          price: tempProduct[currency.toLowerCase()]
        }]
      }
    };
    window.dataLayer = window.dataLayer || [];
    if (IsGTMScriptLoaded()) {
      window.dataLayer.push(dataLayerTemp);
    }
    const obj = {
      content_type: 'product',
      content_id: tempProduct.creator,
      currency: currency,
      value: tempProduct[currency.toLowerCase()],
      price: tempProduct[currency.toLowerCase()],
      quantity: 1,
      content_category: tempProduct.category || 'donation',
      content_name: tempProduct.name,
      description: tempProduct.description
    };
    if (window.ttq) {
      if (!(checkDomain('matwprojectme.org') || checkDomain('matwproject.org'))) {
        window.ttq.instance(localStorage.getItem('TikTokID')).track('ViewContent', obj);
      }
    }
  };
  const handleCurrency = currency => {
    setCurrentCurrency(currency.value);
  };
  const ShowCurrencies = (currency, _price) => {
    const tempCurrency = currency?.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let final = '';
    price = price ? price.toLocaleString() : '0';
    switch (tempCurrency) {
      case 'GBP':
      case 'EUR':
        final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        final = `${price}`;
        break;
      case 'IDR':
        final = `Rp${price}`;
        break;
      case 'AED':
        final = `Dh${price}`;
        break;
      default:
        final = `$${price}`;
        break;
    }
    return final;
  };
  const handleOtherQuantities = () => {
    let tempProduct = product;
    let customAmount = Number(productAmount) ? Number(productAmount) : 100;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount
    });
    addProduct({
      product: tempProduct,
      currency: currentCurrency
    });
  };
  const handleFixedQuantities = qt => {
    let tempProduct = product;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: Number(qt)
    });
    addProduct({
      product: tempProduct,
      currency: currentCurrency
    });
  };
  const handleQty = value => {
    let tempCount = productAmount;
    if (parseInt(value) > 0) {
      tempCount = parseInt(value);
    } else {
      tempCount = 100;
    }
    setProductAmount(tempCount);
  };
  const handleKeyDown = value => {
    let checkIfNum;
    if (value.key !== undefined) {
      checkIfNum = value.key === '.';
    }
    return checkIfNum && value.preventDefault();
  };
  const handleOnBlurQty = (value, index, prd) => {
    if (Number(value) === 0) {
      handleQty(100, index, prd);
    }
  };
  const getSelectedProduct = products => {
    setIsLoaded(true);
    let tempSelectedProduct;
    let tempWhereMostNeeded;
    products.forEach(item => {
      item.sub_categories.forEach(innerItem => {
        innerItem.products.forEach(innerProduct => {
          if(Number(innerProduct.status) === 1) {
            if (innerProduct.creator === 'MKD-MN-001') {
              tempWhereMostNeeded = innerProduct;
            }
            if (productId === innerProduct.creator) {
              tempSelectedProduct = innerProduct;
            }
          }
        });
      });
    });
    tempSelectedProduct ? setProduct(tempSelectedProduct) : setProduct(tempWhereMostNeeded);
  };
  useEffect(() => {
    if (productId) {
      products && Object.keys(products).length && getSelectedProduct(products);
    }
  }, [productId, products]);
  useEffect(() => {
    selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD');
  }, [selectedCurrencyStore]);
  return <div className="w-full p-2 md:p-3 md:pb-5 rounded-xl shadow-lg bg-white h-full max-w-[300px] mx-auto">
      {isLoaded ? <>
          {/* Image Section */}
          <div className="overflow-hidden img-hover-zoom rounded-md w-full sm:min-h-[169px] md:min-h-[210px] xl:min-h-[280px] 2xl:min-h-[344px] tooltip [&>span]:!block cursor-pointer" onClick={() => {
        product && product.product_type ? navigate(generateLinkWithLang(product.short_description, lang)) : navigate(generateLinkWithLang(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang));
      }}>
            {product && product.image_link ? <LazyLoadImage alt={product.name} effect="blur" width="100%" delayTime={500} afterLoad={() => {
          handleViewItemDataLayer(product, currentCurrency);
        }} className="rounded cursor-pointer block" onError={({
          currentTarget
        }) => {
          currentTarget.onerror = null;
          currentTarget.src = '/images/general/logo-replace.png';
        }} src={product.image_link} /> : <img className="w-full" src="/images/general/logo-replace.png" alt="Placeholder" />}
          </div>

          {/* Product Name */}
          <p className={`text-[#00a3da] cursor-pointer flex justify-center items-center text-sm sm:text-base lg:text-lg xl:text-xl text-center h-[42px] sm:h-[40px] lg:h-[55px] md:mb-2 xs:mb-2 mt-2 leading-4 md:leading-none uppercase ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-medium'}`} onClick={() => {
        product && product.product_type ? navigate(generateLinkWithLang(product.short_description, lang)) : navigate(generateLinkWithLang(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang));
      }}>
            {product && (product.alternativeName || product.name) || <Skeleton height={56} />}
          </p>

          {/* Description Section */}
          <div className="relative text-center">
            <div className={`transition-all duration-300 text-center my-2 relative overflow-hidden ${showMore ? 'h-auto mb-4' : 'max-h-[65px] md:max-h-[78px] mb-0'}`}>
              {product && <p dangerouslySetInnerHTML={{
            __html: product.product_type ? product.description : product.short_description || product.description
          }} ref={ref} className={`text-stone-500 text-sm md:text-base overflow-hidden transition-all duration-200 min-h-[65px] md:min-h-[79px] ${lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}></p>}
            </div>
            {isOverflow && <ChevronDownIcon onClick={handleHeight} className={`md:block w-6 h-6 text-[#00a3da] transition-all duration-300 cursor-pointer mx-auto ${showMore ? 'transform rotate-180' : ''}`} />}
          </div>

          {/* Pricing and Button */}
          <div className="grid grid-cols-6 gap-1 2xl:gap-2">
            <div className={`text-[#f60362] col-span-6 md:text-xl text-center font-bold ${lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}>
              {quantitiesArray.length ? quantitiesArray.map((item, index) => {
            return product && <div key={`button${index}`} className='bg-[#f60362] flex justify-between items-center p-2 cursor-pointer rounded-md text-white text-center text-sm md:text-lg mb-2' onClick={() => handleFixedQuantities(Number(item.quantity))}>
                    {ShowCurrencies(currentCurrency, product[currentCurrency.toLowerCase()] * (Number(item.quantity) || 100), true)}
                    <span className='text-sm font-normal'>Donate Now</span>
                  </div>;
          }) : <span>no quantity</span>}
            </div>
            <div className="col-span-6 border-t border-stone-300 pt-1">
              <div className="w-full flex justify-center items-center bg-white rounded-md h-12 px-2" style={styles.shadow}>
                <div className="flex-[0_0_calc(40%-10px)] !h-[50px]- [&>div]:h-full">
                  <SelectCurrency onChangeCurrency={e => handleCurrency(e)} classNamePrefix="currency-select-new" hideFlag className="[&>div]:!rounded-none [&>div]:!border-none" />
                </div>
                <input value={productAmount} className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent" type="number" onChange={e => handleQty(e.target.value)} onKeyDown={e => handleKeyDown(e)} onBlur={e => handleOnBlurQty(e.target.value)} placeholder="Other Amount" />
              </div>
            </div>
            <div className="flex justify-center col-span-6 mt-2">
              <CustomButton onClick={() => {
            product && product.product_type ? navigate(generateLinkWithLang(product.short_description, lang)) : handleOtherQuantities();
          }} title={'Donate Now'} className={`mx-auto ${lang === 'ar' ? 'font-[AlmariMedium]' : ''}`} />
            </div>
          </div>
        </> : <p className="text-center w-full">No products selected</p>}
    </div>;
};
Builder.registerComponent(ProductCardAnyAmount, {
  name: 'ProductCardAnyAmount',
  inputs: [{
    "name": "productId",
    "type": "string"
  },
  {
    name: 'quantities',
    type: 'list', // Defines the input as a list (array)
    defaultValue: [], // Initializes with an empty array
    minRows: 0,       // Optional: Minimum number of items
    maxRows: 3,     // Optional: Maximum number of items
    defaultItem: { quantity: '100' }, // Defines the structure of each item
    subFields: [
      {
        name: 'quantity',
        type: 'string', // Each item is a string
        required: true, // Makes this field mandatory
        helpText: 'Enter the Quantity.', // Provides guidance to users
      },
    ],
  },
]
});
export default ProductCardAnyAmount;