import React, {useEffect, useRef, useState} from 'react';
import NewHelmetLayout from "../../components/general/newHelmetLayout";
import {Helmet} from "react-helmet";

const KAABA_COORDINATES = {lat: 21.422487, lng: 39.826206};
const INITIAL_ZOOM = 21;
const GREAT_CIRCLE_COLOR = '#FF3D00';  // Red for Great Circle Formula

const QiblaFinder = () => {
    const mapRef = useRef(null);
    const [qiblaInfo, setQiblaInfo] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const lineRef = useRef(null);
    const glowLineRef = useRef(null);
    const markerRef = useRef(null);
    const labelRef = useRef(null);
    const [isGettingLocation, setIsGettingLocation] = useState(false);
    const [locationType, setLocationType] = useState('ip'); // 'ip', 'gps', or 'search'

    const handleLocationToggle = async () => {
        setIsGettingLocation(true);
        setSearchQuery('');

        if (locationType !== 'gps') {
            // Switching to GPS
            try {
                if (!("geolocation" in navigator)) {
                    throw new Error("Geolocation is not supported");
                }

                const position = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject, {
                        enableHighAccuracy: true, timeout: 10000, maximumAge: 0
                    });
                });

                const location = {
                    lat: position.coords.latitude, lng: position.coords.longitude
                };

                updateMapElements(location);
                setLocationType('gps');
            } catch (error) {
                console.error("Error getting GPS location:", error);
                // Fallback to IP
                try {
                    const response = await fetch('https://matwproject.org/ip-api');
                    const data = await response.json();
                    const location = {
                        lat: parseFloat(data.lat), lng: parseFloat(data.lon)
                    };
                    updateMapElements(location);
                    setLocationType('ip');
                } catch (ipError) {
                    console.error("Error getting IP location:", ipError);
                }
            }
        } else {
            // Switching to IP
            try {
                const response = await fetch('https://matwproject.org/ip-api');
                const data = await response.json();
                const location = {
                    lat: parseFloat(data.lat), lng: parseFloat(data.lon)
                };
                updateMapElements(location);
                setLocationType('ip');
            } catch (error) {
                console.error("Error getting IP location:", error);
            }
        }

        setIsGettingLocation(false);
    };

    const calculateGreatCircleQibla = (location) => {
        const lat1 = location.lat * Math.PI / 180;
        const lon1 = location.lng * Math.PI / 180;
        const lat2 = KAABA_COORDINATES.lat * Math.PI / 180;
        const lon2 = KAABA_COORDINATES.lng * Math.PI / 180;
        const dLon = lon2 - lon1;
        const y = Math.sin(dLon) * Math.cos(lat2);
        const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
        let qiblaAngle = Math.atan2(y, x) * 180 / Math.PI;
        return (qiblaAngle + 360) % 360;
    };

    const createDirectionArrow = (location, angle) => {
        const arrowSymbol = {
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 6,
            strokeWeight: 2,
            strokeColor: '#FFFFFF',
            fillColor: GREAT_CIRCLE_COLOR,
            fillOpacity: 1,
            rotation: angle
        };

        if (markerRef.current) {
            markerRef.current.setMap(null);
        }

        markerRef.current = new window.google.maps.Marker({
            position: location, icon: arrowSymbol, map: mapRef.current, title: 'Qibla Direction'
        });

        if (labelRef.current) {
            labelRef.current.setMap(null);
        }

        labelRef.current = new window.google.maps.Marker({
            position: location, map: mapRef.current, label: {
                text: 'Great Circle Direction', color: '#FFFFFF', fontSize: '12px', fontWeight: 'bold'
            }, icon: {
                path: 'M 0,0', fillColor: 'transparent', fillOpacity: 0, strokeWeight: 0
            }
        });
    };

    const calculateDistance = (location) => {
        const R = 6371; // Earth's radius in km
        const lat1 = location.lat * Math.PI / 180;
        const lon1 = location.lng * Math.PI / 180;
        const lat2 = KAABA_COORDINATES.lat * Math.PI / 180;
        const lon2 = KAABA_COORDINATES.lng * Math.PI / 180;

        const dLat = lat2 - lat1;
        const dLon = lon2 - lon1;
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    const updateMapElements = (location) => {
        // Clear existing elements
        if (lineRef.current) lineRef.current.setMap(null);
        if (glowLineRef.current) glowLineRef.current.setMap(null);
        if (markerRef.current) markerRef.current.setMap(null);
        if (labelRef.current) labelRef.current.setMap(null);

        // Calculate angle using Great Circle formula
        const greatCircleAngle = calculateGreatCircleQibla(location);
        const distance = calculateDistance(location);

        // Create glow line
        glowLineRef.current = new window.google.maps.Polyline({
            path: [location, KAABA_COORDINATES],
            geodesic: true,
            strokeColor: GREAT_CIRCLE_COLOR,
            strokeOpacity: 0.3,
            strokeWeight: 8,
            map: mapRef.current,
            zIndex: 0
        });

        // Create main line
        lineRef.current = new window.google.maps.Polyline({
            path: [location, KAABA_COORDINATES],
            geodesic: true,
            strokeColor: GREAT_CIRCLE_COLOR,
            strokeOpacity: 1,
            strokeWeight: 4,
            map: mapRef.current,
            zIndex: 1
        });

        // Create direction arrow
        createDirectionArrow(location, greatCircleAngle);

        // Update qibla info
        setQiblaInfo({
            angle: greatCircleAngle.toFixed(2), distance: distance.toFixed(2), location: {
                lat: location.lat.toFixed(6), lng: location.lng.toFixed(6)
            }
        });

        // Center and zoom map
        mapRef.current.setZoom(INITIAL_ZOOM);
        mapRef.current.panTo(location);

        // Set heading and tilt
        const heading = greatCircleAngle - 90;
        mapRef.current.setHeading(heading);
        mapRef.current.setTilt(67);

        setTimeout(() => {
            mapRef.current.setZoom(INITIAL_ZOOM - 2);
        }, 100);
    };

    useEffect(() => {
        const initializeMap = async () => {
            try {
                // First create the map
                mapRef.current = new window.google.maps.Map(document.getElementById('map'), {
                    zoom: INITIAL_ZOOM, mapTypeId: 'satellite', mapTypeControl: true, mapTypeControlOptions: {
                        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: window.google.maps.ControlPosition.TOP_RIGHT
                    }, zoomControl: true, zoomControlOptions: {
                        position: window.google.maps.ControlPosition.RIGHT_BOTTOM
                    }, streetViewControl: false, fullscreenControl: false, tilt: 67, heading: 0
                });

                // Set up search box after map is created
                const searchInput = document.getElementById('location-search');
                const searchBox = new window.google.maps.places.SearchBox(searchInput);

                searchBox.addListener('places_changed', () => {
                    const places = searchBox.getPlaces();
                    if (places.length === 0) return;

                    const place = places[0];
                    const location = {
                        lat: place.geometry.location.lat(), lng: place.geometry.location.lng()
                    };

                    setSearchQuery(place.formatted_address || place.name);
                    setLocationType('search');
                    updateMapElements(location);
                });

                // Get initial location only after map is ready
                try {
                    const response = await fetch('https://matwproject.org/ip-api');
                    const data = await response.json();
                    const location = {
                        lat: parseFloat(data.lat), lng: parseFloat(data.lon)
                    };
                    updateMapElements(location);
                } catch (error) {
                    console.error('Error getting initial location:', error);
                }

            } catch (error) {
                console.error('Error initializing map:', error);
            }
        };

        // Load Google Maps script
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCXVJ062M2GPfxr4DvmF55qSbSp4qh0S6U&libraries=places`;
        script.async = true;
        script.onload = initializeMap;
        document.head.appendChild(script);

        return () => {
            if (lineRef.current) lineRef.current.setMap(null);
            if (glowLineRef.current) glowLineRef.current.setMap(null);
            if (markerRef.current) markerRef.current.setMap(null);
            if (labelRef.current) labelRef.current.setMap(null);
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {

            // Run after initial render to reorder head elements
            const head = document.head;
            const titleEl = head.querySelector("title");
            const canonicalLink = head.querySelector('link[rel="canonical"]');
            // Gather all alternate links (and any additional link tags you want prioritized)
            const alternateLinks = head.querySelectorAll('link[rel="alternate"]');

            // Remove and re-insert the title at the top of the head
            if (titleEl) {
                head.removeChild(titleEl);
                head.insertBefore(titleEl, head.firstChild);
            }

            // Remove and re-insert canonical link immediately after title
            if (canonicalLink) {
                head.removeChild(canonicalLink);
                // If title is the first child, insert canonical link after it
                head.insertBefore(canonicalLink, titleEl.nextSibling);
            }

            // Re-insert alternate links after canonical link
            alternateLinks.forEach(link => {
                head.removeChild(link);
                head.insertBefore(link, canonicalLink.nextSibling);
            });
        }, 2000); // delay in milliseconds

        return () => clearTimeout(timer);
    }, []);

    return (

        <NewHelmetLayout className="bg-white" hideFooter showRegularImg={false}>
            <Helmet>
                <title>
                    Qibla Finder - Find Qibla Direction without Compass
                </title>
                <link rel="canonical"
                      href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
                <meta
                    name="description"
                    content={`Qibla Finder - Find Qibla Direction without Compass`}
                />

                <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb"/>
                <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default"/>
                <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca"/>
                <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr"/>
                <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us"/>
                <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id"/>
                <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my"/>
                <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg"/>
            </Helmet>

            <div className="relative w-screen h-screen flex flex-col">
                {/* Search and GPS Container */}
                <div className="w-full bg-white shadow-sm flex-shrink-0">
                    <div className="max-w-5xl mx-auto px-4 py-4">
                        <div className="flex gap-2">
                            <div className="flex-1">
                                <input
                                    id="location-search"
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder="Search for a city, place, or address to find Qibla direction"
                                    className="w-full px-4 py-3 rounded-lg border border-gray-200 bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-100 outline-none transition-all duration-200 text-gray-800 placeholder-gray-400"
                                />
                            </div>

                            <button
                                onClick={handleLocationToggle}
                                disabled={isGettingLocation}
                                className="px-4 py-3 bg-white rounded-lg border border-gray-200 hover:bg-gray-50
             focus:outline-none focus:ring-2 focus:ring-blue-100 transition-all duration-200
             flex items-center gap-2"
                            >
                                {isGettingLocation ? (<svg className="animate-spin h-5 w-5 text-gray-600"
                                                           xmlns="http://www.w3.org/2000/svg" fill="none"
                                                           viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>) : (<>
                                    <svg className="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24"
                                         stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                                    </svg>
                                    <span className="text-sm text-gray-600">
    {locationType === 'gps' ? 'Automatic Location [IP]' : locationType === 'ip' ? 'Precise Location [GPS]' : 'Searched Location [Places]'}
</span>
                                </>)}
                            </button>
                        </div>
                    </div>
                </div>

                {/* Map Container */}
                <div className="flex-1 relative">
                    <div id="map" className="w-full h-full absolute inset-0"/>

                    {qiblaInfo && (<div
                        className="absolute bottom-4 left-4 bg-white bg-opacity-90 p-4 rounded-lg shadow-lg max-w-sm z-10">
                        <h2 className="text-lg font-semibold mb-2">Qibla Direction</h2>
                        <div className="space-y-4">
                            <div className="space-y-2">
                                <h3 className="font-medium text-red-500">Great Circle Direction</h3>
                                <p>Direction: {qiblaInfo.angle}° from True North</p>
                            </div>
                            <div className="pt-2 border-t">
                                <p>
                                    <span className="font-medium">Distance to Kaaba:</span> {qiblaInfo.distance} km
                                </p>
                                <p><span className="font-medium">Your Location:</span></p>
                                <p>Lat: {qiblaInfo.location.lat}, Lng: {qiblaInfo.location.lng}</p>
                            </div>
                        </div>
                    </div>)}
                </div>

                {/* Banner - Now part of the flex layout */}
                <div className="bg-[#F60362] w-full flex-shrink-0">
                    <div className="max-w-5xl mx-auto py-4 sm:py-6 md:py-8 px-3 sm:px-6">
                        <p className="text-white text-center text-xs sm:text-sm md:text-base font-medium leading-none m-0">
                            Muslims Around the World (MatW) Project — Bridging Faith and Technology. Empowering the Muslim
                            community through innovative digital solutions, accurate prayer times, Sehr'O'Iftar times, and
                            reliable Qibla directions.
                        </p>
                    </div>
                </div>
            </div>
        </NewHelmetLayout>);
};

export default QiblaFinder;
// import React, {useEffect, useRef, useState} from 'react';
// import NewHelmetLayout from "../../components/general/newHelmetLayout";
// import {Helmet} from "react-helmet";
//
// const KAABA_COORDINATES = { lat: 21.422487, lng: 39.826206 };
// const INITIAL_ZOOM = 21;
//
// // Colors for the two different formulas
// const GREAT_CIRCLE_COLOR = '#FF3D00';  // Red for Great Circle Formula
// const SPHERICAL_COLOR = '#2196F3';     // Blue for Spherical Formula
//
// const QiblaComparison = () => {
//     const mapRef = useRef(null);
//     const [qiblaInfo, setQiblaInfo] = useState({ greatCircle: null, spherical: null });
//     const [searchQuery, setSearchQuery] = useState('');
//     const lineRefs = useRef({ greatCircle: null, spherical: null });
//     const glowLineRefs = useRef({ greatCircle: null, spherical: null });
//     const markerRefs = useRef({ greatCircle: null, spherical: null });
//     const labelRefs = useRef({ greatCircle: null, spherical: null });
//
//     const calculateSphericalQibla = (location) => {
//         const lat1 = location.lat * Math.PI / 180;
//         const lon1 = location.lng * Math.PI / 180;
//         const lat2 = KAABA_COORDINATES.lat * Math.PI / 180;
//         const lon2 = KAABA_COORDINATES.lng * Math.PI / 180;
//         const y = Math.sin(lon2 - lon1);
//         const x = Math.cos(lat1) * Math.tan(lat2) - Math.sin(lat1) * Math.cos(lon2 - lon1);
//         let qiblaAngle = Math.atan2(y, x) * 180 / Math.PI;
//         return (qiblaAngle + 360) % 360;
//     };
//
//     const calculateGreatCircleQibla = (location) => {
//         const lat1 = location.lat * Math.PI / 180;
//         const lon1 = location.lng * Math.PI / 180;
//         const lat2 = KAABA_COORDINATES.lat * Math.PI / 180;
//         const lon2 = KAABA_COORDINATES.lng * Math.PI / 180;
//         const dLon = lon2 - lon1;
//         const y = Math.sin(dLon) * Math.cos(lat2);
//         const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
//         let qiblaAngle = Math.atan2(y, x) * 180 / Math.PI;
//         return (qiblaAngle + 360) % 360;
//     };
//
//     const createDirectionArrow = (location, angle, formula) => {
//         const arrowSymbol = {
//             path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
//             scale: 6,
//             strokeWeight: 2,
//             strokeColor: '#FFFFFF',
//             fillColor: formula === 'greatCircle' ? GREAT_CIRCLE_COLOR : SPHERICAL_COLOR,
//             fillOpacity: 1,
//             rotation: angle
//         };
//
//         if (markerRefs.current[formula]) {
//             markerRefs.current[formula].setMap(null);
//         }
//
//         markerRefs.current[formula] = new window.google.maps.Marker({
//             position: location,
//             icon: arrowSymbol,
//             map: mapRef.current,
//             title: `${formula === 'greatCircle' ? 'Great Circle' : 'Spherical'} Formula`
//         });
//
//         if (labelRefs.current[formula]) {
//             labelRefs.current[formula].setMap(null);
//         }
//
//         const labelContent = formula === 'greatCircle' ? 'Great Circle Formula' : 'Spherical Formula';
//         labelRefs.current[formula] = new window.google.maps.Marker({
//             position: location,
//             map: mapRef.current,
//             label: {
//                 text: labelContent,
//                 color: '#FFFFFF',
//                 fontSize: '12px',
//                 fontWeight: 'bold'
//             },
//             icon: {
//                 path: 'M 0,0',
//                 fillColor: 'transparent',
//                 fillOpacity: 0,
//                 strokeWeight: 0
//             }
//         });
//     };
//
//     const calculateDistance = (location) => {
//         const R = 6371; // Earth's radius in km
//         const lat1 = location.lat * Math.PI / 180;
//         const lon1 = location.lng * Math.PI / 180;
//         const lat2 = KAABA_COORDINATES.lat * Math.PI / 180;
//         const lon2 = KAABA_COORDINATES.lng * Math.PI / 180;
//
//         const dLat = lat2 - lat1;
//         const dLon = lon2 - lon1;
//         const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
//             Math.cos(lat1) * Math.cos(lat2) *
//             Math.sin(dLon/2) * Math.sin(dLon/2);
//         const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
//         return R * c;
//     };
//
//     const updateMapElements = (location) => {
//         // Clear existing lines and markers
//         Object.values(lineRefs.current).forEach(line => line?.setMap(null));
//         Object.values(glowLineRefs.current).forEach(line => line?.setMap(null));
//         Object.values(markerRefs.current).forEach(marker => marker?.setMap(null));
//         Object.values(labelRefs.current).forEach(label => label?.setMap(null));
//
//         // Calculate angles using both formulas
//         const greatCircleAngle = calculateGreatCircleQibla(location);
//         const sphericalAngle = calculateSphericalQibla(location);
//         const distance = calculateDistance(location);
//
//         // Create lines for Great Circle Formula
//         glowLineRefs.current.greatCircle = new window.google.maps.Polyline({
//             path: [location, KAABA_COORDINATES],
//             geodesic: true,
//             strokeColor: GREAT_CIRCLE_COLOR,
//             strokeOpacity: 0.3,
//             strokeWeight: 8,
//             map: mapRef.current,
//             zIndex: 0
//         });
//
//         lineRefs.current.greatCircle = new window.google.maps.Polyline({
//             path: [location, KAABA_COORDINATES],
//             geodesic: true,
//             strokeColor: GREAT_CIRCLE_COLOR,
//             strokeOpacity: 1,
//             strokeWeight: 4,
//             map: mapRef.current,
//             zIndex: 1
//         });
//
//         // Create lines for Spherical Formula
//         glowLineRefs.current.spherical = new window.google.maps.Polyline({
//             path: [location, KAABA_COORDINATES],
//             geodesic: false,
//             strokeColor: SPHERICAL_COLOR,
//             strokeOpacity: 0.3,
//             strokeWeight: 8,
//             map: mapRef.current,
//             zIndex: 0
//         });
//
//         lineRefs.current.spherical = new window.google.maps.Polyline({
//             path: [location, KAABA_COORDINATES],
//             geodesic: false,
//             strokeColor: SPHERICAL_COLOR,
//             strokeOpacity: 1,
//             strokeWeight: 4,
//             map: mapRef.current,
//             zIndex: 1
//         });
//
//         // Create direction arrows
//         createDirectionArrow(location, greatCircleAngle, 'greatCircle');
//         createDirectionArrow(location, sphericalAngle, 'spherical');
//
//         // Update qibla info
//         setQiblaInfo({
//             greatCircle: {
//                 angle: greatCircleAngle.toFixed(2),
//                 distance: distance.toFixed(2),
//                 location: {
//                     lat: location.lat.toFixed(6),
//                     lng: location.lng.toFixed(6)
//                 }
//             },
//             spherical: {
//                 angle: sphericalAngle.toFixed(2),
//                 distance: distance.toFixed(2),
//                 location: {
//                     lat: location.lat.toFixed(6),
//                     lng: location.lng.toFixed(6)
//                 }
//             }
//         });
//
//         // Center and zoom map
//         mapRef.current.setZoom(INITIAL_ZOOM);
//         mapRef.current.panTo(location);
//
//         // Set heading and tilt
//         const averageAngle = (greatCircleAngle + sphericalAngle) / 2;
//         const heading = averageAngle - 90;
//         mapRef.current.setHeading(heading);
//         mapRef.current.setTilt(67);
//
//         setTimeout(() => {
//             mapRef.current.setZoom(INITIAL_ZOOM - 2);
//         }, 100);
//     };
//
//     useEffect(() => {
//         const initializeMap = async () => {
//             try {
//                 mapRef.current = new window.google.maps.Map(document.getElementById('map'), {
//                     zoom: INITIAL_ZOOM,
//                     mapTypeId: 'satellite',
//                     mapTypeControl: true,
//                     mapTypeControlOptions: {
//                         style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
//                         position: window.google.maps.ControlPosition.TOP_RIGHT
//                     },
//                     zoomControl: true,
//                     zoomControlOptions: {
//                         position: window.google.maps.ControlPosition.RIGHT_CENTER
//                     },
//                     streetViewControl: false,
//                     fullscreenControl: true,
//                     tilt: 67,
//                     heading: 0
//                 });
//
//                 const searchInput = document.getElementById('location-search');
//                 const searchBox = new window.google.maps.places.SearchBox(searchInput);
//
//                 searchBox.addListener('places_changed', () => {
//                     const places = searchBox.getPlaces();
//                     if (places.length === 0) return;
//
//                     const place = places[0];
//                     const location = {
//                         lat: place.geometry.location.lat(),
//                         lng: place.geometry.location.lng()
//                     };
//
//                     setSearchQuery(place.formatted_address || place.name);
//                     updateMapElements(location);
//                 });
//
//                 // Get initial location
//                 const response = await fetch('https://matwproject.org/ip-api');
//                 const data = await response.json();
//                 const location = {
//                     lat: parseFloat(data.lat),
//                     lng: parseFloat(data.lon)
//                 };
//                 updateMapElements(location);
//
//             } catch (error) {
//                 console.error('Error initializing map:', error);
//             }
//         };
//
//         const script = document.createElement('script');
//         script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAKENGLI-AM4g3jCzOZjkIggEeA6jsBb4Q&libraries=places`;
//         script.async = true;
//         script.onload = initializeMap;
//         document.head.appendChild(script);
//
//         return () => {
//             Object.values(lineRefs.current).forEach(line => line?.setMap(null));
//             Object.values(glowLineRefs.current).forEach(line => line?.setMap(null));
//             Object.values(markerRefs.current).forEach(marker => marker?.setMap(null));
//             Object.values(labelRefs.current).forEach(label => label?.setMap(null));
//         };
//     }, []);
//
//     return (
//         <>
//             <NewHelmetLayout className="bg-white" hideFooter showRegularImg={false}>
//                 <Helmet>
//                     <title>
//                         {"Qibla Finder"}
//                     </title>
//                     <link
//                         rel="canonical"
//                         href={`${window.location.protocol}//matwproject.org${window.location.pathname}`}
//                     />
//
//                 </Helmet>
//
//                 <div className="relative w-screen h-screen">
//                     <div className="absolute top-4 left-0 right-0 z-10 px-4">
//                         <div className="max-w-5xl mx-auto relative flex items-center">
//                             <div className="w-full mr-24">
//                                 <input
//                                     id="location-search"
//                                     type="text"
//                                     value={searchQuery}
//                                     onChange={(e) => setSearchQuery(e.target.value)}
//                                     placeholder="Search any location to compare Qibla directions..."
//                                     className="w-full px-4 py-3 pr-12 rounded-lg border border-gray-200 bg-white bg-opacity-90 focus:border-blue-500 focus:ring-2 focus:ring-blue-100 outline-none transition-all duration-200 text-gray-800 placeholder-gray-400 shadow-md"
//                                 />
//                                 <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400">
//                                     <svg
//                                         xmlns="http://www.w3.org/2000/svg"
//                                         className="h-5 w-5"
//                                         fill="none"
//                                         viewBox="0 0 24 24"
//                                         stroke="currentColor"
//                                     >
//                                         <path
//                                             strokeLinecap="round"
//                                             strokeLinejoin="round"
//                                             strokeWidth={2}
//                                             d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
//                                         />
//                                     </svg>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//
//                     <div id="map" className="w-full h-full"/>
//
//                     {qiblaInfo.greatCircle && qiblaInfo.spherical && (
//                         <div
//                             className="absolute bottom-4 left-4 bg-white bg-opacity-90 p-4 rounded-lg shadow-lg max-w-sm">
//                             <h2 className="text-lg font-semibold mb-2">Qibla Direction Comparison</h2>
//                             <div className="space-y-4">
//                                 <div className="space-y-2">
//                                     <h3 className="font-medium text-red-500">Great Circle Formula</h3>
//                                     <p>Direction: {qiblaInfo.greatCircle.angle}° from True North</p>
//                                 </div>
//                                 <div className="space-y-2">
//                                     <h3 className="font-medium text-blue-500">Spherical Formula</h3>
//                                     <p>Direction: {qiblaInfo.spherical.angle}° from True North</p>
//                                 </div>
//                                 <div className="pt-2 border-t">
//                                     <p><span
//                                         className="font-medium">Distance to Kaaba:</span> {qiblaInfo.greatCircle.distance} km
//                                     </p>
//                                     <p><span className="font-medium">Your Location:</span></p>
//                                     <p>Lat: {qiblaInfo.greatCircle.location.lat},
//                                         Lng: {qiblaInfo.greatCircle.location.lng}</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </div>
//
//             </NewHelmetLayout>
//         </>
//
//     );
// };
//
// export default QiblaComparison;

// import React, { useEffect, useRef, useState } from 'react';
//
// const KAABA_COORDINATES = { lat: 21.422487, lng: 39.826206 };
// const INITIAL_ZOOM = 21;
//
// const QiblaFinder = () => {
//     const mapRef = useRef(null);
//     const [qiblaInfo, setQiblaInfo] = useState(null);
//     const [locationSource, setLocationSource] = useState(null);
//     const [searchQuery, setSearchQuery] = useState('');
//     const [isFocused, setIsFocused] = useState(false);
//     const lineRef = useRef(null);
//     const glowLineRef = useRef(null);
//     const markerRef = useRef(null);
//
//     // const calculateQiblaAngle = (location) => {
//     //     const lat1 = location.lat * Math.PI / 180;
//     //     const lon1 = location.lng * Math.PI / 180;
//     //     const lat2 = KAABA_COORDINATES.lat * Math.PI / 180;
//     //     const lon2 = KAABA_COORDINATES.lng * Math.PI / 180;
//     //
//     //     const y = Math.sin(lon2 - lon1);
//     //     const x = Math.cos(lat1) * Math.tan(lat2) - Math.sin(lat1) * Math.cos(lon2 - lon1);
//     //     let qiblaAngle = Math.atan2(y, x) * 180 / Math.PI;
//     //     return (qiblaAngle + 360) % 360;
//     // };
//
//     // const calculateQiblaAngle = (location) => {
//     //     // Convert coordinates to radians
//     //     const lat1 = location.lat * Math.PI / 180;
//     //     const lon1 = location.lng * Math.PI / 180;
//     //     const lat2 = KAABA_COORDINATES.lat * Math.PI / 180;
//     //     const lon2 = KAABA_COORDINATES.lng * Math.PI / 180;
//     //
//     //     // Calculate Δλ (difference in longitude)
//     //     const dLon = lon2 - lon1;
//     //
//     //     // Haversine formula components
//     //     const y = Math.sin(dLon) * Math.cos(lat2);
//     //     const x = Math.cos(lat1) * Math.sin(lat2) -
//     //         Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
//     //
//     //     // Calculate bearing and convert to degrees
//     //     let qiblaAngle = Math.atan2(y, x) * 180 / Math.PI;
//     //
//     //     // Normalize to 0-360° compass bearing
//     //     return (qiblaAngle + 360) % 360;
//     // };
//     //
//     // const createDirectionArrow = (location, angle) => {
//     //     const arrowSymbol = {
//     //         path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
//     //         scale: 6,
//     //         strokeWeight: 2,
//     //         strokeColor: '#FFFFFF',
//     //         fillColor: '#FF3D00',
//     //         fillOpacity: 1,
//     //         rotation: angle
//     //     };
//     //
//     //     if (markerRef.current) {
//     //         markerRef.current.setMap(null);
//     //     }
//     //
//     //     markerRef.current = new window.google.maps.Marker({
//     //         position: location,
//     //         icon: arrowSymbol,
//     //         map: mapRef.current,
//     //         title: 'Qibla Direction'
//     //     });
//     // };
//     //
//     // const updateQiblaInfo = (currentLocation) => {
//     //     const qiblaAngle = calculateQiblaAngle(currentLocation);
//     //     const R = 6371; // Earth's radius in km
//     //     const lat1 = currentLocation.lat * Math.PI / 180;
//     //     const lon1 = currentLocation.lng * Math.PI / 180;
//     //     const lat2 = KAABA_COORDINATES.lat * Math.PI / 180;
//     //     const lon2 = KAABA_COORDINATES.lng * Math.PI / 180;
//     //
//     //     const dLat = lat2 - lat1;
//     //     const dLon = lon2 - lon1;
//     //     const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
//     //         Math.cos(lat1) * Math.cos(lat2) *
//     //         Math.sin(dLon/2) * Math.sin(dLon/2);
//     //     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
//     //     const distance = R * c;
//     //
//     //     setQiblaInfo({
//     //         angle: qiblaAngle.toFixed(2),
//     //         distance: distance.toFixed(2),
//     //         location: {
//     //             lat: currentLocation.lat.toFixed(6),
//     //             lng: currentLocation.lng.toFixed(6)
//     //         },
//     //         source: currentLocation.source
//     //     });
//     //
//     //     return qiblaAngle;
//     // };
//
//     const calculateQiblaAngle = (location) => {
//         // Convert coordinates to radians
//         const lat1 = location.lat * Math.PI / 180;
//         const lon1 = location.lng * Math.PI / 180;
//         const lat2 = KAABA_COORDINATES.lat * Math.PI / 180;
//         const lon2 = KAABA_COORDINATES.lng * Math.PI / 180;
//
//         // Calculate Δλ (difference in longitude)
//         const dLon = lon2 - lon1;
//
//         // Haversine formula components for initial bearing
//         const y = Math.sin(dLon) * Math.cos(lat2);
//         const x = Math.cos(lat1) * Math.sin(lat2) -
//             Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
//
//         // Calculate bearing and convert to degrees
//         let qiblaAngle = Math.atan2(y, x) * 180 / Math.PI;
//
//         // Normalize to 0-360° compass bearing
//         return (qiblaAngle + 360) % 360;
//     };
//
//     const createDirectionArrow = (location, angle) => {
//         const arrowSymbol = {
//             path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
//             scale: 6,
//             strokeWeight: 2,
//             strokeColor: '#FFFFFF',
//             fillColor: '#FF3D00',
//             fillOpacity: 1,
//             rotation: angle
//         };
//
//         if (markerRef.current) {
//             markerRef.current.setMap(null);
//         }
//
//         // Create the arrow marker
//         markerRef.current = new window.google.maps.Marker({
//             position: location,
//             icon: arrowSymbol,
//             map: mapRef.current,
//             title: 'Qibla Direction'
//         });
//     };
//
//     const updateQiblaInfo = (currentLocation) => {
//         const qiblaAngle = calculateQiblaAngle(currentLocation);
//
//         // Earth constants
//         const R = 6371; // Earth's radius in km
//         const f = 1/298.257223563; // WGS84 ellipsoid flattening
//
//         // Convert coordinates to radians
//         const lat1 = currentLocation.lat * Math.PI / 180;
//         const lon1 = currentLocation.lng * Math.PI / 180;
//         const lat2 = KAABA_COORDINATES.lat * Math.PI / 180;
//         const lon2 = KAABA_COORDINATES.lng * Math.PI / 180;
//
//         // Calculate using Vincenty's formulae for more accurate distance
//         const L = lon2 - lon1;
//         const tanU1 = (1-f) * Math.tan(lat1);
//         const cosU1 = 1 / Math.sqrt((1 + tanU1*tanU1));
//         const sinU1 = tanU1 * cosU1;
//         const tanU2 = (1-f) * Math.tan(lat2);
//         const cosU2 = 1 / Math.sqrt((1 + tanU2*tanU2));
//         const sinU2 = tanU2 * cosU2;
//
//         let lambda = L;
//         let iterLimit = 100;
//         let sinLambda, cosLambda, sinSigma, cosSigma, sigma, sinAlpha, cosSqAlpha, cos2SigmaM;
//
//         do {
//             sinLambda = Math.sin(lambda);
//             cosLambda = Math.cos(lambda);
//             sinSigma = Math.sqrt((cosU2*sinLambda) * (cosU2*sinLambda) +
//                 (cosU1*sinU2-sinU1*cosU2*cosLambda) * (cosU1*sinU2-sinU1*cosU2*cosLambda));
//
//             if (sinSigma === 0) break;
//
//             cosSigma = sinU1*sinU2 + cosU1*cosU2*cosLambda;
//             sigma = Math.atan2(sinSigma, cosSigma);
//             sinAlpha = cosU1 * cosU2 * sinLambda / sinSigma;
//             cosSqAlpha = 1 - sinAlpha*sinAlpha;
//
//             cos2SigmaM = cosSigma - 2*sinU1*sinU2/cosSqAlpha;
//             if (isNaN(cos2SigmaM)) cos2SigmaM = 0;
//
//             const C = f/16*cosSqAlpha*(4+f*(4-3*cosSqAlpha));
//             const lambdaP = lambda;
//             lambda = L + (1-C) * f * sinAlpha *
//                 (sigma + C*sinSigma*(cos2SigmaM+C*cosSigma*(-1+2*cos2SigmaM*cos2SigmaM)));
//
//             if (Math.abs(lambda-lambdaP) < 1e-12) break;
//         } while (--iterLimit > 0);
//
//         const distance = R * sigma; // Final distance in km
//
//         setQiblaInfo({
//             angle: qiblaAngle.toFixed(2),
//             distance: distance.toFixed(2),
//             location: {
//                 lat: currentLocation.lat.toFixed(6),
//                 lng: currentLocation.lng.toFixed(6)
//             },
//             source: currentLocation.source
//         });
//
//         return qiblaAngle;
//     };
//
//     const updateMapElements = (location) => {
//         // Clear existing lines
//         if (lineRef.current) {
//             lineRef.current.setMap(null);
//         }
//         if (glowLineRef.current) {
//             glowLineRef.current.setMap(null);
//         }
//
//         // Create new glow line
//         glowLineRef.current = new window.google.maps.Polyline({
//             path: [location, KAABA_COORDINATES],
//             geodesic: true,
//             strokeColor: '#FF3D00',
//             strokeOpacity: 0.3,
//             strokeWeight: 8,
//             map: mapRef.current,
//             zIndex: 0
//         });
//
//         // Create new main line
//         lineRef.current = new window.google.maps.Polyline({
//             path: [location, KAABA_COORDINATES],
//             geodesic: true,
//             strokeColor: '#FF3D00',
//             strokeOpacity: 1,
//             strokeWeight: 4,
//             map: mapRef.current,
//             zIndex: 1
//         });
//
//         // Update qibla info and get angle
//         const qiblaAngle = updateQiblaInfo(location);
//
//         // Create direction arrow
//         createDirectionArrow(location, qiblaAngle);
//
//         // Center and zoom map with proper perspective
//         mapRef.current.setZoom(INITIAL_ZOOM);
//         mapRef.current.panTo(location);
//
//         // Smooth transition to new heading and tilt
//         const heading = qiblaAngle - 90; // Offset heading to face Qibla direction
//         mapRef.current.setHeading(heading);
//         mapRef.current.setTilt(67); // Maximum tilt angle
//
//         // Adjust zoom for better perspective with high tilt
//         setTimeout(() => {
//             mapRef.current.setZoom(INITIAL_ZOOM - 2);
//         }, 100);
//     };
//
//     useEffect(() => {
//         const initializeMap = async () => {
//             try {
//                 // Create map instance
//                 mapRef.current = new window.google.maps.Map(document.getElementById('map'), {
//                     zoom: INITIAL_ZOOM,
//                     mapTypeId: 'satellite',
//                     mapTypeControl: true,
//                     mapTypeControlOptions: {
//                         style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
//                         position: window.google.maps.ControlPosition.TOP_RIGHT
//                     },
//                     zoomControl: true,
//                     zoomControlOptions: {
//                         position: window.google.maps.ControlPosition.RIGHT_CENTER
//                     },
//                     streetViewControl: false,
//                     fullscreenControl: true,
//                     tilt: 67,
//                     heading: 0
//                 });
//
//                 // Initialize search box
//                 const searchInput = document.getElementById('location-search');
//                 const searchBox = new window.google.maps.places.SearchBox(searchInput);
//
//                 // Listen for search results
//                 searchBox.addListener('places_changed', () => {
//                     const places = searchBox.getPlaces();
//                     if (places.length === 0) return;
//
//                     const place = places[0];
//                     const location = {
//                         lat: place.geometry.location.lat(),
//                         lng: place.geometry.location.lng(),
//                         source: 'Search'
//                     };
//
//                     setSearchQuery(place.formatted_address || place.name);
//                     updateMapElements(location);
//                 });
//
//                 // Get initial location from IP
//                 const response = await fetch('https://matwproject.org/ip-api');
//                 const data = await response.json();
//                 const location = {
//                     lat: parseFloat(data.lat),
//                     lng: parseFloat(data.lon),
//                     source: 'IP'
//                 };
//                 setLocationSource('IP');
//                 updateMapElements(location);
//
//             } catch (error) {
//                 console.error('Error initializing map:', error);
//             }
//         };
//
//         // Load Google Maps with Places library
//         const script = document.createElement('script');
//         script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAKENGLI-AM4g3jCzOZjkIggEeA6jsBb4Q&libraries=places`;
//         script.async = true;
//         script.onload = initializeMap;
//         document.head.appendChild(script);
//
//         return () => {
//             if (lineRef.current) lineRef.current.setMap(null);
//             if (glowLineRef.current) glowLineRef.current.setMap(null);
//             if (markerRef.current) markerRef.current.setMap(null);
//         };
//     }, []);
//
//     return (
//         <div className="relative w-screen h-screen">
//             <div className="absolute top-4 left-0 right-0 z-10 px-4">
//                 <div className="max-w-5xl mx-auto relative flex items-center">
//                     <div className="w-full mr-24">
//                         <input
//                             id="location-search"
//                             type="text"
//                             value={searchQuery}
//                             onChange={(e) => setSearchQuery(e.target.value)}
//                             placeholder="Search any location to find Qibla direction..."
//                             className="w-full px-4 py-3 pr-12 rounded-lg border border-gray-200 bg-white bg-opacity-90 focus:border-blue-500 focus:ring-2 focus:ring-blue-100 outline-none transition-all duration-200 text-gray-800 placeholder-gray-400 shadow-md"
//                         />
//                         <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400">
//                             <svg
//                                 xmlns="http://www.w3.org/2000/svg"
//                                 className="h-5 w-5"
//                                 fill="none"
//                                 viewBox="0 0 24 24"
//                                 stroke="currentColor"
//                             >
//                                 <path
//                                     strokeLinecap="round"
//                                     strokeLinejoin="round"
//                                     strokeWidth={2}
//                                     d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
//                                 />
//                             </svg>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//
//             <div id="map" className="w-full h-full" />
//
//             {qiblaInfo && (
//                 <div className="absolute bottom-4 left-4 bg-white bg-opacity-90 p-4 rounded-lg shadow-lg max-w-sm">
//                     <h2 className="text-lg font-semibold mb-2">Qibla Information</h2>
//                     <div className="space-y-2 text-sm">
//                         <p><span className="font-medium">Direction:</span> {qiblaInfo.angle}° from True North</p>
//                         <p><span className="font-medium">Distance to Kaaba:</span> {qiblaInfo.distance} km</p>
//                         <p><span className="font-medium">Your Location:</span></p>
//                         <p>Lat: {qiblaInfo.location.lat}, Lng: {qiblaInfo.location.lng}</p>
//                         <p className={`text-xs ${qiblaInfo.source === 'Manual' ? 'text-blue-600' : qiblaInfo.source === 'GPS' ? 'text-green-600' : 'text-orange-600'}`}>
//                             Location source: {qiblaInfo.source}
//                         </p>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };
//
// export default QiblaFinder

/*

* */