import { Link, useLocation } from "react-router-dom";
import { Builder } from '@builder.io/react';

/**
 * NotificationBar Component — Builder.io Compatible
 */
const NotificationBar = ({
  title = "Give for the last 10 nights",
  pageLink = "/30-steps-to-jannah",
  notificationIcon,
  notificationDownBtn,
  classNames = "",
  excludedRoutesCSV = '/checkout,/30-steps-to-jannah',
}) => {
  const location = useLocation();
  const excludedRoutes = excludedRoutesCSV.split(',').map(route => route.trim());

  if (excludedRoutes.includes(location.pathname)) {
    return null;
  }

  return (
    <Link
      to={pageLink}
      className={`block w-full mx-auto text-white bg-[#FF005D] font-signika text-[16px] leading-[28px] py-1 px-4 uppercase relative border-b-2 border-[#FFFFFF7D] ${classNames}`}
    >
      <div className="w-full flex items-center justify-center gap-1 sm:gap-3">
        {notificationIcon && (
          <img src={notificationIcon} alt="bell icon" className="flex-shrink-0 w-[20px] sm:w-[24px]" />
        )}
        <span className="hidden sm:block text-center">{title}</span>

        <div className="sm:hidden w-full overflow-hidden relative flex-1">
          <div className="notification-marquee-track flex">
            <span className="pr-4 whitespace-nowrap">{title}</span>
            <span className="pr-4 whitespace-nowrap">{title}</span>
          </div>
        </div>

        {notificationDownBtn && (
          <img src={notificationDownBtn} alt="arrow icon" className="rotate-[90deg] flex-shrink-0 w-[20px] sm:w-[24px]" />
        )}
      </div>
    </Link>
  );
};

export default NotificationBar;

Builder.registerComponent(NotificationBar, {
  name: 'NotificationBar',
  inputs: [
    {
      name: 'title',
      type: 'text',
      defaultValue: 'Give for the last 10 nights',
      friendlyName: 'Notification Title',
    },
    {
      name: 'pageLink',
      type: 'url',
      defaultValue: '/30-steps-to-jannah',
      friendlyName: 'Link URL',
    },
    {
      name: 'notificationIcon',
      friendlyName: 'Notification Icon',
      type: 'file',
      required: true,
      allowedFileTypes: ['png', 'jpg', 'jpeg', 'svg'],
      description: 'Bell Icon for the banner',
    },
    {
      name: 'notificationDownBtn',
      friendlyName: 'Right Arrow Icon',
      type: 'file',
      required: true,
      allowedFileTypes: ['png', 'jpg', 'jpeg', 'svg'],
      description: 'Bell Icon for the banner',
    },
    {
      name: 'classNames',
      type: 'text',
      friendlyName: 'Extra CSS Classes',
    },
    {
      name: 'excludedRoutesCSV',
      type: 'text',
      defaultValue: '/checkout,/30-steps-to-jannah',
      friendlyName: 'Excluded Routes (comma-separated)',
    }
  ]
});
