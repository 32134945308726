import React from 'react';
import { BiCalendar } from 'react-icons/bi';
import Crypto from '../../images/others/matw-crypto.jpg';
import RamadanBlessing from '../../images/ramadan/ramadan-blessings.png';
import Ramadan2024 from '../../images/ramadan/ramadan-2024 (2).png';
import { useNavigate } from 'react-router-dom';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import SeoData from '../../utils/seo.json';
import { Helmet } from 'react-helmet-new';

const NewsPage = () => {
  /* ----------------------------- Start variables ---------------------------- */

  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  return (
    <NewHelmetLayout className="bg-white">
      {seo &&
        <Helmet>
          {/* <title>{`${seo['fitrana']['title']}`}</title> */}
          <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
        
          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
      }

      <main className="max-w-[1440px] mx-auto">
        <div className="md:container md:mx-auto mt-12">
          <section>
            <div className="text-[#253B7E] mb-3 leading-[27px] sm:leading-[55px] bold text-center text-[24px] sm:text-[48px]">
              Latest News - Stay In The Loop
            </div>
            <p className="text-[#78716C] text-center leading-[20px] sm:leading-[30px] font-brandingMedium text-[16px] sm:text-[20px]">
              Keep up with all the new things that are happening at MATW Project. Be the first to know, be the change!
            </p>
          </section>

          <section className="lg:px-10 mt-10">
            <div className="flex gap-x-4 gap-y-3 flex-wrap sm:flex-nowrap">
              <div className="order-2 sm:order-1 px-4 basis-full sm:basis-1/2 flex flex-col items-start justify-center">
                <div className="bg-[#00A3DA] text-[16px] lg:text-[20px] font-brandingMedium text-[white] inline p-1 px-2 rounded-md">Crypto</div>
                <div className="text-[24px] leading-[30px] lg:text-[36px] lg:leading-[42px] text-[#093484] font-brandingBold my-3">
                  Big News! MATW Project Now <br />
                  Accepts
                  <br />
                  Crypto Donations. Try It Out Today!
                </div>
                <div className="text-[#F60362] mb-3 flex text-[16px] items-center font-brandingMedium">
                  <BiCalendar color="#F60362" size={25} />
                  <span>29 February 2024</span>
                </div>
                <p className="text-[14px] leading-[20px] lg:text-[20px] lg:leading-[30px] mb-3 font-brandingMedium">
                  Simplify your giving experience with MATW Project.
                  <br />
                  Explore the world of crypto donations today!
                </p>
                <button
                  onClick={() => navigate(generateLinkWithLang('/', lang))}
                  className="text-[16px] lg:text-[20px] bg-[#F60362] text-white font-bold rounded lg:rounded-[10px] px-5 lg:px-10 py-2"
                >
                  Donate Now
                </button>
              </div>
              <div className="order-1 sm:order-2 basis-full sm:basis-1/2">
                <img src={Crypto} alt="crypto" />
              </div>
            </div>
          </section>
        </div>
        <section className="bg-[#00A3DA33]/20">
          <div className="lg:px-10 md:container md:mx-auto mt-12">
            <div className="flex gap-x-4 gap-y-3 flex-wrap sm:flex-nowrap">
              <div className="order-2 sm:order-1 px-4 basis-full sm:basis-1/2 flex flex-col items-start justify-center">
                <div className="text-[16px] lg:text-[20px] bg-[#00A3DA] font-brandingMedium text-[white] inline p-1 px-2 rounded-md">
                  Get involved
                </div>
                <div className="text-[24px] leading-[30px] lg:text-[36px] sm:leading-[42px] text-[#093484] font-brandingBold my-3">
                  Ramadan Blessings: Fundraise & Volunteer with us!
                </div>
                <div className="text-[#F60362] mb-3 flex text-[16px] items-center font-brandingMedium">
                  <BiCalendar color="#F60362" size={25} />
                  <span>29 February 2024</span>
                </div>
                <p className="text-[14px] leading-[20px] lg:text-[20px] lg:leading-[30px] mb-3 font-brandingMedium">
                  Make Ramadan count with MATW Project!
                  <br />
                  Fundraise and volunteer for a greater impact. Sign up today and transform lives!
                </p>
                <div className="flex gap-x-4 mb-4 sm:mb-0">
                  <button
                    onClick={() => navigate(generateLinkWithLang('/fundraising', lang))}
                    className="text-[16px] lg:text-[20px] bg-[#F60362] text-white font-bold rounded lg:rounded-[10px] px-3 xl:px-10 py-2"
                  >
                    Fundraise With Us
                  </button>
                  <button
                    onClick={() => navigate(generateLinkWithLang('/Volunteer', lang))}
                    className="text-[16px] lg:text-[20px] bg-[#F60362] text-white font-bold rounded lg:rounded-[10px] px-3 xl:px-10 py-2"
                  >
                    Volunteer With Us
                  </button>
                </div>
              </div>
              <div className="order-1 sm:order-2 basis-full sm:basis-1/2 mt-5">
                <img src={RamadanBlessing} alt="ramadan-blessing" />
              </div>
            </div>
          </div>
        </section>

        <section className="px-4 lg:px-10 mt-10 md:container md:mx-auto">
          <div className="flex gap-x-4 gap-y-8  flex-wrap sm:flex-nowrap">
            <div className="basis-full sm:basis-1/2 drop-shadow-md">
              <img src={Ramadan2024} alt="ramadan" />
            </div>
            <div className="sm:pl-6 basis-full sm:basis-1/2 flex flex-col items-start justify-center">
              <div className="bg-[#00A3DA] text-[16px] lg:text-[20px] font-brandingMedium text-[white] inline p-1 px-2 rounded-md">Ramadan</div>
              <div className="text-[24px] leading-[30px] lg:text-[36px] lg:leading-[42px] text-[#093484] font-brandingBold my-3">
                Ramadan 2024: Help Prevent Suffering, Donate Today!
              </div>
              <div className="text-[#F60362] mb-3 flex text-[16px] items-center font-brandingMedium">
                <BiCalendar color="#F60362" size={25} />
                <span>29 February 2024</span>
              </div>
              <p className="text-[14px] leading-[20px] lg:text-[20px] lg:leading-[30px] mb-3 font-brandingMedium">
                Do you want to see our Muslim brothers and sisters suffer this Ramadan? Neither do we. Let's unite and support those in need. Your
                donation can bring hope and blessings. What are you waiting for?
              </p>
              <button
                onClick={() => navigate(generateLinkWithLang('/ramadan', lang))}
                className="text-[16px] lg:text-[20px] bg-[#F60362] text-white font-bold rounded lg:rounded-[10px] px-5 lg:px-10 py-2"
              >
                Donate Now
              </button>
            </div>
          </div>
        </section>
      </main>
    </NewHelmetLayout>
  );
};

export default NewsPage;
