import React, { useEffect } from 'react';
// import Layout from '../components/general/layout';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import { useSelector } from 'react-redux';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
export default function SocialMedia() {

  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' });
    // window.scroll(0,0);
    window.scroll(0,0);
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout>
      {seo &&
                <Helmet>
                    <title>{`${seo['resourcesAndInformation']['socialMedia']['title']}`}</title>
                    <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
                    <meta name="description" content={`${seo['resourcesAndInformation']['socialMedia']['description']}`} />
                
          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
            }
      <div className="w-full flex flex-col">
        <div className="w-full flex flex-col items-center justify-center">
          <h1 className="text-[#23408f] text-center text-3xl mt-8 font-bold">
            <strong>Social Media</strong>
          </h1>
          <p className="text-center mt-2 mb-6 text-[#777] text-base font-black ">
            <strong>Not all support is through monetary value!</strong>
          </p>
          <p className="text-center text-base text-[#777]">
            Social media has become a global platform for us all to connect on, our work has reached thousands of people through our platforms. We
            appreciate all of our supporters for regularly supporting us through –
          </p>
        </div>

        <div className="mt-8	w-full flex justify-center">
          <div className="flex ">
            <a href="https://www.facebook.com/matwproject/" target="_blank" title="Facebook" rel="noreferrer">
              <img src="/images/social-media/facebook.png" alt="facebook" className=" w-6 h-10 mr-4" />
            </a>
            <a href="https://www.instagram.com/matw_project/?hl=en" target="_blank" title="Instagram" rel="noreferrer">
              <img src="/images/social-media/instagram.png" alt="instagram" className=" w-10 h-10   mr-4" />
            </a>

            <a href="https://twitter.com/matwproject1" target="_blank" title="Twitter" rel="noreferrer">
              <img src="/images/social-media/twitter.png" alt="twitter" className=" w-10 h-10 mr-4" />
            </a>

            <a href="https://www.linkedin.com/company/matwproject/" target="_blank" title="LinkedIn" rel="noreferrer">
              <img src="/images/social-media/linkedin.png" alt="linkedin" className=" w-10 h-10 mr-4" />
            </a>

            <a href="https://www.youtube.com/channel/UCfP_6dLyW8jMpJ5YXf-mzaw" target="_blank" title="YouTube" rel="noreferrer">
              <img src="/images/social-media/youtube.png" alt="youtube" className=" w-10 h-10 mr-4" />
            </a>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row flex-wrap justify-center items-center mt-8">
          <iframe
            className="px-4"
            width="329"
            height="168"
            src="https://www.youtube.com/embed/Maf3XXrzhGI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            className="px-4"
            width="329"
            height="168"
            src="https://www.youtube.com/embed/_qI5MnJB3vE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>

          <iframe
            className="px-4"
            width="329"
            height="168"
            src="https://www.youtube.com/embed/R_xcKNqVi-M"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            className="px-4"
            width="329"
            height="168"
            src="https://www.youtube.com/embed/L1P2KrNsDMc"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </NewHelmetLayout>
  );
}
