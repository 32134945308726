import React, { useEffect, useState } from "react";
import useCart from "../../hooks/cart/useCart";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedCurrency,
  selectUserIP,
  setUserIP,
} from "../../store/user";
import { useNavigate } from "react-router-dom";
import { AsYouType, isValidPhoneNumber } from "libphonenumber-js";
import { Formik, useFormik } from "formik";
import { getCookie } from "../../utils/cookie";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import CheckBox from "../general/checkbox";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BASKET_CACHE_KEY,
  HandleLocalStorage,
} from "../../utils/handleLocalStorage";
import services from "../../services";
import HandleShowRecurring from "../../utils/showRecurringOnDataLayer";
import IsGTMScriptLoaded from "../../utils/isGTMScriptLoaded";
import { sha256 } from "js-sha256";
import {
  AdyenCheckout,
  Dropin,
  Card,
  ApplePay,
  GooglePay,
  Bancontact,
  EPS,
} from "@adyen/adyen-web";
import googlePayLogo from "../../images/general/gpay-icon.svg";
import applePayLogo from "../../images/general/apple-pay.svg";
import AdyenModal from "./components/aydenModal";
import AdminCosts from "./components/adminCosts";
import AddressField from "./components/addressField";
import { useStripe } from "@stripe/react-stripe-js";
import ConfirmModal from "./components/confirmModal";
import useIsExactDomain from "../../hooks/cart/useIsExactDomain";

const LOG_ENDPOINT =
  "https://matw.finbix.io/254fd2a6-5977-40be-8fad-95b5edfa0c98/api/checkout/logs";

export const OneClickCheckout = () => {
  // Data Related States
  const { basketStates, setBasketStates, notifyStorageUpdate } = useCart();

  // User and Product States Starts
  const [tempUser, setTempUser] = useState({});
  const [tempDonor, setTempDonor] = useState({});
  const [tempProduct, setTempProduct] = useState({});
  const userIP = useSelector(selectUserIP);
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState("AU");
  const [countryName, setCountryName] = useState("Australia");
  const [selectedSubscribe, setSelectedSubscribe] = useState("one-off");
  const [userProductsCount, setUserProductsCount] = useState(0);
  const [stripeCustomerId, setStripeCustomerId] = useState("");
  const [ciDialCode, setCiDialCode] = useState(false);

  // User and Product States Ends
  const campaignData = getCookie("campaign_data");
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("Card");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [pastedValue, setPastedValue] = useState("");
  const [isUkTaxPayer, setIsUkTaxPayer] = useState(false);
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const isUsa = useIsExactDomain('us.matwproject.org');
  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);
  const [city, setCity] = useState('')
  const [postalCode, setpostalCode] = useState('')

  const paymentsFlow = [
    { value: "UK", label: "I am a UK citizen" },
    { value: "USD", label: "I am a US Tax payer" },
    { value: "AUD", label: "I am an Australian citizen" },
    { value: "OTHER", label: "None of the above or remain private" },
  ];
  const [selectedPaymentFlow, setSelectedPaymentFlow] = useState(
    paymentsFlow[3],
  );

  // ------------------------------------------------ Formik ------------------------------------

  const postalCodeRegExp =
    /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})|[A-Z]{1,2}\d{1,2}(?:\s?\d?[A-Z]{0,2})?$/;
  const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(emailRegExp, "Invalid email address")
      .required("Enter your email address"),
    phone: Yup.string().required("Phone number is required"),
    isUkTaxPayer: Yup.boolean(),
    address: Yup.string().when("isUkTaxPayer", {
      is: (value) => value === true,
      then: (validationSchema) =>
        validationSchema.required("Enter your home address"),
      otherwise: (validationSchema) => validationSchema,
    }),
    city: Yup.string().when("isUkTaxPayer", {
      is: (value) => value === true,
      then: (validationSchema) => validationSchema.required("Enter your city"),
      otherwise: (validationSchema) => validationSchema,
    }),
    postal_code: Yup.string().when("isUkTaxPayer", {
      is: (value) => value === true,
      then: (validationSchema) =>
        validationSchema
          .matches(postalCodeRegExp, "Invalid postal code")
          .required("Enter your postal code"),
      otherwise: (validationSchema) => validationSchema,
    }),
  });

  const handleOnPaste = (event) => {
    const pastedData = event.clipboardData.getData("Text");
    setPastedValue(pastedData);
  };

  const handlePhoneValidation = (value, country, setFieldValue) => {
    let inputValue = value.startsWith("+") ? value : `+${value}`;
    const asYouType = new AsYouType(country.iso2);
    let formattedPhone = asYouType.input(inputValue);

    if (country.dialCode === "44" && formattedPhone.startsWith("+44 0")) {
      formattedPhone = `+44 ${formattedPhone.slice(5)}`;
    }

    const isValid = isValidPhoneNumber(formattedPhone, country.iso2);
    setIsPhoneValid(isValid);
    setTempUser((prev) => ({
      ...prev,
      phone: formattedPhone,
      phone_country_code: country.dialCode,
    }));
    setTempDonor((prev) => ({
      ...prev,
      phone: formattedPhone,
      phone_country_code: country.dialCode,
    }));
    setFieldValue("phone", formattedPhone);
    setPastedValue("");
    setPhoneError(
      isValid ? "" : "Invalid phone number. Please check and try again.",
    );
  };

  const getAdyenSession = async (tempUser, tempDonor, tempProduct) => {
    try {
      setIsloading(true);
      console.log("tempUser", tempUser);
      let tempResponse = await services.createNewUser(tempUser);
      const newCustomerId = tempResponse.data.user.id;
      setStripeCustomerId(newCustomerId);
      const updatedBasketState = {
        ...basketStates,
        stripe_customer_id: tempResponse.data.user.id,
        paymentFlow: selectedPaymentFlow.value,
        user: {
          ...basketStates?.user,
          ...tempUser,
          id: tempResponse.data.user.id,
          user_id: tempResponse.data.user.user_id,
        },
        donor: {
          ...basketStates?.donor,
          ...tempDonor,
        },
      };

      const updatedProduct = {
        ...tempProduct,
        stripe_customer_id: tempResponse.data.user.id,
        payment_flow: updatedBasketState.user.payment_flow,
      };
      setTempProduct(updatedProduct);

      //   console.log("tempProduct", tempProduct);
      console.log("Updated tempProduct", updatedProduct);

      setBasketStates(updatedBasketState);
      HandleLocalStorage(updatedBasketState);
      notifyStorageUpdate();
      handlePaymentMethodDataLayer("Adyen", selectedMethod, tempUser);

      try {
        let response = await services.getSessionId(updatedProduct); // Use updated product
        initializeAdyenCheckout(response.data.checkout_session);
        console.log("adyen response=", updatedProduct);
        setIsloading(false);
      } catch (error) {
        setIsloading(false);
        const err = error?.response?.data?.errors;
        console.log(err, error);
        if (error?.response?.status === 409) {
          setShowConfirmModal(true);
        }
        if (Object.keys(err).length > 0) {
          for (const key in err) {
            notify(err[key][0]);
          }
        } else {
          notify("Something went wrong! please try later");
        }
        console.error("error========", error);
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  const stripe = useStripe();

  const handleStripe = id => {
    // setIsloading(true);
    if (id) {
      // HandleLocalStorage(basketStates,true);
      stripe.redirectToCheckout({
        sessionId: id,
      });
      setIsloading(false);
    } else {
      setIsloading(false);
      console.log('error');
    }
  };

  const getStripeSession = async (tempUser, tempDonor, tempProduct) => {
    try {
      setIsloading(true);
      let tempResponse = await services.createNewUser(tempUser);
      const newCustomerId = tempResponse.data.user.id;
      setStripeCustomerId(newCustomerId);
      
      const updatedBasketState = {
        ...basketStates,
        stripe_customer_id: newCustomerId,
        paymentFlow: selectedPaymentFlow.value,
        user: {
          ...basketStates?.user,
          ...tempUser,
          id: newCustomerId,
          user_id: tempResponse.data.user.user_id,
        },
        donor: {
          ...basketStates?.donor,
          ...tempDonor,
        },
      };

      const updatedProduct = {
        ...tempProduct,
        stripe_customer_id: newCustomerId,
        payment_flow: updatedBasketState.user.payment_flow,
      };
      setTempProduct(updatedProduct);

      console.log("Updated tempProduct", updatedProduct);

      setBasketStates(updatedBasketState);
      HandleLocalStorage(updatedBasketState);
      notifyStorageUpdate();
      handlePaymentMethodDataLayer("Stripe", selectedMethod, tempUser);

      try {
        let response = await services.getSessionId(updatedProduct); // Use updated product
        console.log('response getSessionId', response.data, response.data.checkout_session.id);
        handleStripe(response.data.checkout_session.id);
        console.log("stripe response=", updatedProduct);
        setIsloading(false);
      } catch (error) {
        setIsloading(false);
        const err = error?.response?.data?.errors;
        console.log(err, error);
        if (error?.response?.status === 409) {
          setShowConfirmModal(true);
        }
        if (Object.keys(err).length > 0) {
          for (const key in err) {
            notify(err[key][0]);
          }
        } else {
          notify("Something went wrong! please try later");
        }
        console.error("error========", error);
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
};

  const initializeAdyenCheckout = async (obj) => {
    let session;
    let identity;
    let checkout_identity;

    try {
      session = obj.gateway_data.session_data;
      identity = obj.payment_intent;
      checkout_identity = obj.checkout_identity;
    } catch (sessionError) {
      console.error("Session creation error:", sessionError);
      await sendErrorToServer(
        `Session creation error: ${sessionError.message || sessionError}`,
      );
      throw sessionError;
    }

    try {
      setShowModal(true);
      const checkout = await AdyenCheckout({
        clientKey: "live_4FZWD44MJRFZDDWPNJBQKFJP6AIY6GRZ", // Replace with your actual Adyen client key
        // clientKey: "test_PFLI6FNNKFDXHMH544MRVCABNUHRZVHA", // Replace with your actual Adyen client key
        session: {
          id: identity, // Unique identifier for the payment session.
          sessionData: session, // The payment session data.
        },
        environment: "live", // Use "live" for production
        // environment: "test", // Use "live" for production
        countryCode: countryCode || "AE", // Replace "AE" with the actual country code if available
        locale: "en-US",
        onPaymentCompleted: (result) => {
          if (result.resultCode === "Authorised") {
            handleSendAdyenResultOnSuccess(
              result.sessionResult,
              checkout_identity,
              identity,
            );
          }
          setShowModal(false);
          // Handle success here (e.g., navigate to success page)
        },
        onPaymentFailed: async (result) => {
          // Log the payment failure
          const errorMessage = `Payment failed with resultCode: ${result.resultCode}`;
          console.error(errorMessage);
          await sendErrorToServer(errorMessage);

          if (result.resultCode === "Refused") {
            notify(
              "Your bank declined the donation payment. Please check your card details and try again.",
            );
          }
          if (result.resultCode === "Cancelled") {
            notify(
              "Your bank cancelled the donation payment. Please check your card details and try again.",
            );
          }
          if (result.resultCode === "Error") {
            notify("There was an error during the donation payment.");
          }
          setShowModal(false);
          // Handle failure here (e.g., display error message to user)
        },

        onError: async (error) => {
          setShowModal(false);
          console.error("Payment error:", error);
          await sendErrorToServer(`Payment error: ${error.message || error}`);
          //notify('Payment error occurred. Please try again.');
        },
      });

      // Initialize Dropin after ensuring the container exists
      await initializeDropin(checkout);
    } catch (error) {
      setShowModal(false);
      console.error("Adyen initialization error:", error);
      await sendErrorToServer(
        `Adyen initialization error: ${error.message || error}`,
      );
      throw error;
    }
  };

  const initializeDropin = async (checkout) => {
    try {
      // Check if 'dropin-container' exists
      let dropinContainer = document.getElementById("dropin-container");

      if (!dropinContainer) {
        // If it doesn't exist, ensure the modal is shown
        setShowModal(true);

        // Wait for the 'dropin-container' to appear with a timeout
        dropinContainer = await waitForElement("#dropin-container", 5000); // 5 seconds timeout
      }

      // Initialize and mount Dropin
      const dropin = new Dropin(checkout, {
        paymentMethodComponents: [Card, ApplePay, GooglePay, Bancontact, EPS],
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true, // Enable the Cardholder Name field
            holderNameRequired: true, // Make the Cardholder Name field required
          },
        },
        instantPaymentTypes: ["card", "applepay", "googlepay"],
      }).mount(dropinContainer);

      // Optionally, handle Dropin events here
    } catch (error) {
      console.error("Error mounting Adyen Dropin:", error);
      await sendErrorToServer(
        `Error mounting Adyen Dropin: ${error.message || error}`,
      );
      //notify('Failed to initialize payment interface. Please try again.');

      // Optionally, hide the modal if Dropin initialization fails
      setShowModal(false);
    }
  };

  const handlePaymentMethodDataLayer = (method, paymentType, tempUser) => {
    let tempItems = basketStates?.products.map((item) => {
      return {
        item_id: item.product.creator,
        item_name: item.product.name,
        item_brand: item.product.category || "",
        item_category: item.product.category || "",
        item_description: item.product.description || "",
        price: item.amount,
        quantity: item.quantity,
      };
    });
    let dataLayerTemp = {
      event: "add_payment_method",
      name: basketStates?.user.name,
      email: basketStates?.user.email,
      phone: basketStates?.user.phone,
      user_email_hashed: sha256(basketStates?.user.email || tempUser.email),
      donor_id: basketStates?.user.user_id || tempUser?.user_id,
      pay_id: stripeCustomerId,
      frequency: HandleShowRecurring(basketStates?.recurring),
      ecommerce: {
        value: basketStates?.grandTotal,
        tax: basketStates?.adminCost,
        currency: basketStates?.currency.toUpperCase(),
        shipping_tier: method,
        payment_type: paymentType,
        items: tempItems,
      },
    };
    window.dataLayer = window.dataLayer || [];
    IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
  };

  // Helper Handlers
  const sendErrorToServer = async errorText => {
    // Retrieve the host from the current window location
    const host = window.location.host;

    // Append the host to the original error text
    const errorWithHost = `${errorText} Host: ${host}`;

    try {
      await fetch(LOG_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // Send the error along with the host information
        body: JSON.stringify([{ error: errorWithHost }]),
      });
      if (!errorWithHost.startsWith('Payment failed with resultCode:') && !errorWithHost.startsWith('Payment error: ApplePay UI dismissed Host')) {
        getStripeSession(tempUser,tempDonor,tempProduct);
      }
    } catch (loggingError) {
      console.error('Failed to send error log to server:', loggingError);
    }
  };

  const waitForElement = (selector, timeout = 5000) => {
    return new Promise((resolve, reject) => {
      const intervalTime = 100; // Check every 100ms
      let elapsedTime = 0;

      const interval = setInterval(() => {
        const element = document.querySelector(selector);
        if (element) {
          clearInterval(interval);
          resolve(element);
        } else {
          elapsedTime += intervalTime;
          if (elapsedTime >= timeout) {
            clearInterval(interval);
            reject(
              new Error(`Element ${selector} not found within ${timeout}ms`),
            );
          }
        }
      }, intervalTime);
    });
  };

  const notify = (text) =>
    toast.error(text, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const handleSendAdyenResultOnSuccess = async (
    session_result,
    checkout_identity,
    identity,
  ) => {
    try {
      await services.sendAdyenResult(checkout_identity, session_result);
      localStorage.removeItem(BASKET_CACHE_KEY);
      navigate(
        `/success?gateway=adyen&currency=${basketStates?.currency}&session_id=${checkout_identity}&transaction_id=${identity}`,
      );
    } catch (error) {
      console.error("Error sending Adyen result:", error);
      notify("We encountered an issue processing your transaction.");
    }
  };

  const checkIsIOS = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAppleDevice = /iphone|ipad|ipod/.test(userAgent);
    const isSafari =
      userAgent.includes("safari") && !userAgent.includes("chrome");

    if (isAppleDevice && isSafari) {
      setSelectedMethod("Apple Pay");
    } else if (isAppleDevice) {
      setSelectedMethod("Card");
    } else {
      setSelectedMethod("Google Pay");
    }
  };

  useEffect(() => {
    fetch("https://matwproject.org/ip-api")
      .then((res) => res.json())
      .then((response) => {
        dispatch(setUserIP(response.query));
        setCountryCode(response.countryCode);
        setCountryName(response.country);
        setCity(response.regionName);
        setpostalCode(response.zip);

        let tempPaymentFlow = basketStates.paymentFlow
          ? basketStates.paymentFlow
          : "OTHER";
        switch (response.countryCode) {
          case "AU":
            tempPaymentFlow = "AUD";
            break;
          case "US":
            tempPaymentFlow = "USD";
            break;
          default:
            tempPaymentFlow = "OTHER";
        }
        if (response.timezone.includes("Europe")) {
            if (response.countryCode === "GB") {
            tempPaymentFlow = "UK";
            //   setIsUkTaxPayer(true);
          } else {
            tempPaymentFlow = "OTHER";
          }
        }
        paymentsFlow.map((item) => {
          if (item.value === tempPaymentFlow) {
            setSelectedPaymentFlow(item);
          }
          return item;
        });
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
    checkIsIOS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIP, basketStates]);

  useEffect(() => {
    if (isUkTaxPayer) {
      setSelectedPaymentFlow(paymentsFlow[0]); // UK when checked
    } else {
      setSelectedPaymentFlow(paymentsFlow[3]); // Reset to "OTHER" when unchecked
    }
  }, [isUkTaxPayer]);

  useEffect(() => {
    const checkGoogleMapsReady = () => {
      if (
        typeof window !== "undefined" &&
        window.google &&
        window.google.maps &&
        window.google.maps.places
      ) {
        setIsGoogleScriptLoaded(true);
      } else {
        const interval = setInterval(() => {
          if (
            window.google &&
            window.google.maps &&
            window.google.maps.places
          ) {
            setIsGoogleScriptLoaded(true);
            clearInterval(interval);
          }
        }, 300);
  
        setTimeout(() => {
          clearInterval(interval);
        }, 10000);
      }
    };
  
    checkGoogleMapsReady();
  }, []);
  

  const handleOptionChange = (value) => {
    // Detect device if no value is provided
    if (!value) {
      const userAgent = navigator.userAgent;

      if (/iPad|iPhone|iPod/.test(userAgent)) {
        value = "Apple Pay"; // Default to Apple Pay for iOS devices
      } else if (/Android/.test(userAgent)) {
        value = "Google Pay"; // Default to Google Pay for Android devices
      } else {
        value = "Card"; // Default to Card for other devices
      }
    }
  };

  const handleSubmit = (values) => {
    if (!isPhoneValid) {
      notify('Please enter a valid phone number.');
      return;
    }
    const updatedUser = {
      ...tempUser,
      ...values,
      isGiftAid: isUkTaxPayer,
      payment_flow: selectedPaymentFlow.value,
      currency: selectedCurrencyStore,
      acceptSms: isConsentChecked,
      countryCode:countryCode,
      country : values?.country || countryName,
      host: window.location.protocol + "//" + window.location.host,
      ip : userIP,
      city : values?.city || city,
      postal_code : values?.postal_code || postalCode,
    };

    const updatedDonor = {
      ...tempDonor,
      ...values,
      phone: tempUser?.phone,
      isGiftAid: isUkTaxPayer,
      payment_flow: selectedPaymentFlow.value,
      currency: selectedCurrencyStore,
      isConsentChecked:isConsentChecked,
      countryCode:countryCode,
      country : values?.country || countryName,
      host: window.location.protocol + "//" + window.location.host,
      ip : userIP,
      city : values?.city || city,
      postal_code : values?.postal_code || postalCode,
    };

    let tempAdyen = [];

    basketStates?.products.forEach((obj) => {
      if (obj.product) {
        tempAdyen.push({
          id: obj.product.creator,
          quantity: obj.quantity,
          recurring: obj.recurring,
          plaque_name: obj.onBehalf,
          url: obj.landing_page || "",
        });
      }
    });

    if (basketStates?.adminCost) {
      tempAdyen.push({
        id: "INT-ADM-OVH-2023",
        quantity: basketStates?.adminCost,
        recurring: basketStates?.recurring,
        plaque_name: "For the sake of Allah SWT",
      });
    }

    const updatedProduct = {
      currency: selectedCurrencyStore,
      mode: basketStates?.mode || "payment",
      products: tempAdyen,
      referal: document.referrer,
      plaque_name:
        tempAdyen.find(
          (item) => item.plaque_name !== "For the sake of Allah SWT",
        )?.plaque_name ?? "",
      payment_flow: selectedPaymentFlow.value,
      host: window.location.protocol + "//" + window.location.host,
      ip: userIP || "",
      gateway: isUsa ? "stripe" : "adyen",
      ...(campaignData || {}),
    };

    // Update states
    setTempUser(updatedUser);
    setTempDonor(updatedDonor);
    setTempProduct(updatedProduct);

    // Call getAdyenSession after states are updated
    // getAdyenSession(updatedUser, updatedDonor, updatedProduct);
    isUsa ? getStripeSession(updatedUser, updatedDonor, updatedProduct) : getAdyenSession(updatedUser, updatedDonor, updatedProduct);
  };


  const handleChildData = (data, setFieldValue) => {
    setFieldValue("postal_code", data.postalCode);

    setTempUser((prevUser) => ({
      ...prevUser,
      city: data.locality,
      postal_code: data.postalCode,
      address: data.streetAddress,
    }));

    setTempDonor((prevDonor) => ({
      ...prevDonor,
      city: data.locality,
      postal_code: data.postalCode,
      address: data.streetAddress,
    }));
  };

  return (
    <div className="flex flex-col gap-2">
      {/* <AdminCosts
        basketStates={basketStates}
        setBasketStates={setBasketStates}
        selectedSubscribe={selectedSubscribe}
        currency={
          selectedCurrencyStore ? selectedCurrencyStore : basketStates?.currency
        }
        onChange={(e) => handleAdminCost(e)}
        isSheet={true}
      /> */}
       {showConfirmModal && (
              <ConfirmModal
                show={showConfirmModal}
                confirm={() => {
                  getStripeSession(tempUser, tempDonor, tempProduct);
                  setShowConfirmModal(false);
                }}
                closeModal={() => setShowConfirmModal(false)}
              />
            )}
      <Formik
        initialValues={{
          email: tempUser?.email || "",
          phone: tempUser?.phone || "",
          address: tempUser?.address || "",
          city: tempUser?.city || "",
          country: tempUser?.country || "",
          postal_code: tempUser?.postal_code || "",
          isUkTaxPayer: isUkTaxPayer,
          isConsentChecked: isConsentChecked,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-2">
              <div className="z-[11]">
                <label className="text-sm md:text-md text-white w-full">
                  <PhoneInput
                    name="phone"
                    className="min-h-[40px] md:min-h-[48px] w-full [&>input]:!w-full [&>input]:!text-base [&>input]:!border-white text-[#777] [&>input]:!rounded-none [&>div.flag-dropdown]:!border-white [&>div.flag-dropdown]:!rounded-none"
                    id="phone"
                    placeholder="Enter your phone number"
                    onBlur={(val) => {
                      let e = {
                        target: { value: val, name: "phone", id: "phone" },
                      };
                      handleBlur(e);
                    }}
                    inputClass={"!w-full"}
                    country={countryCode.toLowerCase()}
                    value={values.phone}
                    autoFormat={ciDialCode}
                    onChange={(val, country) => {
                      let e = {
                        target: { value: val, name: "phone", id: "phone" },
                      };
                      setCiDialCode(country.dialCode !== '225');
                      handlePhoneValidation(
                        pastedValue && pastedValue.length > 0
                          ? pastedValue.startsWith(`+${country.dialCode}`)
                            ? pastedValue
                            : `${country.dialCode}${pastedValue}`
                          : val,
                        country,
                        setFieldValue,
                      );
                      handleChange(e);
                    }}
                    inputProps={{
                      onPaste: handleOnPaste,
                    }}
                  />
                  {(phoneError || errors.phone) && (
                    <small id="phone-error" className="text-[#fff] text-sm">
                      {phoneError ? phoneError : errors.phone}
                    </small>
                  )}
                </label>
              </div>
              <div className="">
                <label className="text-sm md:text-md text-white">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    className="!rounded-none min-h-[40px] md:min-h-[48px] w-full text-base md:text-md border border-white bg-white py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear text-[#777]"
                    onBlur={handleBlur}
                    value={values.email}
                    onChange={handleChange}
                  />
                  {touched.email && errors.email && (
                    <small id="email-error" className="text-[#fff] text-sm">
                      {errors.email}
                    </small>
                  )}
                </label>
              </div>
              <div className="flex items-start">
                <CheckBox
                  name="consentcheckbox"
                  value={isConsentChecked}
                  checked={isConsentChecked}
                  setter={(e) => setIsConsentChecked(e)}
                  inputStyle="h-[20px] w-[20px] !rounded-none !border-transparent"
                >
                  <span className="text-white text-sm sm:text-base">
                    I agree to receive Email, WhatsApp/SMS text messages. Reply
                    STOP to cancel.
                    {/* <a
                      className="text-[#fff] pl-1"
                      target={"_blank"}
                      href="/privacypolicy/"
                    >
                      {" "}
                      <span className="underline">See Privacy Policy.</span>
                    </a> */}
                  </span>
                </CheckBox>
              </div>
              {countryCode === "GB" && (
                <div className="col-span-3 sm:col-span-2 md:col-span-1">
                  <div className="flex items-center">
                    <CheckBox
                      name="isUkTaxPayer"
                      checked={isUkTaxPayer} 
                      className="text-white !text-sm sm:!text-base"
                      inputStyle="h-[20px] w-[20px] !rounded-none !border-transparent"
                      setter={(checked) => {
                        console.log("Checked:", checked);
                        setFieldValue("isUkTaxPayer", checked);
                        setIsUkTaxPayer(checked);
                      }}
                      label="We can claim an extra 25% (amount) of gift aid."
                    />
                  </div>
                </div>
              )}
              {isUkTaxPayer && isGoogleScriptLoaded && (
                <>
                  <div className="mt-3">
                    <AddressField
                      hideLabel
                      name="address"
                      label="Home Address"
                      labelClasses="!text-white"
                      inputClasses="!bg-white !rounded-none"
                      errorClasses="!text-white"
                      defaultValue={values.address}
                      placeholder={"Enter your home address"}
                      setFieldValue={setFieldValue}
                      errors={errors.address}
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        handleChange(e);
                        setFieldValue("address", e.target.value);
                        setTempUser((prev) => ({
                          ...prev,
                          address: e.target.value,
                        }));
                        setTempDonor((prev) => ({
                          ...prev,
                          address: e.target.value,
                        }));
                      }}
                      touched={touched.address}
                      cityName="city"
                      onData={(data) => handleChildData(data, setFieldValue)}
                    />
                  </div>
                  <div className="">
                    <AddressField
                      hideLabel
                      name="city"
                      label="City"
                      labelClasses="!text-white"
                      inputClasses="!bg-white !rounded-none"
                      errorClasses="!text-white"
                      defaultValue={values.city}
                      placeholder={"Enter your city"}
                      types={["locality"]}
                      setFieldValue={setFieldValue}
                      errors={errors.city}
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        handleChange(e);
                        setFieldValue("city", e.target.value);
                        setTempUser((prev) => ({
                          ...prev,
                          city: e.target.value,
                        }));
                        setTempDonor((prev) => ({
                          ...prev,
                          city: e.target.value,
                        }));
                      }}
                      touched={touched.city}
                      value={tempUser?.city}
                    />
                  </div>
                  <div className="">
                    <label className="text-sm md:text-md text-white">
                      <input
                        type="text"
                        name="postal_code"
                        id="postal_code"
                        placeholder="Enter your postal code"
                        className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear text-[#000] bg-white"
                        onBlur={handleBlur}
                        value={tempUser?.postal_code}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("postal_code", e.target.value);
                          setTempUser((prev) => ({
                            ...prev,
                            postal_code: e.target.value,
                          }));
                          setTempDonor((prev) => ({
                            ...prev,
                            postal_code: e.target.value,
                          }));
                        }}
                      />
                      {errors.postal_code && touched.postal_code && (
                        <small
                          id="postal_code-error"
                          className="text-white text-sm"
                        >
                          {errors.postal_code}
                        </small>
                      )}
                    </label>
                  </div>
                </>
              )}
            </div>
            <div className="flex w-full mt-3">
              {isLoading ? (
                <div className="w-full py-3 bg-slate-900 text-white text-center font-medium h-[56px] cursor-no-drop">
                  <span className="inline-flex items-center">
                    Processing...
                    <svg
                      className="ml-2 h-4 w-4 animate-spin"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                        fill="none"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                      />
                    </svg>
                  </span>
                </div>
              ) : (
                <div className="flex gap-3 w-full">
                  {selectedMethod === "Apple Pay" ? (
                    <button
                      disabled={isLoading}
                      className="flex-1 "
                      onClick={() => {
                        handleOptionChange("Google Pay");
                      }}
                      type="submit"
                    >
                      <span className="flex items-center gap-6">
                        <span className="gap-1 mb-1 h-[56px] flex-1 flex items-center justify-center py-3 px-4 bg-slate-900 hover:bg-slate-800 text-white transition-colors duration-200 font-medium">
                          <img
                            src={applePayLogo}
                            alt="Apple Pay"
                            className="h-6 w-auto"
                          />
                          <span className="font-montserratMedium pt-1">
                            Pay
                          </span>
                        </span>
                      </span>
                    </button>
                  ) : selectedMethod === "Google Pay" ? (
                    <button
                      disabled={isLoading}
                      className="flex-1 "
                      // onClick={() => {
                      //   handleOptionChange(selectedMethod);
                      //   getAdyenSession();
                      // }}
                      type="submit"
                    >
                      <span className="flex items-center gap-6">
                        <span className="gap-1 mb-1 h-[56px] flex-1 flex items-center justify-center py-3 px-4 bg-slate-900 hover:bg-slate-800 text-white transition-colors duration-200 font-medium">
                          <img
                            src={googlePayLogo}
                            alt="Google Pay"
                            className="h-6 w-auto"
                          />
                          <span className="font-montserratMedium pt-1">
                            Pay
                          </span>
                        </span>
                      </span>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </form>
        )}
      </Formik>
      <AdyenModal isOpen={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};
