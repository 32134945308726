import axios from 'axios';
const Base = process.env.REACT_APP_BASE_URL;
const Header = process.env.REACT_APP_API_HEADER;
let config = {
  headers: {
    "X-user-matw": Header
  },
};
const instance = axios.create({
  baseURL: Base,
  headers: {
    "X-user-matw": Header
  }
});
function getSessionId(data) {
  return axios.post(`${Base}/checkout/stripe/checkout-session`, data,config);
}
function getSessionIdFuturePayment(data) {
  return axios.post(`${Base}/checkout/stripe/checkout-session-future-payment`, data,config);
}
function createNewUser(data) {
  return axios.post(`${Base}/checkout/stripe/customer/create`, data,config);
}
function sendEmailForGetReceipt(data) {
  return axios.post(`${Base}/bulk-send-receipt`, data,config);
}
function getProduct(data) {
  // return axios.post(`https://matw-new-backend.sajidjaved.com/api/checkout/product`, data);
  return axios.post(`${Base}/checkout/stripe/product`, data,config);
  // return axios.post(`${Base}/checkout/stripe/product`, data);
}
function getCheckoutSessionId(sessionId) {
  return axios.get(`${Base}/checkout/stripe/checkout-session?id=${sessionId}`,config);
}

function postFormContact(params) {
  return instance.post('/contact-form', params);
}
function postFormOpt(params) {
  return instance.post('/opt-out', params);
}
function postFormTeacherTraining(params) {
  return axios.post(`${Base}/teacher-training`, params,config);
}
function postFormFundraising(params) {
  return axios.post(`${Base}/form/fundraising`, params,config);
}
function postVolunteer(params) {
  return instance.post('/volunteer', params,config);
}
function getIp() {
  return axios.get('https://api.ipify.org/');
}
function getSessionIdRamadanDailyDonation(data) {
  return axios.post(`${Base}/checkout/stripe/flexible-donation`, data,config);
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getSessionId,
  getSessionIdFuturePayment,
  postVolunteer,
  postFormContact,
  postFormOpt,
  createNewUser,
  getProduct,
  getCheckoutSessionId,
  sendEmailForGetReceipt,
  postFormTeacherTraining,
  postFormFundraising,
  getIp,
  getSessionIdRamadanDailyDonation
};
