import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

import AllNamazTimes from "./AllNamazTimes";
import NamazTime from "./NamazTime";
import CountriesAndCities from "./CountriesAndCities";

import {
    selectCityName,
    selectCountryName,
    selectPrayerName,
} from "../../store/prayer";

import NewHelmetLayout from "../../components/general/newHelmetLayout";
import {Helmet} from "react-helmet-new";

import {
    convertTo12Hour,
    getCurrentLocation,
    getDefaultPrayerTimes, getLatLngFromApi,
    getPrayersDataUsingLatLng,
} from "./utils";

import countryData from "./CountriesAndCitiesDataset";

/**
 * NamazTimes:
 *   - If URL has NO segments after "/namaz-times" or "/salah-times", do IP-based location
 *   - If 1 segment => either "fajr-time-london" or just "london" or "uk"
 *   - If 2 segments => e.g. "uk/fajr-time-london" or "uk/london"
 *
 *   We parse country/city/prayer from a single central place below, then fetch.
 *   If everything fails, show fallback with default times.
 */
export default function NamazTimes() {
    const location = useLocation();

    // Redux store (if user selected a location from CountriesAndCities)
    const reduxCountry = useSelector(selectCountryName);
    const reduxCity = useSelector(selectCityName);
    const reduxPrayerName = useSelector(selectPrayerName);
    const navigate = useNavigate();

    // Local states
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [locationData, setLocationData] = useState(null); // {city, country}
    const [prayerTimes, setPrayerTimes] = useState({
        prayerTimesData: [
            {
                prayerTimeData: [],
                date: null,
                prayerTimeCalculationMethod: null,
                prayerTimeTimezone: "",
            }
        ]
    });

    // First determine if we're dealing with times or timetable
    const pathType = location.pathname.includes("timetable") ? "timetable" : "times";

    // Decide if "Namaz" or "Salah" for headings
    const namazOrSalah = location.pathname.includes("namaz") ? "Namaz" : "Salah";

    console.log("location.pathname.toLowerCase()", location.pathname.toLowerCase())
    if (location.pathname.toLowerCase().startsWith("/fr/")) {
        navigate(location.pathname.toLowerCase()
            .replace("/fr/namaz-times", "/namaz-times")
            .replace("/fr/salah-times", "/salah-times")
            .replace("/fr/namaz-timetable", "/namaz-timetable")
            .replace("/fr/salah-timetable", "/salah-timetable"))
    }

    // Extract path segments after "/namaz-times" or "/salah-times"
    // e.g.  if the URL is "/namaz-times/uk/fajr-time-ilford"
    // pathSegments might be ["uk", "fajr-time-ilford"]
    const pathSegments = location.pathname.toLowerCase()
        .replace(".org/fr", ".org") // remove leading lang
        .replace(".org.uk/fr", ".org.uk") // remove leading lang
        .replace(/^\/(namaz-times|salah-times|namaz-timetable|salah-timetable)\/*/, "") // remove leading /namaz-times or /salah-times
        .split("/")
        .filter(Boolean);
    // We'll parse these out:
    let finalCountry = "";
    let finalCity = "";
    let singlePrayerName = ""; // e.g. "fajr", if we detect "fajr-time-..."
    let isSinglePrayerRoute = false;

    // Regex to detect a single-prayer segment like "fajr-time-london"
    const singlePrayerRegex = /^(fajr|zuhr|asr|maghrib|isha)-time-(.+)$/i;

    /**
     * parseRouteSegments:
     *   - 0 segments => IP-based
     *   - 1 segment  => could be single prayer or city or country
     *   - 2 segments => either (country, single-prayer) or (country, city)
     */
    function parseRouteSegments() {

        if (pathSegments.length === 0) {
            // Means just "/namaz-times" or "/salah-times" => IP-based
            return;
        }

        if (pathSegments.length === 1) {
            
            // Could be e.g. "fajr-time-london", or "london", or "uk" or "namaz-time-london" or "salah-time-london"
            const seg = pathSegments[0];
            const match = seg.match(singlePrayerRegex);
            if (match) {
                // match[1] => "fajr", match[2] => "london"
                isSinglePrayerRoute = true;
                singlePrayerName = match[1];
                finalCity = match[2];
            } else {
                // It's either a country code or city name
                // We'll guess: if it's 2 or 3 letters => treat as country code, else city
                if (seg.length <= 3) {
                    finalCountry = seg; // e.g. 'uk'
                } else {
                    finalCity = seg.replace("namaz-time-", "").replace("salah-time-", ""); // e.g. 'london'
                }
            }
            return;
        }

        if (pathSegments.length === 2) {
            // Possibly "uk/fajr-time-ilford" or "uk/london" or "uk/namaz-time-london" or "uk/salah-time-london"
            const [seg1, seg2] = pathSegments;

            if (pathType.includes('timetable') && (seg2.includes('fajr') || seg2.includes('zuhr')|| seg2.includes('asr')|| seg2.includes('maghrib')|| seg2.includes('isha'))) {
                navigate(`/${namazOrSalah.toLowerCase()}-${pathType}/`);
            }

            // seg1 => country code or something
            // seg2 => either "fajr-time-..." or a city
            finalCountry = seg1;

            const match2 = seg2.match(singlePrayerRegex);
            if (match2) {
                isSinglePrayerRoute = true;
                singlePrayerName = match2[1];
                finalCity = match2[2];
            } else {
                // It's a city
                finalCity = seg2.replace("namaz-time-", "").replace("salah-time-", "");
            }
            return;
        }

        // if (pathSegments.length >= 1) {
        //     navigate(`/${namazOrSalah.toLowerCase()}-${pathType}/`);
        // }

        // If more than 2 segments => just use the first 2, or fallback
        // but let's keep it extra simple => IP-based fallback
    }

    parseRouteSegments();

    // Decide final prayer name from route or from Redux
    const validPrayers = ["fajr", "zuhr", "asr", "maghrib", "isha"];
    const effectivePrayerName = validPrayers.includes(
        singlePrayerName.toLowerCase()
    )
        ? singlePrayerName
        : reduxPrayerName || "All";

    /**
     * (A) Main effect to fetch times after we figure out route-based city/country
     */
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                
                // 1) If no city/country => try IP-based
                if (!finalCity && !finalCountry && !isSinglePrayerRoute) {
                    await initLocationFromIP();
                } else {
                    await fetchTimesOrCoords(finalCountry, finalCity);
                }
            } catch (err) {
                console.error(err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line
    }, [finalCountry, finalCity, isSinglePrayerRoute]);

    /**
     * (B) If user picks a location from <CountriesAndCities>, Redux updates city/country
     *     => fetch times again.
     */
    useEffect(() => {
        const fetchData = async () => {
            if (
                reduxCountry &&
                reduxCity &&
                reduxCountry !== "NA" &&
                reduxCity !== "NA"
            ) {
                try {
                    console.log('Starting API call...');
                    const response = await getLatLngFromApi(reduxCountry, reduxCity);
                    console.log('GetLatLng Response:', response);

                    if (!response.data || !response.data.lat || !response.data.lng) {
                        console.error('Invalid response structure:', response);
                        return;
                    }

                    fetchPrayerTimes(response.data.lat, response.data.lng);
                    setLocationData({ city: reduxCity, country: reduxCountry });
                    // navigate(`/${namazOrSalah.toLowerCase()}-${pathType}/${reduxCountry}/${reduxCity}`);

                    console.log('Calling fetchPrayerTimes with:', response.data.lat, response.data.lng);
                    // await fetchPrayerTimes(response.data.lat, response.data.lng);
                    console.log('fetchPrayerTimes completed');
                    // setLocationData({city: reduxCity, country: reduxCountry});
                    console.log('Location data set');
                } catch (error) {
                    console.error('Error in fetchData:', error);
                    navigate(`/${namazOrSalah.toLowerCase()}-${pathType}/`);
                }
            }
        };

        fetchData();
    }, [reduxCountry, reduxCity]);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (
    //             reduxCountry &&
    //             reduxCity &&
    //             reduxCountry !== "NA" &&
    //             reduxCity !== "NA"
    //         ) {
    //             
    //             const response = await getLatLngFromApi(reduxCountry, reduxCity);
    //             fetchPrayerTimes(response.data.lat, response.data.lng);
    //             // fetchPrayerTimes({city: reduxCity, country: reduxCountry});
    //             setLocationData({city: reduxCity, country: reduxCountry});
    //         }
    //     };
    //
    //     fetchData()
    //     // if (
    //     //     reduxCountry &&
    //     //     reduxCity &&
    //     //     reduxCountry !== "NA" &&
    //     //     reduxCity !== "NA"
    //     // ) {
    //     //     const {latitude, longitude} = await getLatLngFromApi(reduxCountry, reduxCity)
    //     //     fetchPrayerTimes({ city: reduxCity, country: reduxCountry });
    //     //     setLocationData({ city: reduxCity, country: reduxCountry });
    //     // }
    //     // eslint-disable-next-line
    // }, [reduxCountry, reduxCity]);

    /**
     * Initialize location from IP
     */
    async function initLocationFromIP() {
        try {
            const position = await getCurrentLocation();
            const cityTmp = position?.city || "UnknownCity";
            const countryTmp = position?.country || "UnknownCountry";
            setLocationData({city: cityTmp, country: countryTmp});
            await fetchPrayerTimes(position.coords.latitude, position.coords.longitude);
            // await fetchPrayerTimes({city: cityTmp, country: countryTmp});
        } catch (err) {
            setError(err.message || "Failed IP-based location");
            setPrayerTimes({
                prayerTimeData: getDefaultPrayerTimes(),
                prayerTimeCalculationMethod: "No method used",
                prayerTimeTimezone: "",
            });
        }
    }

    /**
     * If we have only a country or only a city or both,
     * figure out what to do.
     */
    async function fetchTimesOrCoords(_country, _city) {

        // If only country => pick its top city from the dataset
        if (_country && !_city && !isSinglePrayerRoute) {
            const found = findCountryInDataset(_country);
            if (found && found.majorCities?.length) {
                _city = found.majorCities[0].name; // pick #1 city
            }
        }

        // If we now have a city & country => fetch times directly
        if (_city && _country) {
            const response = await getLatLngFromApi(_country, _city);
            fetchPrayerTimes(response.data.lat, response.data.lng);
            // await fetchPrayerTimes({city: _city, country: _country});
            setLocationData({city: _city, country: _country});
            return;
        }

        // If we only have a city => we can try to geocode it
        if (_city && !_country) {
            //todo: findCityInDataset
            try {
                
                const response = await getLatLngFromApi(_country, _city);
                fetchPrayerTimes(response.data.lat, response.data.lng);
                // await fetchPrayerTimes({city: _city, country: _country});
                setLocationData({city: _city, country: _country});
                // throw new Error('Country is not available')
                // const { country, city } = await getCoordinates(_city);
                // await fetchPrayerTimes({ city, country });
                // setLocationData({ city, country });
            } catch (err) {
                // fallback
                setError(err.message);
                await initLocationFromIP();
            }
            return;
        }

        // If we have no city & no country => fallback
        if (!_city && !_country && !isSinglePrayerRoute) {
            await initLocationFromIP();
        }
    }

    /**
     * Actually fetch from AlAdhan
     */
    async function fetchPrayerTimes(latitude, longitude) {
        try {

            setLoading(true);
            
            // const response = await getPrayerDataFromApi({ city, country });
            const response = await getPrayersDataUsingLatLng(latitude, longitude, pathType);

            if (!response.ok) {
                setPrayerTimes({
                    prayerTimeData: getDefaultPrayerTimes(),
                    prayerTimeCalculationMethod: "No method used",
                    prayerTimeTimezone: "",
                });
                return;
            }
            const data = await response.json();

            let prayerTimeDataObject = null

            if (data.data?.timings) {
                prayerTimeDataObject = {
                    prayerTimesData: [
                        {
                            prayerTimeData: [
                                {name: "Fajr", time: convertTo12Hour(data.data.timings.Fajr)},
                                {name: "Zuhr", time: convertTo12Hour(data.data.timings.Dhuhr)},
                                {name: "Asr", time: convertTo12Hour(data.data.timings.Asr)},
                                {name: "Maghrib", time: convertTo12Hour(data.data.timings.Maghrib)},
                                {name: "Isha", time: convertTo12Hour(data.data.timings.Isha)},
                                {
                                    name: "Sunrise",
                                    time: convertTo12Hour(data.data.timings.Sunrise),
                                },
                            ],
                            prayerTimeCalculationMethod: data.data.meta?.method?.name || "",
                            prayerTimeTimezone: data.data.meta?.timezone || "UTC",
                        }
                    ]
                }
            } else if (Array.isArray(data?.data)) {
                // Monthly data
                prayerTimeDataObject = {
                    prayerTimesData: data.data.map(day => {
                        return {
                            prayerTimeData: [
                                {
                                    name: "Fajr",
                                    time: convertTo12Hour(day.timings.Fajr)
                                },
                                {
                                    name: "Sunrise",
                                    time: convertTo12Hour(day.timings.Sunrise)
                                },
                                {
                                    name: "Zuhr",
                                    time: convertTo12Hour(day.timings.Dhuhr)
                                },
                                {
                                    name: "Asr",
                                    time: convertTo12Hour(day.timings.Asr)
                                },
                                {
                                    name: "Maghrib",
                                    time: convertTo12Hour(day.timings.Maghrib)
                                },
                                {
                                    name: "Isha",
                                    time: convertTo12Hour(day.timings.Isha)
                                }
                            ],
                            date: day.date,
                            prayerTimeCalculationMethod: data.data.meta?.method?.name || "",
                            prayerTimeTimezone: data.data.meta?.timezone || "UTC",
                        }
                    })
                }
            }


            setPrayerTimes(prayerTimeDataObject)
            // const data = await data.json();
            // if (!data?.data?.timings?.Fajr) {
            //     setPrayerTimes({
            //         prayerTimeData: getDefaultPrayerTimes(),
            //         prayerTimeCalculationMethod: "No method used",
            //         prayerTimeTimezone: "",
            //     });
            //     return;
            // }
            // setPrayerTimes({
            //     prayerTimeData: [
            //         {name: "Fajr", time: convertTo12Hour(response.data.timings.Fajr)},
            //         {name: "Zuhr", time: convertTo12Hour(data.data.timings.Dhuhr)},
            //         {name: "Asr", time: convertTo12Hour(data.data.timings.Asr)},
            //         {name: "Maghrib", time: convertTo12Hour(data.data.timings.Maghrib)},
            //         {name: "Isha", time: convertTo12Hour(data.data.timings.Isha)},
            //         {
            //             name: "Sunrise",
            //             time: convertTo12Hour(data.data.timings.Sunrise),
            //         },
            //     ],
            //     prayerTimeCalculationMethod: data.data.meta?.method?.name || "",
            //     prayerTimeTimezone: data.data.meta?.timezone || "UTC",
            // });
        } catch (err) {
            setError(err.message);
            setPrayerTimes({
                prayerTimeData: getDefaultPrayerTimes(),
                prayerTimeCalculationMethod: "No method used",
                prayerTimeTimezone: "",
            });
        }finally {
            setLoading(false);
        }
    }

    /**
     * Utility to find a country in your dataset by shortCode or name
     */
    function findCountryInDataset(_country) {
        const allCountries = [
            ...countryData.muslimCountries,
            ...countryData.westernCountries,
        ];
        return allCountries.find(
            (c) =>
                c.name.toLowerCase() === _country.toLowerCase() ||
                c.shortCode?.toLowerCase() === _country.toLowerCase() ||
                c.shortCode2?.toLowerCase() === _country.toLowerCase()
        );
    }

    // ------------------------------------------
    // Render logic
    // ------------------------------------------


    if (error) {
        setError(null)
        navigate(`/${namazOrSalah.toLowerCase()}-${pathType}/`);
    }

    // If user is checking a single prayer
    if (effectivePrayerName.toLowerCase() !== "all") {
        return (
            <NamazTime
                locationData={locationData}
                prayerTimes={prayerTimes.prayerTimesData[0]}
                city={finalCity}
                prayerName={effectivePrayerName}
            />
        );
    }

    // Otherwise, show all prayers
    return (
        <NewHelmetLayout className="bg-white" hideFooter showRegularImg={false}>
            <Helmet>
                <title>
                    {
                        pathSegments.length > 0
                            ? `${namazOrSalah} Time in ${
                                locationData?.city
                                    ?.replace(/-/g, " ")
                                    ?.replace(/\b\w/g, (c) => c.toUpperCase())
                            } - Live ${namazOrSalah} Time ${
                                locationData?.city
                                    ?.replace(/-/g, " ")
                                    ?.replace(/\b\w/g, (c) => c.toUpperCase())
                            } today`
                            : `${namazOrSalah} Time - Your local ${namazOrSalah} Times`
                    }
                </title>
                <link rel="canonical"
                      href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
                <meta
                    name="description"
                    content={`${namazOrSalah} Time in ${
                        locationData?.city
                            ?.replace(/-/g, " ")
                            ?.replace(/\b\w/g, (c) => c.toUpperCase())
                    }`}
                />

                <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb"/>
                <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default"/>
                <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca"/>
                <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr"/>
                <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us"/>
                <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id"/>
                <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my"/>
                <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg"/>
            </Helmet>
            {loading && <div className="flex items-center justify-center w-full h-screen">
                <div className="text-xl font-semibold">Loading prayer times...</div>
            </div>}
            {/* Show the Country/City selectors if we do have city data */}
            {!loading && locationData?.city && (
                <CountriesAndCities
                    pathType={pathType}
                    locationData={locationData}
                    namazOrSalah={namazOrSalah}
                    defaultCountry={locationData.country || ""}
                    defaultCity={locationData.city || ""}
                />
            )}

            {
                !loading && (<AllNamazTimes
                    locationData={locationData}
                    prayerTimes={prayerTimes.prayerTimesData[0]}
                    monthlyPrayerTimes={prayerTimes}
                    city={finalCity}
                    pathType={pathType}
                    namazOrSalah={namazOrSalah}
                />)
            }

        </NewHelmetLayout>
    );
}
