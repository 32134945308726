import React from 'react';
import MaxWidthContainer from '../../../components/general/maxWidthContainer';

const BlessingAndMercy = props => {
  /* ----------------------------- Start variables ---------------------------- */

  /* -------------------------------------------------------------------------- */
  return (
    <section className="mb-0 md:mb-8 px-5 md:px-[40px] py-5 md:py-[40px] bg-[url('../src/images/ali-banat/feel-pattern.png')] bg-[#14a3dc2c]">
      <MaxWidthContainer className="bg-white p-5 md:p-[40px] md-p-[40px] rounded-[10px]">
        <p className="mx-auto mb-5 md:w-10/12 text-[25px] leading-7 md:leading-[50px] md:text-[40px] text-center text-[#093686] font-brandingBold">
          {/* <span className="text-[#F60362] mx-1">Eid Mubarak</span>! Feel your rewards by spreading Eid Joy to an orphan! */}
          Ali Banat’s Vision Was Clear: Build A Legacy To Help Every Needy Muslim Around The World.
        </p>
        <div className={`space-y-3 md:space-y-5 text-[#78716C] font-BrandingMediumItalic md:text-lg md:text-center md:w-10/12 md:mx-auto mt-4 ${props.descriptionStyle}`}>
          <p>
            The MATW Project, started by Ali Banat as a legacy of compassion, has grown to reach millions of Muslims around the world.
            Through our collective efforts, we have been able to deliver life-saving aid, reaching over <span className="text-[#093686] mx-1 font-bold">19 million</span> Muslims
            in some of the poorest and most vulnerable regions. With our help, MATW continues to provide food, clean water, medical aid,
            and shelter to those in desperate need. Our support is transforming lives, restoring hope, and building a better future for the Ummah.
            Together, we can make an even greater impact. Discover our impact reports, see the difference we are making, and continue supporting
            this life-changing cause. Every donation matters.
          </p>
        </div>
        <div className="mx-auto mt-6 w-full flex flex-col md:flex-row justify-center items-center">{props.buttons}</div>
      </MaxWidthContainer>
    </section>
  );
};
export default BlessingAndMercy;
