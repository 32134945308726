import { useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
// import ShowCurrencies from '../../../utils/showCurrencies';
// import useHandleAddProduct from '../../../utils/handleAddProduct';
import 'react-lazy-load-image-component/src/effects/blur.css';
// import ConvertAUD from '../../../utils/convertAUD';
import ConvertUSD from '../../../utils/convertUSD';
import CustomButton from '../../../components/general/button';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { endDateOfAppealCampaign, endDateOfGazaCampCampaign, endDateOfLimbsAppealCampaign } from '../details';
// import IsGTMScriptLoaded from '../../../utils/isGTMScriptLoaded';
// import limbsImg from '../../../images/landings/palestine/big-Limbs_of_Hope_Campaign_Website_Image.jpg';
import { generateLinkWithLang } from '../../../utils/linkGenerator';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { campaignDates } from '../dates';
import handleDaysLeft from '../handleDaysLeft';

const AppealFundraiserProductBoxV3 = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const ref = useRef(null);
  const navigate = useNavigate();
  const refImg = useRef(null);
  const refLazyImg = useRef(null);
  const { product, currency, goal, campaign, isVisibleDescription = true, } = props;
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [showMore, setShowMore] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleHeight = () => {
    setShowMore(!showMore);
  };

  const handlePercentage = (total_sale, goal) => {
    return `${((Number(total_sale) / Number(goal)) * 100).toFixed(2)}%`;
  };
  // const handleDaysLeft2 = () => {
  //   var now = moment(new Date());
  //   var after = moment(
  //     new Date(
  //       campaign === 'limbs-of-hope' ? endDateOfLimbsAppealCampaign : campaign === 'gaza-camp' ? endDateOfGazaCampCampaign : endDateOfAppealCampaign,
  //     ),
  //     'DD-MM-YYYY HH:mm:ss',
  //   );
  //   var timeDifference = after.diff(now, 'days');
  //   return timeDifference;
  // };
  const ShowCurrencies = (currency, _price, showAbbr = true) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = '';
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'EUR':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        _final = `${price}`;
        break;
      case 'IDR':
        _final = `Rp${price}`;
        break;
      case 'AED':
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    }
    return _final;
  };

  useEffect(() => {
      if (product) {
        if (ref.current && ref.current.clientHeight > 60) {
          setIsOverflow(true);
        }
      }
      // Include dependencies that affect the rendering of the ref'd component
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, ref.current]);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-full max-[767px]:max-w-[355px] shadow-lg bg-white overflow-hidden mx-auto">
      <div className="overflow-hidden w-full">
        {/* {console.log("fundraiser campaigns=",campaign,product)} */}
        <div
          ref={refImg}
          className="overflow-hidden cursor-pointer max-h-[395px] w-full flex items-center"
          onClick={() => navigate(generateLinkWithLang(`/appeals/${product.link}`, lang))}
        >
          {product ? (
            (product.product_image_link || product.image_link) ? (
              <LazyLoadImage
                ref={refLazyImg}
                alt={product.product_name || product.name}
                effect="blur"
                width={`100%`}
                delayTime={500}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = '/images/general/logo-replace.png';
                }}
                src={product.product_image_link || product.image_link}
              />
            ) : (
              <img className="w-full" src="/images/general/logo-replace.png" alt="" />
            )
          ) : (
            <Skeleton height={344} />
          )}
        </div>
      </div>
      <div className="p-2 md:p-3 md:pb-5">
        <h3
          className="text-[#00a3da] sm:text-md md:text-md lg:text-lg xl:text-xl font-medium h-[48px] lg:h-[56px] mb-2 flex items-center cursor-pointer"
          onClick={() => navigate(generateLinkWithLang(`/appeals/${product.link}`, lang))}
        >
          {product ? (product.product_name || product.name) : <Skeleton height={56} />}
        </h3>
        <div className="relative">
          {product ? (
            <>
              <div
                className={`transition-all duration-300 my-2 relative overflow-hidden ${
                    showMore ? 'h-auto mb-4' : 'max-h-[65px] md:max-h-[72px]'
                  } ${(product.product_description || product.description).split(' ').length > 28 ? 'mb-0' : 'mb-0'}`}
              >
                <p ref={ref} className={`text-stone-500 text-sm sm:text-sm md:text-base lg:text-base overflow-hidden transition-all duration-200 min-h-[65px] md:min-h-[79px] px-2 md:px-3`}>
                  {product.product_description || product.description}
                </p>
              </div>
              {
                // Chevron icon
                (isOverflow || !isVisibleDescription) && (
                  <ChevronDownIcon
                    onClick={handleHeight}
                    className={` md:block w-6 h-6 text-[#00a3da] transition-all duration-300 cursor-pointer mx-auto ${
                      showMore ? 'transform rotate-180' : ''
                    }`}
                  />
                )
              }
            </>
          ) : (
            <Skeleton count={4} />
          )}
        </div>
        <div className="basis-full flex-col md:basis-1/2 flex justify-center pt-1 md:pt-2">
          <div className="flex items-center justify-between mb-2">
            <div className="flex w-full text-sm">
              <strong className="text-[#00a3da] font-bold mr-0.5">{product.total_supporters || product.totalSupporters}</strong>
              <span className="inline-block lg:w-auto mr-1">Supporters,</span>
              <strong className="text-[#00a3da] font-bold mr-0.5">{handleDaysLeft(campaign)}</strong>
              <span className="inline-block lg:w-auto">days left</span>
            </div>
          </div>
          <div className="flex w-full h-2 bg-[#E3E3E3] bg-opacity-10- mb-2">
            <div className={`bg-[#F60362]`} style={{ width: `${handlePercentage(product.total_sale || product.totalRaised, ConvertUSD(currency, goal, false))}` }}></div>
          </div>
        </div>
        <div className="basis-full flex justify-between items-center mt-2">
          <div className="flex flex-col w-full">
            <div className="text-lg md:text-2xl text-[#00a3da] basis-full font-bold flex items-center">
              {(product.total_sale || product.totalRaised) && ShowCurrencies(currency, Math.round(product.total_sale || product.totalRaised), false)}
              <span className="text-xs ml-0.5">{currency}</span>
            </div>
            <div className="flex flex-wrap w-full text-xs items-center">
              <span className="text-[#78716C] text-xs">Raised of</span>
              <div className="text-[#00a3da] font-bold mx-1 text-sm">{ShowCurrencies(currency, Math.round(ConvertUSD(currency, goal, false)))}</div>
              Goal
            </div>
          </div>
          {product ? (
            <CustomButton
              onClick={() => navigate(generateLinkWithLang(`/appeals/${product.link}`, lang))}
              type="button"
              title={'Donate'}
              className={`!rounded-none`}
            />
          ) : (
            <Skeleton height={43} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppealFundraiserProductBoxV3;
