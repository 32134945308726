import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import { IoMdInformationCircle } from 'react-icons/io';
import { Tooltip } from 'react-tooltip';

export const ProductBoxCartLast10Nights = ({
  product,
  alt,
  onAdd,
  onAddQty,
  onReduceQty,
  onUpdateQty,
  setQuantity
}) => {
  /* ----------------------------- Start variables ---------------------------- */
  const [inputValue, setInputValue] = useState(50);
  const [showMore] = useState(false);
  /* -------------------------------------------------------------------------- */
  /* ------------------------------ Start methods ----------------------------- */
  const toProperCase = (text) => {
    return text
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  const handleInputChange = (e) => {
    // const value = e.target.value.trim();
    // const quantity = isNaN(value) || value === '' || Number(value) < 0 ? 0 : Math.floor(Number(value));
    // setInputValue(quantity);
    // setQuantity(quantity);
    let value = e.target.value.trim();

    // If the input has more than 1 character and starts with '0', remove the leading zero
    if (value.length > 1 && value.startsWith('0')) {
      value = value.replace(/^0+/, ''); // remove leading zeros
    }

    const quantity = isNaN(value) || value === '' || Number(value) < 0
      ? 0
      : Math.floor(Number(value));

    setInputValue(value); // Keep the user-typed value in the input
    setQuantity(quantity); // Store the valid numeric value
  };
  const handleIncrease = () => {
    setQuantity(inputValue + 5);
    setInputValue(prevValue => prevValue + 5);
    // onAddQty(product, inputValue + 5)
  }
  const handleDecrease = () => {
    if (inputValue > 4) {
      setQuantity(inputValue - 5);
      setInputValue(prevValue => prevValue - 5);
    } else {
      setQuantity(0);
      setInputValue(0);
    }
    // onAdd(product, inputValue - 5)
  }
  /* -------------------------------------------------------------------------- */

  return (
    <div className={`w-full rounded-md overflow-hidden gap-2 md:gap-4 grid grid-cols-[auto,1fr] shadow-[0_7px_29px_0px_rgba(100,100,111,0.2)]- bg-white`}>
      {/* Image Container */}
      <div className={`overflow-hidden tooltip img-hover-zoom w-[72px] md:w-[108px] aspect-square`}>
        {product ? (
          product.image_link ? (
            <LazyLoadImage
              alt={alt || product.alt || product.name}
              effect="blur"
              className={`!rounded-none cursor-pointer w-full h-full`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = '/images/general/logo-replace.png';
              }}
              src={product.image_link}
            />
          ) : (
            <img className="w-full h-full object-cover" src="/images/general/logo-replace.png" alt="" />
          )
        ) : (
          <Skeleton height={100} width={100} />
        )}
      </div>
      {/* Right Container */}
      {/* {console.log("productss=", product, product.description)} */}
      <div className={`flex flex-col justify-between md:items-baseline gap-1 sm:gap-3 p-2 md:py-3 pl-0`}>
        <div className={`flex gap-1 flex-col items-center overflow-hidden transition-all duration-300 ${showMore ? 'h-auto' : 'max-h-[24px]'}`}>
          <div className="w-full flex justify-between gap-2 items-center font-signika">
            <p className={`text-[#00a3da] cursor-pointer md:text-lg font-semibold md:font-bold ${!showMore ? 'line-clamp-1' : ''}`}>
              <span className="sm:hidden">{product ? toProperCase(product.alternativeName || product.name) : <Skeleton width={70} height={12} count={1} />}</span>
              <span className="hidden sm:block">
                {product ? product.alternativeName || product.name : <Skeleton width={200} height={12} count={1} />}
              </span>
            </p>
            <div className="text-LightBlue">
              <span id={`clickable${product.id}`} className='text-sm'>
                <IoMdInformationCircle className="text-xl" />
              </span>
              <Tooltip anchorSelect={`#clickable${product.id}`} clickable className="max-w-xs z-[1200] text-sm">
                {product.description}
              </Tooltip>
            </div>
          </div>
        </div>
        {/* Button Container */}
        <div className="flex gap-3 items-center w-full justify-between">
          {product ? <>
            <div className={`flex justify-between rounded-md border-[.1rem] border-transparent bg-LightBlue bg-opacity-10 focus:border-[#25b9db] w-full text-[#777]`}>
              <button onClick={handleDecrease}
                className={`w-[26px] h-[26px] rounded-md bg-LightBlue text-white flex items-start justify-center cursor-pointer sm:h-8 ${product.quantity <= 0 ? 'opacity-50 !cursor-not-allowed' : ''}`}
                disabled={inputValue === 0}
              >
                -
              </button>
              <input
                type="number"
                value={inputValue}
                onChange={e => handleInputChange(e, product, product)}
                className="w-9 text-center opacity-0- w-0- appearance-none bg-transparent text-[#777] border-none outline-none hover:outline-none focus:outline-none qty-input sm:h-8"
              />
              <div onClick={handleIncrease} className="w-[26px] h-[26px] rounded-md bg-LightBlue text-white flex items-start justify-center cursor-pointer sm:h-8">
                +
              </div>
            </div>
            <div className="flex gap-2 items-center w-full">
              <button
                className={`relative w-full rounded-md cursor-pointer transition-all duration-300 bg-pink text-white flex justify-center items-center gap-2 text-xs sm:text-base h-7 sm:h-8 px-2 sm:px-3`}
                onClick={() => {
                  // handleAddToCart(product, inputValue);
                }}
              >
                Save
              </button>
            </div>
          </>
            : <div className="flex gap-2 items-center">
              <Skeleton height={36} width={102} />
            </div>
          }
        </div>
      </div>
    </div>
  );
};
