import { useEffect, useState } from "react";

const useIsExactDomain = (domain) => {
  const [isExactDomain, setIsExactDomain] = useState(false);

  useEffect(() => {
    setIsExactDomain(window.location.hostname === domain);
  }, [domain]);

  return isExactDomain;
};

export default useIsExactDomain;
