import { useEffect } from 'react';
import WidgetHome from '../../components/widgets/widgetHomeOrdered';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import Accordionn from '../../components/general/accordion';
import clickHereImg from '../../images/others/clickheretoseeourprojects.jpg';
// import liketoSeeImg from '../../images/others/liketoseeusinaction.jpg';
import liketoSeeImg from '../../images/others/liketoseeusinaction.jpg';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const FAQsPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const allFaqs = [
    {
      id: 0,
      question: 'How can we get involved with MATW?',
      answer: `We love to hear from individuals who are passionate about helping others and wish to support or volunteer on behalf of MATW.
        To volunteer please fill out our application
        Don’t forget simply spreading our mission and sharing our story goes a long way too!
        Alternatively, for all other collaboration inquiries please call our head office on +612 9758 9037 or email info@matwproject.org`,
    },
    {
      id: 1,
      question: 'What are MATW’s administration fees?',
      answer: `At MATW we aim to have the lowest administration fees possible so that our beneficiaries receive 100% of your donations. We have been fortunate enough to run the organisation, since inception, with no administration fees. This is because of the financial commitment of our sponsors. These sponsors contribute to the running costs of MATW. We are grateful to them for their generosity and kindness.
        If you would like to become a MATW sponsor and help with the running costs of our organisation please contact us on +612 9758 9037 or email info@matwproject.org`,
    },
    {
      id: 2,
      question: 'How do I know funds are being distributed appropriately?',
      answer: `At MATW our main aim is to be an open, accountable and transparent organisation. We strive to keep our donors informed through every stage of the projects we are working on through our social media channels as well as regular project updates on our website and our newsletters.
            We value our supporters and work with them collaboratively by allowing them to select the projects they would like their donations to be allocated to.
            MATW is a due diligence organisation and as a not-for-profit it is also bound by regulatory checks including mandatory audits conducted both externally and internally. These audits are conducted to ensure that MATW is abiding by its legal obligations at all times as well as ensuring that we are fulfilling our moral obligations to our donors.`,
    },
    {
      id: 3,
      question: 'Does MATW have any partnerships?',
      answer: `Yes! MATW has established many amazing partnerships since establishment which are still ongoing today.  One mission of our founder Ali Banat was to create unity amongst like-minded organisations to further our positive impact on the world!`,
    },
    {
      id: 4,
      question: 'Are there any employment opportunities with MATW?',
      answer: `MATW is rapidly expanding and we are looking for new members who bring value and insight to our team. If you have a passion to help those in need while gaining rewardable and valuable work experience in the charity sector, we would like to hear from you.`,
    },
    // {
    //     id: 5, question: 'How can we donate to MATW?', answer: `You can donate via:
    //     Our website using either Paypal or Bank Transfer (Please note you do not have to create a Paypal account to donate via this platform)
    //     Direct Bank Deposit –
    //     Account Name: MATW International LTD
    //     BSB: 112879
    //     Account Number: 47879 1884
    //     BIC/SWIFT: SGBLAU2S
    //     IBAN: 112879478791884
    //     Bank Name: St. George Bank
    //     Address: 4-16 Montgomery St, Kogarah NSW 2217
    //     Ref: Project type (e.g. Orphan Housing, Sheep sacrifice…)
    //     Visit our GoFundMe page Click link> https://www.gofundme.com/f/matwproject?utm_source=customer&utm_medium=copy_link-tip&utm_campaign=p_cp+share-sheet
    //                     Prefer a face to face engagement?
    //     3. Visit our Greenacre office @ 53 Juno Parade, Greenacre NSW 2190 and one of our lovely staff members will assist
    //     For further information or assistance please contact our office +612 9758 9037 or email info@matwproject.org`
    // },
    {
      id: 5,
      question: 'How can we donate to MATW?',
      answer: `You can donate via our website using card or PayPal. Alternatively you can send a bank transfer or use one of the fundraising platforms linked from our website.`,
    },
    {
      id: 6,
      question: 'How can international donors donate to MATW? And can we donate in multiple currencies?',
      answer: `Our international donors can donate in the currency of their choice through Paypal. We have this payment option available on our website. You do not require a Paypal account nor do you have to create an account to donate. For assistance please call +612 9758 9037 or email info@matwproject.org`,
    },
    {
      id: 7,
      question: 'Will we receive a confirmation once we have made a donation?',
      answer: `If you donate on our website you will see a donation success prompt when you have made a successful payment.
            We will also send you a confirmation email to your nominated email address within 1-2 business days once the transaction has been completed successfully which will contain a copy of your donation receipt.
            If you require further assistance please contact our head office +612 9758 9037 or email info@matwproject.org.`,
    },
    {
      id: 8,
      question: 'Are your projects Zakat applicable?',
      answer: `Our values at MATW are based on Islamic principles.
            All our projects in Africa are Zakat compliant. This includes the following projects:
            Water wells
            Sponsorships
            Schooling and Education
            Housing construction (Orphan and Widow)
            Food distribution
            Masjid Construction
            However, our Rohingya Refugee village project is not Zakat compliant.`,
    },
    {
      id: 9,
      question: 'How can we donate our Zakat al mal?',
      answer: `Zakat Al-mal can be donated via our website under the ‘Donate’ tab. There is a specific Zakat Al-mal category.`,
    },
    {
      id: 10,
      question: 'What MATW projects are best suited to Sadaqah Jariyah?',
      answer: `Sadaqah Jariyah is a form of continuous charity which will benefit an individual even after they have passed. Aisha (ra) narrates: “A man came to the Prophet (SAW) and said: “My mother died suddenly and she did not leave a will, but I think that if she could have spoken she would have given in charity. Will she have a reward if I give in charity on her behalf?” The prophet (saw) said “Yes”. [al-bukharri – 1388; Muslim – 1004] Our view on Sadaqah Jariyah is simple, we take into consideration the longevity of the donation and also its continual benefits. For example, knowledge has a continuous cycle of rewards, when you give knowledge there are many beneficiaries as knowledge is endless and passed down from one to another. The following MATW Projects are of most benefit: Education Programs (such as schooling, Dawah men and distribution of the Quran); Construction of Water wells; Construction and maintenance of Masjids; Orphan Sponsorship; Widow Sponsorship; and Housing Construction and Sponsorship.`,
    },
    {
      id: 11,
      question: 'How can we fundraise for MATW?',
      answer: `Fantastic! We are always thrilled to know people are fundraising for us!
            Here are two ways to get started NOW!
            Create your own facebook fundraiser, you can choose MATW Project International as the given charity and even choose the project you wish to fundraise for, its that easy!
            Collect one of our shop money boxes or keep at home money tins from our Bankstown office. This is a great way for local businesses to give back and also get the whole family involved in fundraising!For all other fundraising opportunities please contact us so we can have a detailed chat about what you have in mind. This will help us to advise you and guide you with the next steps. Please give us a call on +612 9758 9037 or email info@matwproject.org to get in touch with us.
            If you have any other questions or concerns please do not hesitate to call us on +612 9758 9037.`,
    },
    {
      id: 12,
      question: 'Are my donations tax deductible?',
      answer: `Yes!  All your donations are 100% tax deductible with MATW.  We are also ACNC registered.`,
    },
  ];
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
  }, [selectedCurrencyStore]);
  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0, 0);
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout>
      {seo && (
        <Helmet>
          <title>{`${seo['resourcesAndInformation']['faq']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
          <meta name="description" content={`${seo['resourcesAndInformation']['faq']['description']}`} />

          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
      )}
      <WidgetHome selectedCurrency={e => {}} />
      <div className={`w-full bg-[url('../src/images/others/faq.jpg')] bg-cover text-white h-auto md:h-[340px] flex justify-center items-center`}>
        <div className="w-full h-full bg-[#093484] bg-opacity-70 py-4">
          <div className="md:container md:mx-auto px-2 sm:px-3 h-full flex items-center">
            <h1 className="w-full text-white text-lg sm:text-2xl md:text-5xl text-center">FAQs</h1>
          </div>
        </div>
      </div>
      {/* <div className="md:container md:mx-auto mt-5">
                <div className="grid grid-cols-12 px-3 md:px-4">
                    <h1 className="col-span-12 text-center text-6xl text-[#093484] py-2 md:py-8 font-['Gotcha']">
                        Our Purpose...
                    </h1>
                    <p className="col-span-12 text-center text-xl">
                        Building Legacies with Global impact <br />
                        <span>#Legacy #Impact</span>
                    </p>
                    <div className="col-start-1 col-span-12 rounded-md bg-white p-6 mt-4">
                        <div className="grid grid-cols-12">
                            <div className="col-start-1 col-span-12 md:col-start-2 md:col-span-10">
                                <div className="flex flex-row justify-center flex-wrap">
                                    <div className="py-2 flex flex-row flex-wrap">
                                        <div className='basis-full lg:basis-3/4 order-2 lg:order-1 px-2 text-[#777]'>
                                            <h2 className='text-[#00a3da] text-3xl my-4'>A message from the CEO (and Ali’s friend)</h2>
                                            <p className=' mb-3'>Assalamou Alaykum,</p>
                                            <p className=' mb-3'>Going into the 5th year of MATWs operation, I feel very privileged to be writing this message as part of such an amazing and fruitful organisation. This is a special time in our organisations history as we move into what we hope to be the most prosperous year that is 2021.</p>
                                            <p className="">We began our journey January 16, 2016 to which our dedicated team has spent the past 5 years continuing the legacy of my long-time friend and brother, the late Ali Banat (الله يرحمها). Our focus has been strengthening the brand that is MATW, which is identical with our commitment to help those in need through real projects with great impact.</p>
                                            <p className="">As an organisation it is important to start the new year with goals. The goals for MATW moving into the new year are:</p>
                                            <ul className='list-disc pl-4 my-5'>
                                                <li>Maximising the impact of your donations through sustainable developments</li>
                                                <li>Improving the experiences of our donors</li>
                                                <li>Focus on communal involvement and engagement promoting change.</li>
                                                <li>Building equality, equity, and every other thing we do at MATW that are inspired by our values of trustworthiness, efficiency, sustainability, influence, and faith.</li>
                                            </ul>
                                            <p className="pt-5 mb-5">To our ongoing supporters and MATW family we are humbled and thankful for your support over the years and we look forward to continuing working together in changing the lives of those who need us most!</p>
                                            <p>
                                                Sincerely <br />
                                                Mahmoud Ismail<br />
                                                CEO MATW Project <br />
                                            </p>
                                            <img src={signImg} alt="MATW" className='max-w-[200px] mt-5' />
                                        </div>
                                        <div className="basis-full lg:basis-1/4 order-1 lg:order-2 px-2">
                                            <img src={aliFaqImg} alt="Ali banat" className='w-full' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full mt-24 bg-[url('../src/images/banners/MATW-Banner-faq.jpg')] bg-cover text-white py-20">
                <div className="md:container md:mx-auto flex justify-center flex-wrap relative px-4">
                    <img src="../images/general/logo.png" alt="" className='absolute left-0 right-0 -top-[155px] mx-auto w-[150px]' />
                    <p className='my-2 mt-10 text-3xl text-center basis-full md:basis-5/6'>
                        MATW (and why it's not just another charity)
                    </p>
                    <p className="my-2 text-center basis-full md:basis-5/6">
                        We manage all projects ourselves from beginning to end. This means we are directly involved in project execution every step of the way without the involvement of third parties. We use in-house models which means no middle man is involved. If we can’t control it, we won’t do it.
                    </p>
                    <div className="border-t border-[#999] basis-full md:basis-5/6 mt-10 flex flex-wrap">
                        <div className="flex items-center justify-center text-white basis-full md:basis-1/3 px-2 py-4 md:px-4 text-2xl">
                            1. We build legacies
                        </div>
                        <div className="flex items-center justify-center text-white basis-full md:basis-1/3 px-2 py-4 md:px-4 text-2xl">
                            2. We focus on maximum impact
                        </div>
                        <div className="flex items-center justify-center text-white basis-full md:basis-1/3 px-2 py-4 md:px-4 text-2xl">
                            3. We are completely social (media)
                        </div>
                    </div>
                    <p className="basis-full text-center text-white text-2xl mt-10">
                        MATW is big on Social Media and has built its entire global audience on it.
                    </p>
                </div>
            </div>
            <div className="w-full bg-white py-20">
                <div className="md:container md:mx-auto flex justify-center flex-wrap px-4">
                    <div className="grid grid-cols-12">
                        <div className="col-start-1 col-span-12 md:col-start-2 md:col-span-10 flex flex-row justify-center flex-wrap">
                            <div className='basis-full lg:basis-3/4 order-2 lg:order-1 px-2 text-[#777]'>
                                <h2 className='text-[#00a3da] text-3xl my-4'>Why Africa?</h2>
                                <p className='mb-3 text-[#f60362] text-lg'>Africa is one of the top regions in the world that suffers the most from the global water crisis. <br />
                                    In Africa, 45% of deaths in children under the age of 5 is caused by poor nutrition.
                                    In Africa, 650 people die every day (including children) from preventable diseases related to contaminated water.</p>
                            </div>
                            <div className="basis-full lg:basis-1/4 order-1 lg:order-2 px-2">
                                <img src={whyAfricaImg} alt="MATW" className='w-full' />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="w-full bg-[url('../src/images/banners/MATW-Home-Banner-Donation-Policy.jpg')] bg-cover text-white py-20">
                <div className="md:container md:mx-auto flex justify-center flex-wrap relative px-4">
                    <img src={donationHeadingImg} alt="Matw" className='max-w-full' />
                    <p className='my-2 mt-10 text-xl text-center basis-full'>
                        Our policy is a simple $ for $ policy. What we receive from donors for projects goes directly to the projects. No admin fees or hidden fees are added!
                        <br />
                        <br />
                        We have a generous network of sponsors that have kindly donated to our day to day admin costs, so you don't have to.
                    </p>

                </div>
            </div> */}
      <div className="w-full py-20">
        <div className="md:container md:mx-auto flex justify-center flex-wrap relative px-4">
          <h2 className="text-[#23408f] text-2xl">FAQ's</h2>
          {allFaqs ? (
            allFaqs.map((item, index) => {
              return (
                <Accordionn key={`keyfaq${index}`} title={item.question} index={index} level={1}>
                  {item.answer}
                </Accordionn>
              );
            })
          ) : (
            <div role="status" className="w-full flex justify-center items-center min-h-[500px]">
              <svg
                aria-hidden="true"
                className="w-20 h-20 mr-2 text-gray-200 animate-spin fill-[#00a3da]"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </div>
      <div className="w-full py-20 bg-white flex flex-col items-center justify-center">
        <img src={liketoSeeImg} alt="Matw" className="max-w-full" />
        <a href="/">
          <img src={clickHereImg} alt="Matw" className="max-w-full" />
        </a>
      </div>
    </NewHelmetLayout>
  );
};

export default FAQsPage;
