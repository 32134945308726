import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-new";
import NewHelmetLayout from "../general/newHelmetLayout";
import DonorMakeDonation from "./DonorMakeDonation";
import {Link, useNavigate} from 'react-router-dom';
import { generateLinkWithLang } from "../../utils/linkGenerator";
import { useTranslation } from 'react-i18next';

function Spinner() {
    return (
        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
            Loading...
        </div>
    );
}

export default function NewDonorLogin() {
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const navigate = useNavigate();

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState('');

    // For the login form
    const [email, setEmail] = useState('');

    // OTP states
    const [otpRequested, setOtpRequested] = useState(false);
    const [code, setCode] = useState('');

    // Data returned by the API calls
    const [userData, setUserData] = useState({
        profileImage: '',
        name: '',
        totalDonated: 0,
        donationCount: 0,
        subscriptions: [],
        receipts: [],
        currency: '$'
    });

    // Will feed into DonorMakeDonation
    const [productList, setProductList] = useState([]);

    // Loading, error, and success states
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    // Collapsible states
    const [subsOpen, setSubsOpen] = useState(true);
    const [receiptsOpen, setReceiptsOpen] = useState(true);

    // Show-only-X-items states (start with 10)
    const [subsToShow, setSubsToShow] = useState(10);
    const [receiptsToShow, setReceiptsToShow] = useState(10);

    // ===========================
    // A. Logout Helper
    // ===========================
    const handleLogout = () => {
        console.warn("Logging out user...");
        setIsLoggedIn(false);
        setToken('');
        setEmail('');
        setOtpRequested(false);
        setCode('');
        setUserData({
            profileImage: '',
            name: '',
            totalDonated: 0,
            donationCount: 0,
            subscriptions: [],
            receipts: [],
            currency: '$'
        });
        setProductList([]);
        setErrorMsg('');
        setSuccessMsg('');

        localStorage.removeItem("matwAuthToken");
        localStorage.removeItem("matwUserData");
        localStorage.removeItem("matwProductList");
        localStorage.removeItem("matwLastActivity");
    };

    // ===========================
    // B. Attempt parse localStorage safely
    // ===========================
    function safeGetLocalJSON(key, defaultValue) {
        try {
            const raw = localStorage.getItem(key);
            if (!raw) return defaultValue;
            const parsed = JSON.parse(raw);
            // If it has any weird shape, fallback to defaultValue:
            if (typeof parsed !== 'object' || parsed === null) return defaultValue;
            return parsed;
        } catch {
            return defaultValue;
        }
    }

    // ===========================
    // 0) Check localStorage on mount
    // ===========================
    useEffect(() => {
        const storedToken = localStorage.getItem("matwAuthToken");
        const storedUserData = safeGetLocalJSON("matwUserData", null);
        const storedProductList = safeGetLocalJSON("matwProductList", null);

        // [SECURITY] track lastActivity for 48-hour timeout
        const lastActivity = localStorage.getItem("matwLastActivity");
        if (lastActivity) {
            const now = Date.now();
            const diff = now - parseInt(lastActivity, 10);
            const hours48 = 48 * 60 * 60 * 1000;
            if (diff > hours48) {
                handleLogout();
                return;
            }
        }

        if (storedToken && storedUserData) {
            console.log("Found stored token & userData in localStorage");
            setToken(storedToken);

            // Ensure subscriptions/receipts arrays exist:
            const safeUserData = {
                profileImage: storedUserData.profileImage || '',
                name: storedUserData.name || '',
                totalDonated: storedUserData.totalDonated || 0,
                donationCount: storedUserData.donationCount || 0,
                subscriptions: Array.isArray(storedUserData.subscriptions) ? storedUserData.subscriptions : [],
                receipts: Array.isArray(storedUserData.receipts) ? storedUserData.receipts : [],
                currency: storedUserData.currency || '$',
                id: storedUserData.id // pass along if present
            };
            setUserData(safeUserData);

            setIsLoggedIn(true);

            if (storedProductList) {
                setProductList(storedProductList);
            }

            if (safeUserData.id) {
                console.log("Re-fetching Subscriptions & Receipts, Stats with userId=", safeUserData.id);
                fetchSubsAndReceipts(safeUserData.id);
                fetchStats(safeUserData.id);
            } else {
                console.log("No userId found in userData, skipping re-fetch");
            }
        }

        // [SECURITY] Update matwLastActivity on user clicks
        const handleActivity = () => {
            localStorage.setItem("matwLastActivity", Date.now().toString());
        };
        window.addEventListener("click", handleActivity);
        return () => {
            window.removeEventListener("click", handleActivity);
        };
    }, []);

    // =======================
    // 1) Request OTP
    // =======================
    const handleRequestOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMsg('');
        setSuccessMsg('');

        try {
            const response = await fetch("https://backend.matwproject.org/api/auth/donor/otp-request", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email })
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => null);
                throw new Error(errorData?.error || "OTP request failed");
            }

            setOtpRequested(true);
            setSuccessMsg("OTP sent to your email!");
        } catch (error) {
            console.error("OTP Request Error:", error);
            setErrorMsg("Error: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    // =======================
    // 2) Verify OTP
    // =======================
    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMsg('');
        setSuccessMsg('');

        try {
            const response = await fetch(
                "https://matw.finbix.io/254fd2a6-5977-40be-8fad-95b5edfa0c98/api/login/verify-otp",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({ email, code })
                }
            );

            if (!response.ok) {
                const errorData = await response.json().catch(() => null);
                throw new Error(errorData?.error || "OTP verification failed");
            }

            const data = await response.json();

            // Save token
            const newToken = data.token || "";
            setToken(newToken);
            setIsLoggedIn(true);

            // Partially fill user data + product list
            const partialUserData = {
                profileImage: data?.userData?.profileImage || '',
                name: data?.userData?.name || '',
                totalDonated: data?.userData?.totalDonated || 0,
                donationCount: data?.userData?.donationCount || 0,
                subscriptions: Array.isArray(data?.userData?.subscriptions) ? data.userData.subscriptions : [],
                receipts: Array.isArray(data?.userData?.receipts) ? data.userData.receipts : [],
                currency: data?.userData?.currency || '$',
                id: data?.userData?.id
            };
            setUserData(partialUserData);

            const incomingProducts = data.productList || [];
            setProductList(incomingProducts);

            // Persist in localStorage
            localStorage.setItem("matwAuthToken", newToken);
            localStorage.setItem("matwUserData", JSON.stringify(partialUserData));
            localStorage.setItem("matwProductList", JSON.stringify(incomingProducts));

            // [SECURITY] set last activity time now
            localStorage.setItem("matwLastActivity", Date.now().toString());

            setSuccessMsg("You have logged in successfully!");

            // After verification, fetch Subscriptions & Receipts
            if (partialUserData.id) {
                await fetchSubsAndReceipts(partialUserData.id);
                await fetchStats(partialUserData.id);
            }

        } catch (error) {
            console.error("OTP Verification Error:", error);
            setErrorMsg("Error: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    // =======================
    // 3) fetchStats
    // =======================
    async function fetchStats(userId) {
        if (!userId) return;
        const localToken = localStorage.getItem("matwAuthToken");
        if (!localToken) {
            handleLogout();
            return;
        }

        try {
            setLoading(true);
            const res = await fetch(
                `https://matw.finbix.io/254fd2a6-5977-40be-8fad-95b5edfa0c98/api/donor/stats?userId=${userId}`,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "x-donor-token": localToken
                    }
                }
            );

            if (!res.ok) {
                if (res.status === 401) {
                    setErrorMsg("Session expired. Please log in again.");
                    handleLogout();
                    return;
                }
                const errorData = await res.json().catch(() => null);
                throw new Error(errorData?.error || "Error fetching stats");
            }

            const data = await res.json();
            if (!data.success) {
                throw new Error(data.error || "Unknown error in stats");
            }

            setUserData(prev => {
                const updated = {
                    ...prev,
                    totalDonated: data.totalDonated || 0,
                    donationCount: data.donationCount || 0,
                    currency: data.currency || '$'
                };
                localStorage.setItem("matwUserData", JSON.stringify(updated));
                return updated;
            });
        } catch (err) {
            console.error("Error fetching donor stats:", err);
            setErrorMsg("Error: " + err.message);
        } finally {
            setLoading(false);
        }
    }

    // =======================
    // 4) fetchSubsAndReceipts
    // =======================
    async function fetchSubsAndReceipts(userId) {
        if (!userId) return;
        const localToken = localStorage.getItem("matwAuthToken");
        if (!localToken) {
            handleLogout();
            return;
        }

        setLoading(true);

        // 4a) Subscriptions
        try {
            const subsRes = await fetch(
                `https://matw.finbix.io/254fd2a6-5977-40be-8fad-95b5edfa0c98/api/donor/subscriptions?userId=${userId}`,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "x-donor-token": localToken
                    }
                }
            );

            if (!subsRes.ok) {
                if (subsRes.status === 401) {
                    setErrorMsg("Session expired. Please log in again.");
                    handleLogout();
                    return;
                }
                const errorData = await subsRes.json().catch(() => null);
                throw new Error(errorData?.error || "Fetching subscriptions failed");
            }

            const subsData = await subsRes.json();
            if (!subsData.success) {
                throw new Error(subsData.error || "Unknown error fetching subscriptions");
            }

            setUserData(prev => {
                const updated = {
                    ...prev,
                    subscriptions: Array.isArray(subsData.subscriptions) ? subsData.subscriptions : []
                };
                return updated;
            });

        } catch (err) {
            console.error("Error fetching subscriptions:", err);
            setErrorMsg("Error: " + err.message);
        }

        // 4b) Receipts
        try {
            const recRes = await fetch(
                `https://matw.finbix.io/254fd2a6-5977-40be-8fad-95b5edfa0c98/api/donor/receipts?userId=${userId}`,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "x-donor-token": localToken
                    }
                }
            );

            if (!recRes.ok) {
                if (recRes.status === 401) {
                    setErrorMsg("Session expired. Please log in again.");
                    handleLogout();
                    return;
                }
                const errorData = await recRes.json().catch(() => null);
                throw new Error(errorData?.error || "Fetching receipts failed");
            }

            const recData = await recRes.json();
            if (!recData.success) {
                throw new Error(recData.error || "Unknown error fetching receipts");
            }

            setUserData(prev => {
                const updated = {
                    ...prev,
                    receipts: Array.isArray(recData.receipts) ? recData.receipts : []
                };
                return updated;
            });
        } catch (err) {
            console.error("Error fetching receipts:", err);
            setErrorMsg("Error: " + err.message);
        } finally {
            // Save final userData to localStorage
            setUserData(latest => {
                localStorage.setItem("matwUserData", JSON.stringify(latest));
                setLoading(false);
                return latest;
            });
        }
    }

    // =======================
    // Styles
    // =======================
    const mainBgStyle = {
        backgroundColor: 'rgb(230, 248, 255)',
        minHeight: '100vh'
    };
    const pageContainerStyle = {
        margin: '0 auto',
        maxWidth: '600px',
        padding: '1rem'
    };
    const boxBorderColor = 'rgb(0, 166, 223)';
    const cardBackgroundColor = '#fff';
    const collapsibleHeaderBG = 'rgb(0, 166, 223)';
    const loginWrapperStyle = {
        minHeight: '80vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };
    const loginFormContainerStyle = {
        border: `1px solid ${boxBorderColor}`,
        borderRadius: '8px',
        padding: '2rem',
        maxWidth: '400px',
        width: '100%',
        textAlign: 'center',
        backgroundColor: cardBackgroundColor
    };
    const inputStyle = {
        padding: '0.5rem',
        fontSize: '1rem',
        border: `1px solid ${boxBorderColor}`,
        borderRadius: '4px'
    };
    const loginButtonStyle = {
        backgroundColor: '#008cba',
        color: '#fff',
        border: 'none',
        padding: '0.6rem 1rem',
        borderRadius: '4px',
        cursor: 'pointer'
    };
    const topBoxStyle = {
        border: `1px solid ${boxBorderColor}`,
        borderRadius: '8px',
        padding: '1rem',
        marginBottom: '1rem',
        backgroundColor: cardBackgroundColor
    };
    const headerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    };
    const profileSectionStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '0.8rem'
    };
    const profileImageStyle = {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        objectFit: 'cover'
    };
    const userNameStyle = {
        fontWeight: 'bold',
        color: 'rgb(0, 166, 223)',
        fontSize: '1.0rem'
    };
    const userTagStyle = {
        fontSize: '0.9rem',
        color: 'rgb(169, 169, 169)'
    };
    const logoutButtonStyle = {
        backgroundColor: 'rgb(0,163,219)',
        color: '#fff',
        border: 'none',
        padding: '0.4rem 0.8rem',
        borderRadius: '8px',
        cursor: 'pointer'
    };
    const statsContainerStyle = {
        display: 'flex',
        marginTop: '1rem',
        gap: '1rem'
    };
    const statBoxStyle = {
        flex: 1,
        border: `1px solid ${boxBorderColor}`,
        borderRadius: '8px',
        padding: '0.8rem',
        textAlign: 'center',
        backgroundColor: cardBackgroundColor
    };
    const favoriteDonationsStyle = {
        border: `1px solid ${boxBorderColor}`,
        borderRadius: '8px',
        backgroundColor: cardBackgroundColor,
        marginBottom: '1rem',
        padding: 0
    };
    const favoriteDonationsTitleStyle = {
        fontWeight: 'bold',
        fontSize: '1rem',
        margin: '1rem',
        color: 'rgb(0, 166, 223)'
    };
    const collapsibleCardStyle = {
        border: `1px solid ${boxBorderColor}`,
        borderRadius: '8px',
        backgroundColor: cardBackgroundColor,
        marginBottom: '1rem',
        overflow: 'hidden'
    };
    const collapsibleHeaderStyle = {
        backgroundColor: collapsibleHeaderBG,
        color: '#fff',
        padding: '0.6rem 1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer'
    };
    const collapsibleContentStyle = {
        backgroundColor: '#fff',
        padding: '1rem'
    };
    const itemRowStyle = {
        border: '1px solid rgb(162,228,246)',
        borderRadius: '4px',
        padding: '0.5rem',
        marginBottom: '0.5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    };
    const itemTitleStyle = {
        color: 'rgb(0,166,223)',
        fontWeight: 'bold'
    };
    const itemMinorTextStyle = {
        fontSize: '0.8rem',
        color: '#666'
    };
    const itemAmountStyle = {
        color: 'rgb(0,166,223)',
        fontWeight: 'bold'
    };
    const linkStyle = {
        marginLeft: '1rem',
        background: 'none',
        border: 'none',
        padding: 0,
        color: 'rgb(0,166,223)',
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: '0.9rem'
    };
    const whiteBgBlueTextButtonStyle = {
        backgroundColor: '#fff',
        color: 'rgb(0,166,223)',
        border: `1px solid rgb(0,166,223)`,
        padding: '0.4rem 0.8rem',
        borderRadius: '8px',
        cursor: 'pointer',
        fontSize: '0.9rem'
    };
    const loadMoreButtonStyle = {
        ...whiteBgBlueTextButtonStyle,
        marginTop: '0.5rem'
    };

    // Safe slicing in case userData is partially corrupted
    const displayedSubs = Array.isArray(userData?.subscriptions)
        ? userData.subscriptions.slice(0, subsToShow)
        : [];

    const displayedReceipts = Array.isArray(userData?.receipts)
        ? userData.receipts.slice(0, receiptsToShow)
        : [];

    const toggleSubsOpen = () => setSubsOpen(!subsOpen);
    const toggleReceiptsOpen = () => setReceiptsOpen(!receiptsOpen);

    const currencySymbol = userData.currency || '$';
    const totalDonatedString = Number(userData.totalDonated).toLocaleString();
    const donationCountString = Number(userData.donationCount).toLocaleString();

    if (!isLoggedIn) {
        return (
            <NewHelmetLayout
                hideHighlights
                hideFooter
                showRegularImg={false}
                hideDonateNowButton
            >
                <Helmet>
                    <title>Muslim Charity | Islamic Charity | 100% Donation Policy | MATW Project</title>
                    <link
                        rel="canonical"
                        href={`${window.location.protocol}//${window.location.host.replace(
                            /^www\./i,
                            ""
                        )}${window.location.pathname}`}
                    />
                    <meta
                        name="description"
                        content="MATW Project - Muslim charity supporting Muslims around the world❤️100% Donation Policy❤️Our Islamic Charity helps YOU make a difference to Muslims in need. We deliver appeals, aid & relief globally."
                    />
                </Helmet>

                <div style={mainBgStyle}>
                    <div style={loginWrapperStyle}>
                        <div style={loginFormContainerStyle}>
                            <h2 style={{ marginBottom: '1rem' }}>Welcome Back to MATW Donor Portal</h2>
                            <form
                                onSubmit={otpRequested ? handleVerifyOtp : handleRequestOtp}
                                style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                            >
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    style={inputStyle}
                                />
                                {otpRequested && (
                                    <input
                                        type="text"
                                        placeholder="Enter OTP code"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                        required
                                        style={inputStyle}
                                    />
                                )}
                                <button type="submit" style={loginButtonStyle}>
                                    {otpRequested ? 'Verify OTP' : 'Request OTP'}
                                </button>

                                {loading && <Spinner />}
                                {errorMsg && <div style={{ color: 'red' }}>{errorMsg}</div>}
                                {successMsg && <div style={{ color: 'green' }}>{successMsg}</div>}
                            </form>
                        </div>
                    </div>
                </div>
            </NewHelmetLayout>
        );
    }

    return (
        <NewHelmetLayout
            hideHighlights
            hideFooter
            showRegularImg={false}
            hideDonateNowButton
        >
            <Helmet>
                <title>Muslim Charity | Islamic Charity | 100% Donation Policy | MATW Project</title>
                <link
                    rel="canonical"
                    href={`${window.location.protocol}//${window.location.host.replace(
                        /^www\./i,
                        ""
                    )}${window.location.pathname}`}
                />
                <meta
                    name="description"
                    content="MATW Project - Muslim charity supporting Muslims around the world❤️100% Donation Policy❤️Our Islamic Charity helps YOU make a difference to Muslims in need. We deliver appeals, aid & relief globally."
                />
            </Helmet>

            <div style={mainBgStyle}>
                <div style={pageContainerStyle}>

                    {/* Top Box (User Info + Stats) */}
                    <div style={topBoxStyle}>
                        <div style={headerStyle}>
                            <div style={profileSectionStyle}>
                                <img
                                    src={
                                        userData.profileImage
                                            ? userData.profileImage
                                            : 'https://cdn.builder.io/api/v1/image/assets%2F32b8c354d4bc455dbf10fed3923dfae1%2F15a2f89722a74bc39d82027c10ed8931'
                                    }
                                    alt="User"
                                    style={profileImageStyle}
                                />
                                <div>
                                    <div style={userNameStyle}>{userData.name}</div>
                                    <div style={userTagStyle}>Building My Legacy</div>
                                </div>
                            </div>
                            <button onClick={handleLogout} style={logoutButtonStyle}>
                                Log Out
                            </button>
                        </div>

                        {/* Stats */}
                        <div style={statsContainerStyle}>
                            <div style={statBoxStyle}>
                                <div style={{
                                    fontSize: '1.3rem',
                                    fontWeight: 'bold',
                                    color: 'rgb(0, 166, 223)'
                                }}>
                                    {currencySymbol}{totalDonatedString}
                                </div>
                                <div style={{ color: '#666', fontSize: '0.9rem' }}>
                                    Total Donated
                                </div>
                            </div>
                            <div style={statBoxStyle}>
                                <div style={{
                                    fontSize: '1.3rem',
                                    fontWeight: 'bold',
                                    color: 'rgb(0, 166, 223)'
                                }}>
                                    {donationCountString}
                                </div>
                                <div style={{ color: '#666', fontSize: '0.9rem' }}>
                                    Donations
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Make a Donation */}
                    <div style={favoriteDonationsStyle}>
                        <div style={favoriteDonationsTitleStyle}>My Favorite Donations</div>
                        <DonorMakeDonation productList={productList} />
                    </div>

                    {/* My Subscriptions (Collapsible) */}
                    <div style={collapsibleCardStyle}>
                        <div style={collapsibleHeaderStyle} onClick={toggleSubsOpen}>
                            <span style={{ fontWeight: 'bold' }}>My Subscriptions</span>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <span>{subsOpen ? '▲' : '▼'}</span>
                                <button
                                    style={whiteBgBlueTextButtonStyle}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(generateLinkWithLang('/subscription', lang));
                                    }}
                                >
                                    Add
                                </button>
                            </div>
                        </div>

                        {subsOpen && (
                            <div style={collapsibleContentStyle}>
                                {displayedSubs.length ? (
                                    <>
                                        {displayedSubs.map((sub) => (
                                            <div key={`sub-${sub.id}`} style={itemRowStyle}>
                                                <div>
                                                    <div style={itemTitleStyle}>{sub.title}</div>
                                                    <div style={itemMinorTextStyle}>
                                                        Donation Start Date: {sub.nextDonationDate}
                                                    </div>
                                                </div>
                                                <div>
                                                    <span style={itemAmountStyle}>
                                                        {sub.currency}{Number(sub.amount).toLocaleString()}
                                                    </span>
                                                    <button style={linkStyle}>Change</button>
                                                </div>
                                            </div>
                                        ))}
                                        {userData.subscriptions.length > subsToShow && (
                                            <button
                                                style={loadMoreButtonStyle}
                                                onClick={() => setSubsToShow(prev => prev + 10)}
                                            >
                                                Load More
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ marginBottom: '0.5rem' }}>No Subscriptions yet.</p>
                                        <button
                                            style={whiteBgBlueTextButtonStyle}
                                            onClick={() => navigate(generateLinkWithLang('/subscription', lang))}
                                        >
                                            Add Subscription
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {/* My Receipts (Collapsible) */}
                    <div style={collapsibleCardStyle}>
                        <div style={collapsibleHeaderStyle} onClick={toggleReceiptsOpen}>
                            <span style={{ fontWeight: 'bold' }}>My Receipts</span>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <span>{receiptsOpen ? '▲' : '▼'}</span>
                                <button
                                    style={whiteBgBlueTextButtonStyle}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(generateLinkWithLang('/top-10', lang));
                                    }}
                                >
                                    Donate
                                </button>
                            </div>
                        </div>

                        {receiptsOpen && (
                            <div style={collapsibleContentStyle}>
                                {displayedReceipts.length ? (
                                    <>
                                        {displayedReceipts.map((receipt) => (
                                            <div key={`receipt-${receipt.id}`} style={itemRowStyle}>
                                                <div>
                                                    <div style={itemTitleStyle}>{receipt.title}</div>
                                                    <div style={itemMinorTextStyle}>
                                                        {receipt.type || 'One-Off'} | Donated {receipt.date}
                                                    </div>
                                                </div>
                                                <div>
                                                    <span style={itemAmountStyle}>
                                                        {receipt.currency}{Number(receipt.amount).toLocaleString()}
                                                    </span>
                                                    <Link style={linkStyle} to={receipt.receiptUrl}>View Receipt</Link>
                                                </div>
                                            </div>
                                        ))}
                                        {userData.receipts.length > receiptsToShow && (
                                            <button
                                                style={loadMoreButtonStyle}
                                                onClick={() => setReceiptsToShow(prev => prev + 10)}
                                            >
                                                Load More
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ marginBottom: '0.5rem' }}>No Receipts yet.</p>
                                        <button
                                            style={whiteBgBlueTextButtonStyle}
                                            onClick={() => navigate(generateLinkWithLang('/top-10', lang))}
                                        >
                                            Donate Now
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {loading && <Spinner />}
                    {errorMsg && <div style={{ color: 'red', marginTop: '1rem' }}>{errorMsg}</div>}
                    {successMsg && <div style={{ color: 'green', marginTop: '1rem' }}>{successMsg}</div>}
                </div>
            </div>
        </NewHelmetLayout>
    );
}