import {
    calculateTimeLeft,
    convertTo12Hour,
    convertTo24Hour,
    formatTimeLeft,
    getCurrentLocation,
    getDefaultPrayerTimes, getLatLngFromApi,
    getPrayersDataUsingLatLng
} from "./utils";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-new";
import NewHelmetLayout from "../../components/general/newHelmetLayout";
import countryData from "./CountriesAndCitiesDataset";
import {IftarTimeContent} from "./IftarTimeContent";
import {SehriTimeContent} from "./SehriTimeContent";

export const SehrIftarTime = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [sehrIftarTime, setSehrIftarTime] = useState("00:00");
    const [calculatedSehrIftarTime, setCalculatedSehrIftarTime] = useState("00:00");
    const [shouldShow, setShouldShow] = useState(false);
    const [timeLeftSunrise, setTimeLeftSunrise] = useState("");
    const [error, setError] = useState(null);
    const [locationData, setLocationData] = useState({
        city: null, country: null, coords: {
            latitude: null, longitude: null,
        }
    }); // {city, country}
    const [prayerTimes, setPrayerTimes] = useState({
        prayerTimesData: [{
            prayerTimeData: [], date: null, prayerTimeCalculationMethod: null, prayerTimeTimezone: "",
        }]
    });

    const [calculatedPrayerTimes, setCalculatedPrayerTimes] = useState({
        prayerTimesData: [{
            prayerTimeData: [], date: null, prayerTimeCalculationMethod: null, prayerTimeTimezone: "",
        }]
    });
    const [dataReady, setDataReady] = useState(false);
    const navigate = useNavigate();

    const pathSegments = location.pathname.toLowerCase()
        .replace(".org/fr", ".org") // remove leading lang
        .replace(".org.uk/fr", ".org.uk") // remove leading lang
        .replace(/^\/(iftar-time-today|sehri-time-today)\/*/, "")
        .split("/")
        .filter(Boolean);

    // We'll parse these out:
    let finalCountry = "";
    let finalCity = "";
    let sehriIftarLocationTag = location.pathname.toLowerCase().includes('-today') ? location.pathname.toLowerCase() : location.pathname.toLowerCase().split('/')[1];

    /**
     * parseRouteSegments:
     *   - 0 segments => IP-based
     *   - 1 segment  => could be single prayer or city or country
     *   - 2 segments => either (country, single-prayer) or (country, city)
     */
    function parseRouteSegments() {

        if (pathSegments.length === 0) {
            return;
        }

        if (pathSegments.length === 1) {

            const seg = pathSegments[0];
            if (seg.length <= 3) {
                finalCountry = seg; // e.g. 'uk'
            } else {
                finalCity = seg; // e.g. 'london'
            }
            return;
        }

        if (pathSegments.length === 2) {
            // Possibly "iftar-time/iftar-time-ilford" or "uk/london" or "uk/iftar-time-london" or "uk/sehri-time-london"
            const [seg1, seg2] = pathSegments;

            if (location.pathname.toLowerCase().includes('sehri') && location.pathname.toLowerCase().includes('iftar')) {
                navigate(`/${sehriIftarLocationTag.includes("sehri") ? "sehri" : "iftar"}-time-today/`);
                return;
            }

            if (seg1.toLowerCase() === 'iftar-time' || seg1.toLowerCase() === 'sehri-time'){
                finalCity = seg2.split('-')[2];
                return;
            }else{
                finalCountry = seg1;
                finalCity = seg2;
                return;
            }
        }
    }

    parseRouteSegments();

    const handleMethodChange = (selectedMethod, event) => {
        if (event) {
            event.preventDefault();
        }
        fetchCalculatedSehroIftarTime(selectedMethod)
    };

    function fetchCalculatedSehroIftarTime(selectedMethod) {
        // Don't set loading to true when changing calculation method
        // This allows the UI to remain visible during method changes
        fetchPrayerTimesByMethod(locationData, selectedMethod, true);
    }

    async function fetchPrayerTimesByMethod(locationData, methodId, isMethodChange = false) {
        try {
            debugger
            if (!locationData.city && !locationData.country) return;

            // Only update loading state if this is not a method change
            // if (!isMethodChange) {
            //     setLoading(true);
            //     setDataReady(false);
            // }

            // const locationResponse = await getLatLngFromApi(locationData.country, locationData.city);
            // const response = await getPrayersDataUsingLatLng(latitude, longitude, 'times');

            const response = await getPrayersDataUsingLatLng(
                locationData.coords.latitude || locationData.coords.lat,
                locationData.coords.longitude || locationData.coords.lng,
                'times', methodId);

            if (!response.ok) {
                setCalculatedPrayerTimes({
                    prayerTimeData: getDefaultPrayerTimes(),
                    prayerTimeCalculationMethod: "No method used",
                    prayerTimeTimezone: "",
                });

                // Set loading/data ready states only if not a method change
                if (!isMethodChange) {
                    setLoading(false);
                    setDataReady(true);
                }
                return;
            }
            const data = await response.json();

            let prayerTimeDataObject = null

            if (data.data?.timings) {
                prayerTimeDataObject = {
                    prayerTimesData: [{
                        prayerTimeData: [{name: "Fajr", time: convertTo12Hour(data.data.timings.Fajr)}, {
                            name: "Zuhr",
                            time: convertTo12Hour(data.data.timings.Dhuhr)
                        }, {name: "Asr", time: convertTo12Hour(data.data.timings.Asr)}, {
                            name: "Maghrib",
                            time: convertTo12Hour(data.data.timings.Maghrib)
                        }, {name: "Isha", time: convertTo12Hour(data.data.timings.Isha)}, {
                            name: "Sunrise", time: convertTo12Hour(data.data.timings.Sunrise),
                        },],
                        prayerTimeCalculationMethod: data.data.meta?.method?.name || "",
                        prayerTimeTimezone: data.data.meta?.timezone || "UTC",
                    }]
                }
            } else if (Array.isArray(data?.data)) {
                // Monthly data
                prayerTimeDataObject = {
                    prayerTimesData: data.data.map(day => {
                        return {
                            prayerTimeData: [{
                                name: "Fajr", time: convertTo12Hour(day.timings.Fajr)
                            }, {
                                name: "Sunrise", time: convertTo12Hour(day.timings.Sunrise)
                            }, {
                                name: "Zuhr", time: convertTo12Hour(day.timings.Dhuhr)
                            }, {
                                name: "Asr", time: convertTo12Hour(day.timings.Asr)
                            }, {
                                name: "Maghrib", time: convertTo12Hour(day.timings.Maghrib)
                            }, {
                                name: "Isha", time: convertTo12Hour(day.timings.Isha)
                            }],
                            date: day.date,
                            prayerTimeCalculationMethod: data.data.meta?.method?.name || "",
                            prayerTimeTimezone: data.data.meta?.timezone || "UTC",
                        }
                    })
                }
            }

            setCalculatedPrayerTimes(prayerTimeDataObject);
        } catch (err) {
            setError(err.message);
            setCalculatedPrayerTimes({
                prayerTimeData: getDefaultPrayerTimes(),
                prayerTimeCalculationMethod: "No method used",
                prayerTimeTimezone: "",
            });
        } finally {
            // Now that we have both primary and calculated prayer times, we can show the UI
            // Only update loading state if this is not a method change
            if (!isMethodChange) {
                setLoading(false);
                setDataReady(true);
            }
        }
    }

    useEffect(() => {
        if (calculatedPrayerTimes?.prayerTimesData?.length > 0 && calculatedPrayerTimes?.prayerTimesData[0]?.prayerTimeData?.length) {
            const found = calculatedPrayerTimes?.prayerTimesData[0]?.prayerTimeData.find((p) => p.name?.toLowerCase() === (sehriIftarLocationTag.includes("iftar") ? "Maghrib" : "Fajr").toLowerCase());
            setCalculatedSehrIftarTime(found?.time || "00:00");
        }
    }, [calculatedPrayerTimes, sehriIftarLocationTag]);

    // Set single prayer time from main data
    useEffect(() => {
        if (prayerTimes?.prayerTimesData[0]?.prayerTimeData?.length) {
            const found = prayerTimes?.prayerTimesData[0]?.prayerTimeData.find((p) => p.name?.toLowerCase() === (sehriIftarLocationTag.includes("iftar") ? "Maghrib" : "Fajr").toLowerCase());
            setSehrIftarTime(found?.time || "00:00");

            const sunriseTimer = () => {
                const sunriseObj = prayerTimes?.prayerTimesData[0]?.prayerTimeData.find((p) => p.name.toLowerCase() === "sunrise");
                if (!sunriseObj) return;

                // Get current time in the specified timezone
                const nowStr = new Date().toLocaleString("en-US", {timeZone: prayerTimes?.prayerTimesData[0].prayerTimeTimezone});
                const now = new Date(nowStr);

                // Convert sunrise time to 24 hour format
                const {hours: sunriseHours, minutes: sunriseMinutes} = convertTo24Hour(sunriseObj.time);

                // Create sunrise datetime for today
                const sunriseTime = new Date(now);
                sunriseTime.setHours(sunriseHours, sunriseMinutes, 0);

                // Check if current time is before sunrise
                const isBeforeSunrise = now < sunriseTime;
                setShouldShow(isBeforeSunrise);

                if (isBeforeSunrise) {
                    const diff = calculateTimeLeft(sunriseObj, prayerTimes?.prayerTimesData[0].prayerTimeTimezone);
                    setTimeLeftSunrise(formatTimeLeft(diff));
                }
            };

            if (location.pathname.includes("sehri")) {
                sunriseTimer();
                const interval = setInterval(sunriseTimer, 1000);
                return () => clearInterval(interval);
            }
        }
    }, [prayerTimes, location.pathname, sehriIftarLocationTag]);

    /**
     * Utility to find a country in your dataset by shortCode or name
     */
    function findCountryInDataset(_country) {
        const allCountries = [
            ...countryData.muslimCountries,
            ...countryData.westernCountries,
        ];
        return allCountries.find(
            (c) =>
                c.name.toLowerCase() === _country.toLowerCase() ||
                c.shortCode?.toLowerCase() === _country.toLowerCase() ||
                c.shortCode2?.toLowerCase() === _country.toLowerCase()
        );
    }

    /**
     * (A) Main effect to fetch times after we figure out route-based city/country
     */
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setDataReady(false);

                // 1) If no city/country => try IP-based
                if (!finalCity && !finalCountry) {
                    await initLocationFromIP();
                } else {
                    await fetchTimesOrCoords(finalCountry, finalCity);
                }
            } catch (err) {
                console.error(err);
                setError(err.message);
            }
        })();
        // eslint-disable-next-line
    }, [finalCountry, finalCity]);


    async function fetchTimesOrCoords(_country, _city) {
        // If only country => pick its top city from the dataset
        if (_country && !_city) {
            const found = findCountryInDataset(_country);
            if (found && found.majorCities?.length) {
                _city = found.majorCities[0].name; // pick #1 city
            }
        }

        // If we now have a city & country => fetch times directly
        if (_city && _country) {
            const response = await getLatLngFromApi(_country, _city);

            setLocationData({
                city: _city, country: _country, coords: {
                    lat: response.data.lat,
                    lng: response.data.lng,
                }
            });
            await fetchPrayerTimes(response.data.lat, response.data.lng);
            return;
        }

        // If we only have a city => we can try to geocode it
        if (_city && !_country) {
            try {
                const response = await getLatLngFromApi(_country, _city);

                setLocationData({
                    city: _city, country: _country, coords: {
                        lat: response.data.lat,
                        lng: response.data.lng,
                    }
                });
                await fetchPrayerTimes(response.data.lat, response.data.lng);
            } catch (err) {
                // fallback
                setError(err.message);
                await initLocationFromIP();
            }
            return;
        }

        // If we have no city & no country => fallback
        if (!_city && !_country) {
            await initLocationFromIP();
        }
    }

    async function initLocationFromIP() {
        try {
            const position = await getCurrentLocation();
            const cityTmp = position?.city || "UnknownCity";
            const countryTmp = position?.country || "UnknownCountry";

            setLocationData({
                city: cityTmp,
                country: countryTmp,
                coords: {latitude: position.coords.latitude, longitude: position.coords.longitude}
            });

            await fetchPrayerTimes(position.coords.latitude, position.coords.longitude);
        } catch (err) {
            setError(err.message || "Failed IP-based location");
            setPrayerTimes({
                prayerTimeData: getDefaultPrayerTimes(),
                prayerTimeCalculationMethod: "No method used",
                prayerTimeTimezone: "",
            });
            setLoading(false);
            setDataReady(true);
        }
    }

    useEffect(() => {
        if (locationData.city || locationData.country) {
            // Initial fetch when location changes should show loading
            fetchPrayerTimesByMethod(locationData, 1, false);
        }
    }, [locationData]);

    async function fetchPrayerTimes(latitude, longitude) {
        try {
            setLoading(true);
            setDataReady(false);

            debugger
            const response = await getPrayersDataUsingLatLng(latitude, longitude, 'times');

            if (!response.ok) {
                setPrayerTimes({
                    prayerTimeData: getDefaultPrayerTimes(),
                    prayerTimeCalculationMethod: "No method used",
                    prayerTimeTimezone: "",
                });
                // Don't set loading to false or dataReady to true here
                // We'll wait for fetchPrayerTimesByMethod to complete
                return;
            }

            const data = await response.json();
            let prayerTimeDataObject = null;

            if (data.data?.timings) {
                prayerTimeDataObject = {
                    prayerTimesData: [{
                        prayerTimeData: [{name: "Fajr", time: convertTo12Hour(data.data.timings.Fajr)}, {
                            name: "Zuhr",
                            time: convertTo12Hour(data.data.timings.Dhuhr)
                        }, {name: "Asr", time: convertTo12Hour(data.data.timings.Asr)}, {
                            name: "Maghrib",
                            time: convertTo12Hour(data.data.timings.Maghrib)
                        }, {name: "Isha", time: convertTo12Hour(data.data.timings.Isha)}, {
                            name: "Sunrise", time: convertTo12Hour(data.data.timings.Sunrise),
                        },],
                        prayerTimeCalculationMethod: data.data.meta?.method?.name || "",
                        prayerTimeTimezone: data.data.meta?.timezone || "UTC",
                    }]
                }
            } else if (Array.isArray(data?.data)) {
                // Monthly data
                prayerTimeDataObject = {
                    prayerTimesData: data.data.map(day => {
                        return {
                            prayerTimeData: [{
                                name: "Fajr", time: convertTo12Hour(day.timings.Fajr)
                            }, {
                                name: "Sunrise", time: convertTo12Hour(day.timings.Sunrise)
                            }, {
                                name: "Zuhr", time: convertTo12Hour(day.timings.Dhuhr)
                            }, {
                                name: "Asr", time: convertTo12Hour(day.timings.Asr)
                            }, {
                                name: "Maghrib", time: convertTo12Hour(day.timings.Maghrib)
                            }, {
                                name: "Isha", time: convertTo12Hour(day.timings.Isha)
                            }],
                            date: day.date,
                            prayerTimeCalculationMethod: data.data.meta?.method?.name || "",
                            prayerTimeTimezone: data.data.meta?.timezone || "UTC",
                        }
                    })
                }
            }

            setPrayerTimes(prayerTimeDataObject);

            // After setting the main prayer times, we need to ensure calculated times are fetched
            // We don't set loading/dataReady here since fetchPrayerTimesByMethod will handle that
        } catch (err) {
            setError(err.message);
            setPrayerTimes({
                prayerTimeData: getDefaultPrayerTimes(),
                prayerTimeCalculationMethod: "No method used",
                prayerTimeTimezone: "",
            });

            // In case of error, we still need to get calculated prayer times
            setLoading(true);
            setDataReady(false);
        }
    }

    if (error) {
        setError(null);
        navigate(`/${sehriIftarLocationTag.includes("sehri") ? "sehri" : "iftar"}-time-today/`);
    }

    // Format country name
    const formatCountryName = (name) => {
        if (!name) return "";
        if (name.length === 2) return name.toUpperCase();
        return name
            .replace(/-/g, " ")
            .replace(/\b\w/g, (c) => c.toUpperCase());
    };

    return (<>
        <NewHelmetLayout className="bg-white" hideFooter showRegularImg={false}>
            <Helmet>
                <title>
                    {
                        !loading && dataReady && pathSegments.length > 0 && locationData?.city
                            ? `${sehriIftarLocationTag.includes("iftar") ? "Iftar" : "Sehri"} time in ${
                                locationData.city
                                    .replace(/-/g, " ")
                                    .replace(/\b\w/g, (c) => c.toUpperCase())
                            } ${sehriIftarLocationTag.includes("iftar") ? ` | Iftar time today in ${locationData.city
                                .replace(/-/g, " ")
                                .replace(/\b\w/g, (c) => c.toUpperCase())}` : ` | Sehri time today in ${locationData.city
                                .replace(/-/g, " ")
                                .replace(/\b\w/g, (c) => c.toUpperCase())}`}`
                            : `${sehriIftarLocationTag.includes("iftar") ? "Iftar" : "Sehri"} time today | ${sehriIftarLocationTag.includes("iftar") ? `Iftar time 2025` : `Sehri time 2025` }`
                    }
                </title>
                <link
                    rel="canonical"
                    href={`${window.location.protocol}//matwproject.org${window.location.pathname.toLowerCase()}`}
                />
                <meta
                    name="description"
                    content=  {
                        !loading && dataReady && locationData?.city
                            ? `${sehriIftarLocationTag.includes("iftar") ? "Iftar" : "Sehri"} time in ${
                                locationData.city
                                    .replace(/-/g, " ")
                                    .replace(/\b\w/g, (c) => c.toUpperCase())
                            } | ${sehriIftarLocationTag.includes("iftar") ? `Iftar time today in ${locationData.city
                                .replace(/-/g, " ")
                                .replace(/\b\w/g, (c) => c.toUpperCase())}` : ""}`
                            : `${sehriIftarLocationTag.includes("iftar") ? "Iftar" : "Sehri"} time today | Find accurate timing for your location`
                    }
                />

                <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb"/>
                <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default"/>
                <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca"/>
                <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr"/>
                <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us"/>
                <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id"/>
                <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my"/>
                <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg"/>
            </Helmet>

            {loading && !dataReady && <div className="flex items-center justify-center w-full h-screen">
                <div
                    className="text-xl font-semibold">Loading {sehriIftarLocationTag.includes("iftar") ? "Iftar" : "Sehri"} time...
                </div>
            </div>}

            {dataReady && <div className="w-full bg-white px-4 md:px-6">
                <div className="text-center space-y-4 md:space-y-6">
                    <h1 className="text-gray-600 text-xl md:text-2xl p-4 md:p-10">
                        {sehriIftarLocationTag.includes("iftar") ? "Iftar" : "Sehri"} Time in{" "}
                        {locationData?.city
                            ?.replace(/-/g, " ")
                            ?.replace(/\b\w/g, (c) => c.toUpperCase())}
                        {locationData?.country ? `, ${formatCountryName(locationData.country)}` : ""}{" "}
                        today is at:
                    </h1>
                    <div className="text-[#F60362] text-3xl md:text-5xl font-bold">
                        {sehrIftarTime}
                    </div>
                </div>

                {/*/!* If it's Fajr, show sunrise countdown *!/*/}
                {location.pathname.includes("sehri") && shouldShow && (<div className="text-center mt-6 md:mt-8">
                    <h2 className="text-gray-600 text-lg md:text-xl">
                        Time left till Sunrise in{" "}
                        {locationData?.city
                            ?.replace(/-/g, " ")
                            .replace(/\b\w/g, (c) => c.toUpperCase())}
                        :
                    </h2>
                    <div className="text-[#F60362] text-xl md:text-2xl font-bold mt-2">
                        {timeLeftSunrise}
                    </div>
                </div>)}

                <div className="bg-[#F60362] text-white py-6 md:py-8 px-4 md:px-6 mt-6 md:mt-8 text-center rounded">
                    <p className="mt-2 text-sm md:text-base">
                        Give a small amount to charity with our 100% donation policy and seek
                        your reward with Allah swt.
                    </p>
                    <button
                        onClick={() => navigate("/top-10")}
                        className="bg-white text-[#F60362] font-bold px-6 md:px-8 py-2 mt-4 rounded hover:bg-gray-100 transition-colors text-sm md:text-base"
                    >
                        Donate
                    </button>
                </div>

                {
                    calculatedPrayerTimes?.prayerTimesData?.length && (
                        <>
                            <div className="mt-6 md:mt-8">
                                <div className="flex flex-col md:flex-row justify-center gap-2 md:gap-4">
                                    {[{id: 1, text: "(UISK)"}, {id: 2, text: "(ISNA)"}, {id: 3, text: "(MWL)"}, {
                                        id: 4, text: "(UIS)"
                                    }].map((method) => (<button
                                        key={method.id}
                                        onClick={(event) => handleMethodChange(method.id, event)}
                                        className="bg-[#F60362] text-white px-4 md:px-8 py-2 rounded-md text-sm md:text-base whitespace-normal md:whitespace-nowrap hover:bg-[#d1034f] transition-colors"
                                    >
                                        {method.text}
                                    </button>))}
                                </div>
                            </div>
                            <div className="text-center mt-8 md:mt-12">
                                <h2 className="text-gray-600 text-lg md:text-xl px-2">
                                    {(sehriIftarLocationTag.includes("iftar") ? "Iftar" : "Sehri")} Time in{" "}
                                    {locationData?.city
                                        ?.replace(/-/g, " ")
                                        ?.replace(/\b\w/g, (c) => c.toUpperCase())}{" "}
                                    {calculatedPrayerTimes?.prayerTimesData?.[0]?.prayerTimeCalculationMethod &&
                                        `according to ${calculatedPrayerTimes.prayerTimesData[0].prayerTimeCalculationMethod}`}:
                                </h2>
                                <div className="text-[#F60362] text-3xl md:text-5xl font-bold mt-4">
                                    {calculatedSehrIftarTime}
                                </div>
                            </div>
                        </>
                    )
                }

                <div className="bg-blue-600 text-white p-4 md:p-6 mt-6 md:mt-8 rounded text-center">
                    <p className="text-sm md:text-base">
                        Muslims Around the World (MatW) Project — Bridging Faith and Technology. Empowering the Muslim
                        community through innovative digital solutions, accurate prayer times, Sehr'O'Iftar times, and
                        reliable Qibla directions.
                    </p>
                </div>

                <div className="flex justify-center items-center bg-gray-50">
                    {
                        sehriIftarLocationTag.includes("iftar") ? (
                            <IftarTimeContent city={locationData?.city ? locationData.city.charAt(0).toUpperCase() + locationData.city.slice(1) : ''}/>
                        ) : (
                            <SehriTimeContent city={locationData?.city ? locationData.city.charAt(0).toUpperCase() + locationData.city.slice(1) : ''} />
                        )
                    }
                </div>
            </div>}
        </NewHelmetLayout>
    </>)
};