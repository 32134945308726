import { useEffect, useState } from 'react';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import CrisisWidgetV2 from '../../components/general/crisisWidgetV2';
import RamadanDailyDonationWidget from '../../components/ramadan/ramadanDailyDonation';
import axios from 'axios';
import HijriDate from 'hijri-date';
// Import moment first
// import moment from 'moment';

// Then import moment-hijri to extend moment
// import 'moment-hijri';

const TestWidgetPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const products = [
    'EMR-LEB-24-000',
    'EMR-DIS-PSE-2023-01',
    'EMR-DIS-PSE-2023-01-01',
    'MKD-WEL-SDQ-2023-01',
    'MKD-SDQ-OAW-2023-02-119',
    'EM-SDQ-EQ1-02-2023',
    'MKD-EMR-WNT-2023-01',
  ]
  const [ramadanStartGregorian, setRamadanStartGregorian] = useState(null);
  const [ramadanStartHijri, setRamadanStartHijri] = useState(null);
  const [error, setError] = useState(null);
  var moment = require('moment-hijri');
  // console.log(moment().format('iYYYY/iM/iD'));
  /* -------------------------------------------------------------------------- */
  // const getUserLocation = () => {
  //     return new Promise((resolve, reject) => {
  //         if (!navigator.geolocation) {
  //             reject(new Error("Geolocation not supported."));
  //         } else {
  //             navigator.geolocation.getCurrentPosition(
  //                 (position) => {
  //                     resolve({
  //                         latitude: position.coords.latitude,
  //                         longitude: position.coords.longitude,
  //                     });
  //                 },
  //                 (error) => reject(error)
  //             );
  //         }
  //     });
  // };
  // const getRamadanStartDate = async (latitude, longitude, year) => {
  //     try {
  //       // Ramadan is the 9th month in the Islamic calendar
  //       // However, Islamic months do not directly map to Gregorian months
  //       // To find Ramadan, we need to check the Hijri month

  //       // Loop through all Gregorian months to find Ramadan
  //       for (let month = 1; month <= 12; month++) {
  //         const response = await axios.get('https://api.aladhan.com/v1/calendar', {
  //           params: {
  //             latitude,
  //             longitude,
  //             method: 2, // Muslim World League
  //             month,
  //             year,
  //           },
  //         });

  //         if (response.status === 200) {
  //           const data = response.data.data;

  //           // Iterate through each day to find the first day of Ramadan
  //           for (let day of data) {
  //             if (
  //               day.date &&
  //               day.date.hijri &&
  //               day.date.hijri.month &&
  //               day.date.hijri.month.en === 'Ramadan' &&
  //               day.date.hijri.day === '1'
  //             ) {
  //               return day.date.gregorian.date; // e.g., "12-04-2024"
  //             }
  //           }
  //         } else {
  //           throw new Error(`Unexpected response status: ${response.status}`);
  //         }
  //       }

  //       throw new Error('Ramadan start date not found for the given year.');
  //     } catch (error) {
  //       console.error("Error fetching Ramadan start date:", error);
  //       throw error;
  //     }
  //   };
  /* -------------------------------------------------------------------------- */
  const getRamadanStartDate = (currentYear) => {
    try {
      // Verify moment-hijri integration
      console.log(`Does moment have iYear function?`, typeof moment().iYear);
      console.log(`Does moment have iMonth function?`, typeof moment().iMonth);
      console.log(`Does moment have iDate function?`, typeof moment().iDate);

      if (typeof moment().iYear !== 'function') {
        throw new Error("moment-hijri is not correctly integrated.");
      }

      // Refined Hijri year approximation
      const approximateHijriYear = currentYear - 622 + Math.floor((currentYear - 622) / 32.21833);
      console.log(`Approximated Hijri Year: ${approximateHijriYear}`);

      // Ramadan is the 9th month in the Hijri calendar (0-indexed: 8)
      const ramadanMonthIndex = 8;

      // Create a Hijri date for Ramadan 1
      const ramadanStartHijri = moment()
        .iYear(approximateHijriYear)
        .iMonth(ramadanMonthIndex)
        .iDate(1); // 1st day of Ramadan

      console.log(`Hijri Date: ${ramadanStartHijri.format('iYYYY-iMM-iDD')}`);

      // Convert to Gregorian date
      const gregorianDate = ramadanStartHijri.clone().locale('en').format('MMMM Do, YYYY');
      console.log(`Gregorian Date: ${gregorianDate}`);

      // Also, keep the Hijri date
      const hijriDateFormatted = ramadanStartHijri.format('iMMMM iDo, iYYYY');
      console.log(`Formatted Hijri Date: ${hijriDateFormatted}`);

      return { gregorianDate, hijriDateFormatted };
    } catch (err) {
      console.error("Error converting Hijri to Gregorian date:", err);
      throw err;
    }
  };

  useEffect(() => {
    try {
      const currentYear = new Date().getFullYear();
      console.log(`Current Gregorian Year: ${currentYear}`);

      const { gregorianDate, hijriDateFormatted } = getRamadanStartDate(currentYear);
      console.log(`Ramadan Start Date (Gregorian): ${gregorianDate}`);
      console.log(`Ramadan Start Date (Hijri): ${hijriDateFormatted}`);

      setRamadanStartGregorian(gregorianDate);
      setRamadanStartHijri(hijriDateFormatted);
    } catch (err) {
      setError(err.message);
    }
  }, []);

  /* -------------------------------------------------------------------------- */
  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!ramadanStartGregorian) {
    return <div>Loading Ramadan start date...</div>;
  }
  return (
    <NewHelmetLayout hideFooter className="bg-white" style={{ fontFamily: 'Infoma, sans-serif' }}>
      <div className="min-h-[300px] flex items-center justify-center w-full md:w-2/3 mx-auto">
        <h1 className='!font-Geon- !font-Infoma text-4xl text-DarkerBlue'><strong>Infoma</strong> Font Family</h1>
        {/* <div className="w-full mx-auto my-4"> */}
          {/* <HtmlContent htmlCode={rawHtml} /> */}
          {/* <CrisisWidgetV2 productsList={products}
                        // defaultName="Sadaqah Jariyah"
                        defaultCreator="MKD-GEN-MSD-2023-01-121" /> */}
        {/* </div> */}
      </div>
      {/* <div>
                <h1 className='!font-Geon'>First Day of Ramadan</h1>
                <p><strong>Gregorian Date:</strong> {ramadanStartGregorian}</p>
                <p><strong>Hijri Date:</strong> {ramadanStartHijri}</p>
                <p>Please note that the exact start date may vary based on local moon sightings.</p>
            </div> */}
      <div className="w-full md:w-2/3 mx-auto !font-Geon- !font-Infoma">
        <RamadanDailyDonationWidget productsList={products} />
      </div>
    </NewHelmetLayout >
  );
}

export default TestWidgetPage;
