import React from "react";

export const SehriTimeContent = (props) => {

    const { city } = props;

    return (
        <div className="mb-4 p-3 md:p-4 bg-blue-50 rounded-lg mx-2 md:mx-auto max-w-[800px] m-2">
            <div className="text-left">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">Suhoor Time in {city} | Daily Suhoor Time</h2>

                <p className="text-gray-600 text-base md:text-lg leading-relaxed">
                    Need the correct <span
                    className="font-bold">Suhoor time in {city}?
                </span> Eating your pre-dawn meal before Fajr is crucial to keeping your fast throughout the day. Our Suhoor timetable ensures you stop eating on time and prepare for your day of fasting with ease.
                </p>

                <br/>
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                    Why Check Suhoor Times in {city}?
                </h2>
                <ul className="space-y-2 list-none">
                    <li className="text-gray-800">
                        <span className="font-bold">✅ Precise & Verified:</span> We provide the most accurate Suhoor time, so you can complete your meal before Fajr without any uncertainty.
                    </li>
                    <li className="text-gray-800">
                        <span className="font-bold">✅ Easy & Accessible: </span> Whether you’re at home or traveling, our timetable keeps you informed about the latest Suhoor ending time in {city}.
                    </li>
                    <li className="text-gray-800">
                        <span className="font-bold">✅ Fajr Time Updates: </span> Our listings include Fajr Salah times, helping you transition smoothly from Suhoor to your morning prayers.
                    </li>
                </ul>


                <h3 className="text-xl font-bold text-gray-800 mt-8 mb-6">
                    How to Use Our Suhoor Timetable
                </h3>

                <ul className="space-y-2 list-none">
                    <li className="text-gray-800">
                        <span className="font-bold">🔸 Check the Daily Suhoor End Time: </span> Avoid eating beyond Fajr Adhan by referring to the updated schedule.
                    </li>
                    <li className="text-gray-800">
                        <span className="font-bold">🔸 Plan Your Morning Routine: </span> Allow time for hydration, dua, and prayer before Fajr begins.
                    </li>
                    <li className="text-gray-800">
                        <span className="font-bold">🔸 Stay Informed: </span> Bookmark this page to easily check the latest Suhoor time every day.
                    </li>
                </ul>
                <br/>
                Maintain your fast with peace of mind and never miss the Suhoor deadline with our trusted {city} Suhoor timetable!
            </div>
        </div>
    );
}