import countryData from "./CountriesAndCitiesDataset";
import _ from "lodash";
import {alternativeMappings, countries} from "./CountriesShortCode";
import slugify from "slugify";

/**
 * Utility functions for fetching prayer times, calculating the next prayer, etc.
 */

// 1. Helper: Slugify a country string (lowercase, hyphenated)
const slugifyCountry = (countryName = "") => {
    return countryName
        .toLowerCase()
        .trim()
        // Remove any character that is not a word char, whitespace, or hyphen
        .replace(/[^\w\s-]/g, "")
        // Replace 1+ spaces with a single hyphen
        .replace(/\s+/g, "-");
};

// 2. Full Slugified Country-Code Map (ISO 3166-1 Alpha-2)
//    - Key: slugified country name
//    - Value: 2-letter ISO code (uppercase standard)
//    - Includes synonyms for your commonly used short forms:
//      ksa, uae, usa, uk, etc.
export const slugifiedCountryCodeMap = {
    // ---------------------------------------------------------------------------
    // Official ISO 3166-1 English short names --> slug --> 2-letter code
    // ---------------------------------------------------------------------------
    "afghanistan": "AF",
    "aland-islands": "AX",
    "albania": "AL",
    "algeria": "DZ",
    "american-samoa": "AS",
    "andorra": "AD",
    "angola": "AO",
    "anguilla": "AI",
    "antarctica": "AQ",
    "antigua-and-barbuda": "AG",
    "argentina": "AR",
    "armenia": "AM",
    "aruba": "AW",
    "australia": "AU",
    "austria": "AT",
    "azerbaijan": "AZ",
    "bahamas": "BS",
    "bahrain": "BH",
    "bangladesh": "BD",
    "barbados": "BB",
    "belarus": "BY",
    "belgium": "BE",
    "belize": "BZ",
    "benin": "BJ",
    "bermuda": "BM",
    "bhutan": "BT",
    "bolivia-plurinational-state-of": "BO",
    "bonaire-sint-eustatius-and-saba": "BQ",
    "bosnia-and-herzegovina": "BA",
    "botswana": "BW",
    "bouvet-island": "BV",
    "brazil": "BR",
    "british-indian-ocean-territory": "IO",
    "brunei-darussalam": "BN",
    "bulgaria": "BG",
    "burkina-faso": "BF",
    "burundi": "BI",
    "cabo-verde": "CV",
    "cambodia": "KH",
    "cameroon": "CM",
    "canada": "CA",
    "cayman-islands": "KY",
    "central-african-republic": "CF",
    "chad": "TD",
    "chile": "CL",
    "china": "CN",
    "christmas-island": "CX",
    "cocos-keeling-islands": "CC",
    "colombia": "CO",
    "comoros": "KM",
    "congo-the-democratic-republic-of-the": "CD",
    "congo": "CG",
    "cook-islands": "CK",
    "costa-rica": "CR",
    "croatia": "HR",
    "cuba": "CU",
    "curacao": "CW",
    "cyprus": "CY",
    "czechia": "CZ",
    "cote-divoire": "CI",
    "denmark": "DK",
    "djibouti": "DJ",
    "dominica": "DM",
    "dominican-republic": "DO",
    "ecuador": "EC",
    "egypt": "EG",
    "el-salvador": "SV",
    "equatorial-guinea": "GQ",
    "eritrea": "ER",
    "estonia": "EE",
    "eswatini": "SZ",
    "ethiopia": "ET",
    "falkland-islands-malvinas": "FK",
    "faroe-islands": "FO",
    "fiji": "FJ",
    "finland": "FI",
    "france": "FR",
    "french-guiana": "GF",
    "french-polynesia": "PF",
    "french-southern-territories": "TF",
    "gabon": "GA",
    "gambia": "GM",
    "georgia": "GE",
    "germany": "DE",
    "ghana": "GH",
    "gibraltar": "GI",
    "greece": "GR",
    "greenland": "GL",
    "grenada": "GD",
    "guadeloupe": "GP",
    "guam": "GU",
    "guatemala": "GT",
    "guernsey": "GG",
    "guinea": "GN",
    "guinea-bissau": "GW",
    "guyana": "GY",
    "haiti": "HT",
    "heard-island-and-mcdonald-islands": "HM",
    "holy-see": "VA",
    "honduras": "HN",
    "hong-kong": "HK",
    "hungary": "HU",
    "iceland": "IS",
    "india": "IN",
    "indonesia": "ID",
    "iran-islamic-republic-of": "IR",
    "iraq": "IQ",
    "ireland": "IE",
    "isle-of-man": "IM",
    "israel": "IL",
    "italy": "IT",
    "jamaica": "JM",
    "japan": "JP",
    "jersey": "JE",
    "jordan": "JO",
    "kazakhstan": "KZ",
    "kenya": "KE",
    "kiribati": "KI",
    "korea-the-democratic-peoples-republic-of": "KP",
    "korea-the-republic-of": "KR",
    "kuwait": "KW",
    "kyrgyzstan": "KG",
    "lao-peoples-democratic-republic": "LA",
    "latvia": "LV",
    "lebanon": "LB",
    "lesotho": "LS",
    "liberia": "LR",
    "libya": "LY",
    "liechtenstein": "LI",
    "lithuania": "LT",
    "luxembourg": "LU",
    "macao": "MO",
    "madagascar": "MG",
    "malawi": "MW",
    "malaysia": "MY",
    "maldives": "MV",
    "mali": "ML",
    "malta": "MT",
    "marshall-islands": "MH",
    "martinique": "MQ",
    "mauritania": "MR",
    "mauritius": "MU",
    "mayotte": "YT",
    "mexico": "MX",
    "micronesia-federated-states-of": "FM",
    "moldova-the-republic-of": "MD",
    "monaco": "MC",
    "mongolia": "MN",
    "montenegro": "ME",
    "montserrat": "MS",
    "morocco": "MA",
    "mozambique": "MZ",
    "myanmar": "MM",
    "namibia": "NA",
    "nauru": "NR",
    "nepal": "NP",
    "netherlands": "NL",
    "new-caledonia": "NC",
    "new-zealand": "NZ",
    "nicaragua": "NI",
    "niger": "NE",
    "nigeria": "NG",
    "niue": "NU",
    "norfolk-island": "NF",
    "north-macedonia": "MK",
    "northern-mariana-islands": "MP",
    "norway": "NO",
    "oman": "OM",
    "pakistan": "PK",
    "palau": "PW",
    "palestine-state-of": "PS",
    "panama": "PA",
    "papua-new-guinea": "PG",
    "paraguay": "PY",
    "peru": "PE",
    "philippines": "PH",
    "pitcairn": "PN",
    "poland": "PL",
    "portugal": "PT",
    "puerto-rico": "PR",
    "qatar": "QA",
    "republic-of-kosovo": "XK", // Not ISO-official but widely used
    "reunion": "RE",
    "romania": "RO",
    "russian-federation": "RU",
    "rwanda": "RW",
    "saint-barthelemy": "BL",
    "saint-helena-ascension-and-tristan-da-cunha": "SH",
    "saint-kitts-and-nevis": "KN",
    "saint-lucia": "LC",
    "saint-martin-french-part": "MF",
    "saint-pierre-and-miquelon": "PM",
    "saint-vincent-and-the-grenadines": "VC",
    "samoa": "WS",
    "san-marino": "SM",
    "sao-tome-and-principe": "ST",
    "saudi-arabia": "SA",
    "senegal": "SN",
    "serbia": "RS",
    "seychelles": "SC",
    "sierra-leone": "SL",
    "singapore": "SG",
    "sint-maarten-dutch-part": "SX",
    "slovakia": "SK",
    "slovenia": "SI",
    "solomon-islands": "SB",
    "somalia": "SO",
    "south-africa": "ZA",
    "south-georgia-and-the-south-sandwich-islands": "GS",
    "south-sudan": "SS",
    "spain": "ES",
    "sri-lanka": "LK",
    "sudan": "SD",
    "suriname": "SR",
    "svalbard-and-jan-mayen": "SJ",
    "sweden": "SE",
    "switzerland": "CH",
    "syrian-arab-republic": "SY",
    "taiwan-province-of-china": "TW",
    "tajikistan": "TJ",
    "tanzania-united-republic-of": "TZ",
    "thailand": "TH",
    "timor-leste": "TL",
    "togo": "TG",
    "tokelau": "TK",
    "tonga": "TO",
    "trinidad-and-tobago": "TT",
    "tunisia": "TN",
    "turkey": "TR",
    "turkmenistan": "TM",
    "turks-and-caicos-islands": "TC",
    "tuvalu": "TV",
    "uganda": "UG",
    "ukraine": "UA",
    "united-arab-emirates": "AE",
    "united-kingdom": "GB",
    "united-states-minor-outlying-islands": "UM",
    "united-states": "US",
    "uruguay": "UY",
    "uzbekistan": "UZ",
    "vanuatu": "VU",
    "venezuela-bolivarian-republic-of": "VE",
    "viet-nam": "VN",
    "virgin-islands-british": "VG",
    "virgin-islands-us": "VI",
    "wallis-and-futuna": "WF",
    "western-sahara": "EH",
    "yemen": "YE",
    "zambia": "ZM",
    "zimbabwe": "ZW",

    // ---------------------------------------------------------------------------
    // Common synonyms (from your data) --> 2-letter code
    // ---------------------------------------------------------------------------
    "ksa": "SA", // Saudi Arabia
    "uae": "AE", // United Arab Emirates
    "uk": "GB",  // United Kingdom
    "usa": "US", // United States
    "egy": "EG", // Egypt
    "irn": "IR", // Iran
    "pak": "PK", // Pakistan
};

// 3. Utility Functions

export const getCoordinates = async (city) => {
    // Basic geocode using OpenStreetMap
    const response = await fetch(
        `https://nominatim.openstreetmap.org/search?city=${city}&format=json`
    );
    const data = await response.json();
    if (data[0]) {
        return {
            city: city,
            country: data[0].address?.country || "",
            latitude: data[0].lat,
            longitude: data[0].lon,
        };
    }
    throw new Error("City not found");
};

export const convertTo24Hour = (time12h) => {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":").map((num) => parseInt(num, 10));
    if (hours === 12) {
        hours = modifier === "PM" ? 12 : 0;
    } else if (modifier === "PM") {
        hours += 12;
    }
    return {hours, minutes};
};

export const convertTo12Hour = (time24) => {
    if (!time24 || typeof time24 !== "string") return "00:00 AM";
    const [hoursStr, minutesStr] = time24.split(":");
    let hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10) || 0;
    const period = hours >= 12 ? "PM" : "AM";
    if (hours === 0) hours = 12;
    if (hours > 12) hours -= 12;
    return `${hours}:${String(minutes).padStart(2, "0")} ${period}`;
};

export const formatTimeLeft = (time) => {
    return `${String(time.hours).padStart(2, "0")}:${String(time.minutes).padStart(
        2,
        "0"
    )}:${String(time.seconds).padStart(2, "0")}`;
};

export const calculateTimeLeft = (prayerObj, timezone = "UTC") => {
    if (!prayerObj) {
        return {hours: 0, minutes: 0, seconds: 0};
    }
    const nowStr = new Date().toLocaleString("en-US", {timeZone: timezone});
    const now = new Date(nowStr);

    const {hours, minutes} = convertTo24Hour(prayerObj.time);

    const prayerDate = new Date(now);
    prayerDate.setHours(hours, minutes, 0, 0);

    // If the prayer time is earlier or equal to now, assume it's tomorrow
    if (prayerDate <= now) {
        prayerDate.setDate(prayerDate.getDate() + 1);
    }
    const diff = prayerDate - now;

    const h = Math.floor(diff / (1000 * 60 * 60));
    const m = Math.floor((diff / (1000 * 60)) % 60);
    const s = Math.floor((diff / 1000) % 60);

    return {
        hours: h < 0 ? 0 : h,
        minutes: m < 0 ? 0 : m,
        seconds: s < 0 ? 0 : s,
    };
};

export const computeNextPrayer = (prayerTimeData, timezone = "UTC") => {
    if (!prayerTimeData || !prayerTimeData.length) return null;
    const nowStr = new Date().toLocaleString("en-US", {timeZone: timezone});
    const now = new Date(nowStr);

    const dailyMinutes = (date) => date.getHours() * 60 + date.getMinutes();
    const currentMinutes = dailyMinutes(now);

    // Convert each prayer's time to total minutes
    const sorted = prayerTimeData.map((p) => {
        const {hours, minutes} = convertTo24Hour(p.time);
        return {...p, totalMinutes: hours * 60 + minutes};
    });

    // Find the first prayer that is still ahead of "now"
    let next = sorted.find((p) => p.totalMinutes > currentMinutes);

    // If no upcoming prayer, pick tomorrow's Fajr
    if (!next) {
        const fajr = sorted.find((p) => p.name.toLowerCase() === "fajr");
        if (fajr) {
            next = {...fajr};
        }
    }
    return next;
};

export const getDefaultPrayerTimes = () => {
    return [
        {name: "Fajr", time: "00:00 AM"},
        {name: "Zuhr", time: "12:00 PM"},
        {name: "Asr", time: "04:00 PM"},
        {name: "Maghrib", time: "07:00 PM"},
        {name: "Isha", time: "09:00 PM"},
        {name: "Sunrise", time: "06:00 AM"},
    ];
};

export const getCurrentLocation = () => {
    // Fallback location data (example: Jersey City)
    const fallbackData = {
        lat: 40.7308,
        lon: -74.0789,
        city: "Jersey City",
        country: "United States",
    };

    return new Promise((resolve, reject) => {
        // We still keep a timeout for the fetch (you can remove this if not needed).
        const timeoutId = setTimeout(() => {
            // If you want to also fallback on timeout instead of rejecting,
            // then replace reject(...) with resolve(...) using the fallbackData.
            reject(new Error("Location request timed out. Please try again."));
        }, 5000);

        fetch("https://matwproject.org/ip-api")
            .then((response) => {
                clearTimeout(timeoutId);
                if (!response.ok) {
                    // Force the logic to go to the .catch() block
                    // so it can switch to the fallback.
                    throw new Error("Location service unavailable. Please try again.");
                }
                return response.json();
            })
            .then((data) => {
                // If the data is missing lat/lon/city, switch to fallback.
                if (!data.lat || !data.lon || !data.city) {
                    console.warn("Incomplete location data, using fallback.");
                    resolve({
                        coords: {
                            latitude: fallbackData.lat,
                            longitude: fallbackData.lon,
                        },
                        city: fallbackData.city,
                        country: fallbackData.country,
                    });
                } else {
                    // Valid data received
                    resolve({
                        coords: {
                            latitude: data.lat,
                            longitude: data.lon,
                        },
                        city: data.city,
                        country: data.country,
                    });
                }
            })
            .catch((error) => {
                // Any network error or thrown error from above
                clearTimeout(timeoutId);

                // Instead of rejecting, use the fallback data
                console.error("Error occurred, using fallback location:", error.message);
                resolve({
                    coords: {
                        latitude: fallbackData.lat,
                        longitude: fallbackData.lon,
                    },
                    city: fallbackData.city,
                    country: fallbackData.country,
                });
            });
    });
};

const getCurrentMonthYear = () => {
    const today = new Date();
    return {
        month: today.getMonth() + 1, // January is 0, so adding 1 to get 1-12 format
        year: today.getFullYear()
    };
};

export const getPrayersDataUsingLatLng = async (latitude, longitude, pathType = 'times', method ) => {
    
    if (!latitude || !longitude || !pathType) {

        console.error('Missing required parameters:', {latitude, longitude, pathType});
        throw new Error('Required parameters are missing');
    }

    const {month, year} = getCurrentMonthYear();

    // Build URL based on pathType
    if (pathType.includes('timetable')) {
        // Calendar endpoint - don't include method
        return fetch(`https://api.aladhan.com/v1/calendar/${year}/${month}?latitude=${latitude}&longitude=${longitude}`);
    } else {
        // Daily timings endpoint - include method if provided
        const url = `https://api.aladhan.com/v1/timings/today?latitude=${latitude}&longitude=${longitude}${method ? `&method=${method}` : ''}`;
        return fetch(url);
    }
    // return fetch(
    //     `https://api.aladhan.com/${pathType.includes('timetable') ? `v1/calendar/${year}/${month}` : `v1/timings/today`}?latitude=${latitude}&longitude=${longitude}`
    // );
};

// export const getPrayerDataFromApi = (locationData, method = null) => {
//     const methodParam = method ? `&method=${method}` : "";
//
//     // 1. Slugify the incoming country name
//     const slug = slugifyCountry(locationData?.country);
//
//     // 2. Map the slug to a 2-letter code (or fallback to the slug if not found)
//     let isoCode = slugifiedCountryCodeMap[slug] || slug;
//
//     // 3. Uppercase the resulting code
//     isoCode = isoCode.toUpperCase();
//
//     // 4. Safely fix city name if it has hyphens
//     const city = locationData?.city?.replace("-", " ");
//
//     // 5. Fetch from Aladhan with the validated/uppercased country code
//     return fetch(
//         `https://api.aladhan.com/v1/timingsByCity?country=${isoCode.toUpperCase()}&city=${city}${methodParam}`
//     );
// };

export const getCalculationMethod = (methodName) => {
    const methodsMap = {
        Karachi: "University of Islamic Sciences, Karachi (UISK)",
        "North America": "Islamic Society of North America (ISNA)",
        "Muslim World": "Muslim World League (MWL)",
        Qura: "Umm al-Qura University, Saudi Arabia (UIS)",
    };
    if (!methodName) return "No method used";

    for (const [key, val] of Object.entries(methodsMap)) {
        if (methodName.includes(key)) {
            return val;
        }
    }
    return "No method used";
};

// ISSUE: Working with drop box is making a mess
// export const getLatLngFromApi = async (country, city) => {
//     
//     let found = null;
//     if (country.length > 3) {
//         found = allCountries.find(
//             (c) =>
//                 c.name.toLowerCase() === country.toLowerCase() ||
//                 c.shortCode?.toLowerCase() === country.toLowerCase() ||
//                 c.shortCode2?.toLowerCase() === country.toLowerCase()
//         );
//
//         if (!found)
//             throw new Error(`Invalid country`);
//     }
//
//     const locationDataResponse = await fetch(`https://backend.matwcheckout.org/api/cities?iso2=${encodeURIComponent(found ? found.shortCode : country.toUpperCase())}&city_ascii=${encodeURIComponent(city)}`);
//
//     if (!locationDataResponse.ok) {
//         throw new Error(`HTTP error! status: ${locationDataResponse.status}`);
//     }
//     
//     return await locationDataResponse.json();
// }


export const getLatLngFromApi = async (country, city) => {
    try {
        

        let url;

        if (!country || country === 'NA') {
            // City-only route - don't include iso2 parameter
            url = `https://matwproject.org/backend/index.php/api/cities?city_slug=${slugify(city)}`;
        } else {
            // Route with country - include both parameters
            const countryCode = findCountryCode(country);
            if (!countryCode) {
                throw new Error(`Invalid country: ${country}`);
            }
            url = `https://matwproject.org/backend/index.php/api/cities?iso2=${encodeURIComponent(countryCode.toUpperCase())}&city_slug=${slugify(city)}`;
        }

        // const countryCode = findCountryCode(country)
        // url = `https://backend.matwcheckout.org/api/cities?iso2=${encodeURIComponent(countryCode.toUpperCase())}&city_ascii=${encodeURIComponent(city)}`;

        console.log('Fetching from URL:', url);

        const locationDataResponse = await fetch(url);

        // Log the response status and headers
        console.log('Response status:', locationDataResponse.status);
        console.log('Response headers:', Object.fromEntries(locationDataResponse.headers));

        // Get the raw text first
        const responseText = await locationDataResponse.text();

        debugger
        // Log the raw response
        console.log('Raw response:', responseText);

        if (!locationDataResponse.ok) {
            debugger
            throw new Error(`HTTP error! status: ${locationDataResponse.status}, body: ${responseText}`);
        }

        // Check if response starts with <!DOCTYPE
        if (responseText.trim().toLowerCase().startsWith('<!doctype')) {
            throw new Error('Received HTML instead of JSON');
        }

        // Try to parse the JSON
        try {
            const jsonData = JSON.parse(responseText);
            return jsonData;
        } catch (e) {
            throw new Error(`JSON parsing failed: ${e.message}\nResponse was: ${responseText}`);
        }
    } catch (error) {
        console.error('getLatLngFromApi error:', error);
        throw error;
    }
};

export const getCmsRoutes = async () => {
    try {
         const url = `https://backend.matwcheckout.org/api/routes?route_type=namaz-time`;

        const cmsRoutesResponse = await fetch(url);
        const responseText = await cmsRoutesResponse.text();

        if (!cmsRoutesResponse.ok) {
            throw new Error(`HTTP error! status: ${cmsRoutesResponse.status}, body: ${responseText}`);
        }

        // Check if response starts with <!DOCTYPE
        if (responseText.trim().toLowerCase().startsWith('<!doctype')) {
            throw new Error('Received HTML instead of JSON');
        }

        // Try to parse the JSON
        try {
            const jsonData = JSON.parse(responseText);
            return jsonData;
        } catch (e) {
            throw new Error(`JSON parsing failed: ${e.message}\nResponse was: ${responseText}`);
        }
    } catch (error) {
        console.error('getLatLngFromApi error:', error);
        throw error;
    }
};


// Merge and sort all countries alphabetically
export const allCountries = _.orderBy(
    [...countryData.muslimCountries, ...countryData.westernCountries],
    ["name"],
    ["asc"]
);

export function findCountryCode(input) {
    if (!input) return null;
    

    const searchTerm = input.toLowerCase().trim();

    // 1. Check if it's an alternative code
    if (alternativeMappings[searchTerm]) {
        const standardCode = alternativeMappings[searchTerm].toUpperCase();
        const countryByAlternative = countries.find(c => c.iso2 === standardCode);
        if (countryByAlternative) {
            return countryByAlternative.iso2;
        }
    }

    // 2. Check direct ISO2 match
    const byIso2 = countries.find(c => c.iso2.toLowerCase() === searchTerm);
    if (byIso2) {
        return byIso2.iso2;
    }

    // 3. Check country name match
    const byName = countries.find(c => c.countryName.toLowerCase() === searchTerm);
    if (byName) {
        return byName.iso2;
    }

    return null;
}

export function findCountryName(input) {
    if (!input) return null;

    const searchTerm = input.toLowerCase().trim();

    // 1. Check if it's an alternative code
    if (alternativeMappings[searchTerm]) {
        const standardCode = alternativeMappings[searchTerm].toUpperCase();
        const countryByAlternative = countries.find(c => c.iso2 === standardCode);
        if (countryByAlternative) {
            return countryByAlternative.countryName;  // Return name instead of code
        }
    }

    // 2. Check direct ISO2 match
    const byIso2 = countries.find(c => c.iso2.toLowerCase() === searchTerm);
    if (byIso2) {
        return byIso2.countryName;  // Return name instead of code
    }

    // 3. Check country name match
    const byName = countries.find(c => c.countryName.toLowerCase() === searchTerm);
    if (byName) {
        return byName.countryName;  // Return name instead of code
    }

    return null;
}