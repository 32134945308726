import { generateLinkWithLang } from '../../../../../utils/linkGenerator';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Builder } from '@builder.io/react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCurrency } from "../../../../../store/user";
import ReactSelect from 'react-select';
import SelectCurrency from "../../../../general/selectCurrency";
import useHandleAddProduct from "../../../../../utils/handleAddProduct";
import { selectProducts } from "../../../../../store/products";
import useCart from '../../../../../hooks/cart/useCart';
import { openSheet } from '../../../../../store/sheetToggler';

const styles = {
  control: base => ({
    ...base,
    height: '48px',
    border: 'none',
    borderRadius: 'inherit',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)',
  }),
  menuList: base => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "6px",
      height: "0px"
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1"
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555"
    }
  }),
  shadow: {
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
  }
};

const ZakatAlFitrWidget = (
  {
    defaultProduct,
    addToCart,
    arabic = false,
    dropDownProducts,
    mode = 'one-off',
    defaultPrice,
  }
) => {
  const [allProducts, setAllProducts] = useState([]);
  const [addProduct] = useHandleAddProduct();
  const products = useSelector((state) => selectProducts(state));
  const [productsList, setProductsList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({
    creator: defaultProduct?.creator,
    label: defaultProduct?.label,
    value: defaultProduct?.value,
  });
  const [currentProduct, setCurrentProduct] = useState({
    creator: defaultProduct?.creator,
    label: defaultProduct?.label,
    value: defaultProduct?.value,
  });

  const navigate = useNavigate();
  const {
    i18n
  } = useTranslation();
  const lang = i18n.language;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [currentCurrency, setCurrentCurrency] = useState();
  const [productAmount, setProductAmount] = useState(defaultPrice || 1);

  const dispatch = useDispatch();
  const { handleAddToGiving: handleAddToGivingCart } = useCart();
  const [selectedRecurring,setSelectedRecurring] = useState({
    value : mode || 'one-off'
  })
  
  

  const handleSelectedProduct = (value) => {
    const matched = allProducts.find(item => item.id === value.value);
    if (matched) {
      setSelectedProduct(matched);
    }
    setCurrentProduct(value);
  };
  

  const handleAddNewProduct = () => {
        let tempProduct = selectedProduct;
        let customAmount = Number(productAmount)
          ? Number(productAmount)
          : Number(productAmount)
            ? Number(productAmount)
            : 1;
        tempProduct = Object.assign({}, tempProduct, {
          quantity: customAmount,
          recurring: selectedRecurring.value,
        });
    
        if (addToCart) {
          handleAddToGivingCart(tempProduct, "custom");
          dispatch(openSheet());
        } else {
          addProduct({ product: tempProduct, currency: currentCurrency });
        }
      };

      const handleAmountKeyDown = (e) => {
        if (['e', 'E', '+', '-', '.'].includes(e.key)) {
          e.preventDefault();
        }
      };
      
      const handleAmountChange = (e) => {
        const regex = /^$|^[1-9][0-9]*$/; 
        if (regex.test(e.target.value)) {
          setProductAmount(e.target.value);
        }
      };

  /* ------------------------------- Start hooks ------------------------------ */

  const dropDownList = useMemo(() => {
    return Array.isArray(dropDownProducts)
      ? dropDownProducts
      : typeof dropDownProducts === 'string'
        ? dropDownProducts.split(/\s*,\s*/).filter(Boolean)
        : [];
  }, [dropDownProducts]); 

  const getAllProducts = (products) => {
    if (!products) return;
  
    let tempProductsDetailsList = [];
    let tempProductsList = [];
  
    products.forEach((item) => {
      item.sub_categories.forEach((inner_item) => {
        inner_item.products.forEach((inner_item_product) => {
          if (Number(inner_item_product.status) === 1) {
            if (inner_item_product.creator === defaultProduct.creator) {
              setSelectedProduct(inner_item_product);
            }
  
            if (dropDownList.includes(inner_item_product.creator)) {
              tempProductsDetailsList.push(inner_item_product);
              tempProductsList.push({
                value: inner_item_product.id,
                label: inner_item_product.name,
                creator: inner_item_product.creator,
              });
            }
          }
        });
      });
    });

    const matchedOption = tempProductsList.find(
      p => p.creator === defaultProduct.creator
    );
  
    if (matchedOption) {
      setCurrentProduct(matchedOption);
    } else {
      const fallbackOption = {
        value: defaultProduct.value,
        label: defaultProduct.label,
        creator: defaultProduct.creator,
      };
      tempProductsList.unshift(fallbackOption);
      setCurrentProduct(fallbackOption);
    }

    tempProductsList.sort((a, b) => {
      return dropDownList.indexOf(a.creator) - dropDownList.indexOf(b.creator);
    });
  
    setProductsList(tempProductsList);
    setAllProducts(tempProductsDetailsList);
    setIsLoaded(true);
  };
  
  useEffect(() => {
    products && Object.keys(products).length && getAllProducts(products);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    selectedCurrencyStore
      ? setCurrentCurrency(selectedCurrencyStore)
      : setCurrentCurrency("AUD");
  }, [selectedCurrencyStore]);

  return (
    <div
      dir={arabic ? 'rtl' : 'ltr'}
      className={`${arabic ? 'font-[AlmariMedium]' : ''} w-full rounded-lg bg-slate-200- h-[400px]- py-2 md:py-4 flex flex-wrap items-center`}
    >
      <div className="basis-full grid grid-cols-12 gap-2">
        <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-8 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <ReactSelect
            options={productsList}
            className="max-h-[48px] w-full pointer-events-none- rounded-md"
            value={currentProduct}
            defaultValue={selectedProduct}
            isSearchable={false}
            styles={styles}
            placeholder="Select Product"
            onChange={e => handleSelectedProduct(e)}
          />
        </div>
        <div className="col-span-12 hidden md:flex md:col-span-6 lg:col-span-4  justify-center items-center-">
          <button
            className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${
              isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
            }`}
            onClick={() => navigate(generateLinkWithLang('/zakat-calculator', lang))}
          >
            Calculate your Zakat
          </button>
        </div>
        <div
          className="col-span-7 sm:col-span-6 md:col-span-4 lg:col-span-4 flex justify-center items-center bg-white rounded-md h-12 px-2  min-[400px]:mb-0"
          style={styles.shadow}
        >
          <span className="text-sm border-r pr-2 text-[#78716C]">{currentCurrency}</span>
          <input
              value={productAmount}
              className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
              type="number"
              placeholder="Enter amount"
              min={'0'}
              onKeyDown={handleAmountKeyDown}
              onChange={handleAmountChange}
            />
        </div>
        <div className="col-span-5 sm:col-span-6 md:col-span-4 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" hideFlag />
        </div>
        <div className="col-span-12  md:hidden sm:col-span-6 md:col-span-6 lg:col-span-4  justify-center items-center-">
          <button
            className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${
              isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
            }`}
            onClick={() => navigate(generateLinkWithLang('/zakat-calculator', lang))}
          >
            Calculate your Zakat
          </button>
        </div>
        <div className="col-span-12 md:col-span-4 lg:col-span-4 flex justify-center items-center-">
          <button
            className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${
              isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
            }`}
            onClick={handleAddNewProduct}
          >
            Quick Donate
          </button>
        </div>
      </div>
    </div>
  );
}

Builder.registerComponent(ZakatAlFitrWidget, {
  name: 'ZakatAlFitrWidget',
  inputs: [
    {
      name: "selectedCurrency",
      type: "string",
    },
    {
      name: "defaultPrice",
      type: "number",
    },
    {
      name: "addToCart",
      type: "boolean",
      defaultValue: false,
      friendlyName: "Add to side cart or navigate to checkout"
    },
    {
      name: "defaultProduct",
      type: "object",
      defaultValue: {
        creator: "DD-ZK-PAL-25-00",
        label: "Give Zakat Now (Zakat Al Mal)",
        value: 751,
      },
      subFields: [
        {
          name: 'creator',
          type: 'string',
          helperText: 'Enter creator ID',
          required: true,
          hideFromUI: false
        },
        {
          name: 'label',
          type: 'string',
          helperText: 'Enter label',
          required: true,
          hideFromUI: false
        },
        {
          name: 'value',
          type: 'number',
          helperText: 'Enter value',
          required: true,
          hideFromUI: false
        },
      ]
    },
    {
      name: 'dropDownProducts',
      type: 'string', 
      required: true,
      friendlyName: 'Dropdown Product Creators',
      helperText: 'Comma-separated list of product creator IDs to show in the dropdown'
    },   
    {
      name: 'mode',
      type: 'enum',
      friendlyName: 'Donation Mode',
      helperText: 'Select how often the donation should recur.',
      enum: ['one-off', 'week', 'month'],
      enumLabels: ['Single', 'Weekly', 'Monthly'],
      defaultValue: 'one-off',
    },
  ],
});

export default ZakatAlFitrWidget