const ramadanFaqs = [
    {
        id: 0,
        question: 'What is Ramadan and why is it important?',
        answer:'Ramadan is the ninth month of the Islamic lunar calendar, observed by Muslims worldwide. It is a sacred month of fasting, prayer, reflection, and charity. Muslims fast from sunrise to sunset during this month to strengthen their spirituality and empathy for those less fortunate.'
    },
    {
        id: 1,
        question: 'What is the significance of giving during Ramadan?',
        answer:'Giving during Ramadan holds special significance in Islam. it is not only a means of fulfilling the pillar of charity (Zakat) but is also a way to multiply one’s rewards. Muslims believe that their good deeds are amplified during this sacred month, making it a time for acts of charity and kindness.'
    },
    {
        id: 2,
        question: 'How can I get involved with MATW’s Ramadan projects?',
        answer:'You can get involved by donating to our Ramadan campaigns, volunteering your time, or spreading awareness about our initiatives. Your support makes a significant difference in the lives of those in need during Ramadan.'
    },
    {
        id: 3,
        question: 'In which countries does MATW operate during Ramadan?',
        answer:'We operate in various countries across the Middle East, Africa, South Asia, and Southeast Asia. We aim to reach vulnerable communities in these regions to provide essential support during Ramadan.'
    },
    {
        id: 4,
        question: 'What types of projects are run during Ramadan?',
        answer:'We offer a range of Ramadan projects, including Iftar meal distribution, food packs, daily meals for those in need, and Eid gift distribution and celebrations. These initiatives are designed to provide different aspects of support during this holy month.'
    },
    {
        id: 5,
        question: 'How do you ensure transparency and accountability in your charity projects?',
        answer:'We are committed to transparency and accountability at MATW. We provide regular updates on the progress of our projects through our website, and social media channels to keep you up-to-date on how your donation is being used and making a difference in the lives of impoverished and underserved communities. We also work with local partners and organisations to ensure accountability at the grassroots level.'
    },
    {
        id: 6,
        question: 'Can I choose which project my donation goes toward?',
        answer:'Yes, we offer the flexibility to donate to specific projects. You can choose the project that resonates with you the most, and your donation will be directed accordingly.'
    },
    {
        id: 7,
        question: 'How can I stay updated on your Ramadan Projects’ progress?',
        answer:'You can stay updated by visiting our website or following us on social media. We regularly share stories, photos, and updates on the impact of our Ramadan initiatives.'
    },
    {
        id: 8,
        question: 'What impact can be donation make during Ramadan?',
        answer:'Your donation can provide essential assistance, nourishment, support and joy to individuals and families in need during Ramadan. It can help ensure that they have access to Iftar meals, food, daily sustenance and even celebrate Eid with dignity and happiness. Every contribution counts and makes a positive difference in the lives of those we serve.'
    },
    {
        id: 9,
        question: 'How do you select the beneficiaries of your Ramadan Projects?',
        answer:'Beneficiaries are selected through a careful assessment process that considers their needs and vulnerabilities. We work closely with community leaders, local NGOs, and social workers to identify and assist regions and communities who require support the most.'
    },
    {
        id: 10,
        question: 'How do you ensure that aid reaches those in remote or conflict-affected areas?',
        answer:'We collaborate with local partners who have in-depth knowledge of the regions we serve. These partners have experience navigating challenges, including remote or conflict-affected areas. We work together to ensure that aid reaches the intended recipients safely and efficiently.'
    },
    {
        id: 11,
        question: 'What is the impact of my donation beyond Ramadan? ',
        answer:'Your donation’s impact extends far beyond Ramadan. We aim to create sustainable solutions that uplift communities even after the holy month ends. Your support helps improve education, healthcare, community cohesion, and overall well-being in the regions in which we operate.'
    },
];
const lastTenNightsFaqs = [
    {
        id: 0,
        question: 'What is the significance of Laylatul Qadr?',
        answer:`Laylatul Qadr, known as the Night of Decree, is believed to be the holiest night in Ramadan. It's when the Quran was first revealed to Prophet Muhammad (PBUH). Acts of worship during this night are said to be more rewarding than a thousand months of worship.`
    },
    {
        id: 1,
        question: 'When is Laylatul Qadr?',
        answer:'Laylatul Qadr falls within the last ten nights of Ramadan, usually on the 27th night. Scholars encourage us to seek it throughout the last ten nights as its exact date is unknown and can fall on any odd night.'
    },
    {
        id: 2,
        question: 'How can I donate during Laylatul Qadr?',
        answer:`Donating during Laylatul Qadr is simple with MATW Project - in fact, donating throughout the entire month is easy! Just choose your preferred project, select the date you’d like to start donating from, set up your payment plan, and automate your donations. This will Insha’Allah ensure you don't miss out on giving during these sacred nights!`
    },
];
const ramadanDailyDonationFaqs = [
    {
        id: 0,
        question: 'Can I choose my own start date?',
        answer:`You can select your own start date. The automation will end on the last night of Ramadan`
    },
    {
        id: 1,
        question: 'What if I want to give more on particular days?',
        answer:'The option is available to donate more on the odd nights, on Jummah and on the 27th night'
    },
    {
        id: 2,
        question: 'Are donations processed in the evening?',
        answer:`Donations are processed on 1:00am based on the timezone you choose`
    },
    {
        id: 3,
        question: 'Can I select my city/country time zone?',
        answer:`Yes, this will help us identify when your Ramadan will start and end`
    },
    {
        id: 4,
        question: 'Can I set this up before Ramadan starts? (once Ramadan starts) Can I schedule a donation for what is left of Ramadan?',
        answer:`Yes, you can set this up before Ramadan. You also have the option to set this up even if your in the middle of Ramadan and in this scenario, you will be donating for whatever days are remaining`
    },
    {
        id: 5,
        question: 'Can I donate only on the last 10 nights of Ramadan?',
        answer:`You can donate a higher amount for the last 10 nights but the main idea of daily giving automation is to give everyday no matter how small the amount`
    },
    {
        id: 6,
        question: 'What projects will this go towards?',
        answer:`You can choose between the 4 initiatives provided on the page`
    },
    {
        id: 7,
        question: 'Can I automate my donations if Ramadan has already started?',
        answer:`Yes, you can automate your donations even if Ramadan has already started. Your donations will be processed according to the starting date you select up until the remaining days of Ramadan`
    },
    {
        id: 8,
        question: 'Who can I contact for more information?',
        answer:`Kindly send your queries to <a href="#" class="text-LightBlue">info@matwproject.org</a>`
    },
    {
        id: 9,
        question: 'When will my first payment be processed?',
        answer:`Your first payment will be processed at 1am your local time. We process your donations at this time to help ensure that you are able to able to attain the blessing of donating on the odd nights and Laylatul Qadr. If you wish to make a donation during the day, you can simply make a one-off donation in the meantime.`
    },
    {
        id: 10,
        question: 'Why are some days marked as zero contribution?',
        answer:`Your donation total is split across the remaining days of Ramadan. As we operate a 100% donation policy, it’s not financially viable for us to process donation values of less than 1 so we round up to the nearest full number. This might mean that at the start of Ramadan your first day might be a zero value especially if you added multipliers to prioritise the last 10 nights, odd nights and the 27th. You may wish to make a one off donation for your first day in the rare occasion your first day giving value is zero so you can maximise on the blessing and have a bigger impact.`
    },
];
const lastTenNightsDailyGivingFaqs = [
    {
        id: 0,
        question: 'Can I choose my own start date for the last 10 days? ',
        answer:`By entering your Ramadan start date, we are able to determine your odd nights based on your specific start date. Your donation schedule will show payments starting from the first did night based on your Ramadan start date.`
    },
    {
        id: 1,
        question: 'What if I want to give more on Jummah or the 27th night?',
        answer:'You have the option to donate more on the 27th night (Laylatul Qadr). You can customise your donation amounts for this blessed nights and Jummah'
    },
    {
        id: 2,
        question: 'Are donations processed in the evening?',
        answer:`Donations are processed at 01:00 AM based on the timezone you choose.`
    },
    {
        id: 3,
        question: 'Can I select my city/country time zone?',
        answer:`Yes, selecting your time zone helps us ensure your donations are processed at the correct time during the last 10 nights.`
    },
    {
        id: 4,
        question: 'Can I set this up before the last 10 days of Ramadan?',
        answer:`Yes, you can set up your donations in advance. If you start during the last 10 days, your donations will be processed for the remaining nights.`
    },
    {
        id: 5,
        question: 'What projects will my donations go towards?',
        answer:`You can choose between the 4 initiatives provided on the page, such as Orphan Support, Gaza Emergency Fund, Where Most Needed, and General Masjid Donations.`
    },
    {
        id: 6,
        question: 'Can I automate my donations if the last 10 days have already started?',
        answer:`Yes, you can automate your donations even if the last 10 days has already started. Your donations will be processed according to the starting date you select for the last 10 nights.`
    },
    {
        id: 7,
        question: 'Who can I contact for more information?',
        answer:`Kindly send your queries to info@matwproject.org.`
    },
];
export { ramadanFaqs, lastTenNightsFaqs, ramadanDailyDonationFaqs, lastTenNightsDailyGivingFaqs};