// components/DonationCalendar.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import ShowCurrencies from '../../../utils/showCurrencies';
import CustomButton from '../../general/button';
import { useNavigate } from 'react-router-dom';

const DonationCalendar = ({ donationSchedule, currency, ramadanStartDate, timeZone, handleScheduleDonation, totalAmount, numberOfNights, isLastTenNightsGiving }) => {
  // Convert Ramadan start date to Luxon DateTime in user's timezone
  const ramadanStartLuxon = DateTime.fromISO(ramadanStartDate, { zone: timeZone }).startOf('day');
  // const MINIMUM_DONATION = 300; // Define the minimum donation amount
  // const MINIMUM_DONATION = numberOfNights * 10; // Define the minimum donation amount
  const MINIMUM_DONATION = currency.toLowerCase() === 'myr' ? 200 : 50; // Define the minimum donation amount
  const navigate = useNavigate();
  // Determine if the total amount meets the minimum requirement
  const isMinimumMet = totalAmount >= MINIMUM_DONATION;
  // Step 1: Annotate each day with the correct Ramadan Night Number
  const annotatedSchedule = donationSchedule.map((day) => {
    const donationDate = DateTime.fromISO(day.date, { zone: timeZone }).startOf('day');
    const nightNumber = donationDate.diff(ramadanStartLuxon, 'days').days + 1; // Night numbers start at 1

    return {
      ...day,
      nightNumber: Math.floor(nightNumber), // Ensure it's an integer
    };
  });

  // Step 2: Group the annotated schedule by Month and Year
  const groupedByMonth = annotatedSchedule.reduce((acc, day) => {
    const dateLuxon = DateTime.fromISO(day.date, { zone: timeZone });
    const monthYear = dateLuxon.toFormat('LLLL yyyy'); // e.g., "February 2025"

    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }
    acc[monthYear].push(day);
    return acc;
  }, {});

  // Function to determine if a night is special based on the strategy
  const isSpecialNight = (day) => {
    return day.isMultiplierNight;
  };

  return (
    <div className="w-full bg-[#DFEEF2] p-4 md:p-8 rounded-md">
      <h2 className="text-[#253B7E] text-lg md:text-2xl mb-4 font-bold">Your Donation Calendar</h2>
      {Object.keys(groupedByMonth).map((month) => (
        <div key={month} className="mb-6">
          <h3 className="text-black text-md md:text-xl mb-3">{month}</h3>
          <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-9 gap-2 md:gap-2">
            {groupedByMonth[month].map((day) => (
              <div
                key={day.date}
                className={`border border-stone-800 p-1 md:p-2 rounded text-center bg-white- shadow-md- ${isSpecialNight(day) ? 'bg-yellow-100 border-yellow-500' : ''
                  }`}
              >
                <p className="text-base md:text-lg font-bold text-[#253B7E]">Night {day.nightNumber}</p>
                <p className="text-xs text-[#797979]">
                  {DateTime.fromISO(day.date, { zone: timeZone }).toFormat('LLLL d, yyyy')}
                </p>
                <p className="text-sm text-[#253B7E] my-1">
                  {DateTime.fromISO(day.date, { zone: timeZone }).toFormat('cccc')} {/* Day of the week */}
                </p>
                <div className="w-full rounded bg-[#253B7E] text-white text-center md:py-1">
                  <div className="text-base md:text-lg font-semibold">
                    {ShowCurrencies(currency, day.amount)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      {Object.keys(groupedByMonth).length ? <div className="mt-3">
        <CustomButton
          type="button"
          title={'Schedule your donation'}
          className={`w-full sm:w-auto`}
          onClick={() => {
            if (isMinimumMet) {
              localStorage.setItem('IS_DAILY_DONATION', JSON.stringify(true));
              localStorage.setItem('IS_LAST_10_NIGHTS', JSON.stringify(isLastTenNightsGiving ? true : false));
              navigate("/checkout", { state: { from: "30-steps-to-jannah" } });
            }
          }}
        />
      </div> : ''}
    </div>
  );
};

// PropTypes for type checking
DonationCalendar.propTypes = {
  donationSchedule: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired, // ISO date string
      amount: PropTypes.number.isRequired,
      isMultiplierNight: PropTypes.bool.isRequired, // New prop
    })
  ).isRequired,
  currency: PropTypes.string.isRequired, // e.g., 'GBP', 'USD', 'EUR'
  ramadanStartDate: PropTypes.string.isRequired, // ISO date string
  timeZone: PropTypes.string.isRequired, // User's time zone
};

export default DonationCalendar;
