/* eslint-disable react-hooks/exhaustive-deps */
import Palestinepreramadan1 from '../../images/landings/palestine-refugee/palestine-refugee-slider-1.jpg';
import Palestinepreramadan2 from '../../images/landings/palestine-refugee/palestine-refugee-slider-2.jpg';
import Palestinepreramadan3 from '../../images/landings/palestine-refugee/palestine-refugee-slider-3.jpg';
import Palestinepreramadan4 from '../../images/landings/palestine-refugee/palestine-refugee-slider-4.jpg';
import Palestinepreramadan5 from '../../images/landings/palestine-refugee/palestine-refugee-slider-5.jpg';
import Palestinepreramadan6 from '../../images/landings/palestine-refugee/palestine-refugee-slider-6.jpg';
import Palestinepreramadan7 from '../../images/landings/palestine-refugee/palestine-refugee-slider-7.jpg';
import Palestinepreramadan8 from '../../images/landings/palestine-refugee/palestine-refugee-slider-8.jpg';
import GazaCampImage1 from '../../images/landings/palestine-refugee/MATW-Relief-Camp-Gallery-Images-1.jpg';
import GazaCampImage2 from '../../images/landings/palestine-refugee/MATW-Relief-Camp-Gallery-Images-2.jpg';
import GazaCampImage3 from '../../images/landings/palestine-refugee/MATW-Relief-Camp-Gallery-Images-3.jpg';
import GazaCampImage4 from '../../images/landings/palestine-refugee/MATW-Relief-Camp-Gallery-Images-4.jpg';
import PalestineLimbs1 from '../../images/landings/palestine-refugee/Limbs-of-Hope-Website-Banner-1.jpg';
import PalestineLimbs2 from '../../images/landings/palestine-refugee/Limbs-of-Hope-Website-Banner-2.jpg';
import PalestineLimbs3 from '../../images/landings/palestine-refugee/Limbs-of-Hope-Website-Banner-3.jpg';
import PalestineLimbs4 from '../../images/landings/palestine-refugee/Limbs-of-Hope-Website-Banner-4.jpg';
import PalestineLimbs5 from '../../images/landings/palestine-refugee/Limbs-of-Hope-Website-Banner-5.jpg';
import NorthLebanonImg1 from '../../images/landings/north-lebanon/Lebanon-Orphanage-Landing-Page-Image-(1)-.jpg';
import NorthLebanonImg2 from '../../images/landings/north-lebanon/Lebanon-Orphanage-Landing-Page-Image-(2)-.jpg';
import NorthLebanonImg3 from '../../images/landings/north-lebanon/Lebanon-Orphanage-Landing-Page-Image-(3)-.jpg';
import NorthLebanonImg4 from '../../images/landings/north-lebanon/Lebanon-Orphanage-Landing-Page-Image-(4)-.jpg';
import NorthLebanonImg5 from '../../images/landings/north-lebanon/Lebanon-Orphanage-Landing-Page-Image-(5)-.jpg';
import NorthLebanonImg6 from '../../images/landings/north-lebanon/Lebanon-Orphanage-Landing-Page-Image-(6)-.jpg';
import NorthLebanonImg7 from '../../images/landings/north-lebanon/Lebanon-Orphanage-Landing-Page-Image-(7)-.jpg';
import NorthLebanonImg8 from '../../images/landings/north-lebanon/Lebanon-Orphanage-Landing-Page-Image-(8)-.jpg';
import carouselImagesPalestineWaterPipeline1 from '../../images/landings/palestine-refugee/carousel-image-palestine-pipeline (1).jpg';
import carouselImagesPalestineWaterPipeline2 from '../../images/landings/palestine-refugee/carousel-image-palestine-pipeline (2).jpg';
import carouselImagesPalestineWaterPipeline3 from '../../images/landings/palestine-refugee/carousel-image-palestine-pipeline (3).jpg';
import carouselImagesPalestineWaterPipeline4 from '../../images/landings/palestine-refugee/carousel-image-palestine-pipeline (4).jpg';
import carouselImagesPalestineWaterPipeline5 from '../../images/landings/palestine-refugee/carousel-image-palestine-pipeline (5).jpg';
import carouselImagesPalestineWaterPipeline6 from '../../images/landings/palestine-refugee/carousel-image-palestine-pipeline (6).jpg';
import carouselImagesPalestineWaterPipeline7 from '../../images/landings/palestine-refugee/carousel-image-palestine-pipeline (7).jpg';
import carouselImagesPalestineWaterPipeline8 from '../../images/landings/palestine-refugee/carousel-image-palestine-pipeline (8).jpg';
import carouselImagesPalestineWaterPipeline9 from '../../images/landings/palestine-refugee/carousel-image-palestine-pipeline (9).jpg';
import carouselImagesPalestineWaterPipeline10 from '../../images/landings/palestine-refugee/carousel-image-palestine-pipeline (10).jpg';
import carouselImagesPalestineWaterPipeline11 from '../../images/landings/palestine-refugee/carousel-image-palestine-pipeline (11).jpg';
import carouselImagesPalestineWaterPipeline12 from '../../images/landings/palestine-refugee/carousel-image-palestine-pipeline (12).jpg';
import palestineWaterPipelineBanner from '../../images/banners/Help Rebuild Gazas Water Supply Banner.jpg';
import projectBreakingPhoto from '../../images/others/limbs-of-hope-project-braking-img.jpg';
import EveryGazaChildDeservesLimbsImg from '../../images/landings/palestine-refugee/every-gaza-child-deserves-limbs.jpeg';
import gazaBakeryBanner from '../../images/banners/Gaza Bakery Project.jpg';
import gazaBabiesBanner from '../../images/banners/MATW_SaveGazaBabies_2025_WebBanner_FINAL (1).jpg';
import CouldPlayLikeKidsImg from '../../images/landings/palestine-refugee/could-play-like-kids.jpeg';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import { selectProducts } from '../../store/products';
import AppealDonors from './components/appealDonors';
import AppealImpact from './components/appealImpact';
import services from '../../services';
import { useNavigate } from 'react-router-dom';
import { CarouselImages } from '../../components/general/imagesCarousel';
import ImagesSection from '../crisis-and-emergencies/components/common-sections/images';
import CustomButton from '../../components/general/button';
import ShowCurrencies from '../../utils/showCurrencies';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { CloseIcon } from '../../components/general/customIcons';
import { BASKET_CACHE_KEY, HandleLocalStorage } from '../../utils/handleLocalStorage';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import SeoData from '../../utils/seo.json';
// import AppealLevelGivingUAT from './components/appealLevelGivingUAT';
import AppealDetailsTabsUAT from './components/appealDetailTabsUAT';
import AppealCurrentProductUAT from './components/appealCurrentProductUAT';
import { startDateOfAppealCampaign } from './details';
import AppealLevelGiving from './components/appealLevelGiving';
import AppealWidget from './components/appealWidget';
import { getCampaign, getCreatorCodesWithDates } from './dates';
import { calculateCampaigns } from './campaignCalculation';

const MATWAppealDetailsLandingPageUATx = ({ lang = 'en', campaign = 'palestine' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const impactRef = useRef(null);
  const donorsRef = useRef(null);
  const otherAppealsRef = useRef(null);
  const selectGivingRef = useRef(null);
  const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  // let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const products = useSelector(state => selectProducts(state));
  // const orders = useSelector(state => selectLastOrders(state));
  const [selectedCurrency, setSelectedCurrency] = useState();
  // const [productTitle, setProductTitle] = useState('');
  const [givingProducts, setGivingProducts] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [currentgivingProducts, setCurrentGivingProducts] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [goal] = useState(1500000);
  const [currentProduct, setCurrentProduct] = useState();
  const [donors, setDonors] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  // const [orders, setOrders] = useState([]);
  const [appealProducts, setAppealProducts] = useState([0, 0]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [impactReached, setImpactReached] = useState(false);
  const [levelGivingReached, setLevelGivingReached] = useState(false);
  const [donorsReached, setDonorsReached] = useState(false);
  const [otherAppealsReached, setOtherAppealsReached] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  // const [products, setProducts] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [selectedGivingProducts, setSelectedGivingProducts] = useState({
    products: [],
    total: 0,
  });
  const [isMinimized, setIsMinimized] = useState(false);
  const [showMoreItems, setShowMoreItems] = useState(false);
  const carouselImages = [
    { src: Palestinepreramadan1, alt: 'Donate to palestine, they need you' },
    { src: Palestinepreramadan2, alt: 'Palestine Charity to help the palestinian people' },
    { src: Palestinepreramadan3, alt: 'Palestine donation to make an impact' },
    { src: Palestinepreramadan4, alt: 'Palestine appeal, help them today' },
    { src: Palestinepreramadan5, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan6, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan7, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan8, alt: 'donate for palestine emergency appeal' },
  ];
  const carouselImagesLimbs = [
    { src: PalestineLimbs1, alt: 'Limbs of hope' },
    { src: PalestineLimbs2, alt: 'Limbs of hope' },
    { src: PalestineLimbs3, alt: 'Limbs of hope' },
    { src: PalestineLimbs4, alt: 'Limbs of hope' },
    { src: PalestineLimbs5, alt: 'Limbs of hope' },
  ];
  const carouselImagesNorthLebanon = [
    { src: NorthLebanonImg1, alt: 'North Lebanon Orphan' },
    { src: NorthLebanonImg2, alt: 'North Lebanon Orphan' },
    { src: NorthLebanonImg3, alt: 'North Lebanon Orphan' },
    { src: NorthLebanonImg4, alt: 'North Lebanon Orphan' },
    { src: NorthLebanonImg5, alt: 'North Lebanon Orphan' },
    { src: NorthLebanonImg6, alt: 'North Lebanon Orphan' },
    { src: NorthLebanonImg7, alt: 'North Lebanon Orphan' },
    { src: NorthLebanonImg8, alt: 'North Lebanon Orphan' },
  ];
  const carouselImagesGazaCamp = [
    { src: GazaCampImage1, alt: 'Here To Stay: Build a camp in Gaza!' },
    { src: GazaCampImage2, alt: 'Here To Stay: Build a camp in Gaza!' },
    { src: GazaCampImage3, alt: 'Here To Stay: Build a camp in Gaza!' },
    { src: GazaCampImage4, alt: 'Here To Stay: Build a camp in Gaza!' },
  ];
  const carouselImagesPalestineWaterPipeline = [
    { src: carouselImagesPalestineWaterPipeline1, alt: 'Palestine Water Pipeline' },
    { src: carouselImagesPalestineWaterPipeline2, alt: 'Palestine Water Pipeline' },
    { src: carouselImagesPalestineWaterPipeline3, alt: 'Palestine Water Pipeline' },
    { src: carouselImagesPalestineWaterPipeline4, alt: 'Palestine Water Pipeline' },
    { src: carouselImagesPalestineWaterPipeline5, alt: 'Palestine Water Pipeline' },
    { src: carouselImagesPalestineWaterPipeline5, alt: 'Palestine Water Pipeline' },
    { src: carouselImagesPalestineWaterPipeline5, alt: 'Palestine Water Pipeline' },
    { src: carouselImagesPalestineWaterPipeline6, alt: 'Palestine Water Pipeline' },
    { src: carouselImagesPalestineWaterPipeline7, alt: 'Palestine Water Pipeline' },
    { src: carouselImagesPalestineWaterPipeline8, alt: 'Palestine Water Pipeline' },
    { src: carouselImagesPalestineWaterPipeline9, alt: 'Palestine Water Pipeline' },
    { src: carouselImagesPalestineWaterPipeline10, alt: 'Palestine Water Pipeline' },
    { src: carouselImagesPalestineWaterPipeline11, alt: 'Palestine Water Pipeline' },
    { src: carouselImagesPalestineWaterPipeline12, alt: 'Palestine Water Pipeline' },
  ];
  const { i18n } = useTranslation();
  const paramLang = i18n.language;
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  /* ------------------------------ Start methods ----------------------------- */
  const getDonors = async _currency => {
    let req = {
      products: getCampaign(campaign).creatorCodes,
      limit: 20,
      currency: _currency || 'AUD',
      start_date: startDateOfAppealCampaign,
      // end_date: endDateOfAppealCampaign,
      // start_date: new Date()
    };
    try {
      const response = await services.getLatestDonors(req);
      setDonors(response.data);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const handleSelectedGiving = () => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let tempSelectedGiving = [];
    let tempTotalGiving = 0;
    let temp = [];
    givingProducts.map((item, index) => {
      basketStatesFromLocalStorage &&
        basketStatesFromLocalStorage.products.map(innerItem => {
          if (innerItem.product && innerItem.product.creator === item.creator) {
            item = Object.assign({}, item, {
              isAdded: true,
              quantity: innerItem.quantity,
            });
            tempTotalGiving += (item.quantity || 1) * item[selectedCurrencyStore.toLowerCase()];
            tempSelectedGiving.push(item);
          }
          return innerItem;
        });
      temp[index] = item;
      return item;
    });
    tempSelectedGiving.length === 1 && setIsMinimized(false);
    setSelectedGivingProducts({ ...selectedGivingProducts, products: tempSelectedGiving, total: tempTotalGiving });
    // console.log("before filter===", temp, campaign)
    temp = temp.map(item => item).filter(item => item.campaign === campaign);
    // console.log("after filter===", temp,)
    setCurrentGivingProducts(temp);
  };
  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };
  const handleCurrency = (currency, tempBasket) => {
    if (tempBasket.products.length && tempBasket.products[0].product) {
      tempBasket.products.map(item => {
        item.currency = currency;
        item.product.currency = currency;
        item.total = item.quantity * item.product[currency];
        item.amount = item.product[currency];
        return item;
      });
      tempBasket.currency = currency;
      HandleLocalStorage(tempBasket);
    } else {
      tempBasket.currency = currency;
      HandleLocalStorage(tempBasket);
    }
  };
  const handleRemoveProduct = prd => {
    removeProduct(prd);
    handleSelectedGiving();
  };
  const getLatestOrdersVersion3 = async () => {
    let req = {
      products: getCreatorCodesWithDates(),
      currency: selectedCurrencyStore
    };
    try {
      const response = await services.getLatestOrdersV3(req);
      setAllOrders(response.data);
    } catch (error) {
      console.error('error========', error);
    }
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    if (basketStatesFromLocalStorage && basketStatesFromLocalStorage.currency !== selectedCurrency) {
      handleCurrency(selectedCurrency, basketStatesFromLocalStorage);
    }
    if (givingProducts[0]) {
      handleSelectedGiving();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givingProducts, selectedCurrency]);
  useEffect(() => {
    setCurrentProduct(null);
    selectedCurrencyStore && getLatestOrdersVersion3();
    selectedCurrencyStore && getDonors(selectedCurrencyStore);
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  useEffect(() => {
    if (allOrders && products && Object.keys(products).length) {
      // getAllProducts(allOrders);
      const {
        givingProducts,
        currentGivingProducts,
        appealProducts,
        currentProduct
      } = calculateCampaigns({
        products,
        _prds: allOrders,
        selectedCurrencyStore,
        campaign
      });
      // Now you update your React state easily:
      setGivingProducts(givingProducts);
      setCurrentGivingProducts(currentGivingProducts);
      setAppealProducts(appealProducts);
      setCurrentProduct(currentProduct);
    }
  }, [allOrders, products, navigate]);
  useEffect(() => {
    // getLatestOrders();
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setDonorsReached(entry.isIntersecting);
        setImpactReached(false);
        setOtherAppealsReached(false);
        setLevelGivingReached(false);
      },
      {
        root: null, // Use the viewport as the root
        threshold: 0.1, // Trigger callback when 10% of the element is visible
      },
    );
    if (donorsRef.current) {
      observer.observe(donorsRef.current);
    }
    // Cleanup function to unobserve the target element
    return () => {
      if (donorsRef.current) {
        observer.unobserve(donorsRef.current);
      }
    };
  }, [donorsRef]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setImpactReached(entry.isIntersecting);
        setDonorsReached(false);
        setOtherAppealsReached(false);
        setLevelGivingReached(false);
      },
      {
        root: null, // Use the viewport as the root
        threshold: 0.1, // Trigger callback when 10% of the element is visible
      },
    );
    if (impactRef.current) {
      observer.observe(impactRef.current);
    }
    // Cleanup function to unobserve the target element
    return () => {
      if (impactRef.current) {
        observer.unobserve(impactRef.current);
      }
    };
  }, [impactRef]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setOtherAppealsReached(entry.isIntersecting);
        setImpactReached(false);
        setDonorsReached(false);
        setLevelGivingReached(false);
      },
      {
        root: null, // Use the viewport as the root
        threshold: 0.1, // Trigger callback when 10% of the element is visible
      },
    );
    if (otherAppealsRef.current) {
      observer.observe(otherAppealsRef.current);
    }
    // Cleanup function to unobserve the target element
    return () => {
      if (otherAppealsRef.current) {
        observer.unobserve(otherAppealsRef.current);
      }
    };
  }, [otherAppealsRef]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setLevelGivingReached(entry.isIntersecting);
        setImpactReached(false);
        setDonorsReached(false);
        setOtherAppealsReached(false);
      },
      {
        root: null, // Use the viewport as the root
        threshold: 0.1, // Trigger callback when 10% of the element is visible
      },
    );
    if (selectGivingRef.current) {
      observer.observe(selectGivingRef.current);
    }
    // Cleanup function to unobserve the target element
    return () => {
      if (selectGivingRef.current) {
        observer.unobserve(selectGivingRef.current);
      }
    };
  }, [selectGivingRef]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  /* -------------------------------------------------------------------------- */

  return (
    <NewHelmetLayout className="!bg-white" isAppealPage hideDonateNowButton hideHighlights>
      {seo && (
        <Helmet>
          <title>{`${seo['appeals-campaigns'][campaign]['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`} />
          <meta name="description" content={`${seo['appeals-campaigns'][campaign]['description']}`} />
          <div itemscope itemtype="http://schema.org/Product">
            <meta itemprop="brand" content="MATW-Project" />
            <meta itemprop="name" content={`${currentProduct ? currentProduct.name : ''} - MATW Project`} />
            <meta itemprop="description" content={`${currentProduct ? currentProduct.description : ''}`} />
            <meta itemprop="productID" content={`${currentProduct ? currentProduct.creator : ''}`} />
            <meta itemprop="url" content={window.location.href} />
            <meta itemprop="image" content={currentProduct ? currentProduct.image_link : ''} />
            <meta itemprop="price" content={currentProduct ? currentProduct.unit_amount : '100'} />
          </div>

          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
      )}
      {campaign === "gaza-bakery" && (
        <div className="md:container md:mx-auto mt-0 md:mt-2 mb-4">
          <div
            className={`w-full h-auto transition-all transform overflow-hidden lg:min-h-[490px] xl:min-h-[614px] 2xl:min-h-[740px] `}
          >
            <img className={`w-full h-full object-center object-cover`} alt={"Ramadan 2025 - Give like it's their last Ramadan"} src={gazaBakeryBanner} />
          </div>
        </div>
      )}
      {campaign === "palestine-water-pipeline" && (
        <div className="md:container md:mx-auto mt-0 md:mt-2 mb-4">
          <div
            className={`w-full h-auto transition-all transform overflow-hidden lg:min-h-[490px] xl:min-h-[614px] 2xl:min-h-[740px] `}
          >
            <img className={`w-full h-full object-center object-cover`} alt={"Ramadan 2025 - Give like it's their last Ramadan"} src={palestineWaterPipelineBanner} />
          </div>
        </div>
      )}
      {campaign === "gaza-babies" && (
        <div className="md:container md:mx-auto mt-0 md:mt-2 mb-4">
          <div
            className={`w-full h-auto transition-all transform overflow-hidden lg:min-h-[490px] xl:min-h-[614px] 2xl:min-h-[740px] `}
          >
            <img className={`w-full h-full object-center object-cover`} alt={"Gaza New Born Babies"} src={gazaBabiesBanner} />
          </div>
        </div>
      )}
      {/* {console.log("current prd=",currentProduct)} */}
      <AppealCurrentProductUAT
        currentProduct={currentProduct}
        goal={currentProduct ? currentProduct.goal : goal}
        campaign={campaign}
        selectedGivingProducts={selectedGivingProducts.products}
      />
      {campaign !== 'gaza-bakery' &&
        <>
          {/* ------------------- Products Section -------------------- */}
          <div ref={selectGivingRef}>
            {/* {console.log("before shw====", currentProduct)} */}
            <AppealDetailsTabsUAT
              givingProducts={
                // <AppealLevelGivingUAT
                //   givingProducts={currentgivingProducts}
                //   title="More ways to help"
                //   isDetailsPage
                //   campaign={campaign}
                //   handleSelectedGivingProps={() => handleSelectedGiving()}
                // />
                <AppealLevelGiving
                  givingProducts={currentgivingProducts}
                  title="More ways to help"
                  isDetailsPage
                  campaign={campaign}
                  handleSelectedGivingProps={() => handleSelectedGiving()}
                />
              }
              donors={<AppealDonors donors={donors} currency={selectedCurrency} usedOnTab />}
              impact={<AppealImpact usedOnTab />}
              campaign={campaign}
              lang={lang}
              impactReached={impactReached}
              donorsReached={donorsReached}
              otherAppealsReached={otherAppealsReached}
              levelGivingReached={levelGivingReached}
              handleImpactSelected={() => {
                window.scrollTo({ top: impactRef.current.offsetTop - (campaign === 'gaza-camp' ? 400 : 200) });
              }}
              handleDonorsSelected={() => {
                window.scrollTo({ top: donorsRef.current.offsetTop - 200 });
              }}
              handleOtherAppealsSelected={() => {
                window.scrollTo({ top: otherAppealsRef.current.offsetTop - 100 });
              }}
              scrollPosition={scrollPosition}
            />
          </div>
          {/* /* --------------------------------- Youtube -------------------------------- */}
          {currentProduct && currentProduct.youtube && <section className="w-full md:container md:mx-auto py-4 md:py-8 px-4 md:px-0">
            <div className="relative overflow-hidden flex flex-wrap">
              <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
              {/* <YoutubeVideoHero src={`https://www.youtube.com/embed/8fCYKifjeg4?controls=0&autoplay=1&loop=1&mute=1&playlist=8fCYKifjeg4`} /> */}
              <div className={`basis-full min-h-[300px] sm:h-[300px] md:h-[600px] lg:h-[890px]`}>
                <iframe
                  className="video"
                  width={'100%'}
                  height={'100%'}
                  title={'youtube'}
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  // src={
                  //   campaign === 'palestine' || campaign === 'egyptian'
                  //     ? 'https://youtube.com/embed/8fCYKifjeg4?autoplay=0&feature=oembed'
                  //     : campaign === 'limbs-of-hope'
                  //       ? 'https://youtube.com/embed/DnuL-z8zfSw?autoplay=0&feature=oembed'
                  //       : campaign === 'gaza-camp'
                  //         ? 'https://youtube.com/embed/s1NIFIYhC-I?autoplay=0&feature=oembed'
                  //         : 'https://youtube.com/embed/pyV4cpcqvFE?autoplay=0&feature=oembed'
                  // }
                  src={(currentProduct && currentProduct.youtube) || ''}
                />
              </div>
            </div>
          </section>}

          {/* /* ---------------------------------- Text ---------------------------------- */}
          <section className="md:container md:mx-auto px-4 md:px-0 py-8 md:py-12" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
            <h1
              className={`text-2xl md:text-[40px] text-LightBlue ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'
                } mb-5 md:mb-8 md:text-center`}
            >
          {campaign === 'palestine'
            ? 'OUR MATW TEAM HAS NOT STOPPED'
            : campaign === 'egyptian'
              ? 'THEY NEED US NOW MORE THAN EVER'
              : campaign === 'palestine-water-pipeline'
                ? 'About Project Breaking Ground in Gaza'
                : campaign === 'limbs-of-hope'
                  ? 'HOW IT WORKS'
                  : campaign === 'gaza-camp'
                    ? 'Capacity and Current Impact'
                    : campaign === 'gaza-babies'
                    ? ``
                    : 'Bringing Hope and Care to Orphans'}
            </h1>
            <div
              className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'
                }`}
            >
              {campaign === 'palestine' ? (
                <>
                  <p>
                    {' '}
                    We’re on the ground providing life-saving assistance for displaced families in Palestine as well as those who have fled to
                    neighbouring countries seeking refuge. With each day that goes by, the situation becomes more dire and needs become more urgent.
                  </p>
                  <p>Your support is needed now more than ever.</p>
                  <p>
                    You can start by using your Sadaqah for Palestine, which will help us provide urgent medical support, food, clean water, shelter, and
                    family relocation assistance today. You can also donate your Zakat to Palestine, as our Palestine emergency response program is 100%
                    Zakat-applicable.
                  </p>
                </>
              ) : campaign === 'egyptian' ? (
                <>
                  <p>
                    Surviving persecution, these families have done what they can to survive. As Muslims its our duty to support them as much as we can.
                    This is why we have active teams on the ground in neighbouring countries like Egypt, ensuring that every refugee family we support
                    gets the right kind of help they urgently need.
                  </p>
                  <p>
                    We don’t leave vulnerable families on the streets, we make sure they have enough provisions to start again. We provide things like,
                    temporary and permanent housing solutions, furniture like beds, tables, chairs, sofas, mattresses and blankets as well as white goods
                    such as ovens, fridges, microwaves and washing machines. We also provide household and hygiene essentials, clothing and food. As well
                    as, vocational training, healthcare and psychological support for the families. Finally we also provide a cash stipend for the
                    families too.
                  </p>
                </>
              ) : campaign === 'palestine-water-pipeline' ? (
                <>
                  <p>
                    The 15-month conflict and severe economic restrictions have created a catastrophic water crisis in the Gaza strip. Right now, Gaza's main water source is critically depleted, with 97% of its water deemed unsafe for consumption due to high salinity, and pollution. Recognising the urgency, our MATW team have launched a project to construct new water pipelines in three neighbourhoods, and reconstruct damaged water pipelines to restore clean water supply to 150,000 people. These efforts are not just temporary solutions but part of a strategic initiative to build long-term resilience in Gaza’s water sector. The project will be implemented over the next six months.
                  </p>
                  <p><i>The Prophet Muhammed (saw) was asked, “Which charity is best?” He replied, “[Providing] water”’. [Abu Dawud]</i></p>
                  <div className="flex flex-col gap-2">
                    <strong>Project Objectives</strong>
                    <p>Enhance Water Accessibility Provide consistent access to clean and safe drinking water for communities in South Gaza, reducing the burden on families who currently travel long distances to collect water.
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <strong>Improve Public Health</strong>
                    <p>Reduce the spread of waterborne diseases by supplying clean water through a modernized distribution network, particularly benefiting vulnerable women and young children.</p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <strong>Strengthen Infrastructure</strong>
                    <p>Develop a sustainable water infrastructure that can withstand current demands and future growth, ensuring long-term water security for the region.</p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <strong>Community Involvement</strong>
                    <p>Engage local communities in the construction and maintenance of the water lines, fostering a sense of ownership and ensuring sustainability.</p>
                  </div>
                  <img src={projectBreakingPhoto} alt="Every Gaza Child Deserves Limbs" />
                  <h1 className="text-2xl md:text-[40px] text-LightBlue font-brandingBold pt-3 mb-5 md:mb-8 md:text-center">How is MATW Project getting aid into Gaza?</h1>
                  <p className="pt-5">MATW is one of the very few charity organisations partnered with the Jordan Hashemite Charity Organisation- a government organisation who has certain permissions to operate in Gaza. This allows us to respond swiftly and effectively to the urgent needs of those suffering. We have established reserves on the ground, ensuring that we can immediately deliver essential relief items to where it is most needed. We are also working alongside the Egyptian Youth Council and the Egyptian Red Crescent, guaranteeing that these items reach those in Gaza without delay.</p>
                  <div className="flex flex-col gap-2">
                    <strong>Our promise to you.</strong>
                    <p>At MATW, we are unwavering in our commitment to never give up on those who are suffering. Every single life matters, and your support empowers us to continue this critical work on the ground.</p>
                  </div>
                  <p>We prioritize transparency with our donors. Every penny you donate to our Palestine Emergency Appeal goes directly to the field—100% of your donation will be used to provide essential aid.</p>
                  <p>Moreover, all donations to our Palestine Emergency Appeal are 100% Zakat eligible.</p>
                  <p>Trust us to be the partner that makes a real difference in the lives of those in desperate need. Your generosity saves lives. Donate Now.</p>
                </>
              ) : campaign === 'limbs-of-hope' ? (
                <>
                  <p>
                    As Gazans are unable to leave, two customised vans have been specially designed and fitted with all the necessary equipment required
                    to fit the new prosthetics. These are called Mobile Amputee Support Units. Once movement is deemed safe, they will be deployed from
                    the military field hospitals in Jordan to hospitals and neighbourhoods in Gaza to fit the prosthetics.
                  </p>
                  <p>Prosthetics will be fitted with a sensor to measure the use and comfort for amputees.</p>
                  <p>All fittings will be documented and recorded to allow for e-consultations with doctors in Jordan for proper follow-up.</p>
                  <img src={EveryGazaChildDeservesLimbsImg} alt="Every Gaza Child Deserves Limbs" />
                  <h1 className="text-2xl md:text-[40px] text-LightBlue font-brandingBold pt-3 mb-5 md:mb-8 md:text-center">MATW in Palestine</h1>
                  <p className="pt-5">
                    With a long-standing presence in Palestine and supplies on standby for an immediate response, our MATW team were quick to deliver
                    urgent essentials to those directly affected by the conflict in Palestine on October 7. Our direct response efforts have been focused
                    on those who are most vulnerable including orphans, widows, the elderly and families.
                  </p>
                  <p>
                    Our current partnership with The Jordanian Hashemite Charity Organisation, The Egyptian Red Crescent and The Egyptian Youth Council
                    enables us to further increase our delivery impact and further our reach in areas that are difficult to access.
                  </p>
                  <p>
                    To date, our MATW Project team has packed and sent more than 100 trucks carrying essential relief items into Palestine. These items
                    have included the delivery of emergency medical kits, urgent food packs, rice, oil, fuel, hot meals and more to those who have been
                    displaced. &nbsp;
                    <a className="underline" href="https://matwproject.org/pdf/Palestine%20Impact%20Report%20-%202023-24.pdf">
                      Click here to read more about MATW’s Palestine Emergency response.
                    </a>
                  </p>
                  <p>
                    Among our primary focuses now is to ensure that those who are most vulnerable, including orphaned children, are not lost to this
                    conflict or its consequence of famine.
                  </p>
                  <img src={CouldPlayLikeKidsImg} alt="Could play like other kids" />
                </>
              ) : campaign === 'gaza-camp' ? (
                <p>
                  The camp has already accommodated over 400 families, totaling more than 2,500 individuals who were previously homeless and lacked basic
                  resources. Among them, around 1,000 children have found a secure environment where they receive essential care and amenities. MATW is
                  committed to prioritizing the needs of these young residents, many of whom have endured trauma due to ongoing conflicts, ensuring they
                  reside in a supportive and stable environment.
                </p>
              ) : campaign === 'gaza-babies'
              ? ``
              :  (<>
                  <p>
                    We've poured our hearts and souls into this project, working tirelessly day and night to bring this vision to life. Our unwavering
                    dedication and relentless efforts have turned what was once a dream into a promising reality. But our journey is far from over.
                  </p>
                  <p>
                    We call upon your generous spirit to support this cause so that the orphans in North Lebanon can finally receive the urgent care and
                    support they desperately need. Together, we can create a haven of hope, love, and healing for these vulnerable children. Your support
                    can make all the difference.
                  </p>
                </>
              )}
            </div>
          </section>
          {/* /* --------------------------------- Impacts -------------------------------- */}
          {getCampaign(campaign).showImpactSection && <div id="impacts" ref={impactRef}>
            <AppealImpact />
          </div>}
          {/* /* -------------------------------------------------------------------------- */}
          {/* ------------------------- videos section ------------------------------------ */}
          {/* <AppealVideos campaign={campaign} /> */}
          {!(campaign === 'gaza-babies') && <section className="mt-4 md:mt-12 mb-6">
            {campaign === 'palestine' ? (
              <CarouselImages carouselImages={carouselImages} />
            ) : campaign === 'egyptian' ? (
              <ImagesSection />
            ) : campaign === 'limbs-of-hope' ? (
              <CarouselImages carouselImages={carouselImagesLimbs} />
            ) : campaign === 'palestine-water-pipeline' ? (
              <CarouselImages carouselImages={carouselImagesPalestineWaterPipeline} />
            ) : campaign === 'gaza-camp' ? (
              <CarouselImages carouselImages={carouselImagesGazaCamp} />
            ) : campaign === 'north-lebanon-orphan' ? (
              <CarouselImages carouselImages={carouselImagesNorthLebanon} />
            ) : (
              // <CarouselImages carouselImages={carouselImages} />
              <></>
            )}
          </section>}
          {/* /* -------------------------------------------------------------------------- */}
          {/* /* --------------------------------- Donors --------------------------------- */}
          {getCampaign(campaign).showOtherAppeals && <div id="other_appeals" ref={otherAppealsRef}>
            <AppealWidget allProducts={appealProducts} title={'MATW Appeals'} />
          </div>}
          <div id="donors" ref={donorsRef}>
            <AppealDonors donors={donors} currency={selectedCurrency} />
          </div>
          {/* {console.log("selecte giving=",selectedGivingProducts)} */}
          {selectedGivingProducts.products.length ? (
            <div className="fixed bottom-0 sm:hidden- [&>div]:w-full z-20- z-[2200000000]- z-[199] shadow-[rgba(189,189,189,0.5)_0px_-15px_25px_-1px] w-full left-0 p-2 bg-LighterBlue">
              <div className="flex flex-col gap-1 items-center w-full justify-around mx-auto p-2 rounded-lg bg-white">
                <div
                  className={`flex w-full flex-col border-b border-stone-300 ${isMinimized ? '' : 'mb-1'} pb-1 cursor-pointer`}
                  onClick={handleMinimize}
                >
                  <h2 className={`text-DarkerBlue text-lg md:text-2xl w-full font-bold w-100 flex justify-between`}>
                    Your Giving Amount{' '}
                    {/* <span className="text-[#f60362] cursor-pointer" onClick={handleMinimize}>
                  ✖
                </span> */}
                    <ChevronDownIcon
                      className={`w-4 h-4 cursor-pointer text-stone-600 transition-all duration-300 ${isMinimized ? ' transform rotate-180' : ''}`}
                    />
                  </h2>
                  <div className="flex items-center">
                    <span className="text-DarkerBlue text-sm">{selectedGivingProducts.products.length} items in Cart</span>
                    <span className="text-LightBlue text-sm cursor-pointer ml-2" onClick={() => navigate(generateLinkWithLang('/checkout', paramLang))}>
                      View Cart
                    </span>
                  </div>
                </div>
                <div className={`transition-all duration-300 w-full ${isMinimized ? 'h-0' : 'h-auto'} overflow-hidden`}>
                  {/* <div className={`transition-all duration-300 overflow-hidden w-full`} style={{ height: `${isMinimized ? '0px' : `${(selectedGivingProducts.products.length > 1 ? selectedGivingProducts.products.length * 32 : selectedGivingProducts.products.length * 36) + 29}px`}` }}> */}
                  <div className="flex justify-between items-center flex-col py-1 w-full border-b border-stone-200 max-h-[160px] overflow-y-auto">
                    {selectedGivingProducts.products.map((item, index) => {
                      if (showMoreItems) {
                        return (
                          <div key={`giving${index}`} className="w-full flex items-center justify-between mb-1">
                            <div className="text-stone-400 text-xs flex items-center">
                              <CloseIcon className="w-4 h-4 text-stone-400 mr-2 cursor-pointer" onClick={() => handleRemoveProduct(item)} />
                              <p className="text-stone-400 text-xs">{item.name}</p>
                            </div>
                            <p className="text-xs text-DarkerBlue">
                              {ShowCurrencies(selectedCurrencyStore, item.quantity * item[selectedCurrencyStore.toLowerCase()], false)}
                            </p>
                          </div>
                        );
                      } else {
                        return (
                          index < 2 && (
                            <div key={`giving${index}`} className="w-full flex items-center justify-between mb-1">
                              <div className="text-stone-400 text-xs flex items-center">
                                <CloseIcon className="w-4 h-4 text-stone-400 mr-2 cursor-pointer" onClick={() => handleRemoveProduct(item)} />
                                <p className="text-stone-400 text-xs">{item.name}</p>
                              </div>
                              <p className="text-xs text-DarkerBlue">
                                {ShowCurrencies(selectedCurrencyStore, item.quantity * item[selectedCurrencyStore.toLowerCase()], false)}
                              </p>
                            </div>
                          )
                        );
                      }
                    })}
                  </div>
                  {selectedGivingProducts.products.length > 2 && (
                    <div className="flex border-b border-stone-200 pb-2">
                      <span className="text-LightBlue text-xs flex items-center cursor-pointer mt-1" onClick={() => setShowMoreItems(!showMoreItems)}>
                        {showMoreItems ? (
                          <>
                            Show less items <ChevronDownIcon className={`w-3 h-3 text-stone-500 ml-2`} />
                          </>
                        ) : (
                          <>
                            View more items <ChevronUpIcon className={`w-3 h-3 text-stone-500 ml-2`} />
                          </>
                        )}
                      </span>
                    </div>
                  )}
                  <div className="flex justify-between items-center border-t border-stone-300 py-1 w-full">
                    <p className="text-stone-500 text-xs">Total Amount</p>
                    <p className="text-sm font-bold text-DarkerBlue">{ShowCurrencies(selectedCurrencyStore, selectedGivingProducts.total, false)}</p>
                  </div>
                </div>
                <div className="flex items-center mt-1 w-full">
                  {/* {!isDetailsPage && <CustomButton
                  onClick={() => handleBack()}
                  title={'Back'}
                  type="button"
                  className={`w-auto !bg-[#00a3da] mr-2`} />} */}
                  <CustomButton
                    title={
                      <>
                        <span className='mr-1'>Give</span>
                        {ShowCurrencies(selectedCurrencyStore, selectedGivingProducts.total, false)}
                      </>
                    }
                    link={'/checkout'}
                    className={`${lang === 'ar' ? '' : 'font-["Montserrat"]'} font-bold w-full`}
                  />
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      }
    </NewHelmetLayout>
  );
};
// export const startDateOfAppealCampaign = new Date().toISOString().split('T')[0];
// export const startDateOfAppealCampaign = moment().format('YYYY-MM-DD') === '2024-09-04' ? '2024-09-04' : '2024-09-05' ;
// export const startDateOfAppealCampaign = '2024-09-05';
// export const endDateOfAppealCampaign = '2024-12-20';
// export const endDateOfGazaCampCampaign = '2024-12-25';
// export const endDateOfLimbsAppealCampaign = '2024-12-25';

export default MATWAppealDetailsLandingPageUATx;