import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import banner from '../../images/ramadan/last-10-night-new.png';
import bannerMobile from '../../images/ramadan/last-10-night-new.png';
import ramadanIcon from '../../images/ramadan/ramadan-icon.png';
import matwPolicy from '../../images/ramadan/matw-policy.png';
import { IoMdInformationCircle } from 'react-icons/io';
import { Tooltip } from 'react-tooltip';
import { CustomButton } from '../../components/general';
import { selectProducts } from '../../store/products';
// import ProductBox from '../../components/general/productBox';
import useCart from '../../hooks/cart/useCart';
import { ProductBoxCartLast10Nights } from '../home/components/productBoxCartLast10Nights';
import ShowCurrencies from '../../utils/showCurrencies';
// import useHandleAddProduct from '../../utils/handleAddProduct';
import { useNavigate } from 'react-router-dom';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';
import SelectCurrency from '../../components/general/selectCurrency';
import { BASKET_CACHE_KEY, HandleLocalStorage } from '../../utils/handleLocalStorage';
// import WidgetZakatVertical from '../../components/componentsRepo/src/components/general/WidgetZakatVertical';

const LastTenNightsDailyGivingNew = () => {
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    return (
        <NewHelmetLayout hideFooter hideHighlights hideDonateNowButton className="bg-[#E9F7FF] " childClasses={'pb-0'}>
            {seo &&
                <Helmet>
                    <title>{`${seo['ramadanDaily']['title']}`}</title>
                    <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
                    <meta name="description" content={`${seo['ramadanDaily']['description']}`} />
                </Helmet>
            }
            <section className="flex md:hidden justify-center px-4 pt-4">
                <img src={banner} alt="ramadan 2025" className="max-[500px]:hidden w-full" />
                <img src={bannerMobile} alt="ramadan 2025" className="min-[501px]:hidden w-full" />
            </section>
            <section className="hidden md:flex md:container md:mx-auto gap-4 justify-center pt-4 mb-4">
                <div className="w-1/3 flex flex-col p-4 bg-[#eaf7ff]- bg-white">
                    <LastTenNightsWidget />
                </div>
                <div className="w-2/3 flex flex-col gap-4 bg-[#eaf7ff]- bg-white pb-4">
                    <img src={banner} alt="ramadan 2025" className="max-[500px]:hidden w-full" />
                    <h1 className=' text-pink text-4xl font-bold mx-5'>
                        GIVE LIKE IT’S YOUR LAST RAMADAN
                    </h1>
                    <div className="mx-5">
                        If you knew this was your last Ramadan, how would you give? Would you hold back, waiting for the perfect moment? Would you hesitate and say, “Maybe next year”? Or would you give with everything you have, knowing that this could be your final chance to secure Allah’s mercy, His forgiveness, and His endless reward?
                        <br />
                        <br />
                        Ramadan is already the month where every good deed is multiplied, but the last ten nights are something else. These are the nights when destinies are rewritten, when prayers are answered, and when sins are wiped clean.
                        <br />
                        <br />
                        <h2 className="text-DarkerBlue font-bold text-2xl">A SINGLE NIGHT WORTH MORE THAN A LIFETIME</h2>
                        <br />
                        Allah tells us that Laylatul Qadr, the Night of Power, is better than a thousand months. Think about that. One night of giving, one night of prayer, one night of charity is worth more than eighty three years of worship.
                        <br />
                        Now imagine donating just one dollar or one pound on that night. In Allah’s scale, it is as if you donated every single night for over thirty thousand nights. What if you gave ten dollars? What if you gave fifty? What if you gave one hundred? The reward is beyond imagination.
                        <br />
                        <br />
                        But here is the catch. We do not know exactly which night it is. The Prophet Muhammad, peace and blessings be upon him, told us to search for it in the odd nights of the last ten. The 21st. The 23rd. The 25th. The 27th. The 29th.
                        <br />
                        That means every single night you give, you increase your chances of catching Laylatul Qadr. Every dollar. Every pound. Every cent. Every penny. It could be multiplied beyond anything you can comprehend.
                        <br />
                        <br />
                        <h2 className="text-DarkerBlue font-bold text-2xl">GAZA NEEDS US NOW MORE THAN EVER</h2>
                        <br />
                        Right now, as we sit in our comfortable homes, families in Gaza are fighting for survival. Mothers are holding their starving children. Fathers are struggling to find clean water. Entire families are sleeping under open skies, not knowing where their next meal will come from.
                    </div>
                </div>
            </section>
            <section className="w-full md:hidden py-4 gap-4 px-4">
                <LastTenNightsWidget />
            </section>
        </NewHelmetLayout>
    )
}

export default LastTenNightsDailyGivingNew

export const TitleBox = props => {
    const { text = '', tooltipText = '', className = '', showCurrency = false } = props;
    return (
        <div className={`flex justify-between items-center rounded-md bg-LightBlue px-3 py-2 md:px-6- md:py-2 min-h-[50px] ${className}`}>
            <span className="text-white md:text-xl">
                {text}
            </span>
            {showCurrency && <SelectCurrency
                onChangeCurrency={(e) => { }}
                classNamePrefix="currency-select-new currency-select-new--main !h-[40px]-"
                className="[&>div]:!rounded-none [&>div]:!h-[40px]- !max-h-[40px]- !max-w-[40%] !text-black"
            />}
            {tooltipText && <>
                <span id="clickable" className='text-sm'>
                    <IoMdInformationCircle className="text-xl hidden sm:block" />
                </span>
                <Tooltip anchorSelect="#clickable" clickable className="max-w-xs z-[1200] text-sm">
                    {tooltipText}
                </Tooltip></>}
        </div>
    )
}
export const LastTenNightsWidget = () => {
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector(state => selectProducts(state));
    const [isLoaded, setIsLoaded] = useState(false);
    const [last10NightsProducts, setLast10NightsProducts] = useState([0, 0, 0]);

    const [splitItems, setSplitItems] = useState([
        { title: 'Equally', text: 'Your donation will be equally split across all 10 nights', active: true },
        { title: '+Odd Nights', text: 'Your donation will be split 25% on the even nights and 75% on the odd nights', active: false },
        { title: '+On 27th', text: 'Your donation will be split 20% on all nights and 80% on the 27th night', active: false }
    ]);
    const [startFasting, setStartFasting] = useState([
        { title: 'Sat 1st March', active: true },
        { title: 'Sun 2nd March', active: false },
    ]);
    const activeSplitItem = splitItems.find(item => item.active);
    const { handleAddToGiving, handleReduceQty,
        handleAddQty,
        handleUpdateQty } = useCart();
    // const [addProduct] = useHandleAddProduct();
    const [totalAmount, setTotalAmount] = useState(0)
    /* ------------------------------ Start methods ----------------------------- */
    const handleStartFasting = (index) => {
        const updatedItems = startFasting.map((item, i) => ({
            ...item,
            active: i === index
        }));
        setStartFasting(updatedItems);
    };
    const handleSplit = (index) => {
        const updatedItems = splitItems.map((item, i) => ({
            ...item,
            active: i === index
        }));
        setSplitItems(updatedItems);
    };
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempSelectedProducts = [];
        let prdCreators = ['HDD-2025-003', 'MD-AA-2024-02', 'OPR-GEN-SUP-2023-01-01']
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    prdCreators.map((creator, creator_index) => {
                        if (creator === inner_item_product.creator) {
                            inner_item_product = Object.assign({}, inner_item_product, {
                                quantity: 50,
                            });
                            tempSelectedProducts[creator_index] = inner_item_product;
                        }
                        return creator;
                    })
                    return inner_item_product;
                });
                return inner_item;
            });
            return item;
        });
        tempSelectedProducts = tempSelectedProducts.filter(n => n);
        handleTotalAmount(tempSelectedProducts)
        setLast10NightsProducts(tempSelectedProducts);
    };
    const handleAddProduct = () => {
        if (totalAmount > 0) {
            const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
            HandleLocalStorage(basketStatesFromLocalStorage, true);
            localStorage.setItem('IS_DAILY_DONATION', JSON.stringify(true));
            last10NightsProducts.map(item => {
                item.quantity > 0 && handleAddToGiving(item, item.quantity);
                return item;
            });
            navigate(generateLinkWithLang(`/checkout`, lang));
        }
        // if (addToCart) {
        //     // handleAddToGivingCart(selectedProduct, 'custom');
        //     // dispatch(openSheet());
        // } else {
        //     // addProduct({ product: selectedProduct, currency: currency });
        // }
    }
    const handleChangeQuantity = (quantity, index) => {
        const updatedItems = last10NightsProducts.map((item, i) => ({
            ...item,
            quantity: i === index ? quantity : item.quantity
        }));
        handleTotalAmount(updatedItems)
        setLast10NightsProducts(updatedItems);
    }
    const handleTotalAmount = products => {
        let tempTotal = 0;
        products.map(item => {
            tempTotal += Number(item[selectedCurrencyStore.toLowerCase()]) * item.quantity
            return item;
        })
        setTotalAmount(tempTotal)
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- start hooks ------------------------------ */
    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);
    /* -------------------------------------------------------------------------- */
    return (
        <>
            <div className="w-full flex justify-between items-center mb-4">
                <img src={ramadanIcon} alt="ramadan 2025" className="max-w-[130px]" />
                <h1 className='text-xl md:text-2xl font-bold text-LightBlue text-center'>Never Miss Laylatul Qadr</h1>
                <img src={matwPolicy} alt="ramadan 2025" className="max-w-[130px]" />
            </div>
            <TitleBox text='1. Automate your donations in Ramadan' className='mb-2' />
            <CustomButton
                onClick={() => {
                    console.log("clicked")
                }}
                // icon={<img {...staticImages.donateNowIcon} alt='Donate Now' />}
                title={'Last 10 Nights'}
                // disabled={!isLoaded}
                className={`w-full hover:!bg-[#F60362] !h-[50px] !rounded-md text-sm md:!text-base !capitalize mb-2`}
            />
            <TitleBox text='2. Choose the split' className='mb-2' />
            <div className="w-full flex flex-col gap-2 mb-2">
                <div className="w-full flex justify-between items-center gap-2">
                    {splitItems.map((item, index) => (
                        <div
                            key={index}
                            className={`flex justify-center items-center flex-1 text-center cursor-pointer rounded-lg transition-all min-h-[50px]
                            ${item.active ? 'bg-pink text-white' : 'bg-white text-LightBlue'}`}
                            onClick={() => handleSplit(index)}
                        >
                            {item.title}
                        </div>
                    ))}
                </div>
                <div className="p-4 w-full text-center bg-white text-LightBlue rounded-lg shadow-">
                    {activeSplitItem?.text}
                </div>
            </div>
            <TitleBox text='3. When did you start fasting?' className='mb-2' />
            <div className="w-full flex flex-col gap-3 mb-2">
                <div className="w-full flex justify-between items-center gap-3">
                    {startFasting.map((item, index) => (
                        <div
                            key={index}
                            className={`flex justify-center items-center flex-1 text-center cursor-pointer rounded-lg transition-all min-h-[50px]
                            ${item.active ? 'bg-pink text-white' : 'bg-white text-LightBlue'}`}
                            onClick={() => handleStartFasting(index)}
                        >
                            {item.title}
                        </div>
                    ))}
                </div>
            </div>
            <TitleBox text='4. Choose your donations' className='mb-2' showCurrency />
            <div className="w-full flex flex-col gap-3 mb-2">
                <div className="flex flex-wrap gap-3 justify-center items-start w-full">
                    {last10NightsProducts.length && last10NightsProducts.map((item, productIndex) => (
                        <React.Fragment key={`product-${productIndex}`}>
                            <div className="w-full sm:max-w-[300px]- sm:basis-[calc(50%-8px)]-">
                                <ProductBoxCartLast10Nights
                                    key={productIndex}
                                    product={item}
                                    // onCheck={handleCheckProduct}
                                    onAdd={handleAddToGiving}
                                    onAddQty={handleAddQty}
                                    onReduceQty={handleReduceQty}
                                    onUpdateQty={handleUpdateQty}
                                    // onRemove={handleRemoveProduct}
                                    currency={selectedCurrencyStore}
                                    setQuantity={(e) => handleChangeQuantity(e, productIndex)}
                                />
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="w-full flex justify-end text-LightBlue font-bold text-2xl md:text-2xl mb-2">
                <span className='text-LightBlue mr-1'>Total to pay now:</span>
                {ShowCurrencies(selectedCurrencyStore, totalAmount, true)}
            </div>
            <div className="w-full flex flex-col">
                <div className="flex justify-between md:justify-center gap-2 mb-2">
                    <img src="/images/payment/google-pay-dark.png" alt="google-pay" className="w-full md:max-w-[200px] cursor-pointer" onClick={() => handleAddProduct()} />
                    <img src="/images/payment/apple-pay-dark.png" alt="apple-pay" className="w-full md:max-w-[200px] cursor-pointer" onClick={() => handleAddProduct()} />
                </div>
                <CustomButton
                    onClicked={() => handleAddProduct()}
                    title={'Donate Now'}
                    disabled={totalAmount <= 0}
                    className={`min-h-[50px]`}
                />
            </div>
        </>
    )
}
