import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { selectHighlightMenu } from '../../store/menu';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';
const Highlights = () => {
    const [allMenus, setAllMenus] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [isLoaded, setIsLoaded] = useState(false);
    const menus = useSelector(state => selectHighlightMenu(state));
    const getAllMenus = () => {
        setIsLoaded(true);
        let newMenus = menus.map(item => item).filter(item => item.status)
        setAllMenus(newMenus);
    };
    const { i18n } = useTranslation();
    const lang = i18n.language;
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        // console.error('menus highlight========', menus);
        menus && Object.keys(menus).length && !isLoaded && getAllMenus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menus]);
    /* -------------------------------------------------------------------------- */
    return (
        <div className="w-full bg-[#093484] min-h-[110px] md:min-h-[120px] flex px-2 overflow-x-scroll md:overflow-x-auto">
            <div className="md:mx-auto grid grid-flow-col gap-1 md:min-w-[955px] justify-center items-center">
                {allMenus.map((item, index) => {
                    return item
                        ? <Link key={`links${index}`} to={item.url ? generateLinkWithLang(item.url,lang) : '#'} rel={item.no_follow ? 'nofollow' : ''} className={`min-w-max flex flex-col px-1 justify-center items-center ${item.url === '/zakat' ? '' : 'overflow-hidden'}`}>
                            <img src={item.icon} alt={item.alt} title={item.image_title ? item.image_title : item.title} className={`max-w-full w-[56px] md:w-[68px] lg:w-[78px] ${item.url === '/zakat' ? 'circle_animation circle_animation_hover' : 'circle_animation_hover'} rounded-full`} />
                            <h3 className='text-center text-white text-[12px] mt-1 whitespace-nowrap'>{item.title}</h3>
                        </Link>
                        : <Link key={`links${index}`} to={'#'} className={`min-w-max flex flex-col px-1 justify-center items-center overflow-hidden`}>
                            <div className={`flex justify-center items-center max-w-full w-[56px] md:w-[68px] lg:w-[78px] h-[56px] md:h-[68px] lg:h-[78px] circle_animation_hover rounded-full border-2 border-[#1c9dda]`}>
                                <div className={`max-w-full w-[48px] md:w-[60px] lg:w-[70px] h-[48px] md:h-[60px] lg:h-[70px] circle_animation_hover rounded-full bg-[#1c9dda]`} />
                            </div>
                            <h3 className='text-center text-white text-[12px] mt-1 whitespace-nowrap'>
                                <Skeleton key={`linksloading${index}`} height={8} width={60} />
                            </h3>
                        </Link>
                    // : <div key={`skelet${index}`} className="flex w-[80px] h-[80px] rounded-full overflow-hidden items-center [&_span]:rounded-full">
                    //   <Skeleton key={`linksloading${index}`} height={80} width={80} />
                    //</div>
                })}
            </div>
        </div>
    );
}
export default Highlights;
