import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import Accordion from '../../components/general/accordion';
import muslimAroundImg from '../../images/landings/qurban/muslim-arounds.jpg';
import { qurbanArabicFaqs, qurbanFaqs, qurbanFranceFaqs } from './faqs';
import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
import QurbanSlider from './components/qurban-slider';
import qurbanSlider1 from '../../images/landings/qurban/qurban-slider1.jpg';
import qurbanSlider2 from '../../images/landings/qurban/qurban-slider2.jpg';
import qurbanSlider3 from '../../images/landings/qurban/qurban-slider3.jpg';
import qurbanSlider4 from '../../images/landings/qurban/qurban-slider4.jpg';
import qurbanSlider5 from '../../images/landings/qurban/qurban-slider5.jpg';
import qurbanSlider6 from '../../images/landings/qurban/qurban-slider6.jpg';
import CustomButton from '../../components/general/button';
// import UltimateQurbanWidget from './components/qurbanUltimateWidget';
import { useState } from 'react';
import { useEffect } from 'react';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const images = [
  { src: qurbanSlider1, alt: 'Donate to palestine, they need you' },
  { src: qurbanSlider2, alt: 'Palestine Charity to help the palestinian people' },
  { src: qurbanSlider3, alt: 'Palestine donation to make an impact' },
  { src: qurbanSlider4, alt: 'Palestine appeal, help them today' },
  { src: qurbanSlider5, alt: 'donate for palestine emergency appeal' },
  { src: qurbanSlider6, alt: 'donate for palestine emergency appeal' }
];

const UltimateQurbanLandingPage = ({ title, lang }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [faqs, setFaqs] = useState(qurbanFaqs)
  useEffect(() => {
    lang === 'fr'
      ? setFaqs(qurbanFranceFaqs)
      : lang === 'ar'
        ? setFaqs(qurbanArabicFaqs)
        : setFaqs(qurbanFaqs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);
  /* -------------------------------------------------------------------------- */

  return (
    <NewHelmetLayout className="!bg-white" showRegularImg={false}>
      {seo && (
        <Helmet>
          <title>{`${seo[title.toLowerCase()]['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
          <meta name="description" content={`${seo[title.toLowerCase()]['description']}`} />
        
          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
      )}
      {/* /* -------------------------------------------------------------------------- */}

      {/* --------------------------- Video Section ----------------------------------- */}
      <div className="md:container mx-auto md:py-16">
        <div className="relative overflow-hidden flex flex-wrap">
          <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
          <YoutubeVideoHero src={`https://www.youtube.com/embed/IKvFUTs8Etc?controls=0&autoplay=1&loop=1&mute=1&playlist=IKvFUTs8Etc`} className={'md:!h-[800px] lg:!h-[1000px]'} />
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------------------- Products Section ---------------------------- */}
      <section className="bg-[#253B7E]">
        <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12 flex flex-col">
          <div className={`flex flex-col ${lang === 'ar' ? 'font-[AlmariMedium]' : ''}`} dir={lang === 'ar' ? 'rtl' : 'ltr'}>
            <h1 className="w-full text-white text-[25px] md:text-[40px] leading-7 md:leading-[48px] text-center font-bold mb-2 md:mb-4">
              {lang === 'fr'
                ? `En cet Aïd al-Adha, honorez vos obligations en poussant votre générosité, grâce à un double don de Qurban, et au don additionnel de 2 kg de viande et 2 kg de riz supplémentaires à une famille en difficulté en Palestine !`
                : lang === 'ar'
                  ? `امنح تبرعك بالقربان بعمق هذا عيد الأضحى من خلال أداء واجبك وتقديم قربانين، بالإضافة إلى توفير 2 كيلوغرام إضافي من اللحم و2 كيلوغرام من الأرز لعائلة متعثرة في فلسطين!`
                  : `Take your ${title} donation further this Eid Al-Adha by fulfilling your obligation and delivering two ${title}, as well as delivering an additional 2kg of meat and 2kg of rice for a struggling family in Palestine!`
              }
            </h1>
            <p className="max-w-[900px] mx-auto text-white text-lg text-center mb-2 md:mb-4">
              {lang === 'fr'
                ? `Ainsi, un seul don gracieux de votre part vous permettra d’accomplir quatre bonnes œuvres pendant les dix jours bénis de Dhou al-hijja. Choisissez dès aujourd’hui l’offre groupée « Qurban ultime».`
                : lang === 'ar'
                  ? `أربع أعمال جميلة في العشر الأوائل المباركة من ذو الحجة مع تبرع واحد وفعال. قدم حزمة القرباني+ النهائية اليوم.`
                  : `Four beautiful deeds in the blessed ten days of Dhul Hijjah with one single, impactful donation. Give an Ultimate ${title}+ bundle today.`
              }
            </p>
          </div>
          {/* <UltimateQurbanWidget title={title} lang={lang} /> */}
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* -------------------------------- helped us ------------------------------- */}
      <div className="md:container md:mx-auto px-4" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <div className="flex flex-col py-8 md:py-16">
          <p className={`md:text-lg text-[#78716C] pr-0 ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium text-left sm:pr-12'}`}>
            {lang === 'fr'
              ? <>
                Ce Dhou al-hijja, de nombreuses familles à travers le monde seront en difficulté, en particulier celles de Palestine, qui vivent dans des conditions d’incertitude pesante. Au vu de la conjoncture et de l’augmentation du coût de la vie, offrir le Qurban dans cette région pourrait s’avérer inabordable. Mais chez MATW, nous avons trouvé comment faire en sorte que votre Qurban soit le plus généreux possible. En choisissant une offre groupée « Qurban ultime + », vous pouvez maximiser l’impact de votre don au cours des dix jours bénis de Dhou al-hijja.
                <br />
                <br />
                <span className='text-[#253A7E] font-bold'>et offrir une bête sacrificielle dans le pays de votre choix (Inde ou Yémen), ainsi que 2 kg de viande et 2 kg de riz en Palestine.</span>
                <br />
                <br />
                Les kilos supplémentaires de viande et de riz apporteront une aide indispensable à la Palestine, en proie à la famine.
                <br />
                <br />
                Notre offre groupée « Qurban ultime + » permet à nos généreux donateurs d’accomplir l’ultime action de grâce en faisant don de leur Qurban, tout en obtenant des bénédictions supplémentaires à travers leur soutien aux personnes de notre oumma qui souffrent.
                <br />
                <br />
                Recevez les grâces d’Allah multipliées par les bonnes œuvres qu’Il aime. Faites don d’un pack « Qurban ultime + » et soyez assurés de notre travail sans relâche visant à ce que votre contribution puisse nourrir celles et ceux qui en ont urgemment besoin. Saisissez cette occasion de multiplier vos bénédictions durant les jours les plus gratifiants de l’année.
              </>
              : lang === 'ar'
                ? <>
                  سيواجه العديد من الأسر في جميع أنحاء العالم صعوبات في ذو الحجة هذا، خاصة تلك التي عاشت مع الكثير من عدم اليقين في فلسطين. مع ارتفاع التكاليف في الاقتصاد، قد لا يكون تقديم القرباني في هذه المنطقة متاحًا بنفس السعر، لكننا في MATW وجدنا طريقة لك لتأخذ تبرعك بالقرباني إلى مستوى أعلى. يمكن لتبرع حزمة القرباني+ النهائية واحدة أن يزيد من تأثيرك خلال الأيام المباركة العشر من ذو الحجة.
                  <br />
                  <br />
                  <span className='text-[#253A7E] font-bold'>سيوفر تبرعك بحزمة القرباني+ النهائية قربانًا في بلد يعتمد على حزمتك المختارة (الهند أو اليمن)، بالإضافة إلى 2 كيلوغرام من اللحم و2 كيلوغرام من الأرز في فلسطين.</span>
                  <br />
                  <br />
                  الكيلوغرامات الإضافية من اللحم والأرز الإضافي ستوفر التغذية الضرورية خلال فترة يكاد فيها يكون الفلسطينيون على حافة المجاعة.
                  <br />
                  <br />
                  عرضنا النهائي للقربان+ يوفر لكم، أيها المتبرعون الكرام، فرصة أداء أعظم العبادات بتقديم قربانكم، مع جني البركات الإضافية لدعم أولئك الذين يعانون في أمتنا.
                  <br />
                  <br />
                  ضاعفوا بركاتكم من خلال أداء الأعمال المحبوبة لله. قدموا تبرعكم بعرض القربان+ النهائي وكونوا على يقين أن تبرعكم يعمل بلا كلل لإطعام أولئك الذين يحتاجون بشدة إلى ذلك. استغلوا الفرصة لتضاعفوا بركاتكم خلال أيام السنة الأكثر مكافأة.
                </>
                : <>
                  Many families around the globe will struggle this Dhul Hijjah, especially those who have been living with so much uncertainty in Palestine. With rising costs in the economy, delivering {title} in this region may not be as affordable but we at MATW have found a way for you to take your {title} donation further. One Ultimate {title}+ bundle donation can maximise your impact in the blessed ten days of Dhul Hijjah
                  <br />
                  <br />
                  <span className='text-[#253A7E] font-bold'>Your Ultimate {title}+ bundle donation will provide a {title} sacrifice in a country dependent on your bundle of choice (India or Yemen) as well as 2kg of meat and 2kg rice in Palestine</span>
                  <br />
                  <br />
                  The extra kilos of meat and additional rice will provide much-needed sustenance during a time where Palestine is on the brink of famine
                  <br />
                  <br />
                  Our Ultimate {title}+ bundle provides a way for you, our generous donors, to be able to perform the ultimate Ibadah by donating you {title} whilst also reaping additional blessings for supporting those who are suffering in our Ummah,
                  <br />
                  <br />
                  Multiply your blessings by performing the deeds loved by Allah. Donate an Ultimate {title}+ bundle, and rest assured that your contribution is working tirelessly to feed those who desperately need it. Seize the opportunity to multiply your blessings during the most rewarding days of the year.
                </>
            }
          </p>
        </div>
        <div className="flex flex-wrap sm:flex-nowrap mt-8 justify-center gap-x-0 sm:gap-x-8 gap-y-8">
          <div className={`basis-full md:basis-1/2 sm:bg-[url('../src/images/landings/qurban/muslim-arounds.jpg')] bg-center bg-cover rounded-md`}>
            <img className="sm:hidden" src={muslimAroundImg} alt="img3" />
          </div>
          <div className={`basis-full md:basis-1/2 flex flex-col justify-center ${lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}>
            <div className="text-[25px] md:text-[40px] text-[#253A7E] font-bold leading-[30px] md:leading-[45px]">
              {lang === 'fr'
                ? `Ayez confiance en nous pour votre Qurban`
                : lang === 'ar'
                  ? `مسلمون حول العالم (MATW) من أجل أضاحيكم`
                  : `Muslims Around The World (MATW) for YOUR ${title}`
              }
            </div>
            <p className={`md:text-lg text-[#F60362] mt-3 sm:mt-6 ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium text-left'}`}>
              {lang === 'fr'
                ? `Cette année, compte tenu des coûts croissants, notre équipe MATW s’est engagée à proposer deux options pour vous aider à remplir votre obligation en Palestine.`
                : lang === 'ar'
                  ? `نظرًا لارتفاع التكاليف، التزم فريقنا في MATW بتقديم خيارين لأداء واجبك في فلسطين هذا العام.`
                  : `Due to rising costs, our MATW team has committed to offering two options to fulfill your obligation in Palestine this year.`
              }
            </p>
            <p className={`md:text-lg text-[#78716C] pr-0 ${lang === 'ar' ? 'font-[AlmariMedium] sm:pl-12' : 'font-brandingMedium text-left sm:pr-12'}`}>
              {lang === 'fr'
                ? <>
                  La première consiste à contribuer partiellement à l’achat d’un bœuf, et la seconde à faire don d’un bœuf entier.
                  <br />
                  <br />
                  Les familles les plus vulnérables, qui comptent notamment des orphelins, des veuves et des personnes âgées, seront prioritaires pour recevoir une viande sacrificielle de la plus haute qualité, fraîchement livrée pour l’Aïd.
                  <br />
                  <br />
                  Toutes les commandes de Qurban des donateurs de MATW sont traitées et préparées pendant l’Aïd, afin de garantir leur fraîcheur : aucune minute n’est ainsi perdue au cours de ces célébrations du dixième au treizième jour de Dhou al-hijja. Le Qurban est fraîchement préparé et livré aux plus démunis, chaque panier contenant 3 à 5 kg de viande destinés à nourrir une famille de 5 à 8 personnes.
                </>
                : lang === 'ar'
                  ? <>
                    الخيار الأول هو المساهمة في حصة من بقرة، والخيار الثاني هو التبرع ببقرة كاملة.
                    <br />
                    <br />
                    سيتم توصيل قرباننا بأعلى جودة ممكنة، طازجًا في يوم العيد. يُعطى الأولوية للأسر الأكثر ضعفًا، مثل الأيتام والأرامل وكبار السن، لاستلام لحم القربان بأفضل جودة.
                    <br />
                    <br />
                    جميع طلبات القربان التي نتلقاها من المتبرعين حول العالم تُعالج وتُجهز طازجة في يوم العيد، من اليوم العاشر إلى اليوم الثالث عشر من ذو الحجة. كل حزمة قربان تحتوي على ما بين 3 و 5 كيلوغرامات من اللحم، وتكفي لإطعام عائلة تتكون من 5 إلى 8 أشخاص.
                  </>
                  : <>
                    The first is the contribution to the share of a cow and the second is the donation of an entire cow.
                    <br />
                    <br />
                    Our {title} will be delivered with the highest quality, fresh on Eid. The most vulnerable families are prioritised to receive the best quality {title} meat. This includes orphans, widows and the elderly.
                    <br />
                    <br />
                    All of Muslim Around The World’s {title} orders received from donors are processed and done fresh on Eid. From the 10th to 13th days of Dhul Hijjah, not a single moment is spared. {title} is processed and delivered fresh to vulnerable families with each {title} pack containing 3-5kg of meat and feeding a family of 5-8 people.
                  </>
              }
            </p>
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h1 className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'}`}>
          {lang === 'fr'
            ? `Comment procède-t-on ?`
            : lang === 'ar'
              ? `كيف يعمل؟`
              : `How does it work?`
          }
        </h1>
        <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-lg space-y-4 ${lang === 'ar' ? 'font-[AlmariMedium] font-medium' : 'font-brandingMedium'}`}>
          <p>
            {lang === 'fr'
              ? <>
                Notre processus est simple. À l’approche du Qurban, faites simplement un don et notre équipe MATW s’occupera du reste. Sélectionnez d’abord l’endroit où vous souhaitez envoyer votre Qurban, ainsi que les quantités que vous voulez offrir. Notre équipe achète et contrôle le bétail à l’avance pour vérifier que les animaux répondent bien aux exigences. Votre Qurban est ensuite préparé pendant l’Aïd et distribué aux familles les plus pauvres.
                <br />
                <br />
                Récoltez les fruits de vos bonnes actions au cours des premiers jours bénis de Dhou al-hijja, les 10 jours les plus sacrés de l’année ! Faites don de votre Qurban dès aujourd’hui.
              </>
              : lang === 'ar'
                ? <>
                  تعتمد عمليتنا على البساطة. عندما يحين وقت التبرع بالقربان، ببساطة قم بالتبرع وسيقوم فريقنا في MATW بالبقية. ابدأ بتحديد مكان إرسال قربانك والكمية التي ترغب في التبرع بها. يتم شراء وفحص الماشية مسبقًا بعناية من قبل فريقنا للتأكد من أن الحيوانات تلبي كافة المتطلبات. يتم معالجة قربانك خلال عيد الأضحى وتوزيعه على أسر الأشد فقرًا!
                  <br />
                  <br />
                  اجنِ الأجور في أفضل الأيام العشرة في السنة - أيام العشر الأولى المباركة من ذو الحجة! قدم قربانك اليوم واحصُل على مكافأةٍ عظيمةٍ بفضل عطائك الكريم.
                </>
                : <>
                  Our process is simple. When the time comes to give your {title} donation, simply donate and our MATW team takes care of the rest. Start by selecting where you’d like to send your {title} and the quantity you’d like to donate. The livestock is purchased and checked ahead of time with our team ensuring the animals meet the checking requirements. Your {title} is then processed during Eid and distributed to the poorest families!
                  <br />
                  <br />
                  Reap the rewards of the 10 best days of the year - the blessed first days of Dhul Hijjah! Give your {title} today.
                </>
            }
          </p>
        </div>
      </div>

      {/* /* --------------------------------- Images --------------------------------- */}
      <QurbanSlider images={images} />
      {/* /* -------------------------------------------------------------------------- */}

      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h2 className={`text-[#253B7E] sm:text-center mb-6 md:mb-8 text-[25px] sm:text-[50px] ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'text-left font-brandingBold'}`}>
          {/* {title} <span className="text-[#F60362]"> FAQ’s</span> */}
          {lang === 'fr'
            ? <><span className="text-[#F60362] mr-1">FAQ</span>sur le Qurban</>
            : lang === 'ar'
              ? <>الأسئلة الشائعة حول<span className="text-[#F60362] mr-1">الأضاحي</span></>
              : <>{title} <span className="text-[#F60362]"> FAQ’s</span></>
          }
        </h2>
        <div className="grid grid-cols-3 gap-x-16 gap-y-8">
          <div className="col-span-3 sm:col-span-1">
            <p className={`text-[#253B7E] text-[18px] md:text-2xl ${lang === 'ar' ? 'font-bold font-[AlmariMedium]' : 'font-brandingBold'}`}>{faqs[0].question.replaceAll("Qurban", title)}</p>
            <p className={`text-base ${lang === 'ar' ? 'font-medium font-[AlmariMedium]' : 'font-brandingMedium'} mt-3 text-[#78716C]`}>{faqs[0].answer.replaceAll("Qurban", title)}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className={`text-[#253B7E] text-[18px] md:text-2xl ${lang === 'ar' ? 'font-bold font-[AlmariMedium]' : 'font-brandingBold'}`}>{faqs[1].question.replaceAll("Qurban", title)}</p>
            <p className={`text-base ${lang === 'ar' ? 'font-medium font-[AlmariMedium]' : 'font-brandingMedium'} mt-3 text-[#78716C]`}>{faqs[1].answer.replaceAll("Qurban", title)}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className={`text-[#253B7E] text-[18px] md:text-2xl ${lang === 'ar' ? 'font-bold font-[AlmariMedium]' : 'font-brandingBold'}`}>{faqs[2].question.replaceAll("Qurban", title)}</p>
            <p className={`text-base ${lang === 'ar' ? 'font-medium font-[AlmariMedium]' : 'font-brandingMedium'} mt-3 text-[#78716C]`}>{faqs[2].answer.replaceAll("Qurban", title)}</p>
          </div>
        </div>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
          {qurbanFaqs.map((item, index) => {
            return (
              index > 2 && (
                <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                  <Accordion
                    key={`keyallfaq${index}`}
                    shadow
                    title={item.question}
                    index={index}
                    level={1}
                    noBorder={true}
                    className="!text-[#253B7E] text-lg font-brandingBold"
                  >
                    {item.answer}
                  </Accordion>
                </div>
              )
            );
          })}
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* -------------------------------------Impact------------------------------- */}
      <section className='w-full py-4 bg-primaryMain'>
        <div className='md:container mx-auto flex flex-col xl:flex-row items-center gap-y-4 gap-x-3 justify-between'>
          <h2 className='md:w-[330px] font-gotcha text-[35px] leading-[50px] tracking-[2.1px] text-white'>Add impact to your inbox</h2>
          <input type="text" placeholder='Full Name' className='w-[350px] xl:w-[225px] px-5 py-3 rounded bg-transparent border border-white placeholder:text-white text-sm outline-none hover:opacity-90 transition text-white' />
          <input type="text" placeholder='Enter Email' className='w-[350px] xl:w-[225px] px-5 py-3 rounded bg-transparent border border-white placeholder:text-white text-sm outline-none hover:opacity-90 transition text-white' />
          <CustomButton
            className='min-w-[140px] w-[350px] xl:w-fit !h-[50px] !rounded-[10px] hover:!bg-[#f60362] hover:opacity-90 transition'
            title={'Subscribe'} />
          <div className="w-[350px] xl:w-[284px] flex items-start text-[10px]">
            <input
              className="relative float-left mt-1 mr-1 h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-[rgba(0,0,0,0.25)] bg-white outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-[#00a3da] checked:bg-[#00a3da] checked:before:opacity-[0.16] checked:after:absolute checked:after:ml-[0.25rem] checked:after:-mt-px checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-t-0 checked:after:border-l-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:bg-white focus:after:content-[''] checked:focus:border-[#00a3da] checked:focus:bg-[#00a3da] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:ml-[0.25rem] checked:focus:after:-mt-px checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
              type="checkbox"
              onChange={(e) => 0}
              // value={value}
              // checked={checked}
              id={"subscribe-terms-conditions-checkbox"}
            />
            <label
              className={`hover:cursor-pointer text-white w-[320px] xl:w-[257px] leading-3 font-brandingMedium  flex items-start flex-col sm:flex-row`} htmlFor={"subscribe-terms-conditions-checkbox"}>
              By clicking 'Subscribe' I accept the terms and conditions of the privacy policy and consent to receive email updates and stories from MATW  in compliance with GDPR.
            </label>
          </div>
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
    </NewHelmetLayout>
  );
};
export default UltimateQurbanLandingPage;