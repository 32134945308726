import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import ReactSelect from 'react-select';
import creditImg from '../../images/payment/credit-cards.png';
import SelectCurrency from '../general/selectCurrency';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { selectProducts } from '../../store/products';
import CustomButton from '../general/button';
import ShowCurrencies from '../../utils/showCurrencies';
import { useLocation } from 'react-router-dom';
import { openSheet } from '../../store/sheetToggler';
import useCart from '../../hooks/cart/useCart';

const styles = {
  menuList: base => ({
    ...base,

    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
};

const WidgetHome = props => {
  const location = useLocation();
  const isFoodWaterPage = location.pathname === '/food-and-water-aid';

  /* ----------------------------- Start variables ---------------------------- */
  const {
    selectedCurrency,
    className,
    defaultValue = 150,
    defaultProduct: {
      // creator = 'DD-EMR-PAL-24-11-01', label = "Loading...", value = 741
      creator = 'HDD-ZK-PAL-25-01',
      label = 'Loading...',
      value = 885,
    } = {},
    amountInputClassNames,
    selectorClassNames,
    reqFromOrphanPage = false,
    reqFromUSAPage = false,
    reqFromUKPage = false,
    addToCart = true,
  } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [productsList, setProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [providedProducts, setProvidedProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const products = useSelector(state => selectProducts(state));
  const [addProduct] = useHandleAddProduct();
  const [currentCurrency, setCurrentCurrency] = useState();
  const [selectedProduct, setSelectedProduct] = useState({
    creator: creator,
    label: label,
    value: value,
  });
  // const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedPrice, setSelectedPrice] = useState(props.selectedPrice || 200);
  const [productAmount, setProductAmount] = useState(selectedPrice ? null : defaultValue);
  const [currentProduct, setCurrentProduct] = useState({
    creator: creator,
    label: label,
    value: value,
  });
  // console.log('props=', props, creator, label, value);
  const suggestedPrices = props.suggestedPrices || [100, 150, 200, 500];
  const isUKDomain = window.location.hostname === 'matwproject.org.uk';
  /* -------------------------------------------------------------------------- */
  const dispatch = useDispatch();
  const { handleAddToGiving: handleAddToGivingCart } = useCart();
  // const handleAmountChange = e => {
  //   const newValue = e.target.value;
  //   const regex = /^(?:[1-9]\d*)?$/;

  //   if (regex.test(newValue)) {
  //     setProductAmount(newValue);
  //   }
  // };

  const providedProductsIds = [
    'DD-FA-GEN-24-01', // Food Aid support
    'DD-FA-GEN-24-02', // Water Support
    'DD-EMR-PAL-24-11-02', // Save Lives in Gaza- Food
    'DD-EMR-PAL-24-11-03', // Save Lives in Gaza- Water
    'DD-ORP-GEN-24-04', // Support an orphan with food
    'DD-ORP-GEN-24-05', // Support an orphan with water
  ];

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempMakeDonationProducts = [];
    let tempProductsList = [];
    let tempImportantProducts = [];

    let providedProductsHolder = [];
    let OrphanProductList = [];
    let usaTempProducts = [];
    let tempDefaultProduct = {};
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // const relevantProductIds = isFoodWaterPage ? providedProducts : productsList;
          //
          // if (relevantProductIds.includes(inner_item_product.creator)) {
          //   providedProductsHolder.push({
          //     value: inner_item_product.id,
          //     label: inner_item_product.name,
          //     creator: inner_item_product.creator
          //   });
          // }
          if (inner_item_product.creator === creator) tempDefaultProduct = inner_item_product;
          // For specific products
          if (providedProductsIds.includes(inner_item_product.creator)) {
            providedProductsHolder.push({
              value: inner_item_product.id,
              label: inner_item_product.name,
              creator: inner_item_product.creator,
            });
          }

          if (reqFromUSAPage) {
            if (inner_item_product.creator === 'RM24-ZM-013') {
              tempMakeDonationProducts.push(inner_item_product);
              usaTempProducts[0] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
            }
          }

          if (inner_item.name === 'Earthquake Appeal') {
            if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') {
              // Morocco Appeal
              tempMakeDonationProducts.push(inner_item_product);
              tempImportantProducts[3] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
            }
          }
          if (inner_item.name === 'Libya Floods') {
            if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') {
              // Libya Floods
              tempMakeDonationProducts.push(inner_item_product);
              tempImportantProducts[4] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
            }
          }
          if (inner_item.name === 'Support An Orphan') {
            const creatorPrefix = 'DD-ORP-GEN-24-';
            const creatorNumber = parseInt(inner_item_product.creator.replace(creatorPrefix, ''), 10);

            if (!isNaN(creatorNumber) && creatorNumber >= 4 && creatorNumber <= 12) {
              const index = creatorNumber - 4;
              OrphanProductList[index] = {
                value: inner_item_product.id,
                label: inner_item_product.name,
                creator: inner_item_product.creator,
              };
            }
          }

          if (inner_item_product.creator === 'HDD-2025-001') {
            isUKDomain
              ? (tempImportantProducts[2] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
              : (tempImportantProducts[2] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            tempMakeDonationProducts.push(inner_item_product);
          }
          
          if (inner_item_product.creator === 'HDD-EMR-PAL-NBB-25-01') {
            tempImportantProducts[1] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
          }
          if (inner_item_product.creator === 'HDD-ZK-PAL-25-01') {
            tempImportantProducts[0] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
          }
          if (inner_item_product.creator === 'HDD-2025-003') {
            isUKDomain
              ? (tempImportantProducts[0] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
              : (tempImportantProducts[3] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          }
          if (inner_item_product.creator === 'HDD-ZK-PAL-25-02') {
            tempImportantProducts[4] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
          }
          if (inner_item_product.creator === 'HDD-2025-002') {
            tempImportantProducts[5] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
          }

          if (inner_item_product.creator === 'HDD-2025-EMR-PAL-1') {
            tempImportantProducts[6] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
          }

          if (inner_item_product.creator === 'HDD-2025-006') {
            tempImportantProducts[7] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
          }

          if (inner_item_product.creator === 'HDD-2025-008') {
            tempMakeDonationProducts.push(inner_item_product);
            tempImportantProducts[8] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
          }

          if (inner_item_product.creator === 'HDD-2025-009') {
            tempMakeDonationProducts.push(inner_item_product);
            tempImportantProducts[9] = {
              value: inner_item_product.id,
              label: inner_item_product.name,
              creator: inner_item_product.creator,
            };
          }

          if (inner_item_product.creator === 'HDD-2025-005') {
            // Palestine Appeal
            // setSelectedProduct(inner_item_product);
            // setCurrentProduct({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
            tempImportantProducts[10] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
            tempMakeDonationProducts.push(inner_item_product);
          }

          if (inner_item_product.creator === 'HDD-2025-011') {
            tempMakeDonationProducts.push(inner_item_product);
            tempImportantProducts[11] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
          }
          if (inner_item_product.creator === 'HDD-2025-007') {
            // Palestine Medical Relief
            tempMakeDonationProducts.push(inner_item_product);
            tempImportantProducts[12] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
          }

          if (inner_item_product.creator === 'HDD-2025-010') {
            tempMakeDonationProducts.push(inner_item_product);
            tempImportantProducts[13] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
          }

          // if (inner_item.name === 'Make A Donation') {
          //   // if (inner_item_product.creator === 'MKD-MN-001') {
          //   //   // Where Most Needed
          //   //   setSelectedProduct(inner_item_product);
          //   //   setCurrentProduct({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          //   // }
          //   if (inner_item_product.creator !== 'MKD-EM-TUR-EQ-01-2023') {
          //     tempMakeDonationProducts.push(inner_item_product);
          //     tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          //   }
          // }
          //
          // if (inner_item_product.creator === defaultValue.creator) {
          //   setSelectedProduct(inner_item_product);
          //   setCurrentProduct({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          // }
          //
          // console.log("************************")
          // console.log(inner_item_product.name)
          // console.log("************************")
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempImportantProducts = tempImportantProducts.concat(usaTempProducts);
    tempImportantProducts = tempImportantProducts.concat(tempProductsList);

    if (reqFromUSAPage) {
      const defaultProductIndex = tempImportantProducts.findIndex(product => product.creator === 'RM24-ZM-013');

      if (defaultProductIndex !== -1) {
        const [defaultProductCreator] = tempImportantProducts.splice(defaultProductIndex, 1);
        tempImportantProducts.splice(1, 0, defaultProductCreator);
      }
    }
    tempDefaultProduct &&
      tempImportantProducts.unshift({ value: tempDefaultProduct.id, label: tempDefaultProduct.name, creator: tempDefaultProduct.creator });
    tempImportantProducts = tempImportantProducts.filter(n => n);
    // ✅ **Merge and Remove Duplicates Based on `creator`**
    // const mergedProducts = [...tempImportantProducts, ...tempProductsList].filter(Boolean); // Remove empty items
    const uniqueProducts = Array.from(new Map(tempImportantProducts.map(product => [product.creator, product])).values());

    const prodList = reqFromOrphanPage ? OrphanProductList : uniqueProducts;
    // const prodList = reqFromOrphanPage ? OrphanProductList : tempImportantProducts
    setProductsList(prodList);
    setAllProducts(tempMakeDonationProducts);
    setProvidedProducts(providedProductsHolder);

    let tempPrd = {};
    if (isFoodWaterPage) {
      tempPrd = { value: providedProductsHolder[0].id, label: providedProductsHolder[0].name, creator: providedProductsHolder[0].creator };
    } else if (tempDefaultProduct) {
      tempPrd = { value: tempDefaultProduct.id, label: tempDefaultProduct.name, creator: tempDefaultProduct.creator };
    } else {
      tempPrd = { value: prodList[0].id, label: prodList[0].name, creator: prodList[0].creator };
    }
    setSelectedProduct(isFoodWaterPage ? providedProductsHolder[0] : tempDefaultProduct ? tempDefaultProduct : prodList[0]);
    // setCurrentProduct(isFoodWaterPage ? providedProductsHolder[0] : tempDefaultProduct ? tempDefaultProduct : prodList[0])
    setCurrentProduct(tempPrd);
  };
  const handleCurrency = currency => {
    setCurrentCurrency(currency.value);
    selectedCurrency(currency);
  };

  const handleProduct = value => {
    setSelectedProduct(
      [].concat
        .apply(
          [],
          [].concat.apply(
            [],
            products.map(p => p.sub_categories.map(sc => sc.products)),
          ),
        )
        .find(x => x.creator === value.creator),
    );
    setCurrentProduct(value);
  };

  const handleAddNewProduct = () => {
    let tempProduct = [].concat
      .apply(
        [],
        [].concat.apply(
          [],
          products.map(p => p.sub_categories.map(sc => sc.products)),
        ),
      )
      .find(x => x.creator === selectedProduct.creator);
    let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice) ? Number(selectedPrice) : 100;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
      //   recurring: selectedRecurring.value,
    });

    if (addToCart) {
      handleAddToGivingCart(tempProduct, 'custom');
      dispatch(openSheet());
    } else {
      addProduct({ product: tempProduct, currency: currentCurrency });
    }
  };
  // const handleScroll = () => {
  //     const position = window.pageYOffset;
  //     setScrollPosition(position);
  // };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // getProducts();
    // window.addEventListener('scroll', handleScroll, { passive: true });
    // return () => {
    //     window.removeEventListener('scroll', handleScroll);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    products.length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    // <div className={`w-full bg-[#00a3da] px-3 md:px-6 py-3 md:py-6 flex items-center ${className} ${scrollPosition > 300 ? 'fixed top-0 md:top-[64px] lg:top-[96px] w-full z-30' : 'relative'}`}>
    <div className={`w-full bg-[#00a3da] px-3 md:px-6 py-3 md:py-6 flex items-center ${className} md:top-[64px] lg:top-[96px] w-full z-30`}>
      <div className="w-full md:container md:mx-auto">
        <div className="grid grid-cols-12 lg:grid-cols-[1.5fr_1fr_1.5fr_1fr_1fr] gap-2 lg:gap-2 md:contaier mx-auto lg:px-2">
          <div
            className={`col-span-12 md:col-span-5 lg:col-span-1 order-3 md:order-1 flex items-center bg-white rounded-md h-[38px] ${
              reqFromUSAPage ? 'usa-select' : ''
            }`}
          >
            <div className="min-w-max">
              <SelectCurrency onChangeCurrency={e => handleCurrency(e)} classNamePrefix="home_select" />
            </div>
            <input
              value={productAmount}
              name="amount"
              className={`h-[38px] text-[#777] w-full truncate p-2 ${
                !reqFromUSAPage && 'rounded-lg'
              } text-sm pl-1 md:text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent`}
              type="number"
              min={'0'}
              onChange={e => {
                const regex = /^(?:[1-9]\d*)?$/;
                if (regex.test(e.target.value)) {
                  setProductAmount(e.target.value);
                  setSelectedPrice(null);
                }
              }}
              placeholder="Amount"
            />
          </div>
          <div className="col-span-12 lg:col-span-1 order-2 md:order-3 lg:order-2 grid grid-cols-12 lg:flex gap-2">
            {suggestedPrices.map((price, index) => {
              return (
                <div key={`prices${index}`} className="col-span-3">
                  <div
                    className={`h-[38px] text-sm transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[60px] ${
                      !reqFromUSAPage && 'rounded-md'
                    } ${selectedPrice === price ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                    onClick={() => {
                      setSelectedPrice(price);
                      setProductAmount('');
                    }}
                  >
                    {ShowCurrencies(currentCurrency, price, false)}
                    <span className={`uppercase ml-1 text-xs`}>{currentCurrency}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className={`col-span-12 md:col-span-7 lg:!col-span-1 order-1 md:order-2 lg:order-3 ${selectorClassNames ? selectorClassNames : ''} ${
              reqFromUSAPage ? 'usa-select' : ''
            }`}
          >
            <ReactSelect
              options={isFoodWaterPage ? providedProducts : productsList}
              className="max-h-[48px] [&>div]:!rounded-md"
              classNamePrefix="home-select"
              value={currentProduct}
              isSearchable={false}
              styles={styles}
              onChange={e => handleProduct(e)}
            />
          </div>
          <div
            className={`col-span-6 lg:col-span-1 order-4 ${
              !reqFromUSAPage && 'rounded-md'
            } px-3 h-[38px] bg-[#80ceff] flex items-center justify-center`}
          >
            <img
              src={creditImg}
              alt="MATW"
              className="w-full h-auto w-[260px]- h-[20px]- sm:w-[254px]- sm:h-[28px]- md:w-[221px]- md:h-[25px]- lg:w-[125px]- lg:h-[14px]- xl:w-full- xl:h-auto-"
            />
          </div>
          <div className="order-5 col-span-6 lg:col-span-1">
            <CustomButton
              onClick={() => {
                handleAddNewProduct();
              }}
              title={'Quick Donate'}
              disabled={!isLoaded}
              className={`w-full hover:!bg-[#F60362] !h-[38px] !px-2 ${isLoaded ? 'blob red' : ''} ${reqFromUSAPage && '!rounded-none'}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetHome;
