import React from "react";

export const  IftarTimeContent = (props) => {

    const {city} = props

    return (
        <div className="mb-4 p-3 md:p-4 bg-blue-50 rounded-lg mx-2 md:mx-auto max-w-[800px] m-2">
            <div className="text-left">
                <h2 className="text-2xl font-bold text-gray-800 mb-6"> Iftar Time in {city} | Daily Iftar Time Today </h2>

                <p className="text-gray-600 text-base md:text-lg leading-relaxed">
                    Are you looking for the accurate <span
                    className="font-bold">Iftar time in {city}?
                </span> Breaking your fast at the correct time is an essential part of Ramadan and daily fasting. Our comprehensive Iftar timetable ensures you stay updated on Iftar timings in {city}, so you can open your fast with confidence and ease.
                </p>

                <br/>
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                    Why Check Iftar Times in {city}?
                </h2>
                <ul className="space-y-2 list-none">
                    <li className="text-gray-800">
                        <span className="font-bold">✅ Accurate & Reliable: </span> We use verified sources to provide the most accurate Iftar time, so you never break your fast too early or too late.
                    </li>
                    <li className="text-gray-800">
                        <span className="font-bold">✅ Convenient & Accessible: </span>Whether you’re at home, work, or traveling in Nottingham, our easy-to-read schedule helps you plan your Iftar smoothly.
                    </li>
                    <li className="text-gray-800">
                        <span className="font-bold">✅ Local Adjustments: </span> Our listings factor in <span className="font-semibold">daylight variations</span>,
                        ensuring the correct Iftar time every day.
                    </li>
                </ul>


                <h3 className="text-xl font-bold text-gray-800 mt-8 mb-6">
                    How to Use Our Iftar Timetable
                </h3>

                <ul className="space-y-2 list-none">
                    <li className="text-gray-800">
                        <span className="font-bold">🔸 Check Daily Iftar Time:</span> Find the exact time to break your fast today.
                    </li>
                    <li className="text-gray-800">
                        <span className="font-bold">🔸 Plan Your Iftar Meal:</span> Prepare your food in advance so you can focus on Dua and gratitude.
                    </li>
                    <li className="text-gray-800">
                        <span className="font-bold">🔸 Share with Others:</span> Help friends and family stay updated on Iftar time in {city}.
                    </li>
                </ul>
                <br/>
                Stay connected to your fasting schedule and never miss the moment of Iftar with our trusted {city} Iftar timetable!
            </div>
        </div>
    );
}