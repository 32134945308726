/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { orphansFaqs } from './faqs';
import Accordion from '../../components/general/accordion';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectProducts } from '../../store/products';
import { OurPromiseSection } from '../../components/general';
import VideosSection from '../../components/general/videosSection';
import { videos } from '../crisis-and-emergencies/fixtures';
import { helpUseReachMoreSection, worldProgramSection } from './orphans-content';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import WidgetMain from '../../components/widgets/widgetMain';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
import ProductBox from '../../components/general/productBox';
import { ProductBoxCart } from '../home/components/productBoxCart';
import useCart from '../../hooks/cart/useCart';
import { ButtonDonateRounded } from '../home/components/buttonDonateRounded';
import scrollToTop from '../../hooks/ui/useScrollToTop';
import sortProductsByCurrency from '../../utils/productSortHandler';
import useElementRemoval from '../../hooks/ui/useElementRemoval';

const suggestedPrices = [50, 150, 250, 500, 1000, 2000];

const defaultListProduct = {
  name: 'Orphan Feast',
  creator: 'MD-AA-2024-06',
}

const OrphansUat = ({ lang = 'en' }) => {
  const bannerMobilFr = 'https://cdn.matwproject.org/images/banners/Orphans-Generic-Hero-Banner-Mobile-FR.webp';
  const bannerMobileAr = 'https://cdn.matwproject.org/images/banners/Orphans-Generic-Hero-Banner-Mobile-AR.webp';

  const bannerDesktopEng = 'https://cdn.builder.io/api/v1/image/assets%2F32b8c354d4bc455dbf10fed3923dfae1%2F2692abafe48940d087f3611a84713edc';
  const bannerDesktopFr = 'https://cdn.builder.io/api/v1/image/assets%2F32b8c354d4bc455dbf10fed3923dfae1%2Fbd22f9b66d664eb0806633d00bdb3fe0?width=500&height=500';

  const products = useSelector(state => selectProducts(state));
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const faqsRef = useRef(null);
  const moreInfoRef = useRef(null);
  const appealsRef = useRef(null);
  const [activeSection, setActiveSection] = useState('');
  const [showScrollMore, setShowScrollMore] = useState(false);
  const startRef = useRef(null);
  const endRef = useRef(null);

  const [orphansFeastProducts, setOrphansFeastProducts] = useState([0, 0, 0, 0])
  const [orphansSupportProducts, setOrphansSupportProducts] = useState([0, 0, 0, 0])
  const [ramadanProducts, setRamadanProducts] = useState([0, 0, 0])

  const {
    handleAddToGiving,
    handleRemoveProduct,
    handleCheckProduct,
  } = useCart()

  useElementRemoval("#zsiq_float");

  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState(selectedCurrencyStore);

  const scrollToRef = ref => {
    if (ref.current) {
      // Calculate offset position above the element
      const offsetPosition = ref.current.offsetTop - 150; // Adjust 100 pixels above the element
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setActiveSection(entry.target.id);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.3,
    });

    if (faqsRef.current) observer.observe(faqsRef.current);
    if (moreInfoRef.current) observer.observe(moreInfoRef.current);
    if (appealsRef.current) observer.observe(appealsRef.current);

    return () => {
      if (faqsRef.current) observer.unobserve(faqsRef.current);
      if (moreInfoRef.current) observer.unobserve(moreInfoRef.current);
      if (appealsRef.current) observer.unobserve(appealsRef.current);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (startRef.current && endRef.current) {
        const startPosition = startRef.current.getBoundingClientRect().top;
        const endPosition = endRef.current.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;

        if (startPosition < windowHeight && endPosition > 870) {
          setShowScrollMore(true);
        } else {
          setShowScrollMore(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getAllProducts = () => {
    setIsLoaded(true);
    let orphanFeastProductsList = [];
    let supportAnOrphanProductsList = [];
    let tempRamadanProducts = [];
    products.forEach(item => {
      item.sub_categories.forEach(inner_item => {
        inner_item.products.forEach(inner_item_product => {
          if (inner_item_product.creator === 'FST-DEL-F150-23-01-010') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Festin pour 150 orphelins` : lang === 'ar' ? `وليمة لـ 150 يتيم` : 'Feast for 150 Orphans',
              description:
                lang === 'fr'
                  ? `Faites une différence dans la vie des orphelins en parrainant (Wallima) un festin.`
                  : lang === 'ar'
                    ? `أحدث فرقا في حياة الأيتام من خلال رعاية وليمة غذائية `
                    : inner_item_product.description,
            });
            orphanFeastProductsList[0] = inner_item_product;
          } else if (inner_item_product.creator === 'FST-DEL-F50-2023-01-009') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Festin pour 50 orphelins` : lang === 'ar' ? `وليمة لـ 50 يتيم` : 'Feast for 50 Orphan',
              description:
                lang === 'fr'
                  ? `Faites une différence dans la vie des orphelins en parrainant (Wallima) un festin.`
                  : lang === 'ar'
                    ? `أحدث فرقا في حياة الأيتام من خلال رعاية وليمة غذائية `
                    : inner_item_product.description,
            });
            orphanFeastProductsList[1] = inner_item_product;
          } else if (inner_item_product.creator === 'FST-DEL-F25-23-01-008') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Festin pour 25 orphelins` : lang === 'ar' ? `وليمة لـ 25 يتيم` : 'Feast for 25 Orphan',
              description:
                lang === 'fr'
                  ? `Faites une différence dans la vie des orphelins en parrainant (Wallima) un festin.`
                  : lang === 'ar'
                    ? `أحدث فرقا في حياة الأيتام من خلال رعاية وليمة غذائية `
                    : inner_item_product.description,
            });
            orphanFeastProductsList[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'ORP-GEN-24-01') {
            supportAnOrphanProductsList[0] = {
              ...inner_item_product,
            };
          }
          if (inner_item_product.creator === 'ORP-GEN-24-02') {
            supportAnOrphanProductsList[1] = {
              ...inner_item_product,
            };
          }
          if (inner_item_product.creator === 'ORP-GEN-24-03') {
            supportAnOrphanProductsList[2] = {
              ...inner_item_product,
            };
          }
          // Ramadan products
          if (inner_item_product.creator === 'RM25-00-008') tempRamadanProducts.push(inner_item_product)
          if (inner_item_product.creator === 'RM25-00-009') tempRamadanProducts.push(inner_item_product)
          if (inner_item_product.creator === 'RM25-00-010') tempRamadanProducts.push(inner_item_product)
        });
      });
    });

    orphanFeastProductsList = orphanFeastProductsList.filter(n => n);
    supportAnOrphanProductsList = supportAnOrphanProductsList.filter(n => n);
    tempRamadanProducts = tempRamadanProducts.filter(n => n);

    const sortedOrphanFeastProducts = sortProductsByCurrency(orphanFeastProductsList, selectedCurrencyStore.toLowerCase());
    const sortedOrphanSupportProducts = sortProductsByCurrency(supportAnOrphanProductsList, selectedCurrencyStore.toLowerCase());
    const sortedRamadanProducts = sortProductsByCurrency(tempRamadanProducts, selectedCurrencyStore.toLowerCase());

    setOrphansFeastProducts(sortedOrphanFeastProducts)
    setOrphansSupportProducts(sortedOrphanSupportProducts)
    setRamadanProducts(sortedRamadanProducts)
  };

  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [products]);

  const handleProductsUI = (isInWidget = false) => (
    <div className={`md:container w-full mx-auto px-4 md:px-0 ${isInWidget && "mb-6"} md:py-12`}>
            <div className="flex justify-center items-center my-4 md:my-7 mx-auto">
        <p className={`bg-[#14a2dc] text-[#fff] text-[14px] text-center rounded-none p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase`}>
          Ramadan
        </p>
      </div>
      <div className="flex flex-wrap gap-3 md:gap-4 justify-center items-start w-full">
        {products &&
          ramadanProducts.map((item, index) => {
            return (
              <>
                <div key={`pageProducts${index}`} className='w-full md:max-w-[300px] md:basis-[calc(50%-8px)] hidden md:block'>
                  <ProductBox
                    product={item}
                    currency={selectedCurrency ? selectedCurrency : 'AUD'}
                    titleClasses={`px-[10px] !text-lg !h-auto !mt-3 min-h-[56px]`}
                    childClasses="!rounded-none !p-0 !pb-3 md:!pb-5 md:!p-0"
                    imageContainerClass="!rounded-none"
                    imageClass="!rounded-none"
                    descriptionStyle="px-2 md:px-3"
                    skeletonStyle="px-2"
                    squareButton
                  />
                </div>
                <div className="w-full md:hidden">
                  <ProductBoxCart
                    key={index}
                    product={item}
                    onCheck={handleCheckProduct}
                    onAdd={handleAddToGiving}
                    onRemove={handleRemoveProduct}
                    currency={selectedCurrency ? selectedCurrency : 'AUD'}
                  />
                </div>
              </>
            );
          })}
      </div>
      <div className="flex justify-center items-center my-4 md:my-7 mx-auto">
        <p className={`bg-[#14a2dc] text-[#fff] text-[14px] text-center rounded-none p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase`}>
          Orphan Feasts
        </p>
      </div>
      <div className="flex flex-wrap gap-3 md:gap-4 justify-center items-start w-full">
        {products &&
          orphansFeastProducts.map((item, index) => {
            return (
              <>
                <div key={`pageProducts${index}`} className='w-full md:max-w-[300px] md:basis-[calc(50%-8px)] hidden md:block'>
                  <ProductBox
                    product={item}
                    currency={selectedCurrency ? selectedCurrency : 'AUD'}
                  />
                </div>
                <div className="w-full md:hidden">
                  <ProductBoxCart
                    key={index}
                    product={item}
                    onCheck={handleCheckProduct}
                    onAdd={handleAddToGiving}
                    onRemove={handleRemoveProduct}
                    currency={selectedCurrency ? selectedCurrency : 'AUD'}
                  />
                </div>
              </>
            );
          })}
      </div>
      <div className="flex justify-center items-center my-4 md:my-7 mx-auto">
        <p className={`bg-[#14a2dc] text-[#fff] text-[14px] text-center rounded-none p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase`}>
          Orphan Support
        </p>
      </div>
      <div className="flex flex-wrap gap-3 md:gap-4 justify-center items-start w-full">
        {products &&
          orphansSupportProducts.map((item, index) => {
            return (
              <>
                <div key={`pageProducts${index}`} className='w-full md:max-w-[300px] md:basis-[calc(50%-8px)] hidden md:block'>
                  <ProductBox
                    product={item}
                    currency={selectedCurrency ? selectedCurrency : 'AUD'}
                    titleClasses={`px-[10px] !text-lg !h-auto !mt-3 min-h-[56px]`}
                    childClasses="!rounded-none !p-0 !pb-3 md:!pb-5 md:!p-0"
                    imageContainerClass="!rounded-none"
                    imageClass="!rounded-none"
                    descriptionStyle="px-2 md:px-3"
                    skeletonStyle="px-2"
                    squareButton
                  />
                </div>
                <div className="w-full md:hidden">
                  <ProductBoxCart
                    key={index}
                    product={item}
                    onCheck={handleCheckProduct}
                    onAdd={handleAddToGiving}
                    onRemove={handleRemoveProduct}
                    currency={selectedCurrency ? selectedCurrency : 'AUD'}
                  />
                </div>
              </>
            );
          })}
      </div>

    </div>
  )

  return (
    <NewHelmetLayout
      hideDonateNowButton={true}
      highlights={false}
      className="bg-white"
      isRamadanPage={true}
      isRelativeMobile
    >
      {seo && (
        <Helmet>
          <title>{`${seo['orphans']['orphans']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`} />
          <meta name="description" content={`${seo['orphans']['orphans']['description']}`} />

          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
      )}
      <img
        src={lang === 'fr' ? bannerMobilFr : lang === 'ar' ? bannerMobileAr : 'https://cdn.builder.io/api/v1/image/assets%2F32b8c354d4bc455dbf10fed3923dfae1%2F2692abafe48940d087f3611a84713edc'}
        className="w-full lg:hidden"
      />

      <div className="lg:hidden">
        <WidgetMain
          selectedCurrency={e => setSelectedCurrency(e.value)}
          className="!w-full !py-[18px]"
          suggestedPrices={suggestedPrices}
          isTimer={false}
          defaultProduct={{ creator: 'MD-AA-2024-06', label: 'Orphans Around The World Fund', value: 660 }}
          defaultListProduct={defaultListProduct}
          isOrphanPage={true}
        />
      </div>
      <div className="md:hidden">
        {handleProductsUI(true)}
      </div>
      <MaxWidthContainer className="my-6 !max-w-[1520px]">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className={`hidden lg:block flex-1 lg:flex-[0_0_450px] bg-[#eaf7ff]`}>
            <WidgetMain
              selectedCurrency={e => setSelectedCurrency(e.value)}
              className="!w-full !py-[18px]"
              suggestedPrices={suggestedPrices}
              isTimer={false}
              defaultProduct={{ creator: 'MD-AA-2024-06', label: 'Orphans Around The World Fund', value: 660 }}
              defaultListProduct={defaultListProduct}
              isOrphanPage={true}
            />
          </div>
          <div className="flex-1 bg-[#eaf7ff]">
            <img
              src={lang === 'fr' ? bannerDesktopFr : bannerDesktopEng}
              className="w-full hidden lg:block"
            />
            <div className="flex flex-col gap-2 p-4">
              <p className="hidden sm:block text-3xl home-main-title  font-inter font-extrabold">
                MATW’s orphans around the world
                <span className="text-[#F60362]"> program.</span>
              </p>
              <p className="sm:hidden text-2xl font-inter font-extrabold">
                MATW’s orphans around the world
                <span className="text-[#F60362]"> program.</span>
              </p>
              <p className="lg:text-lg font-inter text-[#333333] leading-[26px]">
                At MATW, we believe that every child deserves access to life’s most basic necessities and a positive start in life. From our humble beginnings with
                Ali Banat to where we are today, MATW’s Orphan projects have always been a top priority in our plan to alleviate suffering around the world.
              </p>
            </div>
          </div>
        </div>
      </MaxWidthContainer>
      <div id="appeals" ref={appealsRef}>
        {/* <section className="bg-[#253B7E]"> */}
        <div className="hidden md:block">
          {handleProductsUI()}
        </div>
        {/* </section> */}
      </div>
      <MaxWidthContainer className="mb-4 my-8 md:my-12">
        <div className="flex flex-col gap-5 md:gap-8 max-w-[1000px] mx-auto">
          <p className="text-4xl lg:text-6xl leading-[1.6] text-[#00a3da] text-center font-gotcha font-bold mb-1 md:mb-4 lg:mb-8">
            Every Day, 5,700 Children Become Orphans.
          </p>
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-inter">
            Pause for a moment and imagine this: a child wakes up tomorrow without a parent. They have no one to turn to. No place to go. They are left to fend for
            themselves in a world that seems to have turned its back on them.
          </p>
          <div className="flex flex-col gap-3">
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-inter">
              Can you imagine that being your <span className="text-[#00a3da] font-medium">child?</span>
            </p>
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-inter">
              Now multiply that heartbreak by <span className="text-[#00a3da] font-medium">5,700.</span> That’s the staggering number of children who lose everything every single day. And for those in conflict zones or
              hit by disasters, the situation is even worse. These children face unimaginable struggles—no safe place to sleep, no food to eat, and no one to care for
              them when they’re sick or scared.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-inter">
              But there’s hope. There’s <span className="text-[#00a3da] font-medium">YOU.</span>
            </p>
            <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-inter">
              <span className="text-[#00a3da] font-medium">At MATW,</span> we’ve already cared for more than <span className="text-[#00a3da] font-medium">50,000</span> orphans worldwide. That’s <span className="text-[#00a3da] font-medium">50,000 </span>
              children who now have a safe environment, warm meals, access to healthcare,
              and an education that gives them hope and a future. But there are millions more waiting.
            </p>
          </div>
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-inter">
            You have the power to make a difference. Imagine the smile on a child’s face when they receive their first <span className="text-[#00a3da] font-medium">hot meal in weeks.</span> Imagine the peace in their
            hearts knowing they have a safe place to sleep. Imagine the future they can build with the education and care you make possible.
          </p>
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-inter">
            This isn’t just <span className="text-[#00a3da] font-medium">charity—it’s life-changing.</span> It’s giving children back their childhood, their dreams, and their future.
          </p>
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-inter">
            But we can’t do it without <span className="text-[#00a3da] font-medium">YOU.</span>
          </p>
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-inter">
            <span className="text-[#00a3da] font-medium">Right now,</span> children are waiting. Right now, you can <span className="text-[#00a3da] font-medium">act.</span>
          </p>
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-inter">
            Don’t let another day pass by while they struggle alone. <span className="text-[#00a3da] font-medium">Click below</span> to save a life.
          </p>
          <p className="text-lg md:text-2xl leading-[1.6] text-[#707886] text-center font-inter">
            Don’t let another day pass by while they struggle alone. <span className="text-[#00a3da] font-medium">Click below</span> to save a life.
          </p>
        </div>
        <div className="flex justify-center items-center mt-8 md:mt-12">
          <ButtonDonateRounded title="donate now" onClick={scrollToTop} />
        </div>
      </MaxWidthContainer>
      {/* ----------------- videos section ---------------- */}
      <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />
      {/* ----------------- videos section --END-------------- */}
      <OurPromiseSection lang={lang} />
      <main className="max-w-[1440px] mx-auto">
        <section className="xl:px-10 mt-5 sm:mt-12" id="more-info" ref={moreInfoRef}>
          <div className="flex justify-center">
            <div className="text-left font-brandingBold sm:text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-6xl px-4 w-full sm:w-10/12">
              {helpUseReachMoreSection.title.main[lang]}
              <br />
              <span className="text-[#F60362] mr-2">{helpUseReachMoreSection.title.highlighted[lang]}</span>
            </div>
          </div>
          <div className="px-4 md:px-6 mt-2 sm:mt-10 leading-5">
            <div className="text-[#78716C] lg:px-20 leading-6 text-[18px] text-left  font-brandingMedium">
              {helpUseReachMoreSection.description.section1[lang]}
              <br />
              <br />
              {helpUseReachMoreSection.description.section2[lang]}
              <br />
              <br />
              {helpUseReachMoreSection.description.section3[lang]}
            </div>
          </div>
        </section>
        {/* scroll more start */}
        <section className="xl:px-20 mt-8" id="scroll-more-start" ref={startRef}>
          <div className="relative overflow-hidden flex flex-wrap">
            <div
              className="py-3 order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[170px]
                          md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                          right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute"
            >
              <div className="w-full text-[24px] sm:text-[50px] md:text-[30px] lg:text-[28px] px-8 text-white text-center font-brandingBold">
                The Prophet PBUH said:
              </div>
              <p className="text-[18px] sm:text-[18px] font-brandingMedium leading-6 mt-3 px-10 text-white text-center">
                “Whoever takes in an orphan among the Muslims to raise, to feed him and give him drink, Allah admits him into Paradise without a
                doubt, unless he has done a sin for which he is not forgiven.”
              </p>
            </div>
            <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
            <div className="basis-full max-[380px]:min-h-[209px] min-[381px]:min-h-[300px] min-[410px]:min-h-[300px] sm:h-[300px] md:h-[600px] lg:h-[1000px]">
              <iframe
                className="video pointer-events-none"
                width={'100%'}
                height={'100%'}
                title={'youtube'}
                frameBorder={0}
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://www.youtube.com/embed/ciUzaL90cxA?controls=0&autoplay=1&loop=1&mute=1&playlist=ciUzaL90cxA`}
              ></iframe>
            </div>
          </div>
        </section>

        <section className="xl:px-20 sm:py-8">
          <div>
            <div className="flex justify-center pt-4">
              <div className="text-left font-brandingBold sm:text-center font-bold text-[#253B7E] text-2xl sm:text-6xl px-4 w-full sm:w-8/12">
                {worldProgramSection.title[lang]}
              </div>
            </div>
            <div className="px-4 md:px-28 text-[18px] mt-8 leading-5">
              <p className="text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px]">
                {worldProgramSection.description.section1[lang]}
                <br />
                <br />
                {worldProgramSection.description.section2[lang]}
                <br />
                <br />
                {worldProgramSection.description.section3[lang]}
              </p>
              <div>
                <h2 className="text-[#253B7E] my-5 text-2xl sm:text-[45px] sm:leading-[54px] font-bold">
                  {worldProgramSection.list.title.top[lang]}
                  <br />
                  {worldProgramSection.list.title.bottom[lang]}
                </h2>
                <ul className="text-[#78716C] list-disc pl-3 leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px]">
                  {worldProgramSection.list.listItems.map(item => (
                    <li>{item[lang]}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="px-4 xl:px-20 bg-white" id="faqs" ref={faqsRef}>
          <h1 className="text-[#253B7E] text-center my-4 text-[30px] sm:text-[60px] font-bold">
            {lang === 'fr' ? (
              <>
                <span className="text-[#F60362]">FAQ </span>
                sur les dons de la MATW
              </>
            ) : lang === 'ar' ? (
              ''
            ) : (
              <>
                MATW donations
                <span className="text-[#F60362]"> FAQ’s</span>
              </>
            )}
          </h1>
          <div className="grid grid-cols-3 gap-x-16 gap-y-8">
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{orphansFaqs[0].question[lang]}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3">{orphansFaqs[0].answer[lang]}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{orphansFaqs[1].question[lang]}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3">{orphansFaqs[1].answer[lang]}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{orphansFaqs[2].question[lang]}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3">{orphansFaqs[2].answer[lang]}</p>
            </div>
          </div>
          <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
            {orphansFaqs.map((item, index) => {
              return (
                index > 2 && (
                  <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                    <Accordion key={`keyallfaq${index}`} shadow title={item.question[lang]} index={index} level={1} noBorder={true}>
                      {item.answer[lang]}
                    </Accordion>
                  </div>
                )
              );
            })}
          </div>
        </section>
        {/* Scroll more end */}
        <div id="scroll-more-end" ref={endRef}></div>
      </main>
      {showScrollMore && (
        <>
          <div
            className="fixed xs:bottom-12 md:bottom-0 left-1/2 transform -translate-x-1/2 w-full text-center text-[#14A2DC] text-[14px] font-medium py-2"
            style={{ zIndex: 999, background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0.01%, #FFFFFF 100%)' }}
          >
            <div className="text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            scroll more
          </div>
        </>
      )}
    </NewHelmetLayout>
  );
};

export default OrphansUat;
