import { useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ShowCurrencies from '../../../utils/showCurrencies';
import CustomButton from '../../../components/general/button';
import { useNavigate } from 'react-router-dom';
import IsGTMScriptLoaded from '../../../utils/isGTMScriptLoaded';
import { generateLinkWithLang } from '../../../utils/linkGenerator';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import {checkDomain} from "../../../utils/checkDomain";

const LimbsProductBox = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const navigate = useNavigate();
    const pRef = useRef(null);
    const [addProduct] = useHandleAddProduct();
    const { product, currency, arabic = false, buttonLable, linkBtnClasses, view = 'list', showDescription = false } = props;
    const [isAnyAmount, setIsAnyAmount] = useState(false);
    const [showMore, setShowMore] = useState(false);
    // const [isOverflow, setIsOverflow] = useState(false);
    const { i18n } = useTranslation();
    const lang = i18n.language;
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleCustomProductAmount = (product) => {
        if (
            Number(process.env.REACT_APP_MAKE_DONATION_CATEGORY_ID) === Number(product.category_id) ||
            product.id === 52 ||
            product.id === 69
        ) {
            setIsAnyAmount(true);
        } else {
            // if (Number(product[currency.toLowerCase()]) === 1) {
            let hasQuantity = product.quantity
                ? product.quantity > 1
                    ? true
                    : false
                : false
            // if (Number(product[currency.toLowerCase()]) < 6 && !hasQuantity) {
            if (Number(product.aud) < 2 && !hasQuantity) {
                setIsAnyAmount(true);
            } else {
                setIsAnyAmount(false);
            }
        }
    }

    const handleViewItemDataLayer = (tempProduct, currency) => {
        // console.log("datalayer view item", tempProduct)
        let dataLayerTemp = {
            event: 'view_item',
            ecommerce: {
                currency: currency.toUpperCase(),
                items: [{
                    item_id: tempProduct.creator,
                    item_name: tempProduct.name,
                    item_brand: tempProduct.category || "",
                    item_category: tempProduct.category || "",
                    item_description: tempProduct.description || '',
                    price: tempProduct[currency.toLowerCase()],
                }]
            }
        }
        window.dataLayer = window.dataLayer || [];
        // console.log("datalayer view item=", tempProduct, dataLayerTemp, window.dataLayer)
        IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);

        let obj = {
            content_type: 'product',
            content_id: tempProduct.creator,
            currency: currency,
            value: tempProduct[currency.toLowerCase()],
            price: tempProduct[currency.toLowerCase()],
            quantity: 1,
            content_category: tempProduct.category ? tempProduct.category : 'donation',
            content_name: tempProduct.name,
            description: tempProduct.description,
        }
        // window.ttq = window.ttq || [];
        if (!(checkDomain('matwprojectme.org') || checkDomain('matwproject.org'))) {
            window.ttq && window.ttq.track('ViewContent', {
                ...obj
            });
        }

    }
    const handleHeight = () => {
        setShowMore(!showMore);
    };
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        product && handleCustomProductAmount(product)
        // product && showDescription && pRef.current && pRef.current.clientHeight > 30 && setIsOverflow(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);
    /* -------------------------------------------------------------------------- */
    return (
        <>
            {view === 'list'
                ? <div className="basis-full min-[450px]:basis-8/12 md:basis-9/12 lg:basis-10/12 flex flex-col items-center rounded-md shadow-md bg-white p-2">
                    <div className={`w-full flex flex-row items-start md:flex-wrap md:flex-col mt-2 sm:mt-0`}>
                        <div
                        onClick={() => navigate(generateLinkWithLang(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang))}
                        className='w-[60px] min-w-[60px] md:w-full rounded-md sm:flex sm:mr-2 justify-center items-center mr-1 md:mb-2 [&>span]:w-full overflow-hidden'>
                            {product ? product.image_link
                                ? <LazyLoadImage
                                    alt={product.name}
                                    effect="blur"
                                    width={`100%`}
                                    delayTime={500}
                                    className='rounded cursor-pointer'
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "/images/general/logo-replace.png";
                                    }}
                                    src={product.image_link} // use normal <img> attributes as props
                                />
                                : <img className='w-full' src="/images/general/logo-replace.png" alt="" />
                                : <Skeleton width={`100%`} height={123} />
                            }
                        </div>
                        <div className="w-full pl-1 md:pl-0">
                            <h3 className="text-[#777] block- text-lg md:text-base md:justify-center md:text-center md:min-h-[30px] lg:min-h-[48px] flex items-center md:w-full [&>span]:w-full mb-2">
                                {product ? product.alternativeName || product.name : <Skeleton width={`100%`} height={30} />}
                            </h3>
                            {showDescription && <p ref={pRef} className={`text-sm md:text-xs text-[#888] overflow-hidden mb-2 ${showMore ? 'h-auto' : 'md:h-[50px]'}`}>{product.description}</p>}
                            {showDescription && <ChevronDownIcon
                                onClick={handleHeight}
                                className={`hidden md:block w-6 h-6 text-[#00a3da] transition-all duration-500 cursor-pointer mx-auto ${showMore ? 'transform rotate-180' : ''
                                    }`}
                            />}
                        </div>
                    </div>
                    <div className="w-full flex md:flex-col sm:justify-between items-center justify-end">
                        <h2 className={`text-[#f60362] text-lg text-center font-bold mr-2 sm:mr-0`}>
                            {product
                                ? isAnyAmount
                                    ? 'Any Amount'
                                    : <>{product.quantity
                                        ? ShowCurrencies(currency, product.quantity * product[currency.toLowerCase()], true)
                                        : ShowCurrencies(currency, product[currency.toLowerCase()], true)
                                    }</>
                                : <Skeleton width={`100%`} height={20} />}
                        </h2>
                        <CustomButton
                            onClick={() => { addProduct({ product: product, currency: currency }) }}
                            title={buttonLable ? buttonLable : arabic ? 'تبرع' : 'Donate'}
                            className={`${linkBtnClasses ? linkBtnClasses : ''} ${arabic ? 'font-[AlmariMedium]' : ''} !h-8 md:!h-10 !text-xs`} />
                    </div>
                </div>
                : <div className="w-full p-1 md:p-2 md:pb-5 rounded-xl shadow-lg bg-white flex flex-col">
                    <div className="overflow-hidden img-hover-zoom rounded-md w-full tooltip [&>span]:!block"
                        onClick={() => navigate(generateLinkWithLang(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang))}>
                        {product ? product.image_link
                            ? <LazyLoadImage
                                alt={product.alt ? product.alt : product.name}
                                effect="blur"
                                width={`100%`}
                                delayTime={500}
                                afterLoad={() => {
                                    handleViewItemDataLayer(product, currency)
                                }}
                                className='rounded cursor-pointer block'
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/images/general/logo-replace.png";
                                }}
                                src={product.image_link} // use normal <img> attributes as props
                            />
                            : <img className='w-full' src="/images/general/logo-replace.png" alt="" />
                            : <Skeleton height={344} />
                        }
                    </div>
                    <p className={`text-[#777] cursor-pointer flex justify-center items-center ${view === 'grid' ? 'text-[11px] [&_*]:text-[10px] min-h-[50px] min-[400px]:min-h-[40px]' : 'text-xs'} sm:text-base text-center min-h-[26px] sm:min-h-[40px] lg:h-[56px] my-2 uppercase ${arabic ? 'font-[AlmariMedium] font-bold' : 'font-medium'}`}
                        onClick={() => navigate(generateLinkWithLang(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang))}>
                        {product ? product.alternativeName || product.name : <Skeleton height={56} />}
                    </p>
                    {showDescription && <p className={`text-xs text-[#666] text-center overflow-hidden mb-2 ${showMore ? 'h-auto' : 'h-[50px]'}`}>{product.description}</p>}
                    {showDescription && <ChevronDownIcon
                        onClick={handleHeight}
                        className={`md:block w-6 h-6 text-[#00a3da] transition-all duration-500 cursor-pointer mx-auto ${showMore ? 'transform rotate-180' : ''
                            }`}
                    />}
                    <div className="grid grid-cols-6 gap-1 2xl:gap-2">
                        {product ? <div className={`text-[#f60362] col-span-6 ${view === 'grid' ? 'text-sm' : 'text-sm'} md:text-lg text-center font-bold ${arabic ? 'font-[AlmariMedium]' : ''}`}>
                            {isAnyAmount
                                ? arabic ? 'أي مبلغ' : 'Any Amount'
                                : <>{product.quantity
                                    ? ShowCurrencies(currency, product.quantity * product[currency.toLowerCase()], true)
                                    : ShowCurrencies(currency, product[currency.toLowerCase()], true)
                                }</>
                            }
                        </div> : <div className='col-span-6'><Skeleton /></div>}
                        <div className='flex justify-center col-span-6'>
                            {product
                                ? <CustomButton
                                    onClick={() => { addProduct({ product: product, currency: currency }) }}
                                    title={buttonLable ? buttonLable : arabic ? 'تبرع' : 'Donate'}
                                    className={`${linkBtnClasses ? linkBtnClasses : ''} ${arabic ? 'font-[AlmariMedium]' : ''} !h-6 md:!h-10 !text-[10px] md:!text-sm !px-2`} />
                                : <Skeleton height={43} />
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default LimbsProductBox;