export const BASKET_CACHE_KEY = 'BASKET_CACHE_KEY';
const HandleLocalStorage = (basketStates, reset = false) => {
  // console.log('==basket HandleLocalStorage', basketStates);
  if (reset) {
    HandleResetLocalStorage(basketStates);
  } else {
    if (basketStates) {
      let tempTotal = Number(basketStates.adminCost);
      basketStates.products.map(item => (tempTotal = tempTotal + item.total));
      basketStates.grandTotal = tempTotal;
      localStorage.setItem(BASKET_CACHE_KEY, JSON.stringify(basketStates));
      // localStorage.setItem('IS_DAILY_DONATION', JSON.stringify(false));
    }
  }
};
const IsExpired = basketStates => {
  let now = Date.now();
  if (basketStates) {
    if (!basketStates.timestamp || basketStates.timestamp < now) {
      HandleResetLocalStorage(basketStates);
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
const HandleResetLocalStorage = basketStates => {
  // console.log('on reset local=', basketStates);
  const initialState = {
    timestamp: Date.now() + 24 * 60 * 60 * 7 * 1000,
    stripe_customer_id: '',
    currency: basketStates ? basketStates.currency : 'AUD',
    mode: 'payment',
    adminCost: 0,
    grandTotal: 0,
    recurring: 'one-off',
    paymentFlow: '',
    donor: {
      name: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      postal_code: '',
      countryCode: '',
      country: '',
    },
    user: {
      currency: basketStates ? basketStates.currency : 'AUD',
      email: '',
      id: '',
      name: '',
      phone: '',
      plaque_name: '',
    },
    products: [
      {
        product: null,
        quantity: 1,
        amount: 0,
        total: 0,
        currency: basketStates ? basketStates.currency : 'AUD',
        recurring: 'one-off',
        onBehalf: 'For the sake of Allah SWT',
      },
    ],
  };
  // localStorage.setItem('IS_DAILY_DONATION', JSON.stringify(false));
  localStorage.setItem(BASKET_CACHE_KEY, JSON.stringify(initialState));
};

export { HandleLocalStorage, IsExpired, HandleResetLocalStorage };
