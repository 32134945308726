import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Loader } from '../../components/general';
import { sadaqahJariyahFaqs } from './faqs';
import services from '../../services';
import Accordionn from '../../components/general/accordion';
import SadaqahWidget from './sadaqahWidget';
// import ChangeDocumentTitle from '../../utils/changeDocumentTitle';
import ProductBox from '../../components/general/productBox';
import FundraiserProductBox from '../../components/general/fundraiserProductBox';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const OrphanagesLandingPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [futureProducts, setFutureProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [products, setProducts] = useState({
        name: '',
        products: [0]
    });
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getFundraiserProducts = async () => {
        let tempFutureProducts = [];
        try {
            const response = await services.fundraiserProducts();
            response.data.map(item => {
                item.raised = 0;
                if (item.name === 'Build A Classroom') {
                    item.goal = 200000;
                    tempFutureProducts.push(item)
                }
                return item;
            });
            // getSupportOrphanProduct(tempFutureProducts);
            getProductsByName('support an orphan', tempFutureProducts);
        } catch (error) {
            console.error('error========', error);
        }
    };
    // const getSupportOrphanProduct = async (tempFutureProduct) => {
    //     let tempFutureProducts = tempFutureProduct;
    //     try {
    //         const response = await services.categoryProductsByName('Orphans');
    //         response.data.products.map(item => {
    //             item.raised = 0;
    //             item.goal = 200000;
    //             if (item.name === 'Support an Orphan Centre') tempFutureProducts.push(item);
    //             return item;
    //         });
    //         getProductsByName('support an orphan', tempFutureProducts);
    //     } catch (error) {
    //         console.error('error========', error);
    //     }
    // };
    const getProductsByName = async (name, tempFutureProduct) => {
        let tempFutureProducts = tempFutureProduct;
        try {
            const response = await services.categoryProductsByName(name);
            let tempOrphanProducts = [];
            let tempAllProducts = response.data;
            response.data.products.map(item => {
                if (Number(item.status) === 1) {
                    item.raised = 0;
                    item.goal = 200000;
                    if (item.name === 'Orphanage House') tempOrphanProducts.push(item);
                    // if (item.name === 'Orphanage House') tempFutureProducts.push(item);
                    if (item.name === 'Orphan Centre Lebanon') tempFutureProducts.push(item);
                    if (item.name === 'HealthGuard Orphans') tempFutureProducts.push(item);
                    if (item.name === 'Nurturing Futures') tempFutureProducts.push(item);
                }
                return item;
            });
            tempAllProducts.products = tempOrphanProducts;
            setProducts(tempAllProducts);
            // ChangeDocumentTitle(`${response.data.name} - ${response.data.category ? response.data.category.name : ''} - MATW Project Muslim Charity`);
            getOrphanProductsReports(tempFutureProducts);
        } catch (error) {
            console.error('error========', error);
        }
    };
    const getOrphanProductsReports = async (tempFutureProducts) => {
        try {
            const response = await services.orphanProductTransactions();
            response.data.length && response.data.map((item, i) => {
                tempFutureProducts.map(future_item => {
                    if (future_item.name === item.product_name) {
                        future_item.raised = item.total_sales
                    }
                    return future_item
                })
                return null;
            })
            // Object.keys(response.data.data).length && Object.keys(response.data.data).map((keyName, i) => {
            //     tempFutureProducts.map(future_item => {
            //         if (future_item.name === keyName) {
            //             future_item.raised = response.data.data[keyName]
            //         }
            //         return future_item
            //     })
            //     return null;
            // })
            setFutureProducts(tempFutureProducts)
        } catch (error) {
            console.error('error========', error);
        }
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        // window.scroll(0, 0);
        getFundraiserProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <NewHelmetLayout className='!bg-white'>
            {seo &&
                <Helmet>
                    <title>{`${seo['sadaqahJariyah']['orphans']['title']}`}</title>
                    <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
                    <meta name="description" content={`${seo['sadaqahJariyah']['orphans']['description']}`}/>

          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
            }
            <div className="w-full flex flex-wrap max-[380px]:min-h-[135px] min-[381px]:min-h-[165px] min-[501px]:min-h-[205px] sm:min-h-[260px] lg:min-h-[400px] xl:min-h-[500px] 2xl:min-h-[600px] px-4 md:px-0">
                <div className="w-full h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                    <iframe className='video'
                        width={'100%'}
                        height={'100%'}
                        title={'youtube'}
                        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                        src={`https://youtube.com/embed/eiqJowZoLBc?autoplay=0`}
                    >
                    </iframe>
                </div>
            </div>

            {/* /* ------------------------------- Widget ----------------------------------- */}
            <div className='w-full lg:container lg:mx-auto px-4 xl:px-2 py-4 md:py-6 flex justify-center items-center'>
                <SadaqahWidget
                    defaultProduct={{
                        creator: "MKD-SDQ-OAW-2023-02-119",
                        label: "Support an Orphan Centre",
                        value: 324
                    }} />
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------- Section One - Texts ---------------------------------- */}
            <div className="w-full flex flex-wrap justify-center md:container md:mx-auto px-4 py-4 md:py-8">
                <div className="basis-full flex flex-col justify-center mb-8 md:mb-16">
                    <div className="w-full md:container flex justify-center">
                        <div className="flex items-start mr-2 md:mr-4 w-[48px]">
                            <svg className='rotate-180' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 186.15">
                                <path d="M242.5,87.53c-1.52,7.1-2.55,14.35-4.63,21.27-10.7,35.68-35.15,57.79-69.71,69.59-5,1.71-10.16,2.82-15.56,4.28l-9.89-21.3c24.63-11.76,37.24-31.54,40.19-58.79H142.83V2.5H242.5Z" transform="translate(0 0)" fill='none' stroke='#00a3da' strokeWidth={4} />
                                <path d="M2.5,2.5h99.14c.39.75.67,1,.66,1.31-.22,28.16,1,56.43-1,84.46-3,41-25.34,69.46-62.62,86.09C30.52,178,21.75,180.14,13,183.08L2.5,161.56v-1a16.11,16.11,0,0,0,2.8-.76c18.55-8.7,29.5-23.71,35.12-42.94,1.31-4.48,1.63-9.25,2.48-14.32H2.5Z" transform="translate(0 0)" fill='none' stroke='#00a3da' strokeWidth={4} />
                            </svg>
                        </div>
                        <div className="flex w-[80%] md:w-[60%] xl:w-[40%]">
                            <p className="text-center text-lg md:text-4xl text-[#00a3da]">
                                Have you seen the one who denies the Recompense [the religion]? For that is the one who drives away the
                                <span className="font-['Gotcha'] font-medium text-[#253b7e] md:text-5xl mx-2 md:mx-4">
                                    orphan.
                                </span>
                                <span className="font-['Abeezee'] text-[#253b7e] mx-2 md:mx-4 text-base md:text-lg">
                                    ~ Al Ma’un: 1-2
                                </span>
                            </p>
                        </div>
                        <div className="flex items-end ml-2 md:ml-4 w-12">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 186.15">
                                <path d="M242.5,87.53c-1.52,7.1-2.55,14.35-4.63,21.27-10.7,35.68-35.15,57.79-69.71,69.59-5,1.71-10.16,2.82-15.56,4.28l-9.89-21.3c24.63-11.76,37.24-31.54,40.19-58.79H142.83V2.5H242.5Z" transform="translate(0 0)" fill='none' stroke='#00a3da' strokeWidth={4} />
                                <path d="M2.5,2.5h99.14c.39.75.67,1,.66,1.31-.22,28.16,1,56.43-1,84.46-3,41-25.34,69.46-62.62,86.09C30.52,178,21.75,180.14,13,183.08L2.5,161.56v-1a16.11,16.11,0,0,0,2.8-.76c18.55-8.7,29.5-23.71,35.12-42.94,1.31-4.48,1.63-9.25,2.48-14.32H2.5Z" transform="translate(0 0)" fill='none' stroke='#00a3da' strokeWidth={4} />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------- Section Two - Products ------------------------------- */}
            <div className="w-full px-4 py-8 md:pb-16 md:pt-32 bg-[#e2f0f3]">
                <div className="w-full flex flex-wrap justify-center md:container md:mx-auto">
                    {products.products.map((item, index) => {
                        return <div key={`products.products${index}`} className="basis-full min-[400px]:basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                            <ProductBox product={item} currency={selectedCurrency} />
                        </div>
                    })
                    }
                    <div className="flex w-full justify-center mt-4 md:mt-8">
                        <h1 className="text-2xl md:text-5xl w-full md:w-8/12 text-[#253B7E] text-center font-medium mb-2 md:mb-4">
                            Globally, there are over <strong className='text-[#f60362]'>153 million orphaned</strong> children.
                        </h1>
                    </div>
                    <div className="basis-full flex justify-center">
                        <p className='w-full md:w-8/12 text-center my-3 md:my-6 text-lg md:text-xl'>
                            According to UNICEF, almost 5700 children become orphans every day due to war,
                            natural disasters, poverty, disease, stigma, and medical needs. Whatever the cause,
                            many of these children are lost and alone. They need support.
                        </p>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------- Section Three - Products ----------------------------- */}
            <div className="w-full px-4 py-8 md:pb-16 md:pt-32 bg-[#f1f1f1]">
                <div className="w-full flex flex-wrap justify-center md:container md:mx-auto">
                    <div className="flex w-full justify-center">
                        <h1 className="text-2xl md:text-5xl w-full md:w-7/12 text-[#253B7E] text-center font-medium mb-2 md:mb-4">
                            Together, lets raise funds to build brigther <strong className='text-[#f60362]'>Future.</strong>
                        </h1>
                    </div>
                    {products
                        ? <div className="flex w-full flex-wrap justify-center mt-4 md:mt-6">{futureProducts.map((item, index) => {
                            return <div key={`fundrising${index}`} className="basis-full min-[400px]:basis-1/2 md:basis-1/3 xl:basis-1/4 p-2 overflow-hidden">
                                <FundraiserProductBox goal={item.goal} subTitle='Orphan Education' product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                            </div>
                        })}</div>
                        : <Loader />
                    }
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------- Section Four - FAQ --------------------------- */}
            <div className='w-full h-auto flex flex-col justify-center items-center bg-[#E2EFF3]- py-16'>
                <h2 className="text-2xl md:text-4xl font-medium text-center mt-8 mb-4 text-[#253B7E]">Orphanages donations <strong className='text-[#f60362]'>FAQs</strong></h2>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center px-2 md:px-0 mt-6">
                    {sadaqahJariyahFaqs.map((item, index) => {
                        return (
                            <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                <Accordionn key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordionn>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

        </NewHelmetLayout>
    );
}

export default OrphanagesLandingPage;
