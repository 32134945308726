import React, { useState, useEffect } from 'react';
import SelectCurrency from '../general/selectCurrency';
import CustomButton from '../general/button';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { BASKET_CACHE_KEY } from '../../utils/handleLocalStorage';

function IftarDonationWidget({ products, arabic, buttonLable, _lang }) {
  const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY)) || {};
  const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  const [currentCurrency, setCurrentCurrency] = useState('USD');
  const [topListState, setTopListState] = useState([]);
  const [columns, setColumns] = useState([]);
  const { TopList = [], BottomList = [] } = products;

  // State for total amount
  const [totalAmount, setTotalAmount] = useState(0);

  // State for quantities (for items in TopList and BottomList)
  const [quantities, setQuantities] = useState(() => {
    const initialQuantities = {};
    [...TopList, ...BottomList].forEach(item => {
      initialQuantities[item.id] = { quantity: 0, selected: false };
    });
    return initialQuantities;
  });

  const handleAddQty = (prd, index) => {
    let tempPrd = Object.assign({}, prd, {
      quantity: 1,
    });
    addProduct({ product: tempPrd, currency: currentCurrency, justAddToLocalStorage: true });
    setQuantities(prev => ({
      ...prev,
      [prd.id]: {
        ...prev[prd.id],
        quantity: (prev[prd.id]?.quantity || 0) + 1,
        selected: true,
      },
    }));
    handleSelectedGiving();
  };
  const handleReduceQty = (prd, index) => {
    const currentQty = quantities[prd.id]?.quantity || 0;
    if (currentQty > 1) {
      let tempPrd = { ...prd, quantity: -1 };
      editProduct({ product: tempPrd, currency: currentCurrency, justAddToLocalStorage: true });
      setQuantities(prev => ({
        ...prev,
        [prd.id]: {
          ...prev[prd.id],
          quantity: currentQty - 1,
        },
      }));
      handleSelectedGiving();
    } else {
      handleRemoveProduct(prd);
      setQuantities(prev => {
        const updated = { ...prev };
        delete updated[prd.id];
        return updated;
      });
    }
  };
  const handleRemoveProduct = prd => {
    removeProduct(prd);
    handleSelectedGiving();
  };
  const handleSelectedGiving = () => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let temp = [];

    topListState.map((item, index) => {
      basketStatesFromLocalStorage &&
        basketStatesFromLocalStorage.products.map(innerItem => {
          if (innerItem.product && innerItem.product.creator === item.creator) {
            item = Object.assign({}, item, {
              isAdded: true,
              quantity: innerItem.quantity,
            });
          }
          return innerItem;
        });
      temp[index] = item;
      return item;
    });
    setTopListState(temp);
  };

  const handleCheckboxChange = prd => {
    setQuantities(prevQuantities => {
      const newQuantities = { ...prevQuantities };
      const isSelected = !newQuantities[prd.id]?.selected;

      if (isSelected) {
        // Add the product
        let tempPrd = { ...prd, quantity: 1 };
        addProduct({ product: tempPrd, currency: currentCurrency, justAddToLocalStorage: true });

        newQuantities[prd.id] = {
          quantity: 1,
          selected: true,
        };
      } else {
        // Remove the product
        handleRemoveProduct(prd);
        newQuantities[prd.id] = {
          quantity: 0,
          selected: false,
        };
      }

      return newQuantities;
    });
  };

  // Handle currency change
  const handleCurrency = currency => {
    setCurrentCurrency(currency.value);
  };

  useEffect(() => {
    // Set the TopList in the state
    setTopListState(TopList);
  }, [TopList, currentCurrency]);

  useEffect(() => {
    // Split the TopList into two columns when topListState changes
    const half = Math.ceil(topListState.length / 2);
    setColumns([topListState.slice(0, half), topListState.slice(half)]);
  }, [topListState]);

  useEffect(() => {
    setTotalAmount(basketStatesFromLocalStorage.grandTotal);
  }, [handleCheckboxChange, handleSelectedGiving]);

  return (
    <div className="min-h-screen bg-[#253B7E] flex flex-col items-center justify-center p-0 md:p-4">
      <h1 className="text-4xl font-bold text-white mb-8">Stand with MATW in ending poverty.</h1>
      <div className="bg-white shadow-lg p-1 md:p-4 w-[480px] min-h-[449px] max-w-full mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-primaryDark mb-4">Tomorrow isn’t promised. Don’t wait for Ramadan, give now.</h2>
          {/* TopList: Two-column grid */}
          <div className="grid grid-cols-2 gap-4">
            {columns.map((column, colIndex) => (
              <div key={colIndex} className="space-y-3">
                {column.map(item => {
                  const quantityValue = quantities[item.id]?.quantity || 0;
                  const price = item[currentCurrency] || item.usd || 0;

                  return (
                    <div
                      key={item.id}
                      className={`border rounded-full h-[30px] flex items-center px-1 shadow-md ${quantityValue > 0 ? 'bg-primaryMain' : ''}`}
                    >
                      <div className="flex items-center w-full">
                      <div className="flex items-center justify-center basis-[10%]">
                      <input
                        type="checkbox"
                        className="w-3 h-3 border rounded-sm appearance-none
                        checked:border-white checked:bg-primaryMain
                        checked:after:content-['✓']
                        checked:after:text-white checked:after:flex
                        checked:after:justify-center checked:after:items-center
                        checked:after:absolute checked:after:inset-0
                        checked:after:text-[0.65rem]
                        border-black bg-white relative"
                        checked={quantities[item.id]?.selected || false}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    </div>


                        <div className="flex flex-auto basis-[45%]">
                          <h3
                            className={`font-brandingBold text-[10px] ${
                              quantityValue > 0 ? 'text-white' : 'text-primaryDark'
                            } leading-[12px] text-left decoration-[1px]`}
                          >
                            {item.name}
                          </h3>
                        </div>
                        <div className="flex items-center justify-center basis-[30%]">
                          <p
                            className={`font-brandingBold text-[10px] ${
                              quantityValue > 0 ? 'text-white' : 'text-primaryDark'
                            } leading-[12px] text-center decoration-[1px]`}
                          >
                            ${price} {currentCurrency.toUpperCase()}
                          </p>
                        </div>
                        <div className="flex items-center justify-center basis-[15%]">
                          {quantityValue > 0 && (
                            <div className="flex items-center">
                              <button className="text-white text-[12px] font-bold text-lg px-2" onClick={() => handleReduceQty(item, colIndex)}>
                                -
                              </button>
                              <span className="text-[8px] text-white border border-white rounded-full px-1 py-0.5">{quantityValue}</span>
                              <button className="text-white text-[12px] font-bold text-lg px-2" onClick={() => handleAddQty(item, colIndex)}>
                                +
                              </button>
                            </div>
                          )}
                          {quantityValue === 0 && (
                            <button
                              className="bg-pink text-white rounded-full hover:bg-primaryDark text-xs w-[30px] h-[20px]"
                              onClick={() => handleAddQty(item, colIndex)}
                            >
                              <span className="font-brandingBold text-[8px] font-semibold">Add</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>

          {/* BottomList */}
          <div className="flex py-7">
            <div className="w-[65%]">
              {BottomList.map(item => {
                const price = item[currentCurrency] || item.usd || 0;

                return (
                  <div key={item.id} className="flex items-center">
                    <div className="flex items-center justify-center basis-[10%]">
                      <input
                        type="checkbox"
                        className="w-3 h-3 accent-pink"
                        checked={quantities[item.id]?.selected || false}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    </div>
                    <div className="flex">
                      <h3 className="font-branding text-[12px] font-medium text-left decoration-[1px] text-textPrimary">{item.name}</h3>
                    </div>
                    <div className="flex items-center justify-center ml-1">
                      <p className="font-branding text-[12px] text-left decoration-[1px] font-bold text-pink">
                        ${price} {currentCurrency.toUpperCase()}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="w-[35%] flex justify-end">
              <div className="rounded-lg bg-pink bg-opacity-30 flex flex-col items-center justify-center h-[79px] w-[112px] mt-4">
                <p className="text-textPrimary font-bold text-[12px]">Total Amount</p>
                <p className="text-pink text-sm mt-1 text-[16px] font-bold">${totalAmount}</p>
              </div>
            </div>
          </div>

          <div className="flex py-7">
            <div className="w-[30%]">
              <SelectCurrency onChangeCurrency={e => handleCurrency(e)} classNamePrefix="home_select" />
            </div>
            <div className="w-[70%] flex justify-end">
              <CustomButton
                onClick={() => {
                  const selectedProducts = [...TopList, ...BottomList].filter(item => quantities[item.id]?.selected);
                }}
                title={
                  buttonLable
                    ? buttonLable
                    : arabic || _lang === 'ar'
                    ? 'تبرع الان'
                    : _lang === 'fr'
                    ? 'Faites un don maintenant'
                    : _lang === 'es'
                    ? 'Dona ahora'
                    : 'Donate Now'
                }
                link={'/checkout'}
                className="bg-pink w-[90%] text-white rounded-full hover:bg-primaryDark text-xs w-[30px] h-[20px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IftarDonationWidget;