import { Builder } from '@builder.io/react';
import CustomButton from '../../../../general/button';

const AppealImpact = ({ 
  lang = 'en', 
  buttonClassname = '', 
  usedOnTab = false,
  impactItems,
  impactReportUrl = 'https://matwproject.org/pdf/Palestine%20Impact%20Report%20-%202023-24.pdf',
  sectionPadding = 'py-4 md:py-12',
  titleText = 'Our Latest Impact'
}) => {
  return (
    <section className={`md:container md:mx-auto px-4 md:px-0 ${sectionPadding}`} dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <h2 className={`text-center mb-2 md:mb-4 ${usedOnTab ? 'text-lg md:text-3xl' : 'text-2xl md:text-4xl'} ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-[#253B7E]`}>
        {titleText}
      </h2>
      <div className="w-full grid grid-cols-4 gap-2 md:gap-4">
        {impactItems.map((item, index) => (
          <div
            key={`impacts${index}`}
            className={`col-span-2 ${usedOnTab ? 'md:col-span-1' : 'md:col-span-2'} flex flex-col items-center rounded-lg shadow-lg py-4 md:py-8`}
          >
            <img src={item.img} alt={item.title} className={usedOnTab ? 'w-1/5' : 'auto'} />
            <div className="flex flex-col items-center text-center mt-2">
              <h4 className={`md:text-2xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-[#253B7E]`}>
                {item.count}
              </h4>
              <small className={`text-xs md:text-base ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} uppercase text-[#78716C]`}>
                {item.title}
              </small>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full flex justify-center mt-8">
        <CustomButton
          link={impactReportUrl}
          target="_blank"
          title={'Download Impact Report'}
          className={`!rounded-full ${buttonClassname}`}
        />
      </div>
    </section>
  );
};

// Register as a Builder.io custom component
Builder.registerComponent(AppealImpact, {
  name: 'AppealImpact',
  inputs: [
    {
      name: 'lang',
      type: 'string',
      enum: ['en', 'ar'],
      defaultValue: 'en',
      helperText: 'Choose the language (English or Arabic)',
    },
    {
      name: 'usedOnTab',
      type: 'boolean',
      defaultValue: false,
      helperText: 'Enable if the component is used inside a tab',
    },
    {
      name: 'buttonClassname',
      type: 'string',
      helperText: 'CSS classes for the button',
    },
    {
      name: 'impactItems',
      type: 'list',
      subFields: [
        { name: 'title', type: 'string', required: true, helperText: 'Impact category title' },
        { name: 'img', type: 'file', allowedFileTypes: ['jpeg', 'png', 'svg'], required: true, helperText: 'Impact category image' },
        { name: 'count', type: 'string', required: true, helperText: 'Impact category number' },
      ],
      defaultValue: [],
      helperText: 'List of impact categories',
    },
    {
      name: 'impactReportUrl',
      type: 'url',
      defaultValue: 'https://matwproject.org/pdf/Palestine%20Impact%20Report%20-%202023-24.pdf',
      helperText: 'URL for the impact report download',
    },
    {
      name: 'sectionPadding',
      type: 'string',
      defaultValue: 'py-8 md:py-12',
      helperText: 'Padding configuration for section',
    },
    {
      name: 'titleText',
      type: 'string',
      defaultValue: 'Our Latest Impact',
      helperText: 'Section heading text',
    },
  ],
});

export default AppealImpact;
